import React, { Component } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';
import apiStringServer from '../services/apiStringServer';
import apiCEP from '../services/apiCEP';

import { DataGrid, ptBR } from '@mui/x-data-grid';
import See from '@material-ui/icons/Visibility';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import AttachFile from '@material-ui/icons/AttachFile';

import InputMask from 'react-input-mask';

import theme from '../themes/theme';
import { ThemeProvider } from '@material-ui/core';

import Header from '../components/Header';
import FooterHome from '../components/FooterHome';

import polygonCoords_SJMeriti from './utils/polygonSJMeriti';
import polygonCoords_Comendador from './utils/polygonComendador';
import polygonCoords_Paraty from './utils/polygonParaty';

import polygonCoords_Rio from './utils/polygonRio';
import polygonCoords_Rio1 from './utils/polygonRio1';
import polygonCoords_Rio2 from './utils/polygonRio2';
import polygonCoords_Rio21 from './utils/polygonRio21';
import polygonCoords_Rio3 from './utils/polygonRio3';
import polygonCoords_Rio31 from './utils/polygonRio31';

import { Map, GoogleApiWrapper, Marker, Polygon } from 'google-maps-react';
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0");
Geocode.setLanguage("pt-BR");

let polygonCoords = [
  {lat: -22.990737, lng: -43.420790},
  {lat: -22.991277, lng: -43.423958}
];

let selected_city = '';

let columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'name',
    headerName: 'Nome',
    width: 400,
    editable: false,
    renderHeader: () => (
      <strong>
        {'Nome '}
        <span role="img" aria-label="enjoy">
          
        </span>
      </strong>
    ),
  },
  {
    field: 'user_name',
    headerName: 'Usuário',
    width: 200,
    editable: false,
    renderHeader: () => (
      <strong>
        {'Usuário responsável '}
        <span role="img" aria-label="enjoy">
          👤
        </span>
      </strong>
    ),
  },
  {
    field: 'address',
    headerName: 'Endereço',
    width: 330,
    editable: false,
    renderHeader: () => (
      <strong>
        {'Endereço '}
        <span role="img" aria-label="enjoy">
          🏠
        </span>
      </strong>
    ),
  },
  {
    field: 'area',
    headerName: 'Área',
    width: 120,
    editable: false,
    renderHeader: () => (
      <strong>
        {'Área '}
        <span role="img" aria-label="enjoy">
          🗺️
        </span>
      </strong>
    ),
  },
  {
    field: 'description',
    headerName: 'Descrição',
    width: 330,
    editable: false,
    renderHeader: () => (
      <strong>
        {'Descrição '}
        <span role="img" aria-label="enjoy">
          📜
        </span>
      </strong>
    ),
  },
  {
    field: 'actions',
    headerName: 'Ações',
    renderCell: () => (
      <Actions />
    ),
    sortable: false,
    width: 100,
    headerAlign: 'center',
    filterable: false,
    align: 'center',
    disableColumnMenu: true,
    disableReorder: true,
  },
];

let rows = [];

let rowsArray = [];
let rowArray = [];

let cpf_cnpj = '';

let lat = 0;
let lng = 0;

export class Actions extends Component {

  state = {
    openDialog: false,
    openDialog2: false,
    status: '1',

    area_id: '',
    area_name: '',
    cepMask: '99999-999',
    cep: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    description: '',

    documents: [],
    file: null,
    document_id: '',
    document_name: '',

    open_error: false,
    open_error2: false,
    open: false,
    msg: '',

    page_rows: [],
  }

  handleEdit = async () => {
    setTimeout(async () => {
      this.setState({ openDialog: true });
      await this.setState({ area_id: rowArray[0].id });
      let area_name = rowArray[0].name.toString().split('[').join('');
      area_name = area_name.toString().split(']').join('');
      area_name = area_name.toString().split('📝').join('');
      this.setState({ area_name });
      this.setState({ cep: rowArray[0].cep });
      this.setState({ street: rowArray[0].street });
      this.setState({ number: rowArray[0].number });
      this.setState({ district: rowArray[0].district });
      this.setState({ city: rowArray[0].city });
      this.setState({ state: rowArray[0].state });
      this.setState({ description: rowArray[0].description });
      this.handleDocuments();
    }, 1000);
    setTimeout(() => { 
      this.setState({ cep: rowArray[0].cep }); 
      this.setState({ description: rowArray[0].description });
      rowArray.pop();
    }, 1000);
    //alert(JSON.stringify(rowsArray[0]));
  }

  handleDeleteDoc = async () => {

    try {
      let resp = await api.get('/deleteareadoc', {
        params: {
          document_id: this.state.document_id,
          document_name: this.state.document_name,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('deleted')) {

            this.handleDocuments();

          } else {
            let msg = JSON.stringify(resp.data);
            alert(msg);
          }
        }
      }
    } catch (err) {
      alert('Não foi possível buscar os dados da área. ' + err);
    }
  }

  handleCEP = async () => {

    try{
      let resp = await apiCEP.get(`/ws/${this.state.cep}/json/`);
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('cep')) {
            this.setState({ street: resp.data.logradouro });
            this.setState({ district: resp.data.bairro });
            this.setState({ city: resp.data.localidade });
            this.setState({ state: resp.data.uf });
            this.setState({ open_error2: false });
          } else {
            this.setState({ msg: 'CEP não encontrado.' });
            this.setState({ open_error2: true });
            this.setState({ open: false });
          }
        }
      }
    } catch(err) {
      this.setState({ street: '' });
      this.setState({ number: '' });
      this.setState({ district: '' });
      this.setState({ city: '' });
      this.setState({ state: '' });
      this.setState({ msg: 'CEP não encontrado.' });
      this.setState({ open_error2: true });
      this.setState({ open: false });
    }
  }

  handleDocuments = async () => {

    try {
      let resp = await api.get('/areadocuments', {
        params: {
          area_id: this.state.area_id,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('area_id')) {
            
            this.setState({ documents: resp.data });

          } else {
            this.setState({ documents: [] });
            let msg = JSON.stringify(resp.data);
            const finalmsg = msg.toString().split('"').join('');
            await this.setState({ msg: finalmsg });
            await this.setState({ open: false });
            if (this.state.msg == '[]') {
              this.setState({ open_error: false });
            } else {
              this.setState({ open_error: true });
            }
          }
        }
      }
    } catch (err) {
      console.error(err);
      await this.setState({ msg: 'Usuário incorreto. ' + err });
      await this.setState({ open: false });
      this.setState({ open_error: true });
    }

  }

  handleNewFile = async e => {
    await e.preventDefault();

    await this.setState({ file: e.target.files[0] });
    const fileSize = this.state.file.size;

    if (fileSize > 5000000) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.setState({ msg: 'Tamanho máximo permitido: 5 MB' });
      this.setState({ open_error: true });
      this.setState({ open: false });
    } else {
      this.setState({ fileName: this.state.file.name });

      const data = new FormData();
      data.append('area_id', this.state.area_id);
      data.append('selected_city', selected_city);
      data.append('document', this.state.file);

      try {
        let resp = await api.post('/storeareadocument', data);
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('registred')) {
              //documento salvo
              this.handleDocuments();
            } else {
              this.setState({ msg: resp.data });
              this.setState({ open: false });
              this.setState({ open_error: true });
            }
          }
        }
      } catch (err) {
        this.setState({ msg: 'Não foi possível anexar o documento. ' + err });
        this.setState({ open: false });
        this.setState({ open_error: true });
      }
    }
  }

  handleDelete = async () => {

    setTimeout(async () => {
      try {
        let resp = await api.get('/deletearea', {
          params: {
            area_id: this.state.area_id,
            selected_city: selected_city,
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('deleted')) {
              setTimeout(() => {
                this.setState({ msg: 'Área excluída com sucesso.' });
                alert(this.state.msg);
              }, 2000);
              window.location.reload();
            } else {
              this.setState({ msg: resp.data });
              alert(this.state.msg);
            }
          }
        }
      } catch (err) {
        this.setState({ msg: 'Não foi possível excluir a área. ' + err });
        alert(this.state.msg);
      }
    }, 2000);

  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <div>
        <IconButton
          color="inherit"
          size="small"
          aria-label="see"
          onClick={this.handleSee}
        >
          <See fontSize="small" />
        </IconButton>
        <IconButton
          color="inherit"
          size="small"
          aria-label="edit"
          onClick={this.handleEdit}
        >
          <Edit fontSize="small" />
        </IconButton>
        <IconButton
          color="inherit"
          size="small"
          aria-label="delete"
          onClick={() => this.setState({ openDialog2: true })}
        >
          <Delete fontSize="small" />
        </IconButton>

        <Dialog open={this.state.openDialog} onClose={() => this.setState({ openDialog: false })}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Alteração</DialogTitle>
          <DialogContent>
            <DialogContentText color="#fff">
              Preencha os campos que deseja alterar.
            </DialogContentText>

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              style={{ marginBottom: 17 }}
              name="area_name"
              id="area_name"
              label="Nome"
              placeholder="Digite um nome para a área."
              type="text"
              onChange={this.handleChange}
              value={this.state.area_name}
            />

            <InputMask
              {...this.props.value}
              mask={this.state.cepMask}
              fullWidth
              maskChar=" "
              name="cep"
              id="cep"
              type="text"
              onChange={this.handleChange}
              onBlur={this.handleCEP}
              value={this.state.cep}
            >
              {(inputProps) =>
                <TextField
                  {...inputProps}
                  variant="outlined"
                  margin="normal"
                  label="CEP"
                  style={{ marginBottom: 15, marginTop: -2 }}
                />
              }
            </InputMask>

            {this.state.open_error2 ? (
              <Alert
                style={{ marginBottom: 10 }}
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      this.setState({ open_error2: false });
                      this.setState({ cep: '' });
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {this.state.msg}
              </Alert>
            ) : null}

            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
              <TextField
                name="street"
                variant="outlined"
                fullWidth
                id="street"
                label="Rua"
                InputProps={{ style: styles.input }}
                onChange={this.handleChange}
                value={this.state.street}
              />
              <Typography component="h1" variant="h5" style={{ marginTop: 10 }}>
                -
              </Typography>
              <TextField
                name="number"
                variant="outlined"
                id="number"
                label="Número"
                InputProps={{ style: styles.input }}
                onChange={this.handleChange}
                value={this.state.number}
                style={{ width: 150 }}
              />
            </div>

            <TextField
              name="district"
              variant="outlined"
              fullWidth
              id="district"
              label="Bairro"
              InputProps={{ style: styles.input }}
              onChange={this.handleChange}
              value={this.state.district}
              style={{ marginBottom: 15 }}
            />

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <TextField
                name="city"
                variant="outlined"
                fullWidth
                id="city"
                label="Cidade"
                InputProps={{ style: styles.input }}
                onChange={this.handleChange}
                value={this.state.city}
              />
              <Typography component="h1" variant="h5" style={{ marginTop: 10 }}>
                -
              </Typography>
              <TextField
                name="state"
                variant="outlined"
                id="state"
                label="Estado"
                InputProps={{ style: styles.input }}
                onChange={this.handleChange}
                value={this.state.state}
                style={{ width: 150 }}
              />
            </div>

            <TextField
              name="description"
              variant="outlined"
              multiline
              fullWidth
              style={{ marginTop: 15 }}
              id="description"
              label="Complemento"
              placeholder="Descreva algum detalhe a mais sobre a área"
              InputProps={{ style: styles.input }}
              onChange={this.handleChange}
              value={this.state.description}
            />

            <div style={{ flexDirection: 'column', marginTop: 10 }}>
              <Typography variant="h6" color="textSecondary" align="left">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={styles.icon2}>
                    <AttachFile />
                  </div>
                  Anexo de arquivos
                </div>
              </Typography>

              <div style={styles.container2}>

                {this.state.open ? (
                  <Alert
                    severity="success"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          this.handleClose();
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {this.state.msg}
                  </Alert>
                ) : null}

                {this.state.open_error ? (
                  <Alert
                    severity="error"
                    style={{ marginBottom: 10 }}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          this.setState({ open_error: false });
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {this.state.msg}
                  </Alert>
                ) : null}

                <div style={{ display: 'flex', flexDirection: 'column', margin: 10, marginTop: 20 }}>
                  {this.state.documents.map((item) =>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <a target="_blank" href={`${apiStringServer}/area_docs/${item.file}`} rel="noreferrer">
                        <Typography variant="subtitle1" color="textSecondary" align="left">
                          {item.file}
                        </Typography>
                      </a>

                      <IconButton
                        aria-label="close"
                        color="#800000"
                        style={{ marginTop: -15 }}
                        onClick={async () => {
                          await this.setState({ fileName: '' });
                          await this.setState({ document_id: item.id });
                          await this.setState({ document_name: item.file });
                          this.handleDeleteDoc();
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  )}
                </div>

                <input
                  accept="file_extension|audio/*|video/*|image/*|media_type"
                  style={{ display: 'none' }}
                  id="button-attach"
                  multiple
                  type="file"
                  onChange={this.handleNewFile}
                />
                <label htmlFor="button-attach">
                  <Button
                    component="span"
                    variant="contained"
                    size="small"
                    color="primary"
                    style={styles.bt}
                    startIcon={<AttachFile />}
                  >
                    Anexar documento
                  </Button>
                </label>

              </div>
            </div>

          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openDialog: false })} style={{ color: '#001D5F' }}>
              Cancelar
            </Button>
            <Button onClick={async () => {
              this.setState({ openDialog: false });
              this.onStatusChange();
            }} style={{ color: '#001D5F' }}>
              Alterar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.openDialog2} onClose={() => this.setState({ openDialog2: false })}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Exclusão</DialogTitle>
          <DialogContent>
            <DialogContentText color="#fff">
              Deseja excluir esta área e todos os dados relacionados a ela?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openDialog2: false })} style={{ color: '#001D5F' }}>
              Cancelar
            </Button>
            <Button onClick={async () => {
              await this.setState({ openDialog2: false });
              this.handleDelete();
            }} style={{ color: '#001D5F' }}>
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

let areas = [];

export class Areas extends Component {

  state = {
    login: this.props.location.state.fields.login,
    option: this.props.location.state.fields.option,
    user_type: this.props.location.state.fields.user_type,
    selected_area: this.props.location.state.fields.selected_area,
    selected_city: this.props.location.state.fields.selected_city,

    zoom: 14,
    latitude: -22.5242815,
    longitude: -43.7298658,
    activeMarker: {},
    selectedPlace: {},

    places: [],
    address: '',
    complete_address: '',
    polygon: [],
    finalPolygon: [],
    area: '',
    saveAreaBt: true,

    cepMask: '99999-999',
    area_name: '',
    cep: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    description: '',

    adm_areas: [],
    comunities: [],
    completed_comunities: [],

    polygonCoords_RioGeral: [],

    disabled: true,
    open: false,
    open_error: false,
    open_error2: false,
    open_error3: false,
    msg: '',

    tableView: false,
    page_rows: [],
  }

  async componentDidMount() {

    if (this.props.location.state) {
      await this.setState({ login: this.props.location.state.fields.login });
      await this.setState({ option: this.props.location.state.fields.option });
      await this.setState({ user_type: this.props.location.state.fields.user_type });
      await this.setState({ selected_area: this.props.location.state.fields.selected_area });
      await this.setState({ selected_city: this.props.location.state.fields.selected_city });
    }

    this.state.polygonCoords_RioGeral = polygonCoords_Rio.concat(polygonCoords_Rio1);
    this.state.polygonCoords_RioGeral = this.state.polygonCoords_RioGeral.concat(polygonCoords_Rio2);
    this.state.polygonCoords_RioGeral = this.state.polygonCoords_RioGeral.concat(polygonCoords_Rio21);
    this.state.polygonCoords_RioGeral = this.state.polygonCoords_RioGeral.concat(polygonCoords_Rio3);
    this.state.polygonCoords_RioGeral = this.state.polygonCoords_RioGeral.concat(polygonCoords_Rio31);

    selected_city = this.state.selected_city;

    if (this.state.selected_city.includes('buzios')) {
      await this.setState({ latitude: -22.774660 });
      await this.setState({ longitude: -41.917836 });
    } else {
      if (this.state.selected_city.includes('cabo')) {
        await this.setState({ latitude: -22.755854 });
        await this.setState({ longitude: -42.012410 });
        await this.setState({ zoom: 11 });
      } else {
        if (this.state.selected_city.includes('arraial')) {
          await this.setState({ latitude: -22.933008 });
          await this.setState({ longitude: -42.130081 });
          await this.setState({ zoom: 12 });
        } else {
          if (this.state.selected_city.includes('queimados')) {
            await this.setState({ latitude: -22.727356 });
            await this.setState({ longitude: -43.585626 });
            await this.setState({ zoom: 13 });
          } else {
            if (this.state.selected_city.includes('rio')) {
              await this.setState({ latitude: -22.931987594567158 });
              await this.setState({ longitude: -43.44911393720455 });
              await this.setState({ zoom: 11 });
            } else {
              if (this.state.selected_city.includes('sjmeriti')) {
                await this.setState({ latitude: -22.78631 });
                await this.setState({ longitude: -43.3601559 });
                await this.setState({ zoom: 13 });
              } else {
                if (this.state.selected_city.includes('comendador')) {
                  await this.setState({ latitude: -22.049874 });
                  await this.setState({ longitude: -43.2478259 });
                  await this.setState({ zoom: 13 });
                } else {
                  if (this.state.selected_city.includes('paraty')) {
                    await this.setState({ latitude: -23.214500 });
                    await this.setState({ longitude: -44.723303 });
                    await this.setState({ zoom: 14 });
                  }
                }
              }
            }
          }
        }
      }
    }

    try {
      let resp = await api.get('/user', {
        params: {
          cpf_cnpj: this.state.login,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            this.setState({ user_id: [resp.data][0].id });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível buscar os dados do usuário. ' + err);
    }

    await this.handleAdmAreas();

    try {
      let resp = await api.get('/cityareas', {
        params: {
          cpf_cnpj: this.state.login,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            areas = resp.data;

            for (let i = 0; i < resp.data.length; i++) {
              let aux = {
                id: `${resp.data[i].id}`,
                name: resp.data[i].has_files === 'true' ? `[${resp.data[i].area_name}] 📝` : `${resp.data[i].area_name}`,
                user_name: `${resp.data[i].user_name}`,
                address: `${resp.data[i].street}, ${resp.data[i].number} - ${resp.data[i].district}, ${resp.data[i].city} - ${resp.data[i].state}`,
                area: `${resp.data[i].area} m²`,
                description: `${resp.data[i].description}`,
                latitude: `${resp.data[i].latitude}`,
                longitude: `${resp.data[i].longitude}`,
                street: `${resp.data[i].street}`,
                number: `${resp.data[i].number}`,
                district: `${resp.data[i].district}`,
                city: `${resp.data[i].city}`,
                state: `${resp.data[i].state}`,
                cep: `${resp.data[i].zip_code}`,
              };
              rows = Object.assign([], rows);
              await rows.push(aux);
            }

            await this.setState({ page_rows: rows });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg)
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
    await this.setState({ page_rows: rows });

    this.setState({ tableView: true });

    this.getComunities();

  }

  getComunities = async () => {

    try {
      let resp = await api.get('/comunities', {
        params: {
          selected_city: `municipios_${this.state.selected_city}`
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('comunidade')) {

            await this.setState({ comunities: resp.data });

            let all_comunities = [];
            this.state.comunities.map((item) =>
              all_comunities.push(item.polygon)
            );

            //formatar os polígonos das comunidades do SJMeriti de Janeiro para serem exibidos no mapa com o componente Polygon, usando o exemplo da formatação presente no arquivo polygonSJMeriti.js, lembrando que na formatação de all_comunities, cada item é uma string que segue um padrão como neste exemplo de dois itens: [[-43.25148546299994,-22.994067564999966],[-43.251536560999966,-22.994302722999976]] e [[-43.251536560999966,-22.994302722999976],[-43.25148546299994,-22.994067564999966]].
            let polygons = [];
            all_comunities.map((item) => {
              let polygon = [];
              let coords = item.split('],[');
              coords.map((coord) => {
                let coord1 = coord.replace('[[', '').replace(']]', '').replace('[', '').replace(']', '');
                let lat = parseFloat(coord1.split(',')[1]);
                let lng = parseFloat(coord1.split(',')[0]);
                polygon.push({ lat: lat, lng: lng });
              });
              polygons.push(polygon);
            });

            await this.setState({ completed_comunities: polygons });
            
            //alert(JSON.stringify(this.state.completed_comunities));

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg)
          }
        }
      }
    } catch (err) {
      console.log(err);
    }

  }

  handleAdmAreas = async () => {

    try {
      let resp = await api.get('/admareas', {
        params: {
          cpf_cnpj: this.state.login,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            let polygonStr = '';
          
            for(let i = 0; i < resp.data.length; i++) {
              let polygon = resp.data[i].polygon;
              let area = resp.data[i].area;
              let area_name = resp.data[i].area_name;
              let description = resp.data[i].description;
              let latitude = resp.data[i].latitude;
              let longitude = resp.data[i].longitude;
              polygon = polygon.split('"').join('');
              polygon = polygon.split('[').join('');
              polygon = polygon.split('lat').join('"lat"');
              polygon = polygon.split('lng').join('"lng"');
              polygonStr = polygonStr + `{"polygon":[${polygon}],
              "area":${area},
              "area_name":"${area_name}",
              "description":"${description}",
              "latitude":${latitude},
              "longitude":${longitude}},`;
            }

            polygonStr = `[${polygonStr}]`;
            polygonStr = polygonStr.split(',]').join(']');

            let polygonJson = JSON.parse(polygonStr)

            await this.setState({ adm_areas: polygonJson });

          } else {
            let msg = JSON.stringify(resp.data);
            //alert(msg); 
          }
        }
      }
    } catch (err) {
      alert('Não foi possível buscar os dados do usuário. ' + err);
    }

  }

  handleChangeAdress = async () => {

    await Geocode.fromAddress(this.state.address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        this.setState({ latitude: lat, longitude: lng });
        this.setState({ open_error: false });
        this.setState({ zoom: 19 })
      },
      (error) => {
        this.setState({ msg: 'Não foi possível encontrar este endereço.' });
        this.setState({ open_error: true });
      }
    );

    try {
      let resp = await apiCEP.get(`/ws/${this.state.address}/json/`);
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('cep')) {
            this.setState({ address: `${resp.data.logradouro} ${resp.data.bairro}` });
          } else {
            this.setState({ msg: "CEP não encontrado." });
            this.setState({ open_error: true });
          }
        }
      }
    } catch (err) {
      this.setState({ address: this.state.address });
    }

  }

  handleCEP = async () => {

    try{
      let resp = await apiCEP.get(`/ws/${this.state.cep}/json/`);
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('cep')) {
            this.setState({ street: resp.data.logradouro });
            this.setState({ district: resp.data.bairro });
            this.setState({ city: resp.data.localidade });
            this.setState({ state: resp.data.uf });
            this.setState({ open_error2: false });
          } else {
            this.setState({ msg: 'CEP não encontrado.' });
            this.setState({ open_error2: true });
            this.setState({ open: false });
          }
        }
      }
    } catch(err) {
      this.setState({ street: '' });
      this.setState({ number: '' });
      this.setState({ district: '' });
      this.setState({ city: '' });
      this.setState({ state: '' });
      this.setState({ msg: 'CEP não encontrado.' });
      this.setState({ open_error2: true });
      this.setState({ open: false });
    }
  }

  handleLocation = async (lat, lng) => {

    this.setState({ latitude: lat, longitude: lng });
    this.setState({ zoom: 16 });

  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {

    return(

      <div style={styles.directionColumn}>
        
        <div style={styles.html}>

          <Header
            history={this.props.history}
            login={this.state.login}
            option={this.state.option}
            user_type={this.state.user_type}
            selected_area={this.state.selected_area}
            selected_city={this.state.selected_city}
            city={this.state.selected_city}
            {...this.props}
          />

          <ThemeProvider theme={theme}>
            <div style={styles.directionRow}>

              <div>
                <Map
                  key={'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0'}
                  google={this.props.google}
                  zoom={this.props.zoom ? this.props.zoom : this.state.zoom}
                  style={styles.map}
                  mapType="map"
                  center={{
                    lat: this.props.latitude ? this.props.latitude : this.state.latitude,
                    lng: this.props.longitude ? this.props.longitude : this.state.longitude
                  }}
                  onRightclick={(t, map, coord) => this.handlePolygon(coord)}
                >

                  {this.state.selected_city.includes('sjmeriti') ? (
                    <Polygon
                      paths={polygonCoords_SJMeriti}
                      strokeColor="#001D5F"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#001D5F"
                      fillOpacity={0.2}
                    />
                  ) : this.state.selected_city.includes('rio') ? (
                    <Polygon
                      paths={this.state.polygonCoords_RioGeral}
                      strokeColor="#001D5F"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#001D5F"
                      fillOpacity={0.2}
                    />
                  ) : this.state.selected_city.includes('comendador') ? (
                    <Polygon
                      paths={this.state.polygonCoords_Comendador}
                      strokeColor="#001D5F"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#001D5F"
                      fillOpacity={0.2}
                    />
                  ) : this.state.selected_city.includes('paraty') ? (
                    <Polygon
                      paths={this.state.polygonCoords_Paraty}
                      strokeColor="#001D5F"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#001D5F"
                      fillOpacity={0.2}
                    />
                  ) : null}

                  <Polygon
                    paths={polygonCoords}
                    strokeColor="#0000FF"
                    strokeOpacity={0.8}
                    strokeWeight={2}
                    fillColor="#0000FF"
                    fillOpacity={0.35} />

                  {this.state.completed_comunities.map((item) =>
                    <Polygon
                      paths={item}
                      strokeColor="#800"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#800"
                      fillOpacity={0.2}
                    />
                  )}

                  {this.state.polygon.map((item) =>
                    <Marker
                      position={{
                        lat: item.lat,
                        lng: item.lng
                      }}
                      name={'Local atual'}
                      draggable={true}
                      onDragend={(t, map, coord) => this.onMarkerDragEnd(coord, item.lat)}
                    />
                  )}

                  {this.state.adm_areas.map((item) =>
                    <Polygon
                      paths={item.polygon}
                      strokeColor="#0000FF"
                      strokeOpacity={0.8}
                      strokeWeight={2}
                      fillColor="#0000FF"
                      fillOpacity={0.2}
                    />
                  )}

                  {this.state.adm_areas.map((item) =>
                    <Marker
                      position={{
                        lat: item.latitude,
                        lng: item.longitude
                      }}
                      name={`${item.area_name}`}
                      title={`${item.area_name}: ${item.area} m² / ${item.description}`}
                    />
                  )}

                </Map>
              </div>

              {this.state.tableView ? (
                <div style={{ marginLeft: 870 }}>
                  <div style={styles.dataGrid}>
                    <DataGrid
                      rows={this.state.page_rows}
                      columns={columns}
                      pageSize={9}
                      rowsPerPageOptions={[9]}
                      disableSelectionOnClick
                      localeText={ptBR.props.MuiDataGrid.localeText}
                      onCellClick={async (row) => {
                        await rowsArray.push(row);
                        await rowArray.push(rowsArray[0].row);
                        cpf_cnpj = rowsArray[0].row.cpf_cnpj;
                        await this.handleLocation(rowsArray[0].row.latitude, rowsArray[0].row.longitude);
                        rowsArray.pop();
                      }}
                    />
                  </div>
                </div>
              ) : null}

            </div>
          </ThemeProvider>

        </div>
        <FooterHome />
      </div>

    );

  }

}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0',
  language: 'pt-BR'
})(Areas)

let styles = {

  html: {
    backgroundColor: '#E5E6F0', //454545
    height: window.innerHeight,
    width: window.innerWidth,
    margin: -10,
  },

  directionRow: {
    display: 'flex',
    flexDirection: 'row'
  },

  directionColumn: {
    display: 'flex',
    flexDirection: 'column'
  },

  map: {
    height: 600,
    width: window.innerWidth - (window.innerWidth / 1.8),
    marginLeft: 10,
    marginTop: 10,
    alignSelf: 'flex-start',
  },

  container2: {
    height: 300,
    backgroundColor: '#E5E6F0', //454545
    margin: 10,
  },

  button: {
    textTransform: 'none',
  },

  button2: {
    textTransform: 'none',
    marginLeft: 10,
  },

  button3: {
    textTransform: 'none',
    margin: 10,
  },

  icon: {
    marginTop: 20,
    marginLeft: 5,
  },

  icon2: {
    marginTop: 2.5,
    marginRight: 5,
  },

  input: {
    color: '#000',
  },

  dataGrid: { 
    position: 'relative',
    height: 600, 
    width: window.innerWidth - (window.innerWidth / 2.15),
    padding: 10,
  },

  bt: {
    margin: 10,
    marginBottom: 10,
    textTransform: 'none',
    backgroundColor: '#001D5F',
  },

}