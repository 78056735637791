import React, { Component } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';
import apiStringServer from '../services/apiStringServer';

import Header from '../components/Header';
import FooterHome from '../components/FooterHome';
import Table from '../components/Table';
import Log from '../components/Log';

import Accordion from '@material-ui/core/Accordion';
import Divider from '@material-ui/core/Divider';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import House from '@material-ui/icons/House';
import Check from '@material-ui/icons/Check';
import Report from '@material-ui/icons/Report';
import LocationOn from '@material-ui/icons/LocationOn';
import Close from '@material-ui/icons/Close';
import DoNotDisturb from '@material-ui/icons/NotInterestedOutlined';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ListAltOutlined from '@material-ui/icons/ListAltOutlined';
import Add from '@material-ui/icons/Add';
import Apartment from '@material-ui/icons/Apartment';
import Button from '@material-ui/core/Button';
import MapIcon from '@material-ui/icons/Map';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Assignment from '@material-ui/icons/Assignment';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';

import { ThemeProvider } from '@material-ui/core';
import theme from '../themes/theme';
import themeDark from '../themes/themeDark';

import { Map, GoogleApiWrapper, Marker, InfoWindow, Polygon } from 'google-maps-react';

import yellow_marker from '../assets/yellow_marker.png';
import green_marker from '../assets/green_marker.png';
import red_marker from '../assets/red_marker.png';

import polygonCoords_Comendador from './utils/polygonComendador';

export class HomeComendador extends Component {

  state = {
    login: this.props.location.state.fields.login,
    option: this.props.location.state.fields.option,
    user_type: this.props.location.state.fields.user_type,
    selected_area: this.props.location.state.fields.selected_area,
    selected_city: this.props.location.state.fields.selected_city,

    zoom: 13,
    latitude: '',
    longitude: '',
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},

    places: [],

    cpf_cnpj: '',
    name: '',
    email: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',

    new_lat: '',
    new_lng: '',

    expanded: false,
    openDialog: false,

    renderTable: false,
    renderLog: false,

    url: null,

    search: '',

    polygonCoords_ComendadorGeral: [],

    comunities: [],
    completed_comunities: []
  }

  async componentDidMount() {

    if (this.props.location.state) {
      await this.setState({ login: this.props.location.state.fields.login });
      await this.setState({ option: this.props.location.state.fields.option });
      await this.setState({ user_type: this.props.location.state.fields.user_type });
      await this.setState({ selected_area: this.props.location.state.fields.selected_area });
      await this.setState({ selected_city: this.props.location.state.fields.selected_city });
    }

    /*navigator.geolocation.getCurrentPosition(async (position) => {
      this.setState({ status: null });
      await this.setState({ latitude: position.coords.latitude });
      await this.setState({ longitude: position.coords.longitude });
    }, () => {
      this.setState({ status: 'Impossível retornar a localização.' });
    });*/

    await this.setState({ latitude: -22.049874 });
    await this.setState({ longitude: -43.2478259 });

    if (this.state.user_type !== 4) {

      try {
        let resp = await api.get('/locations', {
          params: {
            selected_city: this.state.selected_city
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
  
              this.setState({ places: resp.data });
  
            } else {
              let msg = JSON.stringify(resp.data);
              console.log(msg)
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    } else {

      try {
        let resp = await api.get('/arealocations', {
          params: {
            selected_city: this.state.selected_city,
            selected_area: this.state.selected_area
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
  
              this.setState({ places: resp.data });
  
            } else {
              let msg = JSON.stringify(resp.data);
              console.log(msg)
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    }

    //buscar os polígonos das comunidades do SJMeriti de Janeiro
    this.getComunities();

  }

  getComunities = async () => {

    try {
      let resp = await api.get('/comunities', {
        params: {
          selected_city: 'municipios_comendador'
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('comunidade')) {

            await this.setState({ comunities: resp.data });

            let all_comunities = [];
            this.state.comunities.map((item) =>
              all_comunities.push(item.polygon)
            );

            //formatar os polígonos das comunidades do SJMeriti de Janeiro para serem exibidos no mapa com o componente Polygon, usando o exemplo da formatação presente no arquivo polygonSJMeriti.js, lembrando que na formatação de all_comunities, cada item é uma string que segue um padrão como neste exemplo de dois itens: [[-43.25148546299994,-22.994067564999966],[-43.251536560999966,-22.994302722999976]] e [[-43.251536560999966,-22.994302722999976],[-43.25148546299994,-22.994067564999966]].
            let polygons = [];
            all_comunities.map((item) => {
              let polygon = [];
              let coords = item.split('],[');
              coords.map((coord) => {
                let coord1 = coord.replace('[[', '').replace(']]', '').replace('[', '').replace(']', '');
                let lat = parseFloat(coord1.split(',')[1]);
                let lng = parseFloat(coord1.split(',')[0]);
                polygon.push({ lat: lat, lng: lng });
              });
              polygons.push(polygon);
            });

            await this.setState({ completed_comunities: polygons });
            
            //alert(JSON.stringify(this.state.completed_comunities));

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg)
          }
        }
      }
    } catch (err) {
      console.log(err);
    }

  }

  handleLocations = async () => {
    
    if (this.state.user_type !== 4) {

      try {
        let resp = await api.get('/locations', {
          params: {
            selected_city: this.state.selected_city
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {

              this.setState({ places: resp.data });

            } else {
              let msg = JSON.stringify(resp.data);
              console.log(msg)
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    } else {

      try {
        let resp = await api.get('/arealocations', {
          params: {
            selected_city: this.state.selected_city,
            selected_area: this.state.selected_area
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {

              this.setState({ places: resp.data });

            } else {
              let msg = JSON.stringify(resp.data);
              console.log(msg)
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    }

  }

  handleRegular = async () => {

    if (this.state.user_type !== 4) {

      try {
        let resp = await api.get('/regularlocations', {
          params: {
            selected_city: this.state.selected_city
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
  
              for (let i = 0; i < resp.data.length; i++) {
  
                await this.state.places.pop();
  
              }
  
              await this.setState({ places: [] });
  
              await this.setState({ places: resp.data });
  
            } else {
              await this.setState({ places: resp.data });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    } else {

      try {
        let resp = await api.get('/regulararealocations', {
          params: {
            selected_city: this.state.selected_city,
            selected_area: this.state.selected_area
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
  
              for (let i = 0; i < resp.data.length; i++) {
  
                await this.state.places.pop();
  
              }
  
              await this.setState({ places: [] });
  
              await this.setState({ places: resp.data });
  
            } else {
              await this.setState({ places: resp.data });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    }

  }

  handleNegattive = async () => {
    
    if (this.state.user_type !== 4) {

      try {
        let resp = await api.get('/negattivelocations', {
          params: {
            selected_city: this.state.selected_city
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
  
              for (let i = 0; i < resp.data.length; i++) {
  
                await this.state.places.pop();
  
              }
  
              await this.setState({ places: [] });
  
              await this.setState({ places: resp.data });
  
            } else {
              await this.setState({ places: resp.data });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    } else {

      try {
        let resp = await api.get('/negattivearealocations', {
          params: {
            selected_city: this.state.selected_city,
            selected_area: this.state.selected_area
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
  
              for (let i = 0; i < resp.data.length; i++) {
  
                await this.state.places.pop();
  
              }
  
              await this.setState({ places: [] });
  
              await this.setState({ places: resp.data });
  
            } else {
              await this.setState({ places: resp.data });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    }

  }

  handlePendence = async () => {
    
    if (this.state.user_type !== 4) {

      try {
        let resp = await api.get('/pendencelocations', {
          params: {
            selected_city: this.state.selected_city
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
  
              for (let i = 0; i < resp.data.length; i++) {
  
                await this.state.places.pop();
  
              }
  
              await this.setState({ places: [] });
  
              await this.setState({ places: resp.data });
  
            } else {
              await this.setState({ places: resp.data });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    } else {

      try {
        let resp = await api.get('/pendencearealocations', {
          params: {
            selected_city: this.state.selected_city,
            selected_area: this.state.selected_area
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
  
              for (let i = 0; i < resp.data.length; i++) {
  
                await this.state.places.pop();
  
              }
  
              await this.setState({ places: [] });
  
              await this.setState({ places: resp.data });
  
            } else {
              await this.setState({ places: resp.data });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    }

  }

  handleChangePanel = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  onMarkerClick = (props, marker) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  }

  handleChange = async e => {
    
    await this.setState({ [e.target.name]: e.target.value });

    //dar zoom na completed_comunities que contém o nome da localidade pesquisada
    let localidade = this.state.search;

    if (localidade !== '') {

      let comunity = this.state.comunities.filter(item => item.comunidade.includes(localidade));

      if (comunity.length > 0) {
        let coords = comunity[0].polygon.split('],[')[0];
        coords = coords.split('[[').join('');
        let lat = parseFloat(coords.split(',')[1]);
        let lng = parseFloat(coords.split(',')[0]);
        await this.setState({ latitude: lat }); 
        await this.setState({ longitude: lng });
        this.setState({ zoom: 16 });
      }

    } else {
      await this.setState({ latitude: -22.78631 });
      await this.setState({ longitude: -43.3601559 });
      this.setState({ zoom: 14 });
    }
    
  }

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={styles.html}>
          <Header
            history={this.props.history}
            login={this.state.login}
            option={this.state.option}
            user_type={this.state.user_type}
            selected_area={this.state.selected_area}
            selected_city={this.state.selected_city}
            city={this.state.selected_city}
            {...this.props}
          />

          <ThemeProvider theme={theme}>
            <div style={styles.directionRow}>

              <div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>
                    <TextField
                      style={styles.text}
                      id="search"
                      name="search"
                      value={this.state.search}
                      placeholder='Pesquisar localidade'
                      onChange={this.handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                    />
                  </div>

                  <div style={styles.accordionDiv}>
                    
                    <Accordion
                      TransitionProps={{ unmountOnExit: true }}
                      expanded={this.state.expanded === 'panel1'}
                      onChange={this.handleChangePanel('panel1')}
                      style={styles.accordion}
                      onClick={this.handleLocations}
                      theme={themeDark}
                    >
                      <ThemeProvider theme={themeDark}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <House />
                          <Typography>
                            Painel geral
                          </Typography>
                        </AccordionSummary>
                      </ThemeProvider>
                      <AccordionDetails>
                        <div style={{ display: 'flex', flexDirection: 'column', color: '#343434' }}>
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => {
                              this.setState({ renderTable: false, renderLog: false });
                              this.componentDidMount();
                            }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <MapIcon />
                              </ListItemIcon>
                              <ListItemText primary={`Mapa`} />
                            </ListItem>
                          </List>
                          <Divider />
                          {this.state.user_type !== 3 && this.state.user_type !== 4 ? (
                            <List
                              component="div"
                              disablePadding
                              style={styles.list}
                              onClick={() => {
                                this.props.history.push({
                                  pathname: '/addarea',
                                  state: {
                                    fields: {
                                      login: this.state.login,
                                      option: this.state.option,
                                      user_type: this.state.user_type,
                                      selected_area: this.state.selected_area,
                                      selected_city: this.state.selected_city,
                                      city: this.state.selected_city
                                    }
                                  }
                                });
                              }}
                            >
                              <ListItem button>
                                <ListItemIcon>
                                  <Add />
                                </ListItemIcon>
                                <ListItemText primary={`Cadastrar áreas`} />
                              </ListItem>
                            </List>
                          ) : null}
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => {
                              this.props.history.push({
                                pathname: '/areas',
                                state: {
                                  fields: {
                                    login: this.state.login,
                                    selected_city: this.state.selected_city
                                  }
                                }
                              });
                            }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <ViewColumn />
                              </ListItemIcon>
                              <ListItemText primary={`Tabela de áreas`} />
                            </ListItem>
                          </List>
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => {
                              this.setState({ renderTable: true });
                              this.componentDidMount();
                            }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <Apartment />
                              </ListItemIcon>
                              <ListItemText primary={`RGI (Registro Geral de Imóveis)`} />
                            </ListItem>
                          </List>
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => {
                              this.setState({ renderTable: true });
                              this.componentDidMount();
                            }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <Add />
                              </ListItemIcon>
                              <ListItemText primary={`Cadastro imobiliário`} />
                            </ListItem>
                          </List>
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => {
                              this.props.history.push({
                                pathname: '/urbanproject',
                                state: {
                                  fields: {
                                    login: this.state.login,
                                    option: this.state.option,
                                    user_type: this.state.user_type,
                                    selected_area: this.state.selected_area,
                                    selected_city: this.state.selected_city
                                  }
                                }
                              });
                            }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <Apartment />
                              </ListItemIcon>
                              <ListItemText primary={`Projeto Urbanístico REURB-S`} />
                            </ListItem>
                          </List>
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => {
                              this.setState({ renderTable: true, renderLog: false });
                              this.componentDidMount();
                            }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <ViewColumn />
                              </ListItemIcon>
                              <ListItemText primary={`Tabela de registros`} />
                            </ListItem>
                          </List>
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => {
                              this.setState({ renderLog: true, renderTable: false });
                              this.componentDidMount();
                            }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <ListAltOutlined />
                              </ListItemIcon>
                              <ListItemText primary={`Log de usuários`} />
                            </ListItem>
                          </List>
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      expanded={this.state.expanded === 'panel2'}
                      onChange={this.handleChangePanel('panel2')}
                      style={styles.accordion}
                    >
                      <ThemeProvider theme={themeDark}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <LocationOn />
                          <Typography>
                            Filtros
                          </Typography>
                        </AccordionSummary>
                      </ThemeProvider>
                      <AccordionDetails>
                        <div style={{ display: 'flex', flexDirection: 'column', color: '#343434' }}>
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => { this.handleRegular(); }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <Check />
                              </ListItemIcon>
                              <ListItemText primary={`Imóveis regularizados`} />
                            </ListItem>
                          </List>
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => { this.handlePendence(); }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <Report />
                              </ListItemIcon>
                              <ListItemText primary={`Regularizações em andamento`} />
                            </ListItem>
                          </List>
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => { this.handleNegattive(); }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <Close />
                              </ListItemIcon>
                              <ListItemText primary={`Regularizações negadas`} />
                            </ListItem>
                          </List>
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      expanded={this.state.expanded === 'panel5'}
                      onChange={this.handleChangePanel('panel5')}
                      style={styles.accordion}
                    >
                      <ThemeProvider theme={themeDark}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel5a-content"
                          id="panel5a-header"
                        >
                          <Assignment />
                          <Typography>
                            Formulários
                          </Typography>
                        </AccordionSummary>
                      </ThemeProvider>
                      <AccordionDetails>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <Button
                            color="secondary"
                            style={styles.bt2}
                            startIcon={<Assignment />}
                          >
                            Decisão instauradora da REURB
                          </Button>

                          <Button
                            color="secondary"
                            style={styles.bt2}
                            startIcon={<Assignment />}
                          >
                            Decisão denegatória da instauração da REURB
                          </Button>

                          <Button
                            color="secondary"
                            style={styles.bt2}
                            startIcon={<Assignment />}
                          >
                            Autuação do procedimento
                          </Button>

                          <Button
                            color="secondary"
                            style={styles.bt2}
                            startIcon={<Assignment />}
                          >
                            Certificações comuns do procedimento administrativo
                          </Button>

                          <Button
                            color="secondary"
                            style={styles.bt2}
                            startIcon={<Assignment />}
                          >
                            Anuência expressa do notificado
                          </Button>

                          <Button
                            color="secondary"
                            style={styles.bt2}
                            startIcon={<Assignment />}
                          >
                            Impugnação expressa do notificado
                          </Button>
                        </div>

                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      expanded={this.state.expanded === 'panel6'}
                      onChange={this.handleChangePanel('panel6')}
                      style={styles.accordion}
                    >
                      <ThemeProvider theme={themeDark}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel6a-content"
                          id="panel6a-header"
                        >
                          <AssignmentTurnedIn />
                          <Typography>
                            Modelos de documentação
                          </Typography>
                        </AccordionSummary>
                      </ThemeProvider>
                      <AccordionDetails>

                        <div style={styles.examples}>
                          <a
                            href={`${apiStringServer}/docs/plano_diretor_estrutura_e_conteudo.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Plano Diretor - Estrutura e conteúdo
                          </a>
                          <a
                            href={`${apiStringServer}/docs/lei_complementar_2011_plano_diretor.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Plano Diretor - Lei complementar
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa1_macrozoneamento.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 1 - Macrozoneamento
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa2_hipsometria_hidrografia_e_subbacias_hidrograficas.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 2 - Hipsometria, Hidrografia e Sub-bacias hidrográficas
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa3_areas_protegidas.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 3 - Áreas protegidas
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa4_regioes_administrativas.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 4 - Regiões administrativas
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa5_regioes_de_planejamento.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 5 - Regiões de planejamento
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa6_uso_e_cobertura_do_solo.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 6 - Uso e cobertura do solo
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa7_rede_estrutural_viaria.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 7 - Rede estrutural viária
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa8_limites_administrativos_rarp.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 8 - Limites administrativos RARP
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa9_areas_de_intervencao_e_macrozona.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 9 - Áreas de intervenção Macrozona
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa10_areas_intervencao.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 10 - Áreas de intervenção
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa11_vetores_de_crescimento.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 11 - Vetores de crescimento
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa12_meio_ambiente.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 12 - Meio ambiente
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa13_meio_ambiente_sitios.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 13 - Meio ambiente (sítios)
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa14_saneamento_ambiental.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 14 - Saneamento ambiental
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa15_habitacao.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 15 - Habitação
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa16_transportes.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 16 - Transportes
                          </a>
                          <a
                            href={`${apiStringServer}/docs/relatorio_2009_antecedentes_e_diagnostico.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Relatós Comendador 2009 - Antecedentes e Diagnóstico
                          </a>
                          <a
                            href={`${apiStringServer}/docs/relatorio_2009_proposta_de_politica_urbana_parte3.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Relatório 2009 - Proposta de política urbana (parte 3)
                          </a>
                          <a
                            href={`${apiStringServer}/docs/importancia_do_plano_diretor_municipal.docx`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            A iportância do Plano Diretor Municipal
                          </a>
                          <a
                            href={`${apiStringServer}/docs/atos_e_procedimentos_da_regularizacao_fundiaria.docx`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Atos e procedimentos da regularização fundiária
                          </a>
                        </div>

                      </AccordionDetails>
                    </Accordion>
                  </div>
              </div>
              </div>

              {this.state.renderTable ? (
                <div style={{ marginTop: '4.6%' }}>
                  <Table
                    history={this.props.history}
                    vars={this.state}
                    {...this.props}
                  />
                </div>
              ) : this.state.renderLog === false ? (
                <div style={{
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  zIndex: 0
                }}>
                  <Map
                    key={'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0'}
                    google={this.props.google}
                    zoom={this.state.zoom}
                    style={styles.map}
                    mapType="map"
                    center={{
                      lat: this.state.latitude,
                      lng: this.state.longitude
                    }}
                    onClick={this.onMapClicked}
                  >

                    <Polygon
                      paths={polygonCoords_Comendador}
                      strokeColor="#001D5F"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#001D5F"
                      fillOpacity={0.2}
                    />

                    {this.state.completed_comunities.map((item) =>
                      <Polygon
                        paths={item}
                        strokeColor="#800"
                        strokeOpacity={0.5}
                        strokeWeight={2}
                        fillColor="#800"
                        fillOpacity={0.2}
                      />
                    )}

                    {this.state.places.map((item) =>
                      <Marker
                        icon={{
                          url: (item.active == 1) ? yellow_marker : (item.active == 2) ? green_marker : red_marker,
                          scaledSize: new window.google.maps.Size(32, 40)
                        }}
                        position={{
                          lat: item.latitude,
                          lng: item.longitude
                        }}
                        onClick={async (props, marker) => {
                          this.setState({ cpf_cnpj: item.cpf_cnpj });
                          this.setState({ name: item.name });
                          this.setState({ email: item.email });
                          this.setState({ street: item.street });
                          this.setState({ number: item.number });
                          this.setState({ district: item.district });
                          this.setState({ city: item.city });
                          this.setState({ state: item.state });
                          this.setState({ new_lat: item.latitude });
                          this.setState({ new_lng: item.longitude });
                          this.onMarkerClick(props, marker);
                        }}
                        name={item.name}
                      />
                    )}

                    <InfoWindow
                      marker={this.state.activeMarker}
                      visible={this.state.showingInfoWindow}
                    >

                      <a
                        href={`${apiString}/details?cpf_cnpj=${this.state.cpf_cnpj}&lat=${this.state.new_lat}&lng=${this.state.new_lng}&selected_city=${this.state.selected_city}&login=${this.state.login}`}
                      >
                        <Button
                          fullWidth
                          variant="text"
                          color="primary"
                          style={{ textTransform: 'none' }}
                        >
                          <Typography variant="h6" color="primary" align="left" style={{ maxWidth: 250 }}>
                            {`${this.state.name}`}
                          </Typography>
                        </Button>
                      </a>
                      <Typography variant="subtitle1" color="textSecondary" align="left" style={{ maxWidth: 250 }}>
                        {`${this.state.email}`}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary" align="left" style={{ maxWidth: 250 }}>
                        {`${this.state.street}, ${this.state.number} - ${this.state.district}, ${this.state.city} - ${this.state.state}`}
                      </Typography>

                    </InfoWindow>

                  </Map>
                </div>
              ) : (
                <div style={{ marginTop: '5.5%' }}>
                  <Log
                    history={this.props.history}
                    vars={this.state}
                    {...this.props}
                  />
                </div>
              )}

            </div>
          </ThemeProvider>

        </div>
        <FooterHome />
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0',
  language: 'pt-BR'
})(HomeComendador)

let styles = {
  html: {
    backgroundColor: '#E5E6F0', //454545
    height: window.innerHeight,
    width: window.innerWidth,
    margin: -10,
    paddingBottom: 50,
    zIndex: 0,
  },

  directionRow: {
    display: 'flex',
    flexDirection: 'row'
  },

  directionColumn: {
    display: 'flex',
    flexDirection: 'column'
  },

  text: {
    margin: 10,
    marginTop: '30%',
    width: 335,
    backgroundColor: '#E5E6F0', //343434
    borderRadius: 5,
    zIndex: 1,
  },

  accordionDiv: {
    marginTop: 10,
    zIndex: 1,
  },

  accordion: {
    maxWidth: 350,
    backgroundColor: '#001D5F',
    marginLeft: 10,
    color: '#E5E6F0',
    borderRadius: 5,
    marginBottom: 2,
  },

  list: {
    backgroundColor: '#E5E6F0', //343434
    width: 315,
  },

  map: {
    height: window.innerHeight - 30,
    width: window.innerWidth,
    alignSelf: 'flex-start',
  },

  bt2: {
    margin: 10,
    width: '96.7%',
    textTransform: 'none',
    backgroundColor: '#E5E6F0', //343434
    color: '#343434'
  },

  examples: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#E5E6F0', //454545
    margin: 10,
    marginTop: 30,
    maxHeight: 370,
    overflowY: 'scroll',
  },

  href: {
    margin: 10,
    color: '#343434'
  },

};