let polygonCoords_Paraty = [
  {
    lng: -44.8835893,
    lat: -23.2056536
  },
  {
    lng: -44.8831853,
    lat: -23.2082615
  },
  {
    lng: -44.8834824,
    lat: -23.2110079
  },
  {
    lng: -44.8807966,
    lat: -23.2128437
  },
  {
    lng: -44.8801147,
    lat: -23.2133097
  },
  {
    lng: -44.8814002,
    lat: -23.2159725
  },
  {
    lng: -44.8825702,
    lat: -23.2176142
  },
  {
    lng: -44.8849761,
    lat: -23.2190342
  },
  {
    lng: -44.8860939,
    lat: -23.2202083
  },
  {
    lng: -44.8876071,
    lat: -23.2216831
  },
  {
    lng: -44.8889315,
    lat: -23.2245048
  },
  {
    lng: -44.8893206,
    lat: -23.2265949
  },
  {
    lng: -44.8885781,
    lat: -23.2287653
  },
  {
    lng: -44.8872073,
    lat: -23.2314496
  },
  {
    lng: -44.8862364,
    lat: -23.2335058
  },
  {
    lng: -44.8859508,
    lat: -23.2357903
  },
  {
    lng: -44.8834378,
    lat: -23.2359617
  },
  {
    lng: -44.8808676,
    lat: -23.2365899
  },
  {
    lng: -44.879554,
    lat: -23.2379036
  },
  {
    lng: -44.878483,
    lat: -23.2404076
  },
  {
    lng: -44.8783109,
    lat: -23.2428753
  },
  {
    lng: -44.876731,
    lat: -23.244555
  },
  {
    lng: -44.8751796,
    lat: -23.2461806
  },
  {
    lng: -44.8745048,
    lat: -23.2469305
  },
  {
    lng: -44.87338,
    lat: -23.2479428
  },
  {
    lng: -44.8719928,
    lat: -23.2489551
  },
  {
    lng: -44.8706477,
    lat: -23.2495651
  },
  {
    lng: -44.8694641,
    lat: -23.249834
  },
  {
    lng: -44.8682448,
    lat: -23.2497492
  },
  {
    lng: -44.866219,
    lat: -23.2495362
  },
  {
    lng: -44.8633697,
    lat: -23.2489235
  },
  {
    lng: -44.8627577,
    lat: -23.248866
  },
  {
    lng: -44.8616444,
    lat: -23.2487939
  },
  {
    lng: -44.8605855,
    lat: -23.2487372
  },
  {
    lng: -44.859605,
    lat: -23.2486542
  },
  {
    lng: -44.8590538,
    lat: -23.2486204
  },
  {
    lng: -44.8586941,
    lat: -23.2485891
  },
  {
    lng: -44.8577832,
    lat: -23.2485239
  },
  {
    lng: -44.8562835,
    lat: -23.2484677
  },
  {
    lng: -44.8550463,
    lat: -23.2485427
  },
  {
    lng: -44.8536403,
    lat: -23.2486739
  },
  {
    lng: -44.8521219,
    lat: -23.2488988
  },
  {
    lng: -44.8512693,
    lat: -23.2492185
  },
  {
    lng: -44.8504535,
    lat: -23.2497612
  },
  {
    lng: -44.8497786,
    lat: -23.2502486
  },
  {
    lng: -44.8486351,
    lat: -23.2508859
  },
  {
    lng: -44.8480194,
    lat: -23.2510575
  },
  {
    lng: -44.8472107,
    lat: -23.2513432
  },
  {
    lng: -44.846648,
    lat: -23.2518232
  },
  {
    lng: -44.8461606,
    lat: -23.2524981
  },
  {
    lng: -44.8453732,
    lat: -23.2534542
  },
  {
    lng: -44.844211,
    lat: -23.2541665
  },
  {
    lng: -44.8431612,
    lat: -23.2549538
  },
  {
    lng: -44.8425988,
    lat: -23.255385
  },
  {
    lng: -44.8421302,
    lat: -23.2557224
  },
  {
    lng: -44.8413569,
    lat: -23.256038
  },
  {
    lng: -44.8401744,
    lat: -23.2561756
  },
  {
    lng: -44.836983,
    lat: -23.2556981
  },
  {
    lng: -44.8364313,
    lat: -23.2558349
  },
  {
    lng: -44.8360564,
    lat: -23.2561723
  },
  {
    lng: -44.8359252,
    lat: -23.256566
  },
  {
    lng: -44.8362626,
    lat: -23.257897
  },
  {
    lng: -44.8369375,
    lat: -23.2590405
  },
  {
    lng: -44.8379793,
    lat: -23.2601521
  },
  {
    lng: -44.8390933,
    lat: -23.2610838
  },
  {
    lng: -44.83982,
    lat: -23.2620457
  },
  {
    lng: -44.8400698,
    lat: -23.2628856
  },
  {
    lng: -44.8399931,
    lat: -23.2633896
  },
  {
    lng: -44.8397494,
    lat: -23.2637083
  },
  {
    lng: -44.8390558,
    lat: -23.264177
  },
  {
    lng: -44.838006,
    lat: -23.2649268
  },
  {
    lng: -44.8370687,
    lat: -23.265433
  },
  {
    lng: -44.8362439,
    lat: -23.2659391
  },
  {
    lng: -44.8352691,
    lat: -23.2668202
  },
  {
    lng: -44.8345192,
    lat: -23.2674013
  },
  {
    lng: -44.8337506,
    lat: -23.2676637
  },
  {
    lng: -44.8325134,
    lat: -23.2678512
  },
  {
    lng: -44.8312011,
    lat: -23.2681324
  },
  {
    lng: -44.829214,
    lat: -23.2689197
  },
  {
    lng: -44.8284829,
    lat: -23.2695571
  },
  {
    lng: -44.828033,
    lat: -23.2705882
  },
  {
    lng: -44.828033,
    lat: -23.2718816
  },
  {
    lng: -44.8284832,
    lat: -23.2734498
  },
  {
    lng: -44.8289328,
    lat: -23.2756871
  },
  {
    lng: -44.8288486,
    lat: -23.2762455
  },
  {
    lng: -44.8285017,
    lat: -23.2766057
  },
  {
    lng: -44.8278439,
    lat: -23.2768449
  },
  {
    lng: -44.8272866,
    lat: -23.2771435
  },
  {
    lng: -44.8267387,
    lat: -23.2776285
  },
  {
    lng: -44.8257835,
    lat: -23.2783678
  },
  {
    lng: -44.8245088,
    lat: -23.2795488
  },
  {
    lng: -44.8239276,
    lat: -23.2801862
  },
  {
    lng: -44.8237589,
    lat: -23.2806923
  },
  {
    lng: -44.8237402,
    lat: -23.2810673
  },
  {
    lng: -44.8241713,
    lat: -23.2819296
  },
  {
    lng: -44.8260648,
    lat: -23.285982
  },
  {
    lng: -44.827186,
    lat: -23.2888425
  },
  {
    lng: -44.8274771,
    lat: -23.2906252
  },
  {
    lng: -44.8267208,
    lat: -23.2916214
  },
  {
    lng: -44.8246962,
    lat: -23.2916214
  },
  {
    lng: -44.8222961,
    lat: -23.2905335
  },
  {
    lng: -44.8201596,
    lat: -23.2901966
  },
  {
    lng: -44.8188487,
    lat: -23.2897377
  },
  {
    lng: -44.815848,
    lat: -23.2881721
  },
  {
    lng: -44.8120613,
    lat: -23.2856601
  },
  {
    lng: -44.8078514,
    lat: -23.2835736
  },
  {
    lng: -44.8071498,
    lat: -23.2834855
  },
  {
    lng: -44.8068092,
    lat: -23.2840802
  },
  {
    lng: -44.8071621,
    lat: -23.286876
  },
  {
    lng: -44.8068277,
    lat: -23.2880693
  },
  {
    lng: -44.8053644,
    lat: -23.2900491
  },
  {
    lng: -44.8009557,
    lat: -23.2927778
  },
  {
    lng: -44.7938368,
    lat: -23.2960184
  },
  {
    lng: -44.7902408,
    lat: -23.2975451
  },
  {
    lng: -44.7874214,
    lat: -23.2991069
  },
  {
    lng: -44.7867001,
    lat: -23.2999715
  },
  {
    lng: -44.78626,
    lat: -23.3014002
  },
  {
    lng: -44.7864915,
    lat: -23.3023442
  },
  {
    lng: -44.7871289,
    lat: -23.3031315
  },
  {
    lng: -44.7878412,
    lat: -23.3044062
  },
  {
    lng: -44.788005,
    lat: -23.3057805
  },
  {
    lng: -44.7876685,
    lat: -23.3077572
  },
  {
    lng: -44.7867846,
    lat: -23.3100876
  },
  {
    lng: -44.7857417,
    lat: -23.3111924
  },
  {
    lng: -44.7843919,
    lat: -23.3122796
  },
  {
    lng: -44.7833797,
    lat: -23.3127295
  },
  {
    lng: -44.7825548,
    lat: -23.3132169
  },
  {
    lng: -44.7818373,
    lat: -23.3133694
  },
  {
    lng: -44.7811269,
    lat: -23.313617
  },
  {
    lng: -44.7802116,
    lat: -23.3138543
  },
  {
    lng: -44.7795179,
    lat: -23.3140793
  },
  {
    lng: -44.7787681,
    lat: -23.3144729
  },
  {
    lng: -44.7783744,
    lat: -23.3150353
  },
  {
    lng: -44.778112,
    lat: -23.3155977
  },
  {
    lng: -44.7779433,
    lat: -23.3164225
  },
  {
    lng: -44.7779827,
    lat: -23.316712
  },
  {
    lng: -44.7781245,
    lat: -23.3169938
  },
  {
    lng: -44.7791307,
    lat: -23.3182733
  },
  {
    lng: -44.7792742,
    lat: -23.318822
  },
  {
    lng: -44.7795646,
    lat: -23.3205198
  },
  {
    lng: -44.7796324,
    lat: -23.3212176
  },
  {
    lng: -44.7799436,
    lat: -23.3231224
  },
  {
    lng: -44.7807036,
    lat: -23.3248026
  },
  {
    lng: -44.7816111,
    lat: -23.3258969
  },
  {
    lng: -44.7815611,
    lat: -23.3268429
  },
  {
    lng: -44.7808396,
    lat: -23.3277191
  },
  {
    lng: -44.7792708,
    lat: -23.3287716
  },
  {
    lng: -44.7778243,
    lat: -23.3296555
  },
  {
    lng: -44.7767796,
    lat: -23.3309815
  },
  {
    lng: -44.7762573,
    lat: -23.3323878
  },
  {
    lng: -44.7760564,
    lat: -23.3337138
  },
  {
    lng: -44.77686,
    lat: -23.3357228
  },
  {
    lng: -44.7777496,
    lat: -23.3377495
  },
  {
    lng: -44.7774972,
    lat: -23.338065
  },
  {
    lng: -44.7771502,
    lat: -23.3384988
  },
  {
    lng: -44.7746902,
    lat: -23.3388167
  },
  {
    lng: -44.7726362,
    lat: -23.3387284
  },
  {
    lng: -44.7707526,
    lat: -23.3382542
  },
  {
    lng: -44.769507,
    lat: -23.3384952
  },
  {
    lng: -44.7674176,
    lat: -23.3397408
  },
  {
    lng: -44.7647657,
    lat: -23.3411873
  },
  {
    lng: -44.7633995,
    lat: -23.3415891
  },
  {
    lng: -44.7620341,
    lat: -23.341783
  },
  {
    lng: -44.7608682,
    lat: -23.3413481
  },
  {
    lng: -44.759944,
    lat: -23.3412275
  },
  {
    lng: -44.7591002,
    lat: -23.3425535
  },
  {
    lng: -44.7583368,
    lat: -23.3437187
  },
  {
    lng: -44.7579752,
    lat: -23.3448036
  },
  {
    lng: -44.757643,
    lat: -23.3460322
  },
  {
    lng: -44.7575797,
    lat: -23.346525
  },
  {
    lng: -44.7574327,
    lat: -23.3477769
  },
  {
    lng: -44.7571515,
    lat: -23.3490225
  },
  {
    lng: -44.7568433,
    lat: -23.3495277
  },
  {
    lng: -44.7564282,
    lat: -23.3502079
  },
  {
    lng: -44.7554438,
    lat: -23.3508507
  },
  {
    lng: -44.7541781,
    lat: -23.3514133
  },
  {
    lng: -44.7527316,
    lat: -23.3520763
  },
  {
    lng: -44.7518927,
    lat: -23.3523005
  },
  {
    lng: -44.7508833,
    lat: -23.3527593
  },
  {
    lng: -44.750381,
    lat: -23.3529803
  },
  {
    lng: -44.749487,
    lat: -23.3535328
  },
  {
    lng: -44.748151,
    lat: -23.3544469
  },
  {
    lng: -44.7460416,
    lat: -23.3558733
  },
  {
    lng: -44.7447156,
    lat: -23.3568778
  },
  {
    lng: -44.741461,
    lat: -23.3588868
  },
  {
    lng: -44.739063,
    lat: -23.3613034
  },
  {
    lng: -44.7369547,
    lat: -23.364577
  },
  {
    lng: -44.7357554,
    lat: -23.3656372
  },
  {
    lng: -44.734108,
    lat: -23.3656773
  },
  {
    lng: -44.7325409,
    lat: -23.3659184
  },
  {
    lng: -44.7314962,
    lat: -23.3665211
  },
  {
    lng: -44.7300835,
    lat: -23.3676096
  },
  {
    lng: -44.7290452,
    lat: -23.3684096
  },
  {
    lng: -44.7280809,
    lat: -23.368932
  },
  {
    lng: -44.7268649,
    lat: -23.3688727
  },
  {
    lng: -44.7242414,
    lat: -23.367565
  },
  {
    lng: -44.7241439,
    lat: -23.367541
  },
  {
    lng: -44.7241086,
    lat: -23.3675397
  },
  {
    lng: -44.7240596,
    lat: -23.3675287
  },
  {
    lng: -44.7239949,
    lat: -23.3674943
  },
  {
    lng: -44.7238762,
    lat: -23.3674204
  },
  {
    lng: -44.723833,
    lat: -23.3673748
  },
  {
    lng: -44.7237744,
    lat: -23.3673482
  },
  {
    lng: -44.723925,
    lat: -23.3674165
  },
  {
    lng: -44.7239869,
    lat: -23.3673975
  },
  {
    lng: -44.7241013,
    lat: -23.3674556
  },
  {
    lng: -44.7241816,
    lat: -23.3674327
  },
  {
    lng: -44.7242535,
    lat: -23.3673756
  },
  {
    lng: -44.7244899,
    lat: -23.3673265
  },
  {
    lng: -44.7246643,
    lat: -23.3673278
  },
  {
    lng: -44.7247942,
    lat: -23.3673811
  },
  {
    lng: -44.7250478,
    lat: -23.367318
  },
  {
    lng: -44.7251072,
    lat: -23.3672482
  },
  {
    lng: -44.7251839,
    lat: -23.3672993
  },
  {
    lng: -44.7253486,
    lat: -23.3673567
  },
  {
    lng: -44.7254295,
    lat: -23.3673386
  },
  {
    lng: -44.7257117,
    lat: -23.3673852
  },
  {
    lng: -44.7258058,
    lat: -23.3674007
  },
  {
    lng: -44.7260833,
    lat: -23.3674044
  },
  {
    lng: -44.7262784,
    lat: -23.3673571
  },
  {
    lng: -44.7264987,
    lat: -23.3671814
  },
  {
    lng: -44.7265902,
    lat: -23.36705
  },
  {
    lng: -44.7263996,
    lat: -23.3667738
  },
  {
    lng: -44.7265084,
    lat: -23.3665672
  },
  {
    lng: -44.7263919,
    lat: -23.3663507
  },
  {
    lng: -44.7263849,
    lat: -23.3662435
  },
  {
    lng: -44.7265066,
    lat: -23.3662102
  },
  {
    lng: -44.7270431,
    lat: -23.366021
  },
  {
    lng: -44.7271441,
    lat: -23.3656578
  },
  {
    lng: -44.7272825,
    lat: -23.365647
  },
  {
    lng: -44.7276533,
    lat: -23.3656361
  },
  {
    lng: -44.7278405,
    lat: -23.3654953
  },
  {
    lng: -44.7279398,
    lat: -23.3652967
  },
  {
    lng: -44.7280098,
    lat: -23.3652808
  },
  {
    lng: -44.7280797,
    lat: -23.365242
  },
  {
    lng: -44.7282417,
    lat: -23.3649388
  },
  {
    lng: -44.7284509,
    lat: -23.3646709
  },
  {
    lng: -44.7287587,
    lat: -23.3644197
  },
  {
    lng: -44.7290327,
    lat: -23.364297
  },
  {
    lng: -44.7292136,
    lat: -23.3642687
  },
  {
    lng: -44.7293135,
    lat: -23.3642221
  },
  {
    lng: -44.7296269,
    lat: -23.3638621
  },
  {
    lng: -44.729767,
    lat: -23.3636665
  },
  {
    lng: -44.7300261,
    lat: -23.3635263
  },
  {
    lng: -44.7301332,
    lat: -23.3634276
  },
  {
    lng: -44.7302482,
    lat: -23.3632926
  },
  {
    lng: -44.7303079,
    lat: -23.3632052
  },
  {
    lng: -44.7304378,
    lat: -23.363204
  },
  {
    lng: -44.7304668,
    lat: -23.3631611
  },
  {
    lng: -44.7305355,
    lat: -23.3631511
  },
  {
    lng: -44.730537,
    lat: -23.3632112
  },
  {
    lng: -44.7306474,
    lat: -23.3631987
  },
  {
    lng: -44.7307866,
    lat: -23.3631658
  },
  {
    lng: -44.7308877,
    lat: -23.363165
  },
  {
    lng: -44.7309547,
    lat: -23.3630702
  },
  {
    lng: -44.7308717,
    lat: -23.362963
  },
  {
    lng: -44.7308556,
    lat: -23.3628312
  },
  {
    lng: -44.7308814,
    lat: -23.3627653
  },
  {
    lng: -44.7308518,
    lat: -23.3627193
  },
  {
    lng: -44.7308373,
    lat: -23.3625976
  },
  {
    lng: -44.7307868,
    lat: -23.3625951
  },
  {
    lng: -44.7306829,
    lat: -23.3625361
  },
  {
    lng: -44.7306517,
    lat: -23.3625454
  },
  {
    lng: -44.7306119,
    lat: -23.362531
  },
  {
    lng: -44.7305793,
    lat: -23.3625031
  },
  {
    lng: -44.730578,
    lat: -23.3624431
  },
  {
    lng: -44.7305774,
    lat: -23.3624176
  },
  {
    lng: -44.7304969,
    lat: -23.362312
  },
  {
    lng: -44.7304568,
    lat: -23.3622594
  },
  {
    lng: -44.7304438,
    lat: -23.3623018
  },
  {
    lng: -44.7304138,
    lat: -23.3623442
  },
  {
    lng: -44.7303705,
    lat: -23.3623446
  },
  {
    lng: -44.7303121,
    lat: -23.3623133
  },
  {
    lng: -44.7302852,
    lat: -23.3622797
  },
  {
    lng: -44.7302585,
    lat: -23.3622209
  },
  {
    lng: -44.7302315,
    lat: -23.3620975
  },
  {
    lng: -44.7302508,
    lat: -23.3620584
  },
  {
    lng: -44.7303101,
    lat: -23.3620217
  },
  {
    lng: -44.73036,
    lat: -23.3620021
  },
  {
    lng: -44.7303951,
    lat: -23.3620102
  },
  {
    lng: -44.7304257,
    lat: -23.3620363
  },
  {
    lng: -44.7304472,
    lat: -23.3620813
  },
  {
    lng: -44.7304752,
    lat: -23.3621636
  },
  {
    lng: -44.7305185,
    lat: -23.3620684
  },
  {
    lng: -44.7305385,
    lat: -23.3620421
  },
  {
    lng: -44.7305705,
    lat: -23.3620387
  },
  {
    lng: -44.7305838,
    lat: -23.3620558
  },
  {
    lng: -44.73059,
    lat: -23.3620698
  },
  {
    lng: -44.7306045,
    lat: -23.3620697
  },
  {
    lng: -44.7307093,
    lat: -23.3620246
  },
  {
    lng: -44.7306929,
    lat: -23.3618719
  },
  {
    lng: -44.7307489,
    lat: -23.3618266
  },
  {
    lng: -44.7308934,
    lat: -23.3618389
  },
  {
    lng: -44.7310313,
    lat: -23.361893
  },
  {
    lng: -44.7311054,
    lat: -23.3620087
  },
  {
    lng: -44.7310912,
    lat: -23.3621391
  },
  {
    lng: -44.7311379,
    lat: -23.3621305
  },
  {
    lng: -44.7311718,
    lat: -23.3621535
  },
  {
    lng: -44.7311639,
    lat: -23.3621813
  },
  {
    lng: -44.7310169,
    lat: -23.3623105
  },
  {
    lng: -44.7309408,
    lat: -23.3625
  },
  {
    lng: -44.7309707,
    lat: -23.3625635
  },
  {
    lng: -44.7310234,
    lat: -23.3625954
  },
  {
    lng: -44.7310094,
    lat: -23.3626958
  },
  {
    lng: -44.7310326,
    lat: -23.3627454
  },
  {
    lng: -44.7310335,
    lat: -23.3628191
  },
  {
    lng: -44.7310418,
    lat: -23.3628863
  },
  {
    lng: -44.7310173,
    lat: -23.3630453
  },
  {
    lng: -44.7311047,
    lat: -23.363002
  },
  {
    lng: -44.7311739,
    lat: -23.3629902
  },
  {
    lng: -44.7312027,
    lat: -23.3629284
  },
  {
    lng: -44.7312462,
    lat: -23.3628728
  },
  {
    lng: -44.7312054,
    lat: -23.3628295
  },
  {
    lng: -44.7311572,
    lat: -23.3627619
  },
  {
    lng: -44.7312006,
    lat: -23.3626468
  },
  {
    lng: -44.7312247,
    lat: -23.3626074
  },
  {
    lng: -44.7312739,
    lat: -23.3625735
  },
  {
    lng: -44.7313509,
    lat: -23.3625446
  },
  {
    lng: -44.7313748,
    lat: -23.3625013
  },
  {
    lng: -44.731357,
    lat: -23.3624544
  },
  {
    lng: -44.7313376,
    lat: -23.3622526
  },
  {
    lng: -44.7313059,
    lat: -23.3622335
  },
  {
    lng: -44.7312843,
    lat: -23.362201
  },
  {
    lng: -44.7312967,
    lat: -23.3621551
  },
  {
    lng: -44.7313222,
    lat: -23.3621425
  },
  {
    lng: -44.7313787,
    lat: -23.3621299
  },
  {
    lng: -44.7314048,
    lat: -23.3620866
  },
  {
    lng: -44.7313613,
    lat: -23.3620264
  },
  {
    lng: -44.7314031,
    lat: -23.3619831
  },
  {
    lng: -44.7314419,
    lat: -23.3619818
  },
  {
    lng: -44.7314858,
    lat: -23.3619389
  },
  {
    lng: -44.7315753,
    lat: -23.3617313
  },
  {
    lng: -44.7316389,
    lat: -23.361644
  },
  {
    lng: -44.7318022,
    lat: -23.3615662
  },
  {
    lng: -44.7320607,
    lat: -23.3615178
  },
  {
    lng: -44.7320905,
    lat: -23.3613156
  },
  {
    lng: -44.7319471,
    lat: -23.3609254
  },
  {
    lng: -44.7317914,
    lat: -23.3608052
  },
  {
    lng: -44.7317995,
    lat: -23.360576
  },
  {
    lng: -44.7317299,
    lat: -23.3602489
  },
  {
    lng: -44.7316271,
    lat: -23.3600822
  },
  {
    lng: -44.7316188,
    lat: -23.3600288
  },
  {
    lng: -44.7316389,
    lat: -23.3599844
  },
  {
    lng: -44.7316766,
    lat: -23.3599638
  },
  {
    lng: -44.7317443,
    lat: -23.3599467
  },
  {
    lng: -44.7318087,
    lat: -23.3599565
  },
  {
    lng: -44.7318919,
    lat: -23.3599614
  },
  {
    lng: -44.7319321,
    lat: -23.3599146
  },
  {
    lng: -44.7319696,
    lat: -23.3598408
  },
  {
    lng: -44.731967,
    lat: -23.3596955
  },
  {
    lng: -44.7319616,
    lat: -23.359565
  },
  {
    lng: -44.7320474,
    lat: -23.3594591
  },
  {
    lng: -44.7321467,
    lat: -23.3594393
  },
  {
    lng: -44.7318426,
    lat: -23.3587006
  },
  {
    lng: -44.7317208,
    lat: -23.3584344
  },
  {
    lng: -44.7316882,
    lat: -23.3583299
  },
  {
    lng: -44.7312831,
    lat: -23.3576203
  },
  {
    lng: -44.7308823,
    lat: -23.3570535
  },
  {
    lng: -44.7302397,
    lat: -23.3561927
  },
  {
    lng: -44.7293717,
    lat: -23.3552736
  },
  {
    lng: -44.7284157,
    lat: -23.3544825
  },
  {
    lng: -44.727957,
    lat: -23.3542486
  },
  {
    lng: -44.7278747,
    lat: -23.3543272
  },
  {
    lng: -44.7279134,
    lat: -23.3544304
  },
  {
    lng: -44.7277997,
    lat: -23.3544638
  },
  {
    lng: -44.7276901,
    lat: -23.3543846
  },
  {
    lng: -44.7273727,
    lat: -23.3542194
  },
  {
    lng: -44.7272689,
    lat: -23.3540511
  },
  {
    lng: -44.7270919,
    lat: -23.3538397
  },
  {
    lng: -44.72689,
    lat: -23.3534494
  },
  {
    lng: -44.7267452,
    lat: -23.3534978
  },
  {
    lng: -44.7266753,
    lat: -23.3535213
  },
  {
    lng: -44.7264726,
    lat: -23.3536544
  },
  {
    lng: -44.7263677,
    lat: -23.3537618
  },
  {
    lng: -44.7263368,
    lat: -23.3538518
  },
  {
    lng: -44.7263103,
    lat: -23.3539016
  },
  {
    lng: -44.7262603,
    lat: -23.3539366
  },
  {
    lng: -44.7262167,
    lat: -23.3539442
  },
  {
    lng: -44.7262777,
    lat: -23.3542457
  },
  {
    lng: -44.7263497,
    lat: -23.3544001
  },
  {
    lng: -44.7263627,
    lat: -23.3544163
  },
  {
    lng: -44.726351,
    lat: -23.3544389
  },
  {
    lng: -44.7263195,
    lat: -23.3544399
  },
  {
    lng: -44.7262757,
    lat: -23.3544428
  },
  {
    lng: -44.7262393,
    lat: -23.3544617
  },
  {
    lng: -44.7262011,
    lat: -23.35447
  },
  {
    lng: -44.7260127,
    lat: -23.3543761
  },
  {
    lng: -44.7258595,
    lat: -23.3542801
  },
  {
    lng: -44.725767,
    lat: -23.3541661
  },
  {
    lng: -44.7258989,
    lat: -23.3541674
  },
  {
    lng: -44.7259537,
    lat: -23.3541637
  },
  {
    lng: -44.725954,
    lat: -23.3541211
  },
  {
    lng: -44.7259292,
    lat: -23.3541104
  },
  {
    lng: -44.7258599,
    lat: -23.3541482
  },
  {
    lng: -44.7257459,
    lat: -23.3541048
  },
  {
    lng: -44.725678,
    lat: -23.3540327
  },
  {
    lng: -44.7257578,
    lat: -23.3539777
  },
  {
    lng: -44.7257968,
    lat: -23.353979
  },
  {
    lng: -44.7257806,
    lat: -23.3538941
  },
  {
    lng: -44.7257913,
    lat: -23.3538487
  },
  {
    lng: -44.7257672,
    lat: -23.3537945
  },
  {
    lng: -44.7257216,
    lat: -23.3537809
  },
  {
    lng: -44.725507,
    lat: -23.3537636
  },
  {
    lng: -44.7253326,
    lat: -23.3537714
  },
  {
    lng: -44.7252233,
    lat: -23.3538033
  },
  {
    lng: -44.7249997,
    lat: -23.3539188
  },
  {
    lng: -44.7248459,
    lat: -23.3541003
  },
  {
    lng: -44.7247514,
    lat: -23.3543107
  },
  {
    lng: -44.7247284,
    lat: -23.354576
  },
  {
    lng: -44.7249064,
    lat: -23.354902
  },
  {
    lng: -44.72497,
    lat: -23.3549852
  },
  {
    lng: -44.7249385,
    lat: -23.3551479
  },
  {
    lng: -44.7247991,
    lat: -23.3553048
  },
  {
    lng: -44.724573,
    lat: -23.3553853
  },
  {
    lng: -44.724248,
    lat: -23.3553258
  },
  {
    lng: -44.7239525,
    lat: -23.3553026
  },
  {
    lng: -44.7237996,
    lat: -23.3555955
  },
  {
    lng: -44.7236065,
    lat: -23.3557433
  },
  {
    lng: -44.7236164,
    lat: -23.3559009
  },
  {
    lng: -44.7233371,
    lat: -23.3560793
  },
  {
    lng: -44.7228597,
    lat: -23.3561515
  },
  {
    lng: -44.7225953,
    lat: -23.3560881
  },
  {
    lng: -44.7226927,
    lat: -23.356373
  },
  {
    lng: -44.7227933,
    lat: -23.3566775
  },
  {
    lng: -44.7228009,
    lat: -23.3569161
  },
  {
    lng: -44.7227243,
    lat: -23.3570817
  },
  {
    lng: -44.7224955,
    lat: -23.3570444
  },
  {
    lng: -44.7224443,
    lat: -23.3569948
  },
  {
    lng: -44.7223176,
    lat: -23.3569087
  },
  {
    lng: -44.7221906,
    lat: -23.3571111
  },
  {
    lng: -44.7219425,
    lat: -23.3573506
  },
  {
    lng: -44.7218172,
    lat: -23.3574653
  },
  {
    lng: -44.721591,
    lat: -23.3576059
  },
  {
    lng: -44.7215305,
    lat: -23.3576171
  },
  {
    lng: -44.721382,
    lat: -23.3575216
  },
  {
    lng: -44.7212164,
    lat: -23.3573613
  },
  {
    lng: -44.721082,
    lat: -23.3570903
  },
  {
    lng: -44.7209467,
    lat: -23.3565189
  },
  {
    lng: -44.720656,
    lat: -23.3560857
  },
  {
    lng: -44.7203175,
    lat: -23.3556167
  },
  {
    lng: -44.7199212,
    lat: -23.3549528
  },
  {
    lng: -44.7196951,
    lat: -23.3547161
  },
  {
    lng: -44.7195748,
    lat: -23.3545471
  },
  {
    lng: -44.7194269,
    lat: -23.3544874
  },
  {
    lng: -44.7193688,
    lat: -23.354452
  },
  {
    lng: -44.7192197,
    lat: -23.354258
  },
  {
    lng: -44.719215,
    lat: -23.3542068
  },
  {
    lng: -44.7192892,
    lat: -23.3541676
  },
  {
    lng: -44.7193374,
    lat: -23.3541249
  },
  {
    lng: -44.7193303,
    lat: -23.3540143
  },
  {
    lng: -44.7193631,
    lat: -23.3539864
  },
  {
    lng: -44.7194734,
    lat: -23.3539878
  },
  {
    lng: -44.7195791,
    lat: -23.3540946
  },
  {
    lng: -44.7197086,
    lat: -23.3540687
  },
  {
    lng: -44.7198227,
    lat: -23.3540065
  },
  {
    lng: -44.7199772,
    lat: -23.3539017
  },
  {
    lng: -44.7200857,
    lat: -23.3539031
  },
  {
    lng: -44.7201794,
    lat: -23.3539505
  },
  {
    lng: -44.72026,
    lat: -23.3538871
  },
  {
    lng: -44.7209079,
    lat: -23.3538546
  },
  {
    lng: -44.7210548,
    lat: -23.3538409
  },
  {
    lng: -44.721118,
    lat: -23.3538101
  },
  {
    lng: -44.7211552,
    lat: -23.3537727
  },
  {
    lng: -44.7211602,
    lat: -23.3537261
  },
  {
    lng: -44.7212159,
    lat: -23.3535789
  },
  {
    lng: -44.7212734,
    lat: -23.3535019
  },
  {
    lng: -44.7213784,
    lat: -23.3534784
  },
  {
    lng: -44.7214672,
    lat: -23.3535141
  },
  {
    lng: -44.7217819,
    lat: -23.3535803
  },
  {
    lng: -44.7221029,
    lat: -23.3536854
  },
  {
    lng: -44.7222958,
    lat: -23.3534185
  },
  {
    lng: -44.7224544,
    lat: -23.3530463
  },
  {
    lng: -44.7225248,
    lat: -23.3527298
  },
  {
    lng: -44.7225608,
    lat: -23.3522173
  },
  {
    lng: -44.7225042,
    lat: -23.3517979
  },
  {
    lng: -44.7223357,
    lat: -23.3511021
  },
  {
    lng: -44.7219778,
    lat: -23.3502057
  },
  {
    lng: -44.7216723,
    lat: -23.3496626
  },
  {
    lng: -44.7213388,
    lat: -23.3491968
  },
  {
    lng: -44.7209443,
    lat: -23.3486425
  },
  {
    lng: -44.720401,
    lat: -23.3480484
  },
  {
    lng: -44.720128,
    lat: -23.3477847
  },
  {
    lng: -44.7198501,
    lat: -23.3475379
  },
  {
    lng: -44.7193053,
    lat: -23.3470498
  },
  {
    lng: -44.7184933,
    lat: -23.3465034
  },
  {
    lng: -44.7174075,
    lat: -23.3458996
  },
  {
    lng: -44.7167133,
    lat: -23.3455974
  },
  {
    lng: -44.716501,
    lat: -23.3455174
  },
  {
    lng: -44.7155606,
    lat: -23.3451632
  },
  {
    lng: -44.7139208,
    lat: -23.3446574
  },
  {
    lng: -44.7131265,
    lat: -23.3444214
  },
  {
    lng: -44.7128192,
    lat: -23.34433
  },
  {
    lng: -44.7121327,
    lat: -23.3440985
  },
  {
    lng: -44.7118653,
    lat: -23.3440598
  },
  {
    lng: -44.7116884,
    lat: -23.3440817
  },
  {
    lng: -44.7113654,
    lat: -23.3441444
  },
  {
    lng: -44.7112814,
    lat: -23.3442064
  },
  {
    lng: -44.7114263,
    lat: -23.3443821
  },
  {
    lng: -44.7115653,
    lat: -23.3445303
  },
  {
    lng: -44.7115887,
    lat: -23.3445968
  },
  {
    lng: -44.7116336,
    lat: -23.3447086
  },
  {
    lng: -44.7116323,
    lat: -23.3447734
  },
  {
    lng: -44.7115452,
    lat: -23.3447687
  },
  {
    lng: -44.7115399,
    lat: -23.3447441
  },
  {
    lng: -44.7114744,
    lat: -23.3446317
  },
  {
    lng: -44.7114233,
    lat: -23.344607
  },
  {
    lng: -44.7114261,
    lat: -23.3446715
  },
  {
    lng: -44.7113967,
    lat: -23.3446802
  },
  {
    lng: -44.7113523,
    lat: -23.3446513
  },
  {
    lng: -44.7112747,
    lat: -23.3445825
  },
  {
    lng: -44.7112731,
    lat: -23.3446312
  },
  {
    lng: -44.7112079,
    lat: -23.3446389
  },
  {
    lng: -44.7111352,
    lat: -23.3445899
  },
  {
    lng: -44.7111486,
    lat: -23.3445725
  },
  {
    lng: -44.7111273,
    lat: -23.3445694
  },
  {
    lng: -44.7110851,
    lat: -23.3445347
  },
  {
    lng: -44.7111635,
    lat: -23.3444847
  },
  {
    lng: -44.7111886,
    lat: -23.3444853
  },
  {
    lng: -44.7112041,
    lat: -23.3444609
  },
  {
    lng: -44.7111561,
    lat: -23.344443
  },
  {
    lng: -44.7111484,
    lat: -23.3444233
  },
  {
    lng: -44.7111115,
    lat: -23.3444206
  },
  {
    lng: -44.711084,
    lat: -23.3443862
  },
  {
    lng: -44.7110316,
    lat: -23.3443564
  },
  {
    lng: -44.7109432,
    lat: -23.3442997
  },
  {
    lng: -44.7109432,
    lat: -23.3442758
  },
  {
    lng: -44.7108166,
    lat: -23.3441843
  },
  {
    lng: -44.7103982,
    lat: -23.3439121
  },
  {
    lng: -44.7104363,
    lat: -23.3438637
  },
  {
    lng: -44.7100052,
    lat: -23.3434839
  },
  {
    lng: -44.7097232,
    lat: -23.3434008
  },
  {
    lng: -44.7096501,
    lat: -23.3433984
  },
  {
    lng: -44.709598,
    lat: -23.343353
  },
  {
    lng: -44.7096115,
    lat: -23.3433141
  },
  {
    lng: -44.7095167,
    lat: -23.3433209
  },
  {
    lng: -44.7092406,
    lat: -23.3431278
  },
  {
    lng: -44.7089612,
    lat: -23.3432493
  },
  {
    lng: -44.7089176,
    lat: -23.343305
  },
  {
    lng: -44.7088741,
    lat: -23.3433693
  },
  {
    lng: -44.7087522,
    lat: -23.3433129
  },
  {
    lng: -44.7085974,
    lat: -23.3432144
  },
  {
    lng: -44.7085462,
    lat: -23.3432886
  },
  {
    lng: -44.7083707,
    lat: -23.3432751
  },
  {
    lng: -44.7078575,
    lat: -23.3429253
  },
  {
    lng: -44.7075806,
    lat: -23.3427423
  },
  {
    lng: -44.7075694,
    lat: -23.3428534
  },
  {
    lng: -44.7074971,
    lat: -23.3428702
  },
  {
    lng: -44.7072825,
    lat: -23.3426571
  },
  {
    lng: -44.7071659,
    lat: -23.3428481
  },
  {
    lng: -44.7070368,
    lat: -23.3427917
  },
  {
    lng: -44.7063806,
    lat: -23.3428245
  },
  {
    lng: -44.7063394,
    lat: -23.3427843
  },
  {
    lng: -44.706253,
    lat: -23.3427882
  },
  {
    lng: -44.7062385,
    lat: -23.3428315
  },
  {
    lng: -44.7061808,
    lat: -23.3429668
  },
  {
    lng: -44.7059664,
    lat: -23.3430061
  },
  {
    lng: -44.7060532,
    lat: -23.343346
  },
  {
    lng: -44.7059866,
    lat: -23.3433961
  },
  {
    lng: -44.7057934,
    lat: -23.3433125
  },
  {
    lng: -44.7056035,
    lat: -23.3434128
  },
  {
    lng: -44.7056777,
    lat: -23.3436828
  },
  {
    lng: -44.7055181,
    lat: -23.343929
  },
  {
    lng: -44.7051899,
    lat: -23.3437583
  },
  {
    lng: -44.704991,
    lat: -23.3436577
  },
  {
    lng: -44.7050798,
    lat: -23.3439646
  },
  {
    lng: -44.7049701,
    lat: -23.3439735
  },
  {
    lng: -44.7048229,
    lat: -23.3439311
  },
  {
    lng: -44.7045007,
    lat: -23.343739
  },
  {
    lng: -44.7046675,
    lat: -23.3434584
  },
  {
    lng: -44.7043023,
    lat: -23.3432002
  },
  {
    lng: -44.7041653,
    lat: -23.3428897
  },
  {
    lng: -44.7041953,
    lat: -23.3424078
  },
  {
    lng: -44.7042534,
    lat: -23.3422747
  },
  {
    lng: -44.704148,
    lat: -23.3420233
  },
  {
    lng: -44.704466,
    lat: -23.3417885
  },
  {
    lng: -44.704477,
    lat: -23.341721
  },
  {
    lng: -44.704552,
    lat: -23.341655
  },
  {
    lng: -44.7046063,
    lat: -23.3415897
  },
  {
    lng: -44.7046385,
    lat: -23.3415441
  },
  {
    lng: -44.704662,
    lat: -23.3414561
  },
  {
    lng: -44.7046398,
    lat: -23.3413711
  },
  {
    lng: -44.7045937,
    lat: -23.3412806
  },
  {
    lng: -44.7045232,
    lat: -23.3411803
  },
  {
    lng: -44.7044735,
    lat: -23.3411113
  },
  {
    lng: -44.7044025,
    lat: -23.3410498
  },
  {
    lng: -44.704318,
    lat: -23.3409809
  },
  {
    lng: -44.7042235,
    lat: -23.3409131
  },
  {
    lng: -44.7041544,
    lat: -23.3408497
  },
  {
    lng: -44.7041021,
    lat: -23.3408023
  },
  {
    lng: -44.7040223,
    lat: -23.340742
  },
  {
    lng: -44.7039311,
    lat: -23.3407094
  },
  {
    lng: -44.7038687,
    lat: -23.3406884
  },
  {
    lng: -44.7038104,
    lat: -23.3406459
  },
  {
    lng: -44.7037528,
    lat: -23.3406089
  },
  {
    lng: -44.7036333,
    lat: -23.3405628
  },
  {
    lng: -44.7034952,
    lat: -23.3405185
  },
  {
    lng: -44.7033128,
    lat: -23.3404643
  },
  {
    lng: -44.7032371,
    lat: -23.3404371
  },
  {
    lng: -44.703093,
    lat: -23.3404051
  },
  {
    lng: -44.7028489,
    lat: -23.3403435
  },
  {
    lng: -44.7025592,
    lat: -23.3402709
  },
  {
    lng: -44.7023044,
    lat: -23.340229
  },
  {
    lng: -44.7020174,
    lat: -23.3401909
  },
  {
    lng: -44.7017948,
    lat: -23.3401576
  },
  {
    lng: -44.7015829,
    lat: -23.3401342
  },
  {
    lng: -44.701371,
    lat: -23.3401268
  },
  {
    lng: -44.7012047,
    lat: -23.3401453
  },
  {
    lng: -44.7009848,
    lat: -23.3401798
  },
  {
    lng: -44.7008439,
    lat: -23.3401909
  },
  {
    lng: -44.7006736,
    lat: -23.3402253
  },
  {
    lng: -44.7005462,
    lat: -23.3402734
  },
  {
    lng: -44.7004349,
    lat: -23.3403657
  },
  {
    lng: -44.7002847,
    lat: -23.3404753
  },
  {
    lng: -44.7001211,
    lat: -23.3406218
  },
  {
    lng: -44.7000192,
    lat: -23.3406908
  },
  {
    lng: -44.6999092,
    lat: -23.3407487
  },
  {
    lng: -44.6998541,
    lat: -23.3414531
  },
  {
    lng: -44.6995212,
    lat: -23.3417003
  },
  {
    lng: -44.6993229,
    lat: -23.3419158
  },
  {
    lng: -44.699372,
    lat: -23.3425577
  },
  {
    lng: -44.6992977,
    lat: -23.3427311
  },
  {
    lng: -44.6992842,
    lat: -23.3427311
  },
  {
    lng: -44.6992118,
    lat: -23.3427755
  },
  {
    lng: -44.6992118,
    lat: -23.342869
  },
  {
    lng: -44.6992554,
    lat: -23.343052
  },
  {
    lng: -44.6994157,
    lat: -23.3432384
  },
  {
    lng: -44.6992948,
    lat: -23.343562
  },
  {
    lng: -44.6991216,
    lat: -23.3435865
  },
  {
    lng: -44.6988551,
    lat: -23.3434502
  },
  {
    lng: -44.6986748,
    lat: -23.343495
  },
  {
    lng: -44.698611,
    lat: -23.3436817
  },
  {
    lng: -44.6986584,
    lat: -23.3438046
  },
  {
    lng: -44.6984723,
    lat: -23.3438879
  },
  {
    lng: -44.6979405,
    lat: -23.3436965
  },
  {
    lng: -44.6977098,
    lat: -23.3437236
  },
  {
    lng: -44.6975462,
    lat: -23.3436768
  },
  {
    lng: -44.6974362,
    lat: -23.3436349
  },
  {
    lng: -44.6970635,
    lat: -23.3436413
  },
  {
    lng: -44.6964698,
    lat: -23.3433185
  },
  {
    lng: -44.6964491,
    lat: -23.3432317
  },
  {
    lng: -44.6964748,
    lat: -23.3431062
  },
  {
    lng: -44.696337,
    lat: -23.3430123
  },
  {
    lng: -44.696124,
    lat: -23.3429594
  },
  {
    lng: -44.6960948,
    lat: -23.3430025
  },
  {
    lng: -44.696021,
    lat: -23.3429871
  },
  {
    lng: -44.6957415,
    lat: -23.3429822
  },
  {
    lng: -44.6954905,
    lat: -23.3428786
  },
  {
    lng: -44.6950531,
    lat: -23.3428493
  },
  {
    lng: -44.6942663,
    lat: -23.3429888
  },
  {
    lng: -44.6939999,
    lat: -23.3431132
  },
  {
    lng: -44.6937931,
    lat: -23.343262
  },
  {
    lng: -44.6932945,
    lat: -23.343378
  },
  {
    lng: -44.6930302,
    lat: -23.3435336
  },
  {
    lng: -44.6928403,
    lat: -23.343477
  },
  {
    lng: -44.6923682,
    lat: -23.3439311
  },
  {
    lng: -44.6918475,
    lat: -23.3440607
  },
  {
    lng: -44.6913524,
    lat: -23.3441205
  },
  {
    lng: -44.6909362,
    lat: -23.3441333
  },
  {
    lng: -44.6907601,
    lat: -23.3440512
  },
  {
    lng: -44.690487,
    lat: -23.344276
  },
  {
    lng: -44.6904358,
    lat: -23.3444641
  },
  {
    lng: -44.6903648,
    lat: -23.3447466
  },
  {
    lng: -44.6904669,
    lat: -23.3452006
  },
  {
    lng: -44.6903043,
    lat: -23.3457799
  },
  {
    lng: -44.6902418,
    lat: -23.3462955
  },
  {
    lng: -44.6904337,
    lat: -23.3464369
  },
  {
    lng: -44.6906043,
    lat: -23.3465193
  },
  {
    lng: -44.690596,
    lat: -23.3465862
  },
  {
    lng: -44.6904119,
    lat: -23.3466223
  },
  {
    lng: -44.6904554,
    lat: -23.346704
  },
  {
    lng: -44.6905207,
    lat: -23.347265
  },
  {
    lng: -44.6906752,
    lat: -23.3473988
  },
  {
    lng: -44.6905962,
    lat: -23.3475393
  },
  {
    lng: -44.6904872,
    lat: -23.3475362
  },
  {
    lng: -44.6904361,
    lat: -23.3474905
  },
  {
    lng: -44.6893996,
    lat: -23.3477166
  },
  {
    lng: -44.6892304,
    lat: -23.3478308
  },
  {
    lng: -44.6891906,
    lat: -23.3479696
  },
  {
    lng: -44.6886826,
    lat: -23.3478956
  },
  {
    lng: -44.6882954,
    lat: -23.3477647
  },
  {
    lng: -44.6874171,
    lat: -23.3476471
  },
  {
    lng: -44.6872647,
    lat: -23.3476106
  },
  {
    lng: -44.6869833,
    lat: -23.3475897
  },
  {
    lng: -44.6868336,
    lat: -23.3475045
  },
  {
    lng: -44.6863601,
    lat: -23.3473221
  },
  {
    lng: -44.6860673,
    lat: -23.3473686
  },
  {
    lng: -44.6854952,
    lat: -23.3473371
  },
  {
    lng: -44.6852671,
    lat: -23.3472618
  },
  {
    lng: -44.6850325,
    lat: -23.3474008
  },
  {
    lng: -44.6845403,
    lat: -23.3478708
  },
  {
    lng: -44.6845137,
    lat: -23.3481394
  },
  {
    lng: -44.6846045,
    lat: -23.3482326
  },
  {
    lng: -44.6845965,
    lat: -23.3483139
  },
  {
    lng: -44.6845733,
    lat: -23.3485969
  },
  {
    lng: -44.6847574,
    lat: -23.3487138
  },
  {
    lng: -44.6847939,
    lat: -23.3488731
  },
  {
    lng: -44.6848235,
    lat: -23.3490944
  },
  {
    lng: -44.6849665,
    lat: -23.3492807
  },
  {
    lng: -44.6849804,
    lat: -23.3495352
  },
  {
    lng: -44.6850136,
    lat: -23.3497885
  },
  {
    lng: -44.6851843,
    lat: -23.3501099
  },
  {
    lng: -44.6852504,
    lat: -23.3501
  },
  {
    lng: -44.6854008,
    lat: -23.3503165
  },
  {
    lng: -44.6855805,
    lat: -23.3505812
  },
  {
    lng: -44.6857604,
    lat: -23.3507766
  },
  {
    lng: -44.6859944,
    lat: -23.351067
  },
  {
    lng: -44.6860761,
    lat: -23.3512075
  },
  {
    lng: -44.6863329,
    lat: -23.3515299
  },
  {
    lng: -44.6863532,
    lat: -23.3515355
  },
  {
    lng: -44.6863533,
    lat: -23.3515948
  },
  {
    lng: -44.6864601,
    lat: -23.3516404
  },
  {
    lng: -44.686471,
    lat: -23.3516791
  },
  {
    lng: -44.686438,
    lat: -23.3517271
  },
  {
    lng: -44.6863753,
    lat: -23.3517244
  },
  {
    lng: -44.6862881,
    lat: -23.3517035
  },
  {
    lng: -44.6863423,
    lat: -23.3517699
  },
  {
    lng: -44.6863252,
    lat: -23.351834
  },
  {
    lng: -44.6862724,
    lat: -23.3519004
  },
  {
    lng: -44.6862086,
    lat: -23.3519384
  },
  {
    lng: -44.6860946,
    lat: -23.3519542
  },
  {
    lng: -44.6856879,
    lat: -23.3517734
  },
  {
    lng: -44.6852559,
    lat: -23.3514623
  },
  {
    lng: -44.6850527,
    lat: -23.3513259
  },
  {
    lng: -44.6849973,
    lat: -23.3511888
  },
  {
    lng: -44.6841732,
    lat: -23.3507
  },
  {
    lng: -44.6838836,
    lat: -23.3504814
  },
  {
    lng: -44.6837018,
    lat: -23.3502568
  },
  {
    lng: -44.6835383,
    lat: -23.3502038
  },
  {
    lng: -44.6833033,
    lat: -23.3501521
  },
  {
    lng: -44.6829085,
    lat: -23.3499457
  },
  {
    lng: -44.6826459,
    lat: -23.3495757
  },
  {
    lng: -44.68223,
    lat: -23.3489356
  },
  {
    lng: -44.6820799,
    lat: -23.3488364
  },
  {
    lng: -44.6821011,
    lat: -23.3487555
  },
  {
    lng: -44.6819212,
    lat: -23.3484845
  },
  {
    lng: -44.6817308,
    lat: -23.3483594
  },
  {
    lng: -44.681513,
    lat: -23.348033
  },
  {
    lng: -44.6814987,
    lat: -23.3479925
  },
  {
    lng: -44.6815029,
    lat: -23.3479581
  },
  {
    lng: -44.6815676,
    lat: -23.3479515
  },
  {
    lng: -44.6815558,
    lat: -23.3478586
  },
  {
    lng: -44.6815063,
    lat: -23.3478113
  },
  {
    lng: -44.6813867,
    lat: -23.3476441
  },
  {
    lng: -44.6813954,
    lat: -23.3476192
  },
  {
    lng: -44.6814199,
    lat: -23.3475498
  },
  {
    lng: -44.6813921,
    lat: -23.3474932
  },
  {
    lng: -44.6813054,
    lat: -23.3474489
  },
  {
    lng: -44.68127,
    lat: -23.347376
  },
  {
    lng: -44.681269,
    lat: -23.347304
  },
  {
    lng: -44.6812256,
    lat: -23.3472623
  },
  {
    lng: -44.6811061,
    lat: -23.3471604
  },
  {
    lng: -44.6809908,
    lat: -23.3470949
  },
  {
    lng: -44.680955,
    lat: -23.3470444
  },
  {
    lng: -44.6810845,
    lat: -23.346879
  },
  {
    lng: -44.6812595,
    lat: -23.3467761
  },
  {
    lng: -44.6813562,
    lat: -23.3466031
  },
  {
    lng: -44.681382,
    lat: -23.346342
  },
  {
    lng: -44.6812911,
    lat: -23.3461291
  },
  {
    lng: -44.6814222,
    lat: -23.3460877
  },
  {
    lng: -44.6815081,
    lat: -23.346179
  },
  {
    lng: -44.6815704,
    lat: -23.3460464
  },
  {
    lng: -44.681816,
    lat: -23.3460178
  },
  {
    lng: -44.6820428,
    lat: -23.3460926
  },
  {
    lng: -44.6821804,
    lat: -23.3460655
  },
  {
    lng: -44.6823289,
    lat: -23.3461344
  },
  {
    lng: -44.6825834,
    lat: -23.3463437
  },
  {
    lng: -44.6828018,
    lat: -23.3464528
  },
  {
    lng: -44.6829762,
    lat: -23.3464874
  },
  {
    lng: -44.6831667,
    lat: -23.3464731
  },
  {
    lng: -44.6833219,
    lat: -23.3463995
  },
  {
    lng: -44.683457,
    lat: -23.3462648
  },
  {
    lng: -44.6835097,
    lat: -23.3461386
  },
  {
    lng: -44.6835178,
    lat: -23.3460139
  },
  {
    lng: -44.6834554,
    lat: -23.3459493
  },
  {
    lng: -44.6832787,
    lat: -23.3458214
  },
  {
    lng: -44.6832399,
    lat: -23.3455432
  },
  {
    lng: -44.6832288,
    lat: -23.3451845
  },
  {
    lng: -44.6833492,
    lat: -23.3451455
  },
  {
    lng: -44.6833387,
    lat: -23.3449107
  },
  {
    lng: -44.6834145,
    lat: -23.3448358
  },
  {
    lng: -44.6833972,
    lat: -23.3443956
  },
  {
    lng: -44.6834536,
    lat: -23.3441273
  },
  {
    lng: -44.683597,
    lat: -23.3440651
  },
  {
    lng: -44.6836534,
    lat: -23.3438059
  },
  {
    lng: -44.6837029,
    lat: -23.3435052
  },
  {
    lng: -44.6837881,
    lat: -23.3433779
  },
  {
    lng: -44.6838658,
    lat: -23.3429637
  },
  {
    lng: -44.683972,
    lat: -23.3426099
  },
  {
    lng: -44.6839498,
    lat: -23.3423724
  },
  {
    lng: -44.6839165,
    lat: -23.3422472
  },
  {
    lng: -44.6837033,
    lat: -23.3419665
  },
  {
    lng: -44.6833491,
    lat: -23.3415405
  },
  {
    lng: -44.6829911,
    lat: -23.3411649
  },
  {
    lng: -44.6826921,
    lat: -23.3409395
  },
  {
    lng: -44.6824695,
    lat: -23.3407918
  },
  {
    lng: -44.6823716,
    lat: -23.3406736
  },
  {
    lng: -44.6822695,
    lat: -23.3404163
  },
  {
    lng: -44.6821287,
    lat: -23.3398413
  },
  {
    lng: -44.6820028,
    lat: -23.3394976
  },
  {
    lng: -44.6818619,
    lat: -23.3392464
  },
  {
    lng: -44.6816192,
    lat: -23.3389176
  },
  {
    lng: -44.6815259,
    lat: -23.3388248
  },
  {
    lng: -44.6815193,
    lat: -23.3388037
  },
  {
    lng: -44.6813122,
    lat: -23.3388658
  },
  {
    lng: -44.6812051,
    lat: -23.3389016
  },
  {
    lng: -44.6808595,
    lat: -23.338864
  },
  {
    lng: -44.6805544,
    lat: -23.3390294
  },
  {
    lng: -44.680334,
    lat: -23.3392208
  },
  {
    lng: -44.679825,
    lat: -23.33926
  },
  {
    lng: -44.679529,
    lat: -23.3392017
  },
  {
    lng: -44.6791803,
    lat: -23.338956
  },
  {
    lng: -44.678893,
    lat: -23.3386788
  },
  {
    lng: -44.6786111,
    lat: -23.3386656
  },
  {
    lng: -44.6782521,
    lat: -23.3385561
  },
  {
    lng: -44.6780491,
    lat: -23.3384212
  },
  {
    lng: -44.6774541,
    lat: -23.3380658
  },
  {
    lng: -44.6768579,
    lat: -23.3375824
  },
  {
    lng: -44.6761153,
    lat: -23.3371038
  },
  {
    lng: -44.6759571,
    lat: -23.3372097
  },
  {
    lng: -44.6758176,
    lat: -23.3371038
  },
  {
    lng: -44.6759437,
    lat: -23.3369388
  },
  {
    lng: -44.6754582,
    lat: -23.3363206
  },
  {
    lng: -44.6752543,
    lat: -23.3359118
  },
  {
    lng: -44.6753071,
    lat: -23.3356829
  },
  {
    lng: -44.6750286,
    lat: -23.3355075
  },
  {
    lng: -44.6745085,
    lat: -23.3356841
  },
  {
    lng: -44.6737495,
    lat: -23.336237
  },
  {
    lng: -44.6733236,
    lat: -23.3370217
  },
  {
    lng: -44.6730503,
    lat: -23.3377669
  },
  {
    lng: -44.6729223,
    lat: -23.3387723
  },
  {
    lng: -44.6730179,
    lat: -23.3394091
  },
  {
    lng: -44.6731555,
    lat: -23.3397156
  },
  {
    lng: -44.6732802,
    lat: -23.3398399
  },
  {
    lng: -44.6733701,
    lat: -23.339953
  },
  {
    lng: -44.673454,
    lat: -23.3399137
  },
  {
    lng: -44.6740319,
    lat: -23.3398631
  },
  {
    lng: -44.6743025,
    lat: -23.3400198
  },
  {
    lng: -44.6745961,
    lat: -23.3401428
  },
  {
    lng: -44.6748456,
    lat: -23.3403427
  },
  {
    lng: -44.6749061,
    lat: -23.3403704
  },
  {
    lng: -44.674946,
    lat: -23.3403559
  },
  {
    lng: -44.6749565,
    lat: -23.3403861
  },
  {
    lng: -44.6749581,
    lat: -23.3404168
  },
  {
    lng: -44.6749194,
    lat: -23.3404544
  },
  {
    lng: -44.6748711,
    lat: -23.3404286
  },
  {
    lng: -44.6747907,
    lat: -23.3404054
  },
  {
    lng: -44.6747657,
    lat: -23.3403793
  },
  {
    lng: -44.6747105,
    lat: -23.3403371
  },
  {
    lng: -44.6746928,
    lat: -23.3403465
  },
  {
    lng: -44.6746499,
    lat: -23.3403711
  },
  {
    lng: -44.6745804,
    lat: -23.3404448
  },
  {
    lng: -44.6745583,
    lat: -23.3404371
  },
  {
    lng: -44.6745491,
    lat: -23.3404553
  },
  {
    lng: -44.674629,
    lat: -23.340515
  },
  {
    lng: -44.6746218,
    lat: -23.3405474
  },
  {
    lng: -44.674663,
    lat: -23.3406071
  },
  {
    lng: -44.6746256,
    lat: -23.3406613
  },
  {
    lng: -44.674492,
    lat: -23.340771
  },
  {
    lng: -44.6740191,
    lat: -23.3406445
  },
  {
    lng: -44.6736393,
    lat: -23.3404711
  },
  {
    lng: -44.6734742,
    lat: -23.3401487
  },
  {
    lng: -44.6733666,
    lat: -23.3400722
  },
  {
    lng: -44.6733258,
    lat: -23.3400291
  },
  {
    lng: -44.6731475,
    lat: -23.3400726
  },
  {
    lng: -44.6729679,
    lat: -23.3401979
  },
  {
    lng: -44.6725466,
    lat: -23.3405443
  },
  {
    lng: -44.6723254,
    lat: -23.3410172
  },
  {
    lng: -44.6723602,
    lat: -23.3412678
  },
  {
    lng: -44.672426,
    lat: -23.3412795
  },
  {
    lng: -44.672546,
    lat: -23.3413007
  },
  {
    lng: -44.6726994,
    lat: -23.341256
  },
  {
    lng: -44.6730442,
    lat: -23.341389
  },
  {
    lng: -44.673189,
    lat: -23.3415417
  },
  {
    lng: -44.6733713,
    lat: -23.3416402
  },
  {
    lng: -44.6734412,
    lat: -23.3418372
  },
  {
    lng: -44.6733714,
    lat: -23.3420194
  },
  {
    lng: -44.6733178,
    lat: -23.3421228
  },
  {
    lng: -44.6735753,
    lat: -23.3427878
  },
  {
    lng: -44.6735484,
    lat: -23.3430193
  },
  {
    lng: -44.673779,
    lat: -23.3435019
  },
  {
    lng: -44.6737147,
    lat: -23.3438812
  },
  {
    lng: -44.6735806,
    lat: -23.3439698
  },
  {
    lng: -44.6734465,
    lat: -23.3441521
  },
  {
    lng: -44.6731139,
    lat: -23.3440536
  },
  {
    lng: -44.6729262,
    lat: -23.3443048
  },
  {
    lng: -44.672674,
    lat: -23.3445018
  },
  {
    lng: -44.6725782,
    lat: -23.3446456
  },
  {
    lng: -44.6724747,
    lat: -23.3447837
  },
  {
    lng: -44.672435,
    lat: -23.3449121
  },
  {
    lng: -44.6723511,
    lat: -23.3449971
  },
  {
    lng: -44.6720822,
    lat: -23.3450355
  },
  {
    lng: -44.671945,
    lat: -23.3450798
  },
  {
    lng: -44.6719762,
    lat: -23.3451323
  },
  {
    lng: -44.6717889,
    lat: -23.345278
  },
  {
    lng: -44.6715898,
    lat: -23.3453078
  },
  {
    lng: -44.6717601,
    lat: -23.3454915
  },
  {
    lng: -44.6717083,
    lat: -23.3455535
  },
  {
    lng: -44.6713921,
    lat: -23.3455259
  },
  {
    lng: -44.6715405,
    lat: -23.3456262
  },
  {
    lng: -44.6714973,
    lat: -23.3456696
  },
  {
    lng: -44.6712391,
    lat: -23.3456215
  },
  {
    lng: -44.671173,
    lat: -23.3455369
  },
  {
    lng: -44.6709357,
    lat: -23.3454648
  },
  {
    lng: -44.6707443,
    lat: -23.3455215
  },
  {
    lng: -44.6705553,
    lat: -23.3454138
  },
  {
    lng: -44.6701616,
    lat: -23.3454759
  },
  {
    lng: -44.6699665,
    lat: -23.3453909
  },
  {
    lng: -44.6698342,
    lat: -23.3454232
  },
  {
    lng: -44.6697013,
    lat: -23.3453715
  },
  {
    lng: -44.6694781,
    lat: -23.3456391
  },
  {
    lng: -44.6693936,
    lat: -23.345657
  },
  {
    lng: -44.669281,
    lat: -23.3456493
  },
  {
    lng: -44.6693818,
    lat: -23.3459083
  },
  {
    lng: -44.6693592,
    lat: -23.3459809
  },
  {
    lng: -44.6691817,
    lat: -23.3460281
  },
  {
    lng: -44.6689786,
    lat: -23.3461572
  },
  {
    lng: -44.6687819,
    lat: -23.3462716
  },
  {
    lng: -44.6684034,
    lat: -23.3461401
  },
  {
    lng: -44.6682418,
    lat: -23.3461618
  },
  {
    lng: -44.668179,
    lat: -23.346237
  },
  {
    lng: -44.6680565,
    lat: -23.3463131
  },
  {
    lng: -44.6677396,
    lat: -23.3462451
  },
  {
    lng: -44.6675164,
    lat: -23.3461385
  },
  {
    lng: -44.6674333,
    lat: -23.3461556
  },
  {
    lng: -44.6672744,
    lat: -23.3462613
  },
  {
    lng: -44.6671261,
    lat: -23.346279
  },
  {
    lng: -44.6667566,
    lat: -23.3462508
  },
  {
    lng: -44.6665289,
    lat: -23.3461768
  },
  {
    lng: -44.6663668,
    lat: -23.3460523
  },
  {
    lng: -44.6662955,
    lat: -23.3460424
  },
  {
    lng: -44.6662362,
    lat: -23.3460954
  },
  {
    lng: -44.6663155,
    lat: -23.3461825
  },
  {
    lng: -44.6663443,
    lat: -23.3462919
  },
  {
    lng: -44.6662008,
    lat: -23.3464346
  },
  {
    lng: -44.6659967,
    lat: -23.3464568
  },
  {
    lng: -44.6656033,
    lat: -23.3463431
  },
  {
    lng: -44.6650083,
    lat: -23.3461347
  },
  {
    lng: -44.6647537,
    lat: -23.3462319
  },
  {
    lng: -44.6646863,
    lat: -23.3463436
  },
  {
    lng: -44.6642318,
    lat: -23.3462951
  },
  {
    lng: -44.6640801,
    lat: -23.3462581
  },
  {
    lng: -44.663825,
    lat: -23.3461001
  },
  {
    lng: -44.6634792,
    lat: -23.3459394
  },
  {
    lng: -44.6633697,
    lat: -23.3459963
  },
  {
    lng: -44.6632013,
    lat: -23.3461424
  },
  {
    lng: -44.6631613,
    lat: -23.3464437
  },
  {
    lng: -44.6632141,
    lat: -23.3467488
  },
  {
    lng: -44.6631406,
    lat: -23.346769
  },
  {
    lng: -44.6628167,
    lat: -23.3466649
  },
  {
    lng: -44.6624652,
    lat: -23.3465526
  },
  {
    lng: -44.6621683,
    lat: -23.346403
  },
  {
    lng: -44.6620354,
    lat: -23.3463925
  },
  {
    lng: -44.6618931,
    lat: -23.346624
  },
  {
    lng: -44.6618183,
    lat: -23.3469343
  },
  {
    lng: -44.6618297,
    lat: -23.3471989
  },
  {
    lng: -44.6617913,
    lat: -23.3472588
  },
  {
    lng: -44.6616,
    lat: -23.3471964
  },
  {
    lng: -44.6613421,
    lat: -23.3471648
  },
  {
    lng: -44.6609887,
    lat: -23.3470583
  },
  {
    lng: -44.6605854,
    lat: -23.3468534
  },
  {
    lng: -44.6603115,
    lat: -23.3469027
  },
  {
    lng: -44.6602008,
    lat: -23.3470522
  },
  {
    lng: -44.6598209,
    lat: -23.3470389
  },
  {
    lng: -44.6600707,
    lat: -23.3472112
  },
  {
    lng: -44.6600564,
    lat: -23.3473601
  },
  {
    lng: -44.6599656,
    lat: -23.3474588
  },
  {
    lng: -44.6597577,
    lat: -23.347436
  },
  {
    lng: -44.6595463,
    lat: -23.3472723
  },
  {
    lng: -44.6594612,
    lat: -23.3474043
  },
  {
    lng: -44.6588302,
    lat: -23.3467177
  },
  {
    lng: -44.6584369,
    lat: -23.3465014
  },
  {
    lng: -44.6586567,
    lat: -23.3461961
  },
  {
    lng: -44.6588491,
    lat: -23.3462087
  },
  {
    lng: -44.6593326,
    lat: -23.3463069
  },
  {
    lng: -44.6594211,
    lat: -23.346297
  },
  {
    lng: -44.6588552,
    lat: -23.346068
  },
  {
    lng: -44.6586782,
    lat: -23.345871
  },
  {
    lng: -44.6586209,
    lat: -23.3456814
  },
  {
    lng: -44.6585147,
    lat: -23.3454822
  },
  {
    lng: -44.6586073,
    lat: -23.3453325
  },
  {
    lng: -44.6588082,
    lat: -23.3453023
  },
  {
    lng: -44.6590534,
    lat: -23.3453629
  },
  {
    lng: -44.6591864,
    lat: -23.3454871
  },
  {
    lng: -44.6594189,
    lat: -23.3455451
  },
  {
    lng: -44.6596623,
    lat: -23.3456276
  },
  {
    lng: -44.6598672,
    lat: -23.3456267
  },
  {
    lng: -44.6602277,
    lat: -23.3454336
  },
  {
    lng: -44.6604272,
    lat: -23.3453823
  },
  {
    lng: -44.6605241,
    lat: -23.3450435
  },
  {
    lng: -44.6604851,
    lat: -23.3448103
  },
  {
    lng: -44.6605608,
    lat: -23.3447184
  },
  {
    lng: -44.660901,
    lat: -23.3447304
  },
  {
    lng: -44.6612709,
    lat: -23.3449008
  },
  {
    lng: -44.6618803,
    lat: -23.3449839
  },
  {
    lng: -44.6623796,
    lat: -23.344977
  },
  {
    lng: -44.6624624,
    lat: -23.3449428
  },
  {
    lng: -44.6627197,
    lat: -23.3448365
  },
  {
    lng: -44.6628979,
    lat: -23.3446006
  },
  {
    lng: -44.6630561,
    lat: -23.3442767
  },
  {
    lng: -44.6631172,
    lat: -23.3441102
  },
  {
    lng: -44.6629456,
    lat: -23.343726
  },
  {
    lng: -44.6629242,
    lat: -23.3436029
  },
  {
    lng: -44.6629778,
    lat: -23.3434724
  },
  {
    lng: -44.6629643,
    lat: -23.3433394
  },
  {
    lng: -44.6628222,
    lat: -23.3432508
  },
  {
    lng: -44.6625889,
    lat: -23.3431227
  },
  {
    lng: -44.6623406,
    lat: -23.3429183
  },
  {
    lng: -44.6620351,
    lat: -23.3426531
  },
  {
    lng: -44.6618714,
    lat: -23.3424357
  },
  {
    lng: -44.6618076,
    lat: -23.3423006
  },
  {
    lng: -44.6618667,
    lat: -23.3421524
  },
  {
    lng: -44.661947,
    lat: -23.3420689
  },
  {
    lng: -44.6620639,
    lat: -23.3420905
  },
  {
    lng: -44.6621453,
    lat: -23.3421305
  },
  {
    lng: -44.6621655,
    lat: -23.3422736
  },
  {
    lng: -44.6621876,
    lat: -23.3423572
  },
  {
    lng: -44.6622263,
    lat: -23.3425036
  },
  {
    lng: -44.6622765,
    lat: -23.3426227
  },
  {
    lng: -44.6624027,
    lat: -23.3427513
  },
  {
    lng: -44.66268,
    lat: -23.3429694
  },
  {
    lng: -44.6628501,
    lat: -23.3430514
  },
  {
    lng: -44.6628807,
    lat: -23.3429593
  },
  {
    lng: -44.6629831,
    lat: -23.342885
  },
  {
    lng: -44.6630337,
    lat: -23.3428913
  },
  {
    lng: -44.6632115,
    lat: -23.342924
  },
  {
    lng: -44.6633215,
    lat: -23.3429338
  },
  {
    lng: -44.6635116,
    lat: -23.3429329
  },
  {
    lng: -44.6637903,
    lat: -23.3428924
  },
  {
    lng: -44.6643023,
    lat: -23.3427077
  },
  {
    lng: -44.6644977,
    lat: -23.3425094
  },
  {
    lng: -44.6646356,
    lat: -23.3423885
  },
  {
    lng: -44.6648078,
    lat: -23.3422986
  },
  {
    lng: -44.6649285,
    lat: -23.3422573
  },
  {
    lng: -44.6650196,
    lat: -23.3422485
  },
  {
    lng: -44.6651718,
    lat: -23.3423229
  },
  {
    lng: -44.6658612,
    lat: -23.3427411
  },
  {
    lng: -44.6659827,
    lat: -23.3427255
  },
  {
    lng: -44.6661195,
    lat: -23.3426118
  },
  {
    lng: -44.6663196,
    lat: -23.3424016
  },
  {
    lng: -44.6658073,
    lat: -23.3417957
  },
  {
    lng: -44.6657738,
    lat: -23.3417027
  },
  {
    lng: -44.6657691,
    lat: -23.3415546
  },
  {
    lng: -44.6658373,
    lat: -23.3415194
  },
  {
    lng: -44.6659487,
    lat: -23.3415611
  },
  {
    lng: -44.6660078,
    lat: -23.3415486
  },
  {
    lng: -44.6661248,
    lat: -23.3414273
  },
  {
    lng: -44.6662162,
    lat: -23.3414138
  },
  {
    lng: -44.6663628,
    lat: -23.3413731
  },
  {
    lng: -44.6671003,
    lat: -23.3416786
  },
  {
    lng: -44.6673049,
    lat: -23.3415406
  },
  {
    lng: -44.6669701,
    lat: -23.3409748
  },
  {
    lng: -44.6673182,
    lat: -23.3399877
  },
  {
    lng: -44.6675268,
    lat: -23.3398684
  },
  {
    lng: -44.6681991,
    lat: -23.3409623
  },
  {
    lng: -44.6688156,
    lat: -23.3405388
  },
  {
    lng: -44.668113,
    lat: -23.3394335
  },
  {
    lng: -44.6690747,
    lat: -23.3389538
  },
  {
    lng: -44.669791,
    lat: -23.3400171
  },
  {
    lng: -44.670098,
    lat: -23.3399378
  },
  {
    lng: -44.6700855,
    lat: -23.3384239
  },
  {
    lng: -44.6700444,
    lat: -23.338377
  },
  {
    lng: -44.6700941,
    lat: -23.3382279
  },
  {
    lng: -44.6696671,
    lat: -23.3381468
  },
  {
    lng: -44.6690749,
    lat: -23.33809
  },
  {
    lng: -44.6685572,
    lat: -23.3380853
  },
  {
    lng: -44.6685549,
    lat: -23.3384875
  },
  {
    lng: -44.6685067,
    lat: -23.3383959
  },
  {
    lng: -44.6683837,
    lat: -23.338423
  },
  {
    lng: -44.6682119,
    lat: -23.3384478
  },
  {
    lng: -44.6678991,
    lat: -23.3385774
  },
  {
    lng: -44.6678524,
    lat: -23.3386472
  },
  {
    lng: -44.6678398,
    lat: -23.338726
  },
  {
    lng: -44.6678407,
    lat: -23.3388382
  },
  {
    lng: -44.6678408,
    lat: -23.3388616
  },
  {
    lng: -44.6678409,
    lat: -23.3388856
  },
  {
    lng: -44.6678051,
    lat: -23.3389429
  },
  {
    lng: -44.6677548,
    lat: -23.3389069
  },
  {
    lng: -44.6677544,
    lat: -23.3388998
  },
  {
    lng: -44.6677539,
    lat: -23.3388881
  },
  {
    lng: -44.6677499,
    lat: -23.3388191
  },
  {
    lng: -44.6677416,
    lat: -23.3386501
  },
  {
    lng: -44.6677017,
    lat: -23.3385535
  },
  {
    lng: -44.6675562,
    lat: -23.3384055
  },
  {
    lng: -44.6674512,
    lat: -23.3383567
  },
  {
    lng: -44.6673038,
    lat: -23.3383142
  },
  {
    lng: -44.6671466,
    lat: -23.3383084
  },
  {
    lng: -44.6670058,
    lat: -23.3383156
  },
  {
    lng: -44.6669473,
    lat: -23.3383909
  },
  {
    lng: -44.6660772,
    lat: -23.3390862
  },
  {
    lng: -44.666432,
    lat: -23.3404258
  },
  {
    lng: -44.6664356,
    lat: -23.3405244
  },
  {
    lng: -44.6663101,
    lat: -23.3407017
  },
  {
    lng: -44.6661133,
    lat: -23.3408449
  },
  {
    lng: -44.6659234,
    lat: -23.3410381
  },
  {
    lng: -44.6655794,
    lat: -23.3414067
  },
  {
    lng: -44.6650457,
    lat: -23.34191
  },
  {
    lng: -44.6649671,
    lat: -23.3419534
  },
  {
    lng: -44.6648767,
    lat: -23.341937
  },
  {
    lng: -44.6648086,
    lat: -23.341897
  },
  {
    lng: -44.664696,
    lat: -23.3419166
  },
  {
    lng: -44.6645396,
    lat: -23.3419603
  },
  {
    lng: -44.6644139,
    lat: -23.3420327
  },
  {
    lng: -44.6642958,
    lat: -23.3421131
  },
  {
    lng: -44.6641061,
    lat: -23.3421547
  },
  {
    lng: -44.6636969,
    lat: -23.3422017
  },
  {
    lng: -44.6633014,
    lat: -23.3422371
  },
  {
    lng: -44.6629244,
    lat: -23.3422779
  },
  {
    lng: -44.6626664,
    lat: -23.3422652
  },
  {
    lng: -44.6626763,
    lat: -23.3421743
  },
  {
    lng: -44.6627409,
    lat: -23.342111
  },
  {
    lng: -44.6630305,
    lat: -23.3421048
  },
  {
    lng: -44.6632774,
    lat: -23.3420925
  },
  {
    lng: -44.6634539,
    lat: -23.3420826
  },
  {
    lng: -44.6636506,
    lat: -23.3420572
  },
  {
    lng: -44.6637959,
    lat: -23.3416131
  },
  {
    lng: -44.6637798,
    lat: -23.3409284
  },
  {
    lng: -44.663745,
    lat: -23.340559
  },
  {
    lng: -44.6635062,
    lat: -23.3400049
  },
  {
    lng: -44.6633051,
    lat: -23.3396404
  },
  {
    lng: -44.6631467,
    lat: -23.3394163
  },
  {
    lng: -44.662613,
    lat: -23.3389509
  },
  {
    lng: -44.6621275,
    lat: -23.3386036
  },
  {
    lng: -44.661862,
    lat: -23.3385002
  },
  {
    lng: -44.6616308,
    lat: -23.338447
  },
  {
    lng: -44.6613954,
    lat: -23.3385313
  },
  {
    lng: -44.6613696,
    lat: -23.3385917
  },
  {
    lng: -44.6612357,
    lat: -23.3387033
  },
  {
    lng: -44.6608734,
    lat: -23.3388865
  },
  {
    lng: -44.6603581,
    lat: -23.3390231
  },
  {
    lng: -44.6599646,
    lat: -23.3392544
  },
  {
    lng: -44.6594541,
    lat: -23.3397544
  },
  {
    lng: -44.6592659,
    lat: -23.3397982
  },
  {
    lng: -44.6590323,
    lat: -23.3398621
  },
  {
    lng: -44.6587265,
    lat: -23.3401034
  },
  {
    lng: -44.6584797,
    lat: -23.3403743
  },
  {
    lng: -44.6583241,
    lat: -23.3406255
  },
  {
    lng: -44.6583724,
    lat: -23.3408915
  },
  {
    lng: -44.6584421,
    lat: -23.3411082
  },
  {
    lng: -44.6586482,
    lat: -23.341273
  },
  {
    lng: -44.6585859,
    lat: -23.3414358
  },
  {
    lng: -44.6583614,
    lat: -23.3415226
  },
  {
    lng: -44.658046,
    lat: -23.341579
  },
  {
    lng: -44.6578705,
    lat: -23.3414868
  },
  {
    lng: -44.657426,
    lat: -23.3415924
  },
  {
    lng: -44.6572409,
    lat: -23.3419572
  },
  {
    lng: -44.6571226,
    lat: -23.3420539
  },
  {
    lng: -44.656747,
    lat: -23.3419554
  },
  {
    lng: -44.6565432,
    lat: -23.3420736
  },
  {
    lng: -44.6565539,
    lat: -23.3422164
  },
  {
    lng: -44.6564374,
    lat: -23.3423606
  },
  {
    lng: -44.6564439,
    lat: -23.3424915
  },
  {
    lng: -44.6561762,
    lat: -23.3426711
  },
  {
    lng: -44.6556602,
    lat: -23.3427717
  },
  {
    lng: -44.6554392,
    lat: -23.3427657
  },
  {
    lng: -44.6552504,
    lat: -23.3427927
  },
  {
    lng: -44.6551545,
    lat: -23.3429701
  },
  {
    lng: -44.6549996,
    lat: -23.3429751
  },
  {
    lng: -44.6545959,
    lat: -23.3427779
  },
  {
    lng: -44.6543813,
    lat: -23.3426991
  },
  {
    lng: -44.6543652,
    lat: -23.3425464
  },
  {
    lng: -44.6541613,
    lat: -23.342374
  },
  {
    lng: -44.6540004,
    lat: -23.3421967
  },
  {
    lng: -44.6537537,
    lat: -23.3422214
  },
  {
    lng: -44.6537858,
    lat: -23.3424085
  },
  {
    lng: -44.6536034,
    lat: -23.3424282
  },
  {
    lng: -44.6532494,
    lat: -23.3422017
  },
  {
    lng: -44.652713,
    lat: -23.3416746
  },
  {
    lng: -44.6528579,
    lat: -23.3420933
  },
  {
    lng: -44.6524819,
    lat: -23.3419055
  },
  {
    lng: -44.6521069,
    lat: -23.3416352
  },
  {
    lng: -44.651549,
    lat: -23.3414037
  },
  {
    lng: -44.6511091,
    lat: -23.3409457
  },
  {
    lng: -44.6513506,
    lat: -23.340799
  },
  {
    lng: -44.6509534,
    lat: -23.340399
  },
  {
    lng: -44.6504761,
    lat: -23.3393055
  },
  {
    lng: -44.6500845,
    lat: -23.3388918
  },
  {
    lng: -44.6498538,
    lat: -23.3385273
  },
  {
    lng: -44.6496839,
    lat: -23.3380355
  },
  {
    lng: -44.6491677,
    lat: -23.3378288
  },
  {
    lng: -44.6489141,
    lat: -23.3375129
  },
  {
    lng: -44.6489418,
    lat: -23.3373156
  },
  {
    lng: -44.6487917,
    lat: -23.3371137
  },
  {
    lng: -44.6488989,
    lat: -23.3368378
  },
  {
    lng: -44.6488614,
    lat: -23.3366063
  },
  {
    lng: -44.6486522,
    lat: -23.3359414
  },
  {
    lng: -44.6485169,
    lat: -23.3355378
  },
  {
    lng: -44.6476261,
    lat: -23.3347712
  },
  {
    lng: -44.6476399,
    lat: -23.3346179
  },
  {
    lng: -44.6473691,
    lat: -23.3343401
  },
  {
    lng: -44.6467483,
    lat: -23.3339699
  },
  {
    lng: -44.6463927,
    lat: -23.3336894
  },
  {
    lng: -44.6460614,
    lat: -23.3336051
  },
  {
    lng: -44.6456156,
    lat: -23.3336261
  },
  {
    lng: -44.6453268,
    lat: -23.3337304
  },
  {
    lng: -44.6451216,
    lat: -23.333767
  },
  {
    lng: -44.6447701,
    lat: -23.3340847
  },
  {
    lng: -44.6443206,
    lat: -23.3341235
  },
  {
    lng: -44.6440371,
    lat: -23.3339715
  },
  {
    lng: -44.6435255,
    lat: -23.3336701
  },
  {
    lng: -44.6429456,
    lat: -23.3331012
  },
  {
    lng: -44.6427258,
    lat: -23.3329928
  },
  {
    lng: -44.6423903,
    lat: -23.333016
  },
  {
    lng: -44.6418204,
    lat: -23.3326183
  },
  {
    lng: -44.6413467,
    lat: -23.3322546
  },
  {
    lng: -44.641121,
    lat: -23.3318396
  },
  {
    lng: -44.6405659,
    lat: -23.3312969
  },
  {
    lng: -44.6399777,
    lat: -23.3311228
  },
  {
    lng: -44.6393871,
    lat: -23.3310705
  },
  {
    lng: -44.6384723,
    lat: -23.3314424
  },
  {
    lng: -44.6361924,
    lat: -23.3323553
  },
  {
    lng: -44.633981,
    lat: -23.333415
  },
  {
    lng: -44.6325426,
    lat: -23.3342894
  },
  {
    lng: -44.6310274,
    lat: -23.3353528
  },
  {
    lng: -44.6296583,
    lat: -23.3364097
  },
  {
    lng: -44.6289406,
    lat: -23.3370201
  },
  {
    lng: -44.6285439,
    lat: -23.3374725
  },
  {
    lng: -44.6283023,
    lat: -23.3378131
  },
  {
    lng: -44.6290533,
    lat: -23.3385741
  },
  {
    lng: -44.6292164,
    lat: -23.3390788
  },
  {
    lng: -44.6292956,
    lat: -23.3392621
  },
  {
    lng: -44.6289755,
    lat: -23.3393203
  },
  {
    lng: -44.6288896,
    lat: -23.3394582
  },
  {
    lng: -44.6287824,
    lat: -23.3397833
  },
  {
    lng: -44.6289861,
    lat: -23.3402758
  },
  {
    lng: -44.6290921,
    lat: -23.3405847
  },
  {
    lng: -44.6291794,
    lat: -23.3407782
  },
  {
    lng: -44.6291527,
    lat: -23.3411685
  },
  {
    lng: -44.6288814,
    lat: -23.341589
  },
  {
    lng: -44.6288432,
    lat: -23.3421237
  },
  {
    lng: -44.6286659,
    lat: -23.3425871
  },
  {
    lng: -44.6286388,
    lat: -23.3428462
  },
  {
    lng: -44.6289526,
    lat: -23.3433491
  },
  {
    lng: -44.6291774,
    lat: -23.3437092
  },
  {
    lng: -44.6294661,
    lat: -23.3440172
  },
  {
    lng: -44.6296338,
    lat: -23.3441804
  },
  {
    lng: -44.6296127,
    lat: -23.3444476
  },
  {
    lng: -44.6297476,
    lat: -23.3445884
  },
  {
    lng: -44.6298876,
    lat: -23.3446968
  },
  {
    lng: -44.630084,
    lat: -23.3446368
  },
  {
    lng: -44.6301904,
    lat: -23.3446471
  },
  {
    lng: -44.6302173,
    lat: -23.3446138
  },
  {
    lng: -44.6302682,
    lat: -23.3446126
  },
  {
    lng: -44.6303755,
    lat: -23.3446865
  },
  {
    lng: -44.630515,
    lat: -23.3447727
  },
  {
    lng: -44.6307797,
    lat: -23.3449387
  },
  {
    lng: -44.6311587,
    lat: -23.3452824
  },
  {
    lng: -44.6313843,
    lat: -23.3455901
  },
  {
    lng: -44.6317689,
    lat: -23.346169
  },
  {
    lng: -44.6320224,
    lat: -23.3466098
  },
  {
    lng: -44.6325267,
    lat: -23.3474766
  },
  {
    lng: -44.6325911,
    lat: -23.3478411
  },
  {
    lng: -44.6329653,
    lat: -23.3486041
  },
  {
    lng: -44.6327759,
    lat: -23.3490261
  },
  {
    lng: -44.632795,
    lat: -23.3494368
  },
  {
    lng: -44.6333206,
    lat: -23.3501066
  },
  {
    lng: -44.633796,
    lat: -23.350568
  },
  {
    lng: -44.6341868,
    lat: -23.3506944
  },
  {
    lng: -44.6348121,
    lat: -23.3505065
  },
  {
    lng: -44.6351981,
    lat: -23.3504514
  },
  {
    lng: -44.6353699,
    lat: -23.3505499
  },
  {
    lng: -44.6355844,
    lat: -23.3508552
  },
  {
    lng: -44.6353461,
    lat: -23.3510235
  },
  {
    lng: -44.6351895,
    lat: -23.3510297
  },
  {
    lng: -44.6349094,
    lat: -23.350893
  },
  {
    lng: -44.634783,
    lat: -23.3507926
  },
  {
    lng: -44.6347236,
    lat: -23.3508136
  },
  {
    lng: -44.6346578,
    lat: -23.3509522
  },
  {
    lng: -44.6346428,
    lat: -23.3510892
  },
  {
    lng: -44.634701,
    lat: -23.3512592
  },
  {
    lng: -44.634592,
    lat: -23.3516696
  },
  {
    lng: -44.634615,
    lat: -23.3520301
  },
  {
    lng: -44.6344273,
    lat: -23.3522055
  },
  {
    lng: -44.6341546,
    lat: -23.3521463
  },
  {
    lng: -44.6342708,
    lat: -23.3522718
  },
  {
    lng: -44.6342257,
    lat: -23.3524066
  },
  {
    lng: -44.6339999,
    lat: -23.3525089
  },
  {
    lng: -44.6336555,
    lat: -23.3524749
  },
  {
    lng: -44.6335256,
    lat: -23.3524578
  },
  {
    lng: -44.6334411,
    lat: -23.3525717
  },
  {
    lng: -44.6332307,
    lat: -23.3525603
  },
  {
    lng: -44.6329399,
    lat: -23.3524006
  },
  {
    lng: -44.6325939,
    lat: -23.3521421
  },
  {
    lng: -44.6324483,
    lat: -23.3522583
  },
  {
    lng: -44.6320503,
    lat: -23.3522526
  },
  {
    lng: -44.6317575,
    lat: -23.352289
  },
  {
    lng: -44.6316582,
    lat: -23.3522151
  },
  {
    lng: -44.6315493,
    lat: -23.3522369
  },
  {
    lng: -44.6314135,
    lat: -23.3522075
  },
  {
    lng: -44.6314043,
    lat: -23.3522114
  },
  {
    lng: -44.6314728,
    lat: -23.3522939
  },
  {
    lng: -44.6314841,
    lat: -23.3523634
  },
  {
    lng: -44.6313777,
    lat: -23.3523998
  },
  {
    lng: -44.6311051,
    lat: -23.3523384
  },
  {
    lng: -44.6310382,
    lat: -23.3521889
  },
  {
    lng: -44.6310864,
    lat: -23.3520914
  },
  {
    lng: -44.6307805,
    lat: -23.3519384
  },
  {
    lng: -44.6303523,
    lat: -23.3515592
  },
  {
    lng: -44.6303433,
    lat: -23.3514701
  },
  {
    lng: -44.62839,
    lat: -23.3495673
  },
  {
    lng: -44.6283447,
    lat: -23.3487026
  },
  {
    lng: -44.6281703,
    lat: -23.3484418
  },
  {
    lng: -44.6281917,
    lat: -23.3481913
  },
  {
    lng: -44.6280864,
    lat: -23.3479414
  },
  {
    lng: -44.6279544,
    lat: -23.3476202
  },
  {
    lng: -44.6279242,
    lat: -23.3472668
  },
  {
    lng: -44.6276618,
    lat: -23.3470122
  },
  {
    lng: -44.6276251,
    lat: -23.3468695
  },
  {
    lng: -44.6274595,
    lat: -23.3465667
  },
  {
    lng: -44.6271719,
    lat: -23.3460281
  },
  {
    lng: -44.6270143,
    lat: -23.3458842
  },
  {
    lng: -44.6269938,
    lat: -23.3457749
  },
  {
    lng: -44.6271421,
    lat: -23.3457222
  },
  {
    lng: -44.6273525,
    lat: -23.3457417
  },
  {
    lng: -44.627361,
    lat: -23.3456654
  },
  {
    lng: -44.6272414,
    lat: -23.3454865
  },
  {
    lng: -44.6270583,
    lat: -23.3449387
  },
  {
    lng: -44.6271885,
    lat: -23.3447055
  },
  {
    lng: -44.6270618,
    lat: -23.3445368
  },
  {
    lng: -44.6265174,
    lat: -23.3432539
  },
  {
    lng: -44.6264908,
    lat: -23.3430145
  },
  {
    lng: -44.6264975,
    lat: -23.3423583
  },
  {
    lng: -44.6262144,
    lat: -23.3416158
  },
  {
    lng: -44.6262245,
    lat: -23.3410568
  },
  {
    lng: -44.6260708,
    lat: -23.3407378
  },
  {
    lng: -44.625733,
    lat: -23.3400531
  },
  {
    lng: -44.6255949,
    lat: -23.3397083
  },
  {
    lng: -44.6253893,
    lat: -23.3395548
  },
  {
    lng: -44.6251127,
    lat: -23.3395563
  },
  {
    lng: -44.6250146,
    lat: -23.3395788
  },
  {
    lng: -44.6247513,
    lat: -23.3397068
  },
  {
    lng: -44.6247397,
    lat: -23.3397821
  },
  {
    lng: -44.6247409,
    lat: -23.3398526
  },
  {
    lng: -44.6246744,
    lat: -23.3398722
  },
  {
    lng: -44.6246181,
    lat: -23.339837
  },
  {
    lng: -44.6245692,
    lat: -23.3397497
  },
  {
    lng: -44.624449,
    lat: -23.3397081
  },
  {
    lng: -44.6243759,
    lat: -23.3391462
  },
  {
    lng: -44.6241129,
    lat: -23.3390928
  },
  {
    lng: -44.6236058,
    lat: -23.3391057
  },
  {
    lng: -44.6224845,
    lat: -23.3392127
  },
  {
    lng: -44.621911,
    lat: -23.3393978
  },
  {
    lng: -44.6216371,
    lat: -23.3395353
  },
  {
    lng: -44.6212186,
    lat: -23.3398911
  },
  {
    lng: -44.6210382,
    lat: -23.3401534
  },
  {
    lng: -44.6210418,
    lat: -23.3402949
  },
  {
    lng: -44.6213097,
    lat: -23.3405221
  },
  {
    lng: -44.6214385,
    lat: -23.3406748
  },
  {
    lng: -44.6213848,
    lat: -23.3408816
  },
  {
    lng: -44.6213419,
    lat: -23.3409949
  },
  {
    lng: -44.6214975,
    lat: -23.3410934
  },
  {
    lng: -44.6217274,
    lat: -23.3413308
  },
  {
    lng: -44.6215403,
    lat: -23.3413397
  },
  {
    lng: -44.6212882,
    lat: -23.3412363
  },
  {
    lng: -44.621235,
    lat: -23.3412961
  },
  {
    lng: -44.6210544,
    lat: -23.3412833
  },
  {
    lng: -44.6209274,
    lat: -23.3412244
  },
  {
    lng: -44.6207151,
    lat: -23.3412109
  },
  {
    lng: -44.6203636,
    lat: -23.3413283
  },
  {
    lng: -44.6196933,
    lat: -23.3409867
  },
  {
    lng: -44.6192405,
    lat: -23.3411646
  },
  {
    lng: -44.6185739,
    lat: -23.3415761
  },
  {
    lng: -44.6184183,
    lat: -23.3418938
  },
  {
    lng: -44.6183163,
    lat: -23.3423445
  },
  {
    lng: -44.6182936,
    lat: -23.3428026
  },
  {
    lng: -44.6185498,
    lat: -23.3434245
  },
  {
    lng: -44.6185819,
    lat: -23.3436559
  },
  {
    lng: -44.6186831,
    lat: -23.3438839
  },
  {
    lng: -44.6186136,
    lat: -23.3441219
  },
  {
    lng: -44.6187829,
    lat: -23.3443975
  },
  {
    lng: -44.6185313,
    lat: -23.3447825
  },
  {
    lng: -44.6184143,
    lat: -23.3448069
  },
  {
    lng: -44.6183204,
    lat: -23.3447183
  },
  {
    lng: -44.618175,
    lat: -23.3447676
  },
  {
    lng: -44.6180271,
    lat: -23.3449148
  },
  {
    lng: -44.618054,
    lat: -23.3450996
  },
  {
    lng: -44.6181907,
    lat: -23.3453999
  },
  {
    lng: -44.6181396,
    lat: -23.3456005
  },
  {
    lng: -44.6180773,
    lat: -23.3457253
  },
  {
    lng: -44.6177417,
    lat: -23.3457487
  },
  {
    lng: -44.6174613,
    lat: -23.3459446
  },
  {
    lng: -44.6171331,
    lat: -23.3460074
  },
  {
    lng: -44.6169978,
    lat: -23.346057
  },
  {
    lng: -44.617042,
    lat: -23.3462672
  },
  {
    lng: -44.6170649,
    lat: -23.3464036
  },
  {
    lng: -44.6169099,
    lat: -23.3465723
  },
  {
    lng: -44.6166714,
    lat: -23.3466202
  },
  {
    lng: -44.6164099,
    lat: -23.3465349
  },
  {
    lng: -44.6162499,
    lat: -23.3465415
  },
  {
    lng: -44.6159908,
    lat: -23.3466496
  },
  {
    lng: -44.6158604,
    lat: -23.3470628
  },
  {
    lng: -44.6158241,
    lat: -23.3474558
  },
  {
    lng: -44.6161851,
    lat: -23.3480247
  },
  {
    lng: -44.6162425,
    lat: -23.3482511
  },
  {
    lng: -44.6164173,
    lat: -23.3484703
  },
  {
    lng: -44.6166792,
    lat: -23.3486322
  },
  {
    lng: -44.6169279,
    lat: -23.348972
  },
  {
    lng: -44.6171604,
    lat: -23.3490987
  },
  {
    lng: -44.617314,
    lat: -23.3494148
  },
  {
    lng: -44.617296,
    lat: -23.349572
  },
  {
    lng: -44.6173224,
    lat: -23.3498809
  },
  {
    lng: -44.6176165,
    lat: -23.3501739
  },
  {
    lng: -44.6176946,
    lat: -23.3504906
  },
  {
    lng: -44.6177811,
    lat: -23.3507163
  },
  {
    lng: -44.6175746,
    lat: -23.3508187
  },
  {
    lng: -44.6172569,
    lat: -23.3509614
  },
  {
    lng: -44.6171182,
    lat: -23.3510557
  },
  {
    lng: -44.6169196,
    lat: -23.3510081
  },
  {
    lng: -44.6167568,
    lat: -23.3508812
  },
  {
    lng: -44.6164891,
    lat: -23.3507195
  },
  {
    lng: -44.6161627,
    lat: -23.3505406
  },
  {
    lng: -44.6159468,
    lat: -23.3501372
  },
  {
    lng: -44.6159485,
    lat: -23.3500171
  },
  {
    lng: -44.6158699,
    lat: -23.3497109
  },
  {
    lng: -44.6157692,
    lat: -23.3494824
  },
  {
    lng: -44.6157059,
    lat: -23.3494311
  },
  {
    lng: -44.6155465,
    lat: -23.3492615
  },
  {
    lng: -44.6155332,
    lat: -23.3491846
  },
  {
    lng: -44.615408,
    lat: -23.349121
  },
  {
    lng: -44.615244,
    lat: -23.3491923
  },
  {
    lng: -44.615021,
    lat: -23.349089
  },
  {
    lng: -44.6149934,
    lat: -23.3490046
  },
  {
    lng: -44.6148694,
    lat: -23.348973
  },
  {
    lng: -44.614789,
    lat: -23.3490573
  },
  {
    lng: -44.614587,
    lat: -23.349147
  },
  {
    lng: -44.6144983,
    lat: -23.3491015
  },
  {
    lng: -44.614479,
    lat: -23.3490004
  },
  {
    lng: -44.6142769,
    lat: -23.3490025
  },
  {
    lng: -44.6142103,
    lat: -23.3488507
  },
  {
    lng: -44.6140313,
    lat: -23.3486896
  },
  {
    lng: -44.6138721,
    lat: -23.3486319
  },
  {
    lng: -44.6138271,
    lat: -23.34845
  },
  {
    lng: -44.6139382,
    lat: -23.3483729
  },
  {
    lng: -44.6140552,
    lat: -23.3482075
  },
  {
    lng: -44.6138691,
    lat: -23.3479844
  },
  {
    lng: -44.6136832,
    lat: -23.3478276
  },
  {
    lng: -44.6133581,
    lat: -23.3475951
  },
  {
    lng: -44.6132226,
    lat: -23.3474305
  },
  {
    lng: -44.6128869,
    lat: -23.3473364
  },
  {
    lng: -44.612805,
    lat: -23.3473299
  },
  {
    lng: -44.6126507,
    lat: -23.3473178
  },
  {
    lng: -44.612509,
    lat: -23.3474231
  },
  {
    lng: -44.6123786,
    lat: -23.3474499
  },
  {
    lng: -44.6123021,
    lat: -23.3475201
  },
  {
    lng: -44.6123066,
    lat: -23.3475945
  },
  {
    lng: -44.6122256,
    lat: -23.3476378
  },
  {
    lng: -44.6122324,
    lat: -23.3477473
  },
  {
    lng: -44.6121941,
    lat: -23.3477494
  },
  {
    lng: -44.6121177,
    lat: -23.3475718
  },
  {
    lng: -44.6120029,
    lat: -23.3476234
  },
  {
    lng: -44.611895,
    lat: -23.3475346
  },
  {
    lng: -44.611814,
    lat: -23.3475119
  },
  {
    lng: -44.611751,
    lat: -23.3474561
  },
  {
    lng: -44.611706,
    lat: -23.347452
  },
  {
    lng: -44.6117465,
    lat: -23.347547
  },
  {
    lng: -44.6117085,
    lat: -23.3476377
  },
  {
    lng: -44.6117489,
    lat: -23.3477919
  },
  {
    lng: -44.6118912,
    lat: -23.347814
  },
  {
    lng: -44.6118103,
    lat: -23.347924
  },
  {
    lng: -44.6118118,
    lat: -23.3479735
  },
  {
    lng: -44.611541,
    lat: -23.348235
  },
  {
    lng: -44.6115481,
    lat: -23.3484227
  },
  {
    lng: -44.6116245,
    lat: -23.3484618
  },
  {
    lng: -44.6116885,
    lat: -23.348545
  },
  {
    lng: -44.6116725,
    lat: -23.3487049
  },
  {
    lng: -44.6115979,
    lat: -23.348811
  },
  {
    lng: -44.6116565,
    lat: -23.348886
  },
  {
    lng: -44.6116601,
    lat: -23.3489578
  },
  {
    lng: -44.6117027,
    lat: -23.34893
  },
  {
    lng: -44.611804,
    lat: -23.3489676
  },
  {
    lng: -44.6118911,
    lat: -23.349072
  },
  {
    lng: -44.6118626,
    lat: -23.3491813
  },
  {
    lng: -44.6117471,
    lat: -23.349196
  },
  {
    lng: -44.6116441,
    lat: -23.3490785
  },
  {
    lng: -44.6116299,
    lat: -23.3490051
  },
  {
    lng: -44.6115748,
    lat: -23.3490296
  },
  {
    lng: -44.6114841,
    lat: -23.3490751
  },
  {
    lng: -44.6115997,
    lat: -23.34932
  },
  {
    lng: -44.6115392,
    lat: -23.3494374
  },
  {
    lng: -44.6114006,
    lat: -23.3494945
  },
  {
    lng: -44.6109635,
    lat: -23.3494717
  },
  {
    lng: -44.6105656,
    lat: -23.3494422
  },
  {
    lng: -44.6105121,
    lat: -23.3493085
  },
  {
    lng: -44.6105974,
    lat: -23.3490801
  },
  {
    lng: -44.6104606,
    lat: -23.34893
  },
  {
    lng: -44.6102262,
    lat: -23.3487505
  },
  {
    lng: -44.6098173,
    lat: -23.3486478
  },
  {
    lng: -44.6095965,
    lat: -23.3485601
  },
  {
    lng: -44.6094584,
    lat: -23.3486478
  },
  {
    lng: -44.6089774,
    lat: -23.3485732
  },
  {
    lng: -44.6088668,
    lat: -23.3484511
  },
  {
    lng: -44.6087432,
    lat: -23.3483943
  },
  {
    lng: -44.6085638,
    lat: -23.3483968
  },
  {
    lng: -44.6084299,
    lat: -23.3483038
  },
  {
    lng: -44.6083457,
    lat: -23.3482722
  },
  {
    lng: -44.608191,
    lat: -23.3481601
  },
  {
    lng: -44.6081304,
    lat: -23.3480421
  },
  {
    lng: -44.6081373,
    lat: -23.3479446
  },
  {
    lng: -44.6081361,
    lat: -23.3478706
  },
  {
    lng: -44.6081185,
    lat: -23.347787
  },
  {
    lng: -44.6080945,
    lat: -23.3477241
  },
  {
    lng: -44.608065,
    lat: -23.3476822
  },
  {
    lng: -44.6079872,
    lat: -23.3476342
  },
  {
    lng: -44.6079027,
    lat: -23.347601
  },
  {
    lng: -44.607855,
    lat: -23.3475838
  },
  {
    lng: -44.6077256,
    lat: -23.3475672
  },
  {
    lng: -44.6076787,
    lat: -23.3475691
  },
  {
    lng: -44.6076371,
    lat: -23.3475777
  },
  {
    lng: -44.6075767,
    lat: -23.3475857
  },
  {
    lng: -44.6075097,
    lat: -23.3475925
  },
  {
    lng: -44.6074588,
    lat: -23.3476022
  },
  {
    lng: -44.6074057,
    lat: -23.3476226
  },
  {
    lng: -44.6073373,
    lat: -23.3476454
  },
  {
    lng: -44.6072307,
    lat: -23.3476639
  },
  {
    lng: -44.6071395,
    lat: -23.3476663
  },
  {
    lng: -44.6070344,
    lat: -23.3476896
  },
  {
    lng: -44.6069519,
    lat: -23.347726
  },
  {
    lng: -44.606866,
    lat: -23.3477721
  },
  {
    lng: -44.6067956,
    lat: -23.3478269
  },
  {
    lng: -44.6067655,
    lat: -23.3478842
  },
  {
    lng: -44.6067614,
    lat: -23.3479408
  },
  {
    lng: -44.6067908,
    lat: -23.3480573
  },
  {
    lng: -44.6068158,
    lat: -23.3481799
  },
  {
    lng: -44.606852,
    lat: -23.3483194
  },
  {
    lng: -44.6067659,
    lat: -23.3484984
  },
  {
    lng: -44.6065595,
    lat: -23.3487254
  },
  {
    lng: -44.6064109,
    lat: -23.3490819
  },
  {
    lng: -44.6064823,
    lat: -23.3492191
  },
  {
    lng: -44.6066759,
    lat: -23.3492783
  },
  {
    lng: -44.6068285,
    lat: -23.3494529
  },
  {
    lng: -44.6068421,
    lat: -23.3497771
  },
  {
    lng: -44.6067199,
    lat: -23.3500046
  },
  {
    lng: -44.6065875,
    lat: -23.3501449
  },
  {
    lng: -44.6068187,
    lat: -23.3511121
  },
  {
    lng: -44.6071644,
    lat: -23.3521628
  },
  {
    lng: -44.6072361,
    lat: -23.3533556
  },
  {
    lng: -44.6079104,
    lat: -23.3552682
  },
  {
    lng: -44.608281,
    lat: -23.3560702
  },
  {
    lng: -44.6086516,
    lat: -23.3562015
  },
  {
    lng: -44.6088081,
    lat: -23.3566261
  },
  {
    lng: -44.6086395,
    lat: -23.3566878
  },
  {
    lng: -44.6081137,
    lat: -23.3568804
  },
  {
    lng: -44.6081101,
    lat: -23.3571393
  },
  {
    lng: -44.6082253,
    lat: -23.3578765
  },
  {
    lng: -44.6076058,
    lat: -23.3604227
  },
  {
    lng: -44.6079285,
    lat: -23.3615491
  },
  {
    lng: -44.6077703,
    lat: -23.3631802
  },
  {
    lng: -44.6079519,
    lat: -23.3633175
  },
  {
    lng: -44.6081408,
    lat: -23.3634253
  },
  {
    lng: -44.6081438,
    lat: -23.3639199
  },
  {
    lng: -44.607615,
    lat: -23.3641842
  },
  {
    lng: -44.6078535,
    lat: -23.3644517
  },
  {
    lng: -44.607777,
    lat: -23.3653344
  },
  {
    lng: -44.6077136,
    lat: -23.3656074
  },
  {
    lng: -44.6075178,
    lat: -23.3656714
  },
  {
    lng: -44.6075205,
    lat: -23.3657871
  },
  {
    lng: -44.6075259,
    lat: -23.3658955
  },
  {
    lng: -44.6074588,
    lat: -23.3661392
  },
  {
    lng: -44.6073132,
    lat: -23.3662543
  },
  {
    lng: -44.6071048,
    lat: -23.3663461
  },
  {
    lng: -44.6070672,
    lat: -23.3664175
  },
  {
    lng: -44.6070565,
    lat: -23.3665283
  },
  {
    lng: -44.6070699,
    lat: -23.3666341
  },
  {
    lng: -44.6069889,
    lat: -23.366744
  },
  {
    lng: -44.606453,
    lat: -23.3666095
  },
  {
    lng: -44.606343,
    lat: -23.3665923
  },
  {
    lng: -44.6062894,
    lat: -23.3665874
  },
  {
    lng: -44.6061319,
    lat: -23.3666883
  },
  {
    lng: -44.6060694,
    lat: -23.3669961
  },
  {
    lng: -44.6060292,
    lat: -23.3670576
  },
  {
    lng: -44.6059214,
    lat: -23.3670292
  },
  {
    lng: -44.6048233,
    lat: -23.366546
  },
  {
    lng: -44.6045365,
    lat: -23.3662576
  },
  {
    lng: -44.6044291,
    lat: -23.3661597
  },
  {
    lng: -44.6040103,
    lat: -23.3660239
  },
  {
    lng: -44.6036685,
    lat: -23.3659901
  },
  {
    lng: -44.6033967,
    lat: -23.3657802
  },
  {
    lng: -44.6034801,
    lat: -23.3656807
  },
  {
    lng: -44.6035985,
    lat: -23.3656559
  },
  {
    lng: -44.6036921,
    lat: -23.3657011
  },
  {
    lng: -44.6037621,
    lat: -23.3657167
  },
  {
    lng: -44.6037832,
    lat: -23.3656478
  },
  {
    lng: -44.6038017,
    lat: -23.365435
  },
  {
    lng: -44.6039314,
    lat: -23.3654311
  },
  {
    lng: -44.604034,
    lat: -23.3650974
  },
  {
    lng: -44.6039612,
    lat: -23.3647136
  },
  {
    lng: -44.6038431,
    lat: -23.3643467
  },
  {
    lng: -44.6039263,
    lat: -23.3640759
  },
  {
    lng: -44.6038942,
    lat: -23.3639774
  },
  {
    lng: -44.6039776,
    lat: -23.3634153
  },
  {
    lng: -44.6036742,
    lat: -23.3631303
  },
  {
    lng: -44.6036314,
    lat: -23.3628283
  },
  {
    lng: -44.6034473,
    lat: -23.3625213
  },
  {
    lng: -44.6031907,
    lat: -23.3622172
  },
  {
    lng: -44.6027,
    lat: -23.3618782
  },
  {
    lng: -44.6021078,
    lat: -23.3616961
  },
  {
    lng: -44.6016366,
    lat: -23.3614444
  },
  {
    lng: -44.6011637,
    lat: -23.3608379
  },
  {
    lng: -44.6010358,
    lat: -23.3605925
  },
  {
    lng: -44.6004455,
    lat: -23.3604074
  },
  {
    lng: -44.599884,
    lat: -23.3600233
  },
  {
    lng: -44.6001329,
    lat: -23.3597778
  },
  {
    lng: -44.6000221,
    lat: -23.3595965
  },
  {
    lng: -44.599654,
    lat: -23.359438
  },
  {
    lng: -44.5991279,
    lat: -23.3592694
  },
  {
    lng: -44.5984573,
    lat: -23.3593531
  },
  {
    lng: -44.5982025,
    lat: -23.3592694
  },
  {
    lng: -44.5979728,
    lat: -23.3591194
  },
  {
    lng: -44.5976621,
    lat: -23.3589597
  },
  {
    lng: -44.5974168,
    lat: -23.3588566
  },
  {
    lng: -44.596898,
    lat: -23.35867
  },
  {
    lng: -44.5965219,
    lat: -23.3585496
  },
  {
    lng: -44.596053,
    lat: -23.3585679
  },
  {
    lng: -44.5958592,
    lat: -23.3586953
  },
  {
    lng: -44.595883,
    lat: -23.3589278
  },
  {
    lng: -44.5953464,
    lat: -23.3593634
  },
  {
    lng: -44.5943341,
    lat: -23.3597306
  },
  {
    lng: -44.5941121,
    lat: -23.3597274
  },
  {
    lng: -44.5938073,
    lat: -23.3598437
  },
  {
    lng: -44.5938259,
    lat: -23.3602068
  },
  {
    lng: -44.5935136,
    lat: -23.3604892
  },
  {
    lng: -44.5935734,
    lat: -23.3605745
  },
  {
    lng: -44.5935272,
    lat: -23.3606419
  },
  {
    lng: -44.5934051,
    lat: -23.3606754
  },
  {
    lng: -44.5933704,
    lat: -23.3605928
  },
  {
    lng: -44.5933091,
    lat: -23.3606447
  },
  {
    lng: -44.593338,
    lat: -23.3607913
  },
  {
    lng: -44.5933772,
    lat: -23.3609099
  },
  {
    lng: -44.5934669,
    lat: -23.3610137
  },
  {
    lng: -44.5934952,
    lat: -23.3610521
  },
  {
    lng: -44.5934179,
    lat: -23.361211
  },
  {
    lng: -44.5933324,
    lat: -23.3612907
  },
  {
    lng: -44.5932575,
    lat: -23.3611989
  },
  {
    lng: -44.5932172,
    lat: -23.3612908
  },
  {
    lng: -44.5930495,
    lat: -23.3614496
  },
  {
    lng: -44.5930025,
    lat: -23.36157
  },
  {
    lng: -44.5929559,
    lat: -23.3616343
  },
  {
    lng: -44.5928573,
    lat: -23.3616797
  },
  {
    lng: -44.5926978,
    lat: -23.3617531
  },
  {
    lng: -44.5926172,
    lat: -23.3617403
  },
  {
    lng: -44.5925038,
    lat: -23.3617063
  },
  {
    lng: -44.5924125,
    lat: -23.3616491
  },
  {
    lng: -44.5923402,
    lat: -23.3616535
  },
  {
    lng: -44.5923345,
    lat: -23.3617014
  },
  {
    lng: -44.592237,
    lat: -23.3617077
  },
  {
    lng: -44.5922286,
    lat: -23.3617548
  },
  {
    lng: -44.5921356,
    lat: -23.3617512
  },
  {
    lng: -44.5920411,
    lat: -23.3617911
  },
  {
    lng: -44.5918106,
    lat: -23.3617893
  },
  {
    lng: -44.5917282,
    lat: -23.3618151
  },
  {
    lng: -44.5916383,
    lat: -23.3618844
  },
  {
    lng: -44.5916319,
    lat: -23.3619277
  },
  {
    lng: -44.5915603,
    lat: -23.3619674
  },
  {
    lng: -44.5914918,
    lat: -23.3619115
  },
  {
    lng: -44.5914612,
    lat: -23.3618556
  },
  {
    lng: -44.5914182,
    lat: -23.3618561
  },
  {
    lng: -44.5913593,
    lat: -23.3618885
  },
  {
    lng: -44.591355,
    lat: -23.3619404
  },
  {
    lng: -44.5916027,
    lat: -23.3622116
  },
  {
    lng: -44.5916256,
    lat: -23.3622709
  },
  {
    lng: -44.5915779,
    lat: -23.3623043
  },
  {
    lng: -44.5915704,
    lat: -23.3623918
  },
  {
    lng: -44.5914999,
    lat: -23.3623843
  },
  {
    lng: -44.5914184,
    lat: -23.3624002
  },
  {
    lng: -44.591326,
    lat: -23.3623607
  },
  {
    lng: -44.5912354,
    lat: -23.362387
  },
  {
    lng: -44.591077,
    lat: -23.3623634
  },
  {
    lng: -44.5909712,
    lat: -23.362397
  },
  {
    lng: -44.5909168,
    lat: -23.3623632
  },
  {
    lng: -44.5907424,
    lat: -23.3622646
  },
  {
    lng: -44.5906369,
    lat: -23.3622086
  },
  {
    lng: -44.5903913,
    lat: -23.3621651
  },
  {
    lng: -44.5902317,
    lat: -23.3620693
  },
  {
    lng: -44.5900679,
    lat: -23.3619747
  },
  {
    lng: -44.5900304,
    lat: -23.3619892
  },
  {
    lng: -44.5899632,
    lat: -23.361915
  },
  {
    lng: -44.5897607,
    lat: -23.3618661
  },
  {
    lng: -44.5897086,
    lat: -23.3618101
  },
  {
    lng: -44.589568,
    lat: -23.3617391
  },
  {
    lng: -44.5894488,
    lat: -23.3616261
  },
  {
    lng: -44.5893607,
    lat: -23.3614822
  },
  {
    lng: -44.5892914,
    lat: -23.361195
  },
  {
    lng: -44.5893417,
    lat: -23.3610704
  },
  {
    lng: -44.589279,
    lat: -23.3609154
  },
  {
    lng: -44.5889972,
    lat: -23.3607288
  },
  {
    lng: -44.5887525,
    lat: -23.3607976
  },
  {
    lng: -44.588466,
    lat: -23.3608924
  },
  {
    lng: -44.5883225,
    lat: -23.3609554
  },
  {
    lng: -44.588207,
    lat: -23.3608351
  },
  {
    lng: -44.5880185,
    lat: -23.3609016
  },
  {
    lng: -44.5879008,
    lat: -23.3609102
  },
  {
    lng: -44.5873288,
    lat: -23.3605375
  },
  {
    lng: -44.5865453,
    lat: -23.3601662
  },
  {
    lng: -44.5862806,
    lat: -23.3599389
  },
  {
    lng: -44.586104,
    lat: -23.3596948
  },
  {
    lng: -44.5860253,
    lat: -23.359791
  },
  {
    lng: -44.5856823,
    lat: -23.359517
  },
  {
    lng: -44.5854591,
    lat: -23.3594694
  },
  {
    lng: -44.5852865,
    lat: -23.359566
  },
  {
    lng: -44.5850684,
    lat: -23.3593907
  },
  {
    lng: -44.5851354,
    lat: -23.3591574
  },
  {
    lng: -44.5852744,
    lat: -23.3591612
  },
  {
    lng: -44.5853661,
    lat: -23.359057
  },
  {
    lng: -44.5853559,
    lat: -23.3588454
  },
  {
    lng: -44.5852524,
    lat: -23.3586418
  },
  {
    lng: -44.5850762,
    lat: -23.3585092
  },
  {
    lng: -44.5851305,
    lat: -23.3583526
  },
  {
    lng: -44.5850938,
    lat: -23.3583294
  },
  {
    lng: -44.5849816,
    lat: -23.3583636
  },
  {
    lng: -44.5848501,
    lat: -23.3582903
  },
  {
    lng: -44.5847136,
    lat: -23.3582099
  },
  {
    lng: -44.5845706,
    lat: -23.3580675
  },
  {
    lng: -44.584528,
    lat: -23.3581277
  },
  {
    lng: -44.5843838,
    lat: -23.3581585
  },
  {
    lng: -44.5841032,
    lat: -23.358013
  },
  {
    lng: -44.5837641,
    lat: -23.35792
  },
  {
    lng: -44.5833617,
    lat: -23.3576961
  },
  {
    lng: -44.5831607,
    lat: -23.357696
  },
  {
    lng: -44.5829046,
    lat: -23.3576067
  },
  {
    lng: -44.5825005,
    lat: -23.3573141
  },
  {
    lng: -44.5823158,
    lat: -23.3570769
  },
  {
    lng: -44.5820874,
    lat: -23.3569532
  },
  {
    lng: -44.5819134,
    lat: -23.3566593
  },
  {
    lng: -44.5816317,
    lat: -23.3559969
  },
  {
    lng: -44.5809746,
    lat: -23.3551621
  },
  {
    lng: -44.580854,
    lat: -23.3551006
  },
  {
    lng: -44.5807065,
    lat: -23.3550291
  },
  {
    lng: -44.5805536,
    lat: -23.354906
  },
  {
    lng: -44.5803792,
    lat: -23.3548592
  },
  {
    lng: -44.5802156,
    lat: -23.3548198
  },
  {
    lng: -44.5801191,
    lat: -23.3547903
  },
  {
    lng: -44.5798938,
    lat: -23.3546499
  },
  {
    lng: -44.5797461,
    lat: -23.3542165
  },
  {
    lng: -44.5796631,
    lat: -23.3538866
  },
  {
    lng: -44.5796255,
    lat: -23.3536945
  },
  {
    lng: -44.5794753,
    lat: -23.353335
  },
  {
    lng: -44.5793465,
    lat: -23.3530567
  },
  {
    lng: -44.579132,
    lat: -23.3523918
  },
  {
    lng: -44.5789885,
    lat: -23.3518814
  },
  {
    lng: -44.5787592,
    lat: -23.3515792
  },
  {
    lng: -44.5785741,
    lat: -23.3514019
  },
  {
    lng: -44.5784132,
    lat: -23.3513354
  },
  {
    lng: -44.5781852,
    lat: -23.351296
  },
  {
    lng: -44.5780806,
    lat: -23.3512616
  },
  {
    lng: -44.5776434,
    lat: -23.3508429
  },
  {
    lng: -44.5774744,
    lat: -23.3506903
  },
  {
    lng: -44.5772706,
    lat: -23.3505228
  },
  {
    lng: -44.5768172,
    lat: -23.3503209
  },
  {
    lng: -44.5764283,
    lat: -23.3499047
  },
  {
    lng: -44.5760582,
    lat: -23.3495329
  },
  {
    lng: -44.575609,
    lat: -23.3488916
  },
  {
    lng: -44.5753876,
    lat: -23.348506
  },
  {
    lng: -44.5752535,
    lat: -23.3483878
  },
  {
    lng: -44.5749555,
    lat: -23.348185
  },
  {
    lng: -44.574363,
    lat: -23.3478928
  },
  {
    lng: -44.573673,
    lat: -23.3469677
  },
  {
    lng: -44.5733822,
    lat: -23.3466031
  },
  {
    lng: -44.5729221,
    lat: -23.3463364
  },
  {
    lng: -44.5728098,
    lat: -23.3464057
  },
  {
    lng: -44.5725724,
    lat: -23.3462358
  },
  {
    lng: -44.5725604,
    lat: -23.3461428
  },
  {
    lng: -44.572462,
    lat: -23.3460877
  },
  {
    lng: -44.5725252,
    lat: -23.3460033
  },
  {
    lng: -44.5722612,
    lat: -23.3454352
  },
  {
    lng: -44.5721386,
    lat: -23.3453265
  },
  {
    lng: -44.5721135,
    lat: -23.3452978
  },
  {
    lng: -44.5721245,
    lat: -23.3452566
  },
  {
    lng: -44.57219,
    lat: -23.3452584
  },
  {
    lng: -44.5722479,
    lat: -23.3452379
  },
  {
    lng: -44.5722509,
    lat: -23.3451421
  },
  {
    lng: -44.5721861,
    lat: -23.3451432
  },
  {
    lng: -44.5721455,
    lat: -23.3450841
  },
  {
    lng: -44.5721382,
    lat: -23.34501
  },
  {
    lng: -44.5721895,
    lat: -23.3448965
  },
  {
    lng: -44.5720061,
    lat: -23.34478
  },
  {
    lng: -44.5718766,
    lat: -23.3446689
  },
  {
    lng: -44.5717868,
    lat: -23.3446527
  },
  {
    lng: -44.5716001,
    lat: -23.3442779
  },
  {
    lng: -44.5711191,
    lat: -23.3439111
  },
  {
    lng: -44.5709437,
    lat: -23.3435058
  },
  {
    lng: -44.5709352,
    lat: -23.3430611
  },
  {
    lng: -44.5707462,
    lat: -23.3427117
  },
  {
    lng: -44.5704204,
    lat: -23.3419695
  },
  {
    lng: -44.5703091,
    lat: -23.3419717
  },
  {
    lng: -44.5701179,
    lat: -23.3416597
  },
  {
    lng: -44.5700934,
    lat: -23.3414664
  },
  {
    lng: -44.5701516,
    lat: -23.3414428
  },
  {
    lng: -44.5701552,
    lat: -23.3414242
  },
  {
    lng: -44.5701249,
    lat: -23.3414109
  },
  {
    lng: -44.5698876,
    lat: -23.3410561
  },
  {
    lng: -44.5697008,
    lat: -23.3408177
  },
  {
    lng: -44.5696217,
    lat: -23.3407449
  },
  {
    lng: -44.5692922,
    lat: -23.3403004
  },
  {
    lng: -44.5693194,
    lat: -23.3402511
  },
  {
    lng: -44.5692182,
    lat: -23.3400147
  },
  {
    lng: -44.5691415,
    lat: -23.3399281
  },
  {
    lng: -44.5685026,
    lat: -23.3394329
  },
  {
    lng: -44.56839,
    lat: -23.3391339
  },
  {
    lng: -44.5684033,
    lat: -23.339004
  },
  {
    lng: -44.5683284,
    lat: -23.3389787
  },
  {
    lng: -44.5682866,
    lat: -23.3388696
  },
  {
    lng: -44.5683822,
    lat: -23.3388339
  },
  {
    lng: -44.5683068,
    lat: -23.3386781
  },
  {
    lng: -44.5683198,
    lat: -23.3385671
  },
  {
    lng: -44.5682388,
    lat: -23.3385613
  },
  {
    lng: -44.5679739,
    lat: -23.338236
  },
  {
    lng: -44.5677546,
    lat: -23.338163
  },
  {
    lng: -44.5675399,
    lat: -23.3379864
  },
  {
    lng: -44.5673613,
    lat: -23.337904
  },
  {
    lng: -44.567277,
    lat: -23.3378694
  },
  {
    lng: -44.5670297,
    lat: -23.3376915
  },
  {
    lng: -44.5669191,
    lat: -23.3375514
  },
  {
    lng: -44.5668788,
    lat: -23.3374294
  },
  {
    lng: -44.5668198,
    lat: -23.3373802
  },
  {
    lng: -44.5667715,
    lat: -23.3372646
  },
  {
    lng: -44.5665642,
    lat: -23.3369369
  },
  {
    lng: -44.5665227,
    lat: -23.3369616
  },
  {
    lng: -44.5662887,
    lat: -23.3365319
  },
  {
    lng: -44.5661162,
    lat: -23.3362686
  },
  {
    lng: -44.565874,
    lat: -23.3360118
  },
  {
    lng: -44.5658405,
    lat: -23.3359248
  },
  {
    lng: -44.5657569,
    lat: -23.335858
  },
  {
    lng: -44.5657187,
    lat: -23.335855
  },
  {
    lng: -44.5656044,
    lat: -23.3356744
  },
  {
    lng: -44.5654444,
    lat: -23.3354312
  },
  {
    lng: -44.5653514,
    lat: -23.3353616
  },
  {
    lng: -44.5652345,
    lat: -23.335217
  },
  {
    lng: -44.5652247,
    lat: -23.3351564
  },
  {
    lng: -44.5653382,
    lat: -23.3350181
  },
  {
    lng: -44.5653465,
    lat: -23.3349284
  },
  {
    lng: -44.5652842,
    lat: -23.3348247
  },
  {
    lng: -44.5651091,
    lat: -23.3347162
  },
  {
    lng: -44.5646468,
    lat: -23.3343262
  },
  {
    lng: -44.5644612,
    lat: -23.3342106
  },
  {
    lng: -44.5644156,
    lat: -23.3340371
  },
  {
    lng: -44.5642834,
    lat: -23.3339077
  },
  {
    lng: -44.5641786,
    lat: -23.3339203
  },
  {
    lng: -44.5641572,
    lat: -23.3338971
  },
  {
    lng: -44.5641281,
    lat: -23.3338443
  },
  {
    lng: -44.5640234,
    lat: -23.3338069
  },
  {
    lng: -44.5638674,
    lat: -23.3338167
  },
  {
    lng: -44.5638031,
    lat: -23.3336658
  },
  {
    lng: -44.5636442,
    lat: -23.3333047
  },
  {
    lng: -44.5636426,
    lat: -23.3332093
  },
  {
    lng: -44.5632076,
    lat: -23.3329161
  },
  {
    lng: -44.5631199,
    lat: -23.332765
  },
  {
    lng: -44.5631958,
    lat: -23.3327362
  },
  {
    lng: -44.563225,
    lat: -23.3326967
  },
  {
    lng: -44.5633525,
    lat: -23.3327314
  },
  {
    lng: -44.5634473,
    lat: -23.3326768
  },
  {
    lng: -44.5634687,
    lat: -23.3325456
  },
  {
    lng: -44.563624,
    lat: -23.3325834
  },
  {
    lng: -44.5636175,
    lat: -23.3324759
  },
  {
    lng: -44.5634399,
    lat: -23.3322188
  },
  {
    lng: -44.5634518,
    lat: -23.3320646
  },
  {
    lng: -44.5629246,
    lat: -23.3314576
  },
  {
    lng: -44.5628826,
    lat: -23.3312563
  },
  {
    lng: -44.5624746,
    lat: -23.330751
  },
  {
    lng: -44.5621507,
    lat: -23.3302603
  },
  {
    lng: -44.5619777,
    lat: -23.3301957
  },
  {
    lng: -44.56158,
    lat: -23.3294938
  },
  {
    lng: -44.5616607,
    lat: -23.3293515
  },
  {
    lng: -44.5615637,
    lat: -23.3289971
  },
  {
    lng: -44.561588,
    lat: -23.3285477
  },
  {
    lng: -44.5616157,
    lat: -23.3283252
  },
  {
    lng: -44.5617133,
    lat: -23.3281718
  },
  {
    lng: -44.5617926,
    lat: -23.3279878
  },
  {
    lng: -44.5618534,
    lat: -23.3279338
  },
  {
    lng: -44.5617948,
    lat: -23.3277739
  },
  {
    lng: -44.5618405,
    lat: -23.3276814
  },
  {
    lng: -44.5619196,
    lat: -23.3276353
  },
  {
    lng: -44.5617954,
    lat: -23.3274954
  },
  {
    lng: -44.5617837,
    lat: -23.3272733
  },
  {
    lng: -44.5619149,
    lat: -23.3270528
  },
  {
    lng: -44.5620329,
    lat: -23.3268509
  },
  {
    lng: -44.5621,
    lat: -23.3267351
  },
  {
    lng: -44.5621751,
    lat: -23.3265627
  },
  {
    lng: -44.5621402,
    lat: -23.3264987
  },
  {
    lng: -44.5621222,
    lat: -23.3263027
  },
  {
    lng: -44.5623888,
    lat: -23.3261129
  },
  {
    lng: -44.5626507,
    lat: -23.326108
  },
  {
    lng: -44.5629997,
    lat: -23.3258707
  },
  {
    lng: -44.5633385,
    lat: -23.3255114
  },
  {
    lng: -44.5636203,
    lat: -23.3251448
  },
  {
    lng: -44.5644027,
    lat: -23.3247235
  },
  {
    lng: -44.5649429,
    lat: -23.3246028
  },
  {
    lng: -44.5649473,
    lat: -23.3240424
  },
  {
    lng: -44.5653702,
    lat: -23.3237986
  },
  {
    lng: -44.5655555,
    lat: -23.3237896
  },
  {
    lng: -44.5654536,
    lat: -23.3236596
  },
  {
    lng: -44.5655938,
    lat: -23.3235945
  },
  {
    lng: -44.5657547,
    lat: -23.3236458
  },
  {
    lng: -44.5658073,
    lat: -23.3236461
  },
  {
    lng: -44.5660666,
    lat: -23.3234906
  },
  {
    lng: -44.5662333,
    lat: -23.3232968
  },
  {
    lng: -44.5662576,
    lat: -23.323097
  },
  {
    lng: -44.5661061,
    lat: -23.3228824
  },
  {
    lng: -44.5655069,
    lat: -23.3225449
  },
  {
    lng: -44.565172,
    lat: -23.3222703
  },
  {
    lng: -44.5651487,
    lat: -23.3221584
  },
  {
    lng: -44.5649498,
    lat: -23.3220481
  },
  {
    lng: -44.5647149,
    lat: -23.3219802
  },
  {
    lng: -44.5645393,
    lat: -23.3216789
  },
  {
    lng: -44.5641334,
    lat: -23.3213455
  },
  {
    lng: -44.563963,
    lat: -23.3211618
  },
  {
    lng: -44.5640338,
    lat: -23.3209889
  },
  {
    lng: -44.5640848,
    lat: -23.3208682
  },
  {
    lng: -44.5640634,
    lat: -23.3207918
  },
  {
    lng: -44.564002,
    lat: -23.3206762
  },
  {
    lng: -44.5640546,
    lat: -23.3206738
  },
  {
    lng: -44.5639724,
    lat: -23.3205211
  },
  {
    lng: -44.5638519,
    lat: -23.3204123
  },
  {
    lng: -44.5637213,
    lat: -23.3200549
  },
  {
    lng: -44.5636411,
    lat: -23.3200224
  },
  {
    lng: -44.5635652,
    lat: -23.3197388
  },
  {
    lng: -44.5638716,
    lat: -23.3196655
  },
  {
    lng: -44.5637152,
    lat: -23.3195544
  },
  {
    lng: -44.5637271,
    lat: -23.3193727
  },
  {
    lng: -44.5638323,
    lat: -23.3191145
  },
  {
    lng: -44.563594,
    lat: -23.318447
  },
  {
    lng: -44.5634462,
    lat: -23.3183147
  },
  {
    lng: -44.5632533,
    lat: -23.3178312
  },
  {
    lng: -44.5631067,
    lat: -23.3177636
  },
  {
    lng: -44.5626927,
    lat: -23.3174568
  },
  {
    lng: -44.5625584,
    lat: -23.3173136
  },
  {
    lng: -44.5624948,
    lat: -23.3171411
  },
  {
    lng: -44.5622615,
    lat: -23.3170134
  },
  {
    lng: -44.5621796,
    lat: -23.3171293
  },
  {
    lng: -44.5620349,
    lat: -23.31696
  },
  {
    lng: -44.5616842,
    lat: -23.316703
  },
  {
    lng: -44.561526,
    lat: -23.3165996
  },
  {
    lng: -44.5610372,
    lat: -23.3163791
  },
  {
    lng: -44.5609182,
    lat: -23.3164993
  },
  {
    lng: -44.5606683,
    lat: -23.3164005
  },
  {
    lng: -44.5606841,
    lat: -23.3162496
  },
  {
    lng: -44.5605878,
    lat: -23.3161949
  },
  {
    lng: -44.5604914,
    lat: -23.3162298
  },
  {
    lng: -44.5603446,
    lat: -23.3161533
  },
  {
    lng: -44.5603366,
    lat: -23.3157802
  },
  {
    lng: -44.5600832,
    lat: -23.3156326
  },
  {
    lng: -44.5600561,
    lat: -23.3151808
  },
  {
    lng: -44.5599381,
    lat: -23.3148507
  },
  {
    lng: -44.5599488,
    lat: -23.3148015
  },
  {
    lng: -44.5598783,
    lat: -23.3145799
  },
  {
    lng: -44.5599805,
    lat: -23.3143539
  },
  {
    lng: -44.5600913,
    lat: -23.3142933
  },
  {
    lng: -44.5602542,
    lat: -23.3143702
  },
  {
    lng: -44.5604515,
    lat: -23.3143866
  },
  {
    lng: -44.5604069,
    lat: -23.3142119
  },
  {
    lng: -44.5604895,
    lat: -23.3140414
  },
  {
    lng: -44.5605525,
    lat: -23.31399
  },
  {
    lng: -44.5607425,
    lat: -23.3140695
  },
  {
    lng: -44.5609028,
    lat: -23.3139907
  },
  {
    lng: -44.5610038,
    lat: -23.3137403
  },
  {
    lng: -44.5610932,
    lat: -23.313739
  },
  {
    lng: -44.5611054,
    lat: -23.3138943
  },
  {
    lng: -44.5614716,
    lat: -23.3137338
  },
  {
    lng: -44.5617297,
    lat: -23.3137498
  },
  {
    lng: -44.5619363,
    lat: -23.3137572
  },
  {
    lng: -44.5620383,
    lat: -23.3136783
  },
  {
    lng: -44.562041,
    lat: -23.3135773
  },
  {
    lng: -44.5620061,
    lat: -23.3134319
  },
  {
    lng: -44.5618987,
    lat: -23.3131759
  },
  {
    lng: -44.5616403,
    lat: -23.31291
  },
  {
    lng: -44.5616374,
    lat: -23.3124939
  },
  {
    lng: -44.5618145,
    lat: -23.3123863
  },
  {
    lng: -44.562106,
    lat: -23.3123549
  },
  {
    lng: -44.562262,
    lat: -23.3122329
  },
  {
    lng: -44.5623222,
    lat: -23.3119792
  },
  {
    lng: -44.5622808,
    lat: -23.3116331
  },
  {
    lng: -44.562084,
    lat: -23.3113987
  },
  {
    lng: -44.5618814,
    lat: -23.3112805
  },
  {
    lng: -44.5615546,
    lat: -23.3112534
  },
  {
    lng: -44.5611505,
    lat: -23.3110919
  },
  {
    lng: -44.560834,
    lat: -23.3110869
  },
  {
    lng: -44.560665,
    lat: -23.3110722
  },
  {
    lng: -44.5605792,
    lat: -23.3110672
  },
  {
    lng: -44.5604799,
    lat: -23.3111658
  },
  {
    lng: -44.5600216,
    lat: -23.3112061
  },
  {
    lng: -44.5598529,
    lat: -23.3110884
  },
  {
    lng: -44.5596214,
    lat: -23.3109595
  },
  {
    lng: -44.5593389,
    lat: -23.3109659
  },
  {
    lng: -44.5592114,
    lat: -23.3106874
  },
  {
    lng: -44.5592515,
    lat: -23.3103258
  },
  {
    lng: -44.5590774,
    lat: -23.3100019
  },
  {
    lng: -44.5589108,
    lat: -23.3094415
  },
  {
    lng: -44.5586666,
    lat: -23.3091705
  },
  {
    lng: -44.558186,
    lat: -23.3084646
  },
  {
    lng: -44.5575885,
    lat: -23.3079586
  },
  {
    lng: -44.5570898,
    lat: -23.307501
  },
  {
    lng: -44.557029,
    lat: -23.3071613
  },
  {
    lng: -44.5572404,
    lat: -23.3067544
  },
  {
    lng: -44.5574406,
    lat: -23.3066094
  },
  {
    lng: -44.5573925,
    lat: -23.3064292
  },
  {
    lng: -44.5573847,
    lat: -23.3062369
  },
  {
    lng: -44.5571831,
    lat: -23.3062068
  },
  {
    lng: -44.5570199,
    lat: -23.306299
  },
  {
    lng: -44.5568712,
    lat: -23.306341
  },
  {
    lng: -44.5564807,
    lat: -23.3061184
  },
  {
    lng: -44.5564732,
    lat: -23.3059092
  },
  {
    lng: -44.5563069,
    lat: -23.3055991
  },
  {
    lng: -44.5559623,
    lat: -23.3054737
  },
  {
    lng: -44.5555283,
    lat: -23.3055261
  },
  {
    lng: -44.5554277,
    lat: -23.3054508
  },
  {
    lng: -44.5553793,
    lat: -23.3052654
  },
  {
    lng: -44.555444,
    lat: -23.3051119
  },
  {
    lng: -44.5557011,
    lat: -23.3050127
  },
  {
    lng: -44.5559815,
    lat: -23.3048638
  },
  {
    lng: -44.556125,
    lat: -23.3045082
  },
  {
    lng: -44.5561027,
    lat: -23.3041825
  },
  {
    lng: -44.5560633,
    lat: -23.3040272
  },
  {
    lng: -44.5558774,
    lat: -23.3039088
  },
  {
    lng: -44.5554737,
    lat: -23.3036651
  },
  {
    lng: -44.5548567,
    lat: -23.3034316
  },
  {
    lng: -44.5543076,
    lat: -23.3033851
  },
  {
    lng: -44.5533058,
    lat: -23.3034716
  },
  {
    lng: -44.5526818,
    lat: -23.3036554
  },
  {
    lng: -44.5521322,
    lat: -23.3039274
  },
  {
    lng: -44.5514111,
    lat: -23.3045125
  },
  {
    lng: -44.5514669,
    lat: -23.3047763
  },
  {
    lng: -44.5514553,
    lat: -23.3048607
  },
  {
    lng: -44.5514097,
    lat: -23.3048742
  },
  {
    lng: -44.551376,
    lat: -23.3049318
  },
  {
    lng: -44.5513412,
    lat: -23.3050037
  },
  {
    lng: -44.5514599,
    lat: -23.3054158
  },
  {
    lng: -44.551592,
    lat: -23.3054614
  },
  {
    lng: -44.5516497,
    lat: -23.3056424
  },
  {
    lng: -44.5517721,
    lat: -23.3059004
  },
  {
    lng: -44.5516407,
    lat: -23.3063312
  },
  {
    lng: -44.5515573,
    lat: -23.306271
  },
  {
    lng: -44.5515368,
    lat: -23.3062265
  },
  {
    lng: -44.5515053,
    lat: -23.3062855
  },
  {
    lng: -44.5515745,
    lat: -23.3063437
  },
  {
    lng: -44.5516242,
    lat: -23.306404
  },
  {
    lng: -44.5515296,
    lat: -23.3065785
  },
  {
    lng: -44.551404,
    lat: -23.3065942
  },
  {
    lng: -44.551416,
    lat: -23.3066688
  },
  {
    lng: -44.5515055,
    lat: -23.3066914
  },
  {
    lng: -44.5514675,
    lat: -23.3068063
  },
  {
    lng: -44.5514139,
    lat: -23.3068792
  },
  {
    lng: -44.5513938,
    lat: -23.3069865
  },
  {
    lng: -44.5514719,
    lat: -23.3070199
  },
  {
    lng: -44.5515479,
    lat: -23.3070989
  },
  {
    lng: -44.5516293,
    lat: -23.3073589
  },
  {
    lng: -44.5516405,
    lat: -23.3075114
  },
  {
    lng: -44.5515629,
    lat: -23.3075932
  },
  {
    lng: -44.5515358,
    lat: -23.3076934
  },
  {
    lng: -44.5514252,
    lat: -23.3077193
  },
  {
    lng: -44.5512911,
    lat: -23.3077089
  },
  {
    lng: -44.5512115,
    lat: -23.3077416
  },
  {
    lng: -44.5510855,
    lat: -23.3077932
  },
  {
    lng: -44.5507281,
    lat: -23.3079035
  },
  {
    lng: -44.5505958,
    lat: -23.3075978
  },
  {
    lng: -44.5502568,
    lat: -23.3075336
  },
  {
    lng: -44.5501973,
    lat: -23.3074625
  },
  {
    lng: -44.5500972,
    lat: -23.3074966
  },
  {
    lng: -44.5500554,
    lat: -23.3075983
  },
  {
    lng: -44.5498815,
    lat: -23.307601
  },
  {
    lng: -44.5498103,
    lat: -23.3074823
  },
  {
    lng: -44.5494939,
    lat: -23.3075005
  },
  {
    lng: -44.5492232,
    lat: -23.3072867
  },
  {
    lng: -44.5492568,
    lat: -23.307169
  },
  {
    lng: -44.5495012,
    lat: -23.3071506
  },
  {
    lng: -44.5495967,
    lat: -23.307112
  },
  {
    lng: -44.5496295,
    lat: -23.3068492
  },
  {
    lng: -44.549487,
    lat: -23.3068522
  },
  {
    lng: -44.5492671,
    lat: -23.3059229
  },
  {
    lng: -44.549341,
    lat: -23.3057716
  },
  {
    lng: -44.5492649,
    lat: -23.305538
  },
  {
    lng: -44.5493036,
    lat: -23.3052553
  },
  {
    lng: -44.5489857,
    lat: -23.3050551
  },
  {
    lng: -44.5487488,
    lat: -23.3046748
  },
  {
    lng: -44.5485817,
    lat: -23.3045603
  },
  {
    lng: -44.5482557,
    lat: -23.3043837
  },
  {
    lng: -44.548158,
    lat: -23.3044456
  },
  {
    lng: -44.5478143,
    lat: -23.3045006
  },
  {
    lng: -44.547748,
    lat: -23.3046325
  },
  {
    lng: -44.5473917,
    lat: -23.3045929
  },
  {
    lng: -44.5471448,
    lat: -23.3046304
  },
  {
    lng: -44.5470991,
    lat: -23.3045183
  },
  {
    lng: -44.5469523,
    lat: -23.3044182
  },
  {
    lng: -44.5465198,
    lat: -23.3041591
  },
  {
    lng: -44.5462373,
    lat: -23.3039778
  },
  {
    lng: -44.5460416,
    lat: -23.3037438
  },
  {
    lng: -44.5458297,
    lat: -23.3036304
  },
  {
    lng: -44.5456419,
    lat: -23.3034851
  },
  {
    lng: -44.5455159,
    lat: -23.303394
  },
  {
    lng: -44.545223,
    lat: -23.3031076
  },
  {
    lng: -44.5449184,
    lat: -23.3027757
  },
  {
    lng: -44.5448528,
    lat: -23.3025678
  },
  {
    lng: -44.5447843,
    lat: -23.3025389
  },
  {
    lng: -44.5445787,
    lat: -23.3024498
  },
  {
    lng: -44.5444344,
    lat: -23.3024238
  },
  {
    lng: -44.544072,
    lat: -23.3022265
  },
  {
    lng: -44.5439014,
    lat: -23.3021588
  },
  {
    lng: -44.5436834,
    lat: -23.3021683
  },
  {
    lng: -44.5434986,
    lat: -23.3021319
  },
  {
    lng: -44.5432775,
    lat: -23.3020486
  },
  {
    lng: -44.5431791,
    lat: -23.3020196
  },
  {
    lng: -44.5429568,
    lat: -23.3020812
  },
  {
    lng: -44.543014,
    lat: -23.3021265
  },
  {
    lng: -44.5425616,
    lat: -23.3022669
  },
  {
    lng: -44.5423237,
    lat: -23.3021581
  },
  {
    lng: -44.5422526,
    lat: -23.3021424
  },
  {
    lng: -44.5421965,
    lat: -23.3021537
  },
  {
    lng: -44.5421907,
    lat: -23.3022246
  },
  {
    lng: -44.5421144,
    lat: -23.3022157
  },
  {
    lng: -44.5421004,
    lat: -23.3022748
  },
  {
    lng: -44.5421785,
    lat: -23.3023318
  },
  {
    lng: -44.5421423,
    lat: -23.302587
  },
  {
    lng: -44.5420268,
    lat: -23.3027278
  },
  {
    lng: -44.542027,
    lat: -23.3027529
  },
  {
    lng: -44.5421126,
    lat: -23.3028001
  },
  {
    lng: -44.542063,
    lat: -23.302944
  },
  {
    lng: -44.5419744,
    lat: -23.3030246
  },
  {
    lng: -44.542007,
    lat: -23.3032526
  },
  {
    lng: -44.5420864,
    lat: -23.3033582
  },
  {
    lng: -44.5421723,
    lat: -23.3038377
  },
  {
    lng: -44.5422823,
    lat: -23.3041874
  },
  {
    lng: -44.54236,
    lat: -23.3044925
  },
  {
    lng: -44.5423125,
    lat: -23.3047551
  },
  {
    lng: -44.5421347,
    lat: -23.3049808
  },
  {
    lng: -44.5420035,
    lat: -23.3050381
  },
  {
    lng: -44.541836,
    lat: -23.3049098
  },
  {
    lng: -44.541728,
    lat: -23.3049499
  },
  {
    lng: -44.5417426,
    lat: -23.305048
  },
  {
    lng: -44.5417024,
    lat: -23.3050862
  },
  {
    lng: -44.5414933,
    lat: -23.3050276
  },
  {
    lng: -44.5414838,
    lat: -23.3050666
  },
  {
    lng: -44.5413973,
    lat: -23.3049661
  },
  {
    lng: -44.5410613,
    lat: -23.3049364
  },
  {
    lng: -44.5409074,
    lat: -23.3049531
  },
  {
    lng: -44.5407534,
    lat: -23.3048956
  },
  {
    lng: -44.5406125,
    lat: -23.30483
  },
  {
    lng: -44.5403265,
    lat: -23.3047131
  },
  {
    lng: -44.5402561,
    lat: -23.3046163
  },
  {
    lng: -44.5401711,
    lat: -23.3044691
  },
  {
    lng: -44.5400432,
    lat: -23.3043661
  },
  {
    lng: -44.5399941,
    lat: -23.3042516
  },
  {
    lng: -44.5399393,
    lat: -23.3041108
  },
  {
    lng: -44.5399585,
    lat: -23.3039724
  },
  {
    lng: -44.5400422,
    lat: -23.3037916
  },
  {
    lng: -44.540148,
    lat: -23.3036793
  },
  {
    lng: -44.5402662,
    lat: -23.3035566
  },
  {
    lng: -44.5402806,
    lat: -23.3035149
  },
  {
    lng: -44.5402752,
    lat: -23.3034651
  },
  {
    lng: -44.5402167,
    lat: -23.3033809
  },
  {
    lng: -44.5402603,
    lat: -23.3033335
  },
  {
    lng: -44.5403899,
    lat: -23.3032383
  },
  {
    lng: -44.5404252,
    lat: -23.3031498
  },
  {
    lng: -44.5404878,
    lat: -23.3031492
  },
  {
    lng: -44.5404903,
    lat: -23.303109
  },
  {
    lng: -44.5403956,
    lat: -23.303082
  },
  {
    lng: -44.540277,
    lat: -23.3030603
  },
  {
    lng: -44.5402909,
    lat: -23.3029802
  },
  {
    lng: -44.5403729,
    lat: -23.3029608
  },
  {
    lng: -44.5403713,
    lat: -23.3028852
  },
  {
    lng: -44.5405606,
    lat: -23.302773
  },
  {
    lng: -44.5405348,
    lat: -23.3027496
  },
  {
    lng: -44.5404527,
    lat: -23.3025524
  },
  {
    lng: -44.5404298,
    lat: -23.3023616
  },
  {
    lng: -44.540301,
    lat: -23.3023126
  },
  {
    lng: -44.5402914,
    lat: -23.3022253
  },
  {
    lng: -44.5403395,
    lat: -23.3021268
  },
  {
    lng: -44.5402589,
    lat: -23.3020755
  },
  {
    lng: -44.5403066,
    lat: -23.3020078
  },
  {
    lng: -44.5403273,
    lat: -23.3019206
  },
  {
    lng: -44.5404496,
    lat: -23.30196
  },
  {
    lng: -44.5405186,
    lat: -23.3018945
  },
  {
    lng: -44.5404203,
    lat: -23.3017772
  },
  {
    lng: -44.5402904,
    lat: -23.3018889
  },
  {
    lng: -44.5401863,
    lat: -23.3016317
  },
  {
    lng: -44.5402272,
    lat: -23.3015172
  },
  {
    lng: -44.5401476,
    lat: -23.3013195
  },
  {
    lng: -44.5400875,
    lat: -23.3011343
  },
  {
    lng: -44.5400611,
    lat: -23.3008855
  },
  {
    lng: -44.5405909,
    lat: -23.3009756
  },
  {
    lng: -44.5407929,
    lat: -23.3009554
  },
  {
    lng: -44.5408555,
    lat: -23.3008979
  },
  {
    lng: -44.5407108,
    lat: -23.3004589
  },
  {
    lng: -44.5406954,
    lat: -23.2999139
  },
  {
    lng: -44.5404875,
    lat: -23.2993922
  },
  {
    lng: -44.5402216,
    lat: -23.2993849
  },
  {
    lng: -44.5400757,
    lat: -23.2990581
  },
  {
    lng: -44.5402838,
    lat: -23.2990534
  },
  {
    lng: -44.5400495,
    lat: -23.2983759
  },
  {
    lng: -44.5398886,
    lat: -23.2981788
  },
  {
    lng: -44.5397433,
    lat: -23.2979645
  },
  {
    lng: -44.5395832,
    lat: -23.2976479
  },
  {
    lng: -44.5394729,
    lat: -23.2973856
  },
  {
    lng: -44.5393656,
    lat: -23.2972994
  },
  {
    lng: -44.5391564,
    lat: -23.2971491
  },
  {
    lng: -44.5389337,
    lat: -23.2969988
  },
  {
    lng: -44.5386257,
    lat: -23.2966097
  },
  {
    lng: -44.538787,
    lat: -23.2965394
  },
  {
    lng: -44.5384395,
    lat: -23.2962942
  },
  {
    lng: -44.5383804,
    lat: -23.2963035
  },
  {
    lng: -44.5381526,
    lat: -23.2960348
  },
  {
    lng: -44.5379412,
    lat: -23.2958343
  },
  {
    lng: -44.5378094,
    lat: -23.2956032
  },
  {
    lng: -44.5376106,
    lat: -23.2953194
  },
  {
    lng: -44.5374471,
    lat: -23.2948017
  },
  {
    lng: -44.5373434,
    lat: -23.2945605
  },
  {
    lng: -44.5370831,
    lat: -23.2943557
  },
  {
    lng: -44.5369537,
    lat: -23.2941207
  },
  {
    lng: -44.5368631,
    lat: -23.2939562
  },
  {
    lng: -44.5370454,
    lat: -23.2937746
  },
  {
    lng: -44.5368831,
    lat: -23.2936075
  },
  {
    lng: -44.5367295,
    lat: -23.2935236
  },
  {
    lng: -44.5365244,
    lat: -23.2935703
  },
  {
    lng: -44.5364111,
    lat: -23.2933997
  },
  {
    lng: -44.5359954,
    lat: -23.2932697
  },
  {
    lng: -44.5358294,
    lat: -23.2932904
  },
  {
    lng: -44.5356724,
    lat: -23.2932072
  },
  {
    lng: -44.5355965,
    lat: -23.2930564
  },
  {
    lng: -44.5356238,
    lat: -23.2928863
  },
  {
    lng: -44.5357801,
    lat: -23.2928642
  },
  {
    lng: -44.5356907,
    lat: -23.2927337
  },
  {
    lng: -44.5356907,
    lat: -23.2926287
  },
  {
    lng: -44.5359924,
    lat: -23.2925508
  },
  {
    lng: -44.5357066,
    lat: -23.2921739
  },
  {
    lng: -44.5354829,
    lat: -23.2920822
  },
  {
    lng: -44.5354169,
    lat: -23.2921753
  },
  {
    lng: -44.5349597,
    lat: -23.2921024
  },
  {
    lng: -44.5346716,
    lat: -23.2921983
  },
  {
    lng: -44.5345388,
    lat: -23.2920971
  },
  {
    lng: -44.5341043,
    lat: -23.2919495
  },
  {
    lng: -44.5338174,
    lat: -23.2917774
  },
  {
    lng: -44.5334508,
    lat: -23.2915903
  },
  {
    lng: -44.5331412,
    lat: -23.2913019
  },
  {
    lng: -44.5327405,
    lat: -23.2910198
  },
  {
    lng: -44.5322592,
    lat: -23.2908091
  },
  {
    lng: -44.5320702,
    lat: -23.2908205
  },
  {
    lng: -44.5319935,
    lat: -23.2906943
  },
  {
    lng: -44.5314798,
    lat: -23.2905769
  },
  {
    lng: -44.5310998,
    lat: -23.290472
  },
  {
    lng: -44.5309539,
    lat: -23.2905056
  },
  {
    lng: -44.53065,
    lat: -23.2903837
  },
  {
    lng: -44.5304872,
    lat: -23.2905556
  },
  {
    lng: -44.5299949,
    lat: -23.2904534
  },
  {
    lng: -44.529822,
    lat: -23.2904569
  },
  {
    lng: -44.5298659,
    lat: -23.2903464
  },
  {
    lng: -44.530077,
    lat: -23.2901634
  },
  {
    lng: -44.5299823,
    lat: -23.2901429
  },
  {
    lng: -44.5296967,
    lat: -23.2902486
  },
  {
    lng: -44.529623,
    lat: -23.2901744
  },
  {
    lng: -44.5297918,
    lat: -23.2898066
  },
  {
    lng: -44.5299888,
    lat: -23.2895911
  },
  {
    lng: -44.5299763,
    lat: -23.2894348
  },
  {
    lng: -44.5297925,
    lat: -23.2893511
  },
  {
    lng: -44.5299267,
    lat: -23.288815
  },
  {
    lng: -44.5300091,
    lat: -23.2886501
  },
  {
    lng: -44.5299835,
    lat: -23.2885522
  },
  {
    lng: -44.529726,
    lat: -23.288528
  },
  {
    lng: -44.5294981,
    lat: -23.2884218
  },
  {
    lng: -44.529459,
    lat: -23.2883376
  },
  {
    lng: -44.5295123,
    lat: -23.2882957
  },
  {
    lng: -44.5295941,
    lat: -23.2883221
  },
  {
    lng: -44.5297549,
    lat: -23.2883393
  },
  {
    lng: -44.5301377,
    lat: -23.2885164
  },
  {
    lng: -44.5302246,
    lat: -23.288354
  },
  {
    lng: -44.5302099,
    lat: -23.2880913
  },
  {
    lng: -44.529889,
    lat: -23.2875025
  },
  {
    lng: -44.529221,
    lat: -23.2870778
  },
  {
    lng: -44.5287843,
    lat: -23.2868955
  },
  {
    lng: -44.528355,
    lat: -23.2868389
  },
  {
    lng: -44.5284383,
    lat: -23.2871222
  },
  {
    lng: -44.5281718,
    lat: -23.2874462
  },
  {
    lng: -44.5279782,
    lat: -23.2875403
  },
  {
    lng: -44.5277165,
    lat: -23.2876214
  },
  {
    lng: -44.5277322,
    lat: -23.2877103
  },
  {
    lng: -44.5277046,
    lat: -23.2877714
  },
  {
    lng: -44.5277318,
    lat: -23.2878049
  },
  {
    lng: -44.5277294,
    lat: -23.2879089
  },
  {
    lng: -44.5275833,
    lat: -23.2878849
  },
  {
    lng: -44.5274589,
    lat: -23.2877441
  },
  {
    lng: -44.5273093,
    lat: -23.2877094
  },
  {
    lng: -44.5271878,
    lat: -23.2877486
  },
  {
    lng: -44.5273065,
    lat: -23.2878599
  },
  {
    lng: -44.5272657,
    lat: -23.2879162
  },
  {
    lng: -44.5270789,
    lat: -23.2878842
  },
  {
    lng: -44.5269171,
    lat: -23.2877851
  },
  {
    lng: -44.5267093,
    lat: -23.2876224
  },
  {
    lng: -44.5266325,
    lat: -23.2874614
  },
  {
    lng: -44.5264951,
    lat: -23.2873959
  },
  {
    lng: -44.5263197,
    lat: -23.2873893
  },
  {
    lng: -44.5261126,
    lat: -23.2874319
  },
  {
    lng: -44.5259808,
    lat: -23.2875812
  },
  {
    lng: -44.5259103,
    lat: -23.2877032
  },
  {
    lng: -44.5258289,
    lat: -23.2877261
  },
  {
    lng: -44.5256596,
    lat: -23.2876875
  },
  {
    lng: -44.5254744,
    lat: -23.2876987
  },
  {
    lng: -44.5253752,
    lat: -23.2876494
  },
  {
    lng: -44.5252598,
    lat: -23.2876346
  },
  {
    lng: -44.525225,
    lat: -23.2876765
  },
  {
    lng: -44.5251472,
    lat: -23.2878342
  },
  {
    lng: -44.5250667,
    lat: -23.287913
  },
  {
    lng: -44.5249943,
    lat: -23.2880017
  },
  {
    lng: -44.5249916,
    lat: -23.2880042
  },
  {
    lng: -44.5251583,
    lat: -23.2881229
  },
  {
    lng: -44.5251111,
    lat: -23.2881792
  },
  {
    lng: -44.5249959,
    lat: -23.28843
  },
  {
    lng: -44.5249218,
    lat: -23.2887188
  },
  {
    lng: -44.5247191,
    lat: -23.2890388
  },
  {
    lng: -44.5247286,
    lat: -23.2892734
  },
  {
    lng: -44.5246248,
    lat: -23.2895031
  },
  {
    lng: -44.5245109,
    lat: -23.2895074
  },
  {
    lng: -44.5245042,
    lat: -23.2895473
  },
  {
    lng: -44.5245552,
    lat: -23.2896287
  },
  {
    lng: -44.5245215,
    lat: -23.289795
  },
  {
    lng: -44.5246148,
    lat: -23.2898832
  },
  {
    lng: -44.5246075,
    lat: -23.2899638
  },
  {
    lng: -44.5245775,
    lat: -23.2900637
  },
  {
    lng: -44.524429,
    lat: -23.290142
  },
  {
    lng: -44.5244573,
    lat: -23.2902553
  },
  {
    lng: -44.5243962,
    lat: -23.2905307
  },
  {
    lng: -44.5243227,
    lat: -23.2905861
  },
  {
    lng: -44.5241955,
    lat: -23.2905884
  },
  {
    lng: -44.5239022,
    lat: -23.2905543
  },
  {
    lng: -44.5237813,
    lat: -23.2904961
  },
  {
    lng: -44.5234823,
    lat: -23.2903936
  },
  {
    lng: -44.523435,
    lat: -23.2904598
  },
  {
    lng: -44.5233044,
    lat: -23.2906215
  },
  {
    lng: -44.5231389,
    lat: -23.2907509
  },
  {
    lng: -44.5229527,
    lat: -23.2908462
  },
  {
    lng: -44.5225742,
    lat: -23.2907982
  },
  {
    lng: -44.5225063,
    lat: -23.2908267
  },
  {
    lng: -44.5224926,
    lat: -23.2908809
  },
  {
    lng: -44.5224211,
    lat: -23.290928
  },
  {
    lng: -44.5222743,
    lat: -23.2908877
  },
  {
    lng: -44.5217685,
    lat: -23.2907173
  },
  {
    lng: -44.5216788,
    lat: -23.2907363
  },
  {
    lng: -44.521553,
    lat: -23.2908416
  },
  {
    lng: -44.5213816,
    lat: -23.2909066
  },
  {
    lng: -44.5212417,
    lat: -23.2908997
  },
  {
    lng: -44.5210929,
    lat: -23.2908601
  },
  {
    lng: -44.5211227,
    lat: -23.2912972
  },
  {
    lng: -44.5210066,
    lat: -23.2913873
  },
  {
    lng: -44.5210418,
    lat: -23.2914726
  },
  {
    lng: -44.5208408,
    lat: -23.2914455
  },
  {
    lng: -44.5205959,
    lat: -23.291351
  },
  {
    lng: -44.520286,
    lat: -23.2913983
  },
  {
    lng: -44.5200741,
    lat: -23.2913582
  },
  {
    lng: -44.5199875,
    lat: -23.2912776
  },
  {
    lng: -44.5198206,
    lat: -23.291307
  },
  {
    lng: -44.5195863,
    lat: -23.2912611
  },
  {
    lng: -44.5194159,
    lat: -23.2911955
  },
  {
    lng: -44.5188156,
    lat: -23.2913329
  },
  {
    lng: -44.5184813,
    lat: -23.2914483
  },
  {
    lng: -44.5185866,
    lat: -23.2916699
  },
  {
    lng: -44.5183582,
    lat: -23.2917879
  },
  {
    lng: -44.5178059,
    lat: -23.2918353
  },
  {
    lng: -44.5173909,
    lat: -23.291921
  },
  {
    lng: -44.5173995,
    lat: -23.291993
  },
  {
    lng: -44.5172025,
    lat: -23.292035
  },
  {
    lng: -44.5166956,
    lat: -23.2917842
  },
  {
    lng: -44.5166954,
    lat: -23.2918437
  },
  {
    lng: -44.5167793,
    lat: -23.2920005
  },
  {
    lng: -44.5167731,
    lat: -23.2920488
  },
  {
    lng: -44.5165993,
    lat: -23.2920882
  },
  {
    lng: -44.5166249,
    lat: -23.2922561
  },
  {
    lng: -44.5164234,
    lat: -23.292299
  },
  {
    lng: -44.516308,
    lat: -23.2922955
  },
  {
    lng: -44.5163158,
    lat: -23.2922395
  },
  {
    lng: -44.5162588,
    lat: -23.2922438
  },
  {
    lng: -44.5161973,
    lat: -23.292281
  },
  {
    lng: -44.5158684,
    lat: -23.2922692
  },
  {
    lng: -44.5156483,
    lat: -23.2921945
  },
  {
    lng: -44.5154794,
    lat: -23.2921635
  },
  {
    lng: -44.5152025,
    lat: -23.2920763
  },
  {
    lng: -44.5150828,
    lat: -23.2920119
  },
  {
    lng: -44.5148738,
    lat: -23.291935
  },
  {
    lng: -44.5147094,
    lat: -23.2918255
  },
  {
    lng: -44.514552,
    lat: -23.2918009
  },
  {
    lng: -44.5145733,
    lat: -23.2919475
  },
  {
    lng: -44.5144615,
    lat: -23.2919653
  },
  {
    lng: -44.5143409,
    lat: -23.291915
  },
  {
    lng: -44.5141423,
    lat: -23.2917633
  },
  {
    lng: -44.5139803,
    lat: -23.2917239
  },
  {
    lng: -44.513891,
    lat: -23.2916507
  },
  {
    lng: -44.5138582,
    lat: -23.2916853
  },
  {
    lng: -44.5137132,
    lat: -23.2918082
  },
  {
    lng: -44.5135351,
    lat: -23.291984
  },
  {
    lng: -44.5134754,
    lat: -23.2920996
  },
  {
    lng: -44.5132216,
    lat: -23.2923824
  },
  {
    lng: -44.5132638,
    lat: -23.2925293
  },
  {
    lng: -44.5131925,
    lat: -23.292842
  },
  {
    lng: -44.5135168,
    lat: -23.2932877
  },
  {
    lng: -44.5135609,
    lat: -23.2932571
  },
  {
    lng: -44.5136946,
    lat: -23.2935456
  },
  {
    lng: -44.5137094,
    lat: -23.2936572
  },
  {
    lng: -44.5136842,
    lat: -23.2937453
  },
  {
    lng: -44.5136486,
    lat: -23.2937521
  },
  {
    lng: -44.5136564,
    lat: -23.2937904
  },
  {
    lng: -44.5136051,
    lat: -23.2938549
  },
  {
    lng: -44.5134799,
    lat: -23.2938722
  },
  {
    lng: -44.5133993,
    lat: -23.2939134
  },
  {
    lng: -44.5134085,
    lat: -23.2939796
  },
  {
    lng: -44.5134292,
    lat: -23.2940781
  },
  {
    lng: -44.5134772,
    lat: -23.2942073
  },
  {
    lng: -44.5134064,
    lat: -23.2943697
  },
  {
    lng: -44.513432,
    lat: -23.2944428
  },
  {
    lng: -44.5133745,
    lat: -23.2945529
  },
  {
    lng: -44.5133709,
    lat: -23.2946287
  },
  {
    lng: -44.5133484,
    lat: -23.2947208
  },
  {
    lng: -44.5133728,
    lat: -23.2948061
  },
  {
    lng: -44.5132319,
    lat: -23.2949458
  },
  {
    lng: -44.5132582,
    lat: -23.294988
  },
  {
    lng: -44.5131465,
    lat: -23.2950534
  },
  {
    lng: -44.5130963,
    lat: -23.2950832
  },
  {
    lng: -44.513054,
    lat: -23.2950573
  },
  {
    lng: -44.5130423,
    lat: -23.2950961
  },
  {
    lng: -44.5130886,
    lat: -23.2951715
  },
  {
    lng: -44.5131737,
    lat: -23.295313
  },
  {
    lng: -44.5132255,
    lat: -23.2953706
  },
  {
    lng: -44.5132187,
    lat: -23.2954419
  },
  {
    lng: -44.5133288,
    lat: -23.2955973
  },
  {
    lng: -44.5133233,
    lat: -23.2956646
  },
  {
    lng: -44.5132627,
    lat: -23.29574
  },
  {
    lng: -44.5131641,
    lat: -23.2957946
  },
  {
    lng: -44.5130394,
    lat: -23.2958028
  },
  {
    lng: -44.5129809,
    lat: -23.2957523
  },
  {
    lng: -44.5129026,
    lat: -23.2956989
  },
  {
    lng: -44.5127683,
    lat: -23.2956673
  },
  {
    lng: -44.5125528,
    lat: -23.2956626
  },
  {
    lng: -44.5124154,
    lat: -23.2956184
  },
  {
    lng: -44.5122297,
    lat: -23.2955058
  },
  {
    lng: -44.5120597,
    lat: -23.29526
  },
  {
    lng: -44.5122869,
    lat: -23.2956763
  },
  {
    lng: -44.512181,
    lat: -23.2957865
  },
  {
    lng: -44.5120516,
    lat: -23.2958843
  },
  {
    lng: -44.5119775,
    lat: -23.2959429
  },
  {
    lng: -44.5118188,
    lat: -23.2958254
  },
  {
    lng: -44.5116081,
    lat: -23.295689
  },
  {
    lng: -44.5110738,
    lat: -23.2952976
  },
  {
    lng: -44.5107644,
    lat: -23.2949514
  },
  {
    lng: -44.5104837,
    lat: -23.2947741
  },
  {
    lng: -44.5098567,
    lat: -23.2950145
  },
  {
    lng: -44.5095667,
    lat: -23.2954321
  },
  {
    lng: -44.5096209,
    lat: -23.2959284
  },
  {
    lng: -44.5096073,
    lat: -23.2963459
  },
  {
    lng: -44.5096789,
    lat: -23.2966422
  },
  {
    lng: -44.5096123,
    lat: -23.2969658
  },
  {
    lng: -44.5096099,
    lat: -23.2972705
  },
  {
    lng: -44.5093012,
    lat: -23.2972826
  },
  {
    lng: -44.5088977,
    lat: -23.2971826
  },
  {
    lng: -44.5091366,
    lat: -23.2976784
  },
  {
    lng: -44.50935,
    lat: -23.2978708
  },
  {
    lng: -44.5095186,
    lat: -23.2981661
  },
  {
    lng: -44.5094975,
    lat: -23.2985766
  },
  {
    lng: -44.5089832,
    lat: -23.2988999
  },
  {
    lng: -44.5087984,
    lat: -23.2989206
  },
  {
    lng: -44.5089358,
    lat: -23.299088
  },
  {
    lng: -44.5089037,
    lat: -23.2992357
  },
  {
    lng: -44.5089359,
    lat: -23.2993736
  },
  {
    lng: -44.5093113,
    lat: -23.2998221
  },
  {
    lng: -44.5098102,
    lat: -23.3003639
  },
  {
    lng: -44.5099623,
    lat: -23.3007749
  },
  {
    lng: -44.5099768,
    lat: -23.3008584
  },
  {
    lng: -44.5099078,
    lat: -23.3009005
  },
  {
    lng: -44.5098653,
    lat: -23.3008992
  },
  {
    lng: -44.5098678,
    lat: -23.3009449
  },
  {
    lng: -44.5096994,
    lat: -23.3010378
  },
  {
    lng: -44.5096088,
    lat: -23.3009573
  },
  {
    lng: -44.5095905,
    lat: -23.3010473
  },
  {
    lng: -44.5095311,
    lat: -23.3011287
  },
  {
    lng: -44.5094598,
    lat: -23.3011672
  },
  {
    lng: -44.5093622,
    lat: -23.3011168
  },
  {
    lng: -44.5092484,
    lat: -23.3010562
  },
  {
    lng: -44.5091533,
    lat: -23.3008661
  },
  {
    lng: -44.5090519,
    lat: -23.3007888
  },
  {
    lng: -44.5085972,
    lat: -23.3005517
  },
  {
    lng: -44.5081954,
    lat: -23.3000861
  },
  {
    lng: -44.5075965,
    lat: -23.2995887
  },
  {
    lng: -44.5073771,
    lat: -23.2995806
  },
  {
    lng: -44.5070517,
    lat: -23.2997175
  },
  {
    lng: -44.5068453,
    lat: -23.2998763
  },
  {
    lng: -44.5066687,
    lat: -23.299893
  },
  {
    lng: -44.5065934,
    lat: -23.2998507
  },
  {
    lng: -44.506541,
    lat: -23.2997801
  },
  {
    lng: -44.5065088,
    lat: -23.2997903
  },
  {
    lng: -44.5064617,
    lat: -23.299812
  },
  {
    lng: -44.5064158,
    lat: -23.2998044
  },
  {
    lng: -44.5063426,
    lat: -23.2997779
  },
  {
    lng: -44.5061447,
    lat: -23.2995026
  },
  {
    lng: -44.5061404,
    lat: -23.2994283
  },
  {
    lng: -44.5061089,
    lat: -23.2991939
  },
  {
    lng: -44.5058701,
    lat: -23.2989119
  },
  {
    lng: -44.505838,
    lat: -23.2988192
  },
  {
    lng: -44.5057722,
    lat: -23.298712
  },
  {
    lng: -44.5056786,
    lat: -23.2986671
  },
  {
    lng: -44.5054732,
    lat: -23.2982824
  },
  {
    lng: -44.5051647,
    lat: -23.297839
  },
  {
    lng: -44.504891,
    lat: -23.297537
  },
  {
    lng: -44.5047413,
    lat: -23.2973238
  },
  {
    lng: -44.5046639,
    lat: -23.2973967
  },
  {
    lng: -44.5046319,
    lat: -23.2974007
  },
  {
    lng: -44.5046002,
    lat: -23.2973664
  },
  {
    lng: -44.5045517,
    lat: -23.2971715
  },
  {
    lng: -44.5044807,
    lat: -23.2971562
  },
  {
    lng: -44.5044674,
    lat: -23.2971323
  },
  {
    lng: -44.5044261,
    lat: -23.2971208
  },
  {
    lng: -44.5044071,
    lat: -23.2970109
  },
  {
    lng: -44.504444,
    lat: -23.2969386
  },
  {
    lng: -44.5043447,
    lat: -23.2968059
  },
  {
    lng: -44.5042105,
    lat: -23.2966267
  },
  {
    lng: -44.5040999,
    lat: -23.2964371
  },
  {
    lng: -44.5035928,
    lat: -23.2961049
  },
  {
    lng: -44.5034427,
    lat: -23.2960226
  },
  {
    lng: -44.5033145,
    lat: -23.2958831
  },
  {
    lng: -44.5032293,
    lat: -23.295947
  },
  {
    lng: -44.5029096,
    lat: -23.2957598
  },
  {
    lng: -44.5026979,
    lat: -23.2952944
  },
  {
    lng: -44.5026052,
    lat: -23.2953375
  },
  {
    lng: -44.5024368,
    lat: -23.2952683
  },
  {
    lng: -44.5025227,
    lat: -23.2951707
  },
  {
    lng: -44.5026313,
    lat: -23.2951668
  },
  {
    lng: -44.502575,
    lat: -23.2950014
  },
  {
    lng: -44.502748,
    lat: -23.2949401
  },
  {
    lng: -44.5027308,
    lat: -23.2948485
  },
  {
    lng: -44.5027898,
    lat: -23.2948002
  },
  {
    lng: -44.5028605,
    lat: -23.2948694
  },
  {
    lng: -44.5030076,
    lat: -23.2948659
  },
  {
    lng: -44.5030966,
    lat: -23.2947045
  },
  {
    lng: -44.5031047,
    lat: -23.2944935
  },
  {
    lng: -44.5029384,
    lat: -23.2942165
  },
  {
    lng: -44.5031647,
    lat: -23.2941517
  },
  {
    lng: -44.5032243,
    lat: -23.2939496
  },
  {
    lng: -44.5030008,
    lat: -23.2936558
  },
  {
    lng: -44.5028053,
    lat: -23.2933711
  },
  {
    lng: -44.5027488,
    lat: -23.2932161
  },
  {
    lng: -44.5029144,
    lat: -23.2930438
  },
  {
    lng: -44.5031143,
    lat: -23.2930675
  },
  {
    lng: -44.5032732,
    lat: -23.2932255
  },
  {
    lng: -44.50326,
    lat: -23.2928979
  },
  {
    lng: -44.5034374,
    lat: -23.2928114
  },
  {
    lng: -44.5035274,
    lat: -23.2927832
  },
  {
    lng: -44.5038004,
    lat: -23.2927433
  },
  {
    lng: -44.5038752,
    lat: -23.2928159
  },
  {
    lng: -44.5039797,
    lat: -23.2926949
  },
  {
    lng: -44.5039023,
    lat: -23.2924967
  },
  {
    lng: -44.5040551,
    lat: -23.2924816
  },
  {
    lng: -44.5041936,
    lat: -23.2925027
  },
  {
    lng: -44.504276,
    lat: -23.2922391
  },
  {
    lng: -44.5043877,
    lat: -23.2922742
  },
  {
    lng: -44.5045819,
    lat: -23.2921702
  },
  {
    lng: -44.5044629,
    lat: -23.2921193
  },
  {
    lng: -44.5043086,
    lat: -23.2919188
  },
  {
    lng: -44.5046004,
    lat: -23.291442
  },
  {
    lng: -44.5048729,
    lat: -23.2913787
  },
  {
    lng: -44.5048672,
    lat: -23.291157
  },
  {
    lng: -44.5051434,
    lat: -23.2910341
  },
  {
    lng: -44.5053193,
    lat: -23.2909127
  },
  {
    lng: -44.5057028,
    lat: -23.2910845
  },
  {
    lng: -44.5059103,
    lat: -23.2910517
  },
  {
    lng: -44.5062205,
    lat: -23.2910608
  },
  {
    lng: -44.5064129,
    lat: -23.2912774
  },
  {
    lng: -44.5064519,
    lat: -23.2914151
  },
  {
    lng: -44.5065468,
    lat: -23.2914401
  },
  {
    lng: -44.5066018,
    lat: -23.291516
  },
  {
    lng: -44.5066996,
    lat: -23.2915661
  },
  {
    lng: -44.5066923,
    lat: -23.2914785
  },
  {
    lng: -44.5068123,
    lat: -23.2914733
  },
  {
    lng: -44.5068964,
    lat: -23.2914268
  },
  {
    lng: -44.5069969,
    lat: -23.2914247
  },
  {
    lng: -44.5073214,
    lat: -23.2916025
  },
  {
    lng: -44.5074481,
    lat: -23.2915113
  },
  {
    lng: -44.5075251,
    lat: -23.2914587
  },
  {
    lng: -44.5078335,
    lat: -23.2915044
  },
  {
    lng: -44.507981,
    lat: -23.2914405
  },
  {
    lng: -44.5080191,
    lat: -23.2914828
  },
  {
    lng: -44.5080801,
    lat: -23.2918485
  },
  {
    lng: -44.5081624,
    lat: -23.2919633
  },
  {
    lng: -44.5081692,
    lat: -23.2921622
  },
  {
    lng: -44.5084879,
    lat: -23.2921899
  },
  {
    lng: -44.5088313,
    lat: -23.2923599
  },
  {
    lng: -44.5090217,
    lat: -23.2924462
  },
  {
    lng: -44.5094697,
    lat: -23.2923625
  },
  {
    lng: -44.5099697,
    lat: -23.2922416
  },
  {
    lng: -44.5107625,
    lat: -23.2920915
  },
  {
    lng: -44.5110911,
    lat: -23.2919881
  },
  {
    lng: -44.5112292,
    lat: -23.2918499
  },
  {
    lng: -44.5115001,
    lat: -23.291781
  },
  {
    lng: -44.5118327,
    lat: -23.2915962
  },
  {
    lng: -44.5122177,
    lat: -23.2915162
  },
  {
    lng: -44.512437,
    lat: -23.2914872
  },
  {
    lng: -44.5126772,
    lat: -23.2914462
  },
  {
    lng: -44.5128451,
    lat: -23.2913631
  },
  {
    lng: -44.5129404,
    lat: -23.2913351
  },
  {
    lng: -44.5130058,
    lat: -23.2912745
  },
  {
    lng: -44.5130131,
    lat: -23.2911987
  },
  {
    lng: -44.5129484,
    lat: -23.2909065
  },
  {
    lng: -44.5129441,
    lat: -23.2907091
  },
  {
    lng: -44.5130041,
    lat: -23.2904502
  },
  {
    lng: -44.5128708,
    lat: -23.2903111
  },
  {
    lng: -44.5128809,
    lat: -23.2901611
  },
  {
    lng: -44.5130801,
    lat: -23.2899538
  },
  {
    lng: -44.5134823,
    lat: -23.2895095
  },
  {
    lng: -44.5136003,
    lat: -23.2893691
  },
  {
    lng: -44.513611,
    lat: -23.2892163
  },
  {
    lng: -44.5136915,
    lat: -23.2891128
  },
  {
    lng: -44.5138041,
    lat: -23.288965
  },
  {
    lng: -44.5140831,
    lat: -23.2884994
  },
  {
    lng: -44.5141861,
    lat: -23.2882098
  },
  {
    lng: -44.5142278,
    lat: -23.2879723
  },
  {
    lng: -44.5144398,
    lat: -23.2877012
  },
  {
    lng: -44.514523,
    lat: -23.2874696
  },
  {
    lng: -44.5146597,
    lat: -23.2870852
  },
  {
    lng: -44.5147134,
    lat: -23.2868536
  },
  {
    lng: -44.5148582,
    lat: -23.2866171
  },
  {
    lng: -44.5149922,
    lat: -23.2865087
  },
  {
    lng: -44.515054,
    lat: -23.2862919
  },
  {
    lng: -44.5150978,
    lat: -23.2859413
  },
  {
    lng: -44.5153389,
    lat: -23.2857318
  },
  {
    lng: -44.515451,
    lat: -23.2856119
  },
  {
    lng: -44.5156388,
    lat: -23.2856045
  },
  {
    lng: -44.5157514,
    lat: -23.2854961
  },
  {
    lng: -44.5158453,
    lat: -23.2853237
  },
  {
    lng: -44.5159177,
    lat: -23.2852177
  },
  {
    lng: -44.5162101,
    lat: -23.2850403
  },
  {
    lng: -44.5163576,
    lat: -23.2850256
  },
  {
    lng: -44.5164461,
    lat: -23.2849886
  },
  {
    lng: -44.5167438,
    lat: -23.2847693
  },
  {
    lng: -44.5168833,
    lat: -23.2846461
  },
  {
    lng: -44.5169577,
    lat: -23.2846508
  },
  {
    lng: -44.5172342,
    lat: -23.284467
  },
  {
    lng: -44.5174895,
    lat: -23.2843924
  },
  {
    lng: -44.5177067,
    lat: -23.2842766
  },
  {
    lng: -44.5177926,
    lat: -23.2842076
  },
  {
    lng: -44.5180346,
    lat: -23.2841461
  },
  {
    lng: -44.5182673,
    lat: -23.2842174
  },
  {
    lng: -44.518566,
    lat: -23.2843247
  },
  {
    lng: -44.5186749,
    lat: -23.2844687
  },
  {
    lng: -44.5190102,
    lat: -23.2844712
  },
  {
    lng: -44.5192088,
    lat: -23.2843973
  },
  {
    lng: -44.5194286,
    lat: -23.2844934
  },
  {
    lng: -44.5196754,
    lat: -23.2845082
  },
  {
    lng: -44.5197635,
    lat: -23.2842628
  },
  {
    lng: -44.5201577,
    lat: -23.2841972
  },
  {
    lng: -44.52044,
    lat: -23.2839152
  },
  {
    lng: -44.5205811,
    lat: -23.2835787
  },
  {
    lng: -44.5203519,
    lat: -23.2833699
  },
  {
    lng: -44.520055,
    lat: -23.283346
  },
  {
    lng: -44.5200447,
    lat: -23.2829903
  },
  {
    lng: -44.5198724,
    lat: -23.2828628
  },
  {
    lng: -44.5196365,
    lat: -23.2829247
  },
  {
    lng: -44.5194096,
    lat: -23.2827529
  },
  {
    lng: -44.5194187,
    lat: -23.2824413
  },
  {
    lng: -44.5194018,
    lat: -23.282037
  },
  {
    lng: -44.5191777,
    lat: -23.2815723
  },
  {
    lng: -44.5189003,
    lat: -23.2813794
  },
  {
    lng: -44.5189781,
    lat: -23.2810818
  },
  {
    lng: -44.5188574,
    lat: -23.280815
  },
  {
    lng: -44.5187232,
    lat: -23.2807164
  },
  {
    lng: -44.5187045,
    lat: -23.2804306
  },
  {
    lng: -44.5183477,
    lat: -23.2802902
  },
  {
    lng: -44.5183423,
    lat: -23.2800586
  },
  {
    lng: -44.5184882,
    lat: -23.2795841
  },
  {
    lng: -44.5189807,
    lat: -23.279327
  },
  {
    lng: -44.5194515,
    lat: -23.2795109
  },
  {
    lng: -44.5198231,
    lat: -23.2794127
  },
  {
    lng: -44.5204345,
    lat: -23.2796101
  },
  {
    lng: -44.5207375,
    lat: -23.2797383
  },
  {
    lng: -44.5212927,
    lat: -23.2796323
  },
  {
    lng: -44.521789,
    lat: -23.2797358
  },
  {
    lng: -44.5221366,
    lat: -23.2795946
  },
  {
    lng: -44.5220975,
    lat: -23.27911
  },
  {
    lng: -44.5218864,
    lat: -23.2788032
  },
  {
    lng: -44.5221171,
    lat: -23.2783936
  },
  {
    lng: -44.5220465,
    lat: -23.2781294
  },
  {
    lng: -44.5218694,
    lat: -23.2780136
  },
  {
    lng: -44.5219688,
    lat: -23.2777549
  },
  {
    lng: -44.5220625,
    lat: -23.2776538
  },
  {
    lng: -44.5222397,
    lat: -23.2776292
  },
  {
    lng: -44.5225346,
    lat: -23.2774419
  },
  {
    lng: -44.5226151,
    lat: -23.2771069
  },
  {
    lng: -44.5228994,
    lat: -23.277028
  },
  {
    lng: -44.522937,
    lat: -23.2768826
  },
  {
    lng: -44.5230256,
    lat: -23.2769664
  },
  {
    lng: -44.5231837,
    lat: -23.2770034
  },
  {
    lng: -44.5235324,
    lat: -23.2769097
  },
  {
    lng: -44.5237362,
    lat: -23.2769738
  },
  {
    lng: -44.5238435,
    lat: -23.2769541
  },
  {
    lng: -44.5239455,
    lat: -23.2769935
  },
  {
    lng: -44.5242089,
    lat: -23.276584
  },
  {
    lng: -44.5244175,
    lat: -23.2766633
  },
  {
    lng: -44.5246892,
    lat: -23.2764321
  },
  {
    lng: -44.5249647,
    lat: -23.2766831
  },
  {
    lng: -44.5252705,
    lat: -23.2766042
  },
  {
    lng: -44.5255925,
    lat: -23.2770785
  },
  {
    lng: -44.5258633,
    lat: -23.2767102
  },
  {
    lng: -44.5261218,
    lat: -23.2764935
  },
  {
    lng: -44.5257614,
    lat: -23.2759784
  },
  {
    lng: -44.5257453,
    lat: -23.2758379
  },
  {
    lng: -44.5257051,
    lat: -23.2756803
  },
  {
    lng: -44.5255441,
    lat: -23.2754585
  },
  {
    lng: -44.5255226,
    lat: -23.2751702
  },
  {
    lng: -44.5253698,
    lat: -23.2750667
  },
  {
    lng: -44.5252545,
    lat: -23.2749238
  },
  {
    lng: -44.5256567,
    lat: -23.2746084
  },
  {
    lng: -44.5260001,
    lat: -23.2746922
  },
  {
    lng: -44.5257962,
    lat: -23.2742339
  },
  {
    lng: -44.5258176,
    lat: -23.2738791
  },
  {
    lng: -44.5259571,
    lat: -23.2735588
  },
  {
    lng: -44.5261824,
    lat: -23.2733765
  },
  {
    lng: -44.526633,
    lat: -23.2732089
  },
  {
    lng: -44.5270407,
    lat: -23.2733568
  },
  {
    lng: -44.5268683,
    lat: -23.2729647
  },
  {
    lng: -44.5270006,
    lat: -23.2726989
  },
  {
    lng: -44.5271413,
    lat: -23.2725976
  },
  {
    lng: -44.5273867,
    lat: -23.2727457
  },
  {
    lng: -44.5274539,
    lat: -23.272726
  },
  {
    lng: -44.5274727,
    lat: -23.2726274
  },
  {
    lng: -44.5276563,
    lat: -23.2724242
  },
  {
    lng: -44.5277945,
    lat: -23.2724549
  },
  {
    lng: -44.527942,
    lat: -23.272588
  },
  {
    lng: -44.5280975,
    lat: -23.2725289
  },
  {
    lng: -44.5282477,
    lat: -23.2722825
  },
  {
    lng: -44.5284141,
    lat: -23.272142
  },
  {
    lng: -44.5290206,
    lat: -23.2718587
  },
  {
    lng: -44.5292919,
    lat: -23.2718718
  },
  {
    lng: -44.529377,
    lat: -23.2716591
  },
  {
    lng: -44.5293878,
    lat: -23.2715507
  },
  {
    lng: -44.5295381,
    lat: -23.2713527
  },
  {
    lng: -44.5299697,
    lat: -23.2712772
  },
  {
    lng: -44.5304661,
    lat: -23.2714051
  },
  {
    lng: -44.5307315,
    lat: -23.2712476
  },
  {
    lng: -44.5309756,
    lat: -23.2711737
  },
  {
    lng: -44.5313785,
    lat: -23.2711384
  },
  {
    lng: -44.532019,
    lat: -23.2707844
  },
  {
    lng: -44.5323248,
    lat: -23.2706193
  },
  {
    lng: -44.5325098,
    lat: -23.2704985
  },
  {
    lng: -44.5330247,
    lat: -23.2702423
  },
  {
    lng: -44.5338046,
    lat: -23.2699811
  },
  {
    lng: -44.5345295,
    lat: -23.2698579
  },
  {
    lng: -44.5353898,
    lat: -23.2700269
  },
  {
    lng: -44.5356829,
    lat: -23.2698579
  },
  {
    lng: -44.5358035,
    lat: -23.2696411
  },
  {
    lng: -44.5359819,
    lat: -23.2691644
  },
  {
    lng: -44.5358438,
    lat: -23.2689142
  },
  {
    lng: -44.5356962,
    lat: -23.2688753
  },
  {
    lng: -44.5355434,
    lat: -23.2685865
  },
  {
    lng: -44.5354361,
    lat: -23.2684534
  },
  {
    lng: -44.5352225,
    lat: -23.2683071
  },
  {
    lng: -44.535259,
    lat: -23.2681774
  },
  {
    lng: -44.5356346,
    lat: -23.2679557
  },
  {
    lng: -44.535774,
    lat: -23.2678275
  },
  {
    lng: -44.5359458,
    lat: -23.2678571
  },
  {
    lng: -44.5361603,
    lat: -23.267793
  },
  {
    lng: -44.5367664,
    lat: -23.2674234
  },
  {
    lng: -44.5370052,
    lat: -23.2672386
  },
  {
    lng: -44.5373109,
    lat: -23.2670612
  },
  {
    lng: -44.5378448,
    lat: -23.2668123
  },
  {
    lng: -44.537909,
    lat: -23.2666251
  },
  {
    lng: -44.5383433,
    lat: -23.266726
  },
  {
    lng: -44.5389213,
    lat: -23.266426
  },
  {
    lng: -44.5396525,
    lat: -23.2664624
  },
  {
    lng: -44.5399852,
    lat: -23.2666103
  },
  {
    lng: -44.5399314,
    lat: -23.266763
  },
  {
    lng: -44.5397894,
    lat: -23.2668296
  },
  {
    lng: -44.5397679,
    lat: -23.2669626
  },
  {
    lng: -44.5397947,
    lat: -23.2670858
  },
  {
    lng: -44.5399154,
    lat: -23.2672657
  },
  {
    lng: -44.5399825,
    lat: -23.2674801
  },
  {
    lng: -44.5402667,
    lat: -23.2676526
  },
  {
    lng: -44.5405376,
    lat: -23.2677388
  },
  {
    lng: -44.5404786,
    lat: -23.2678941
  },
  {
    lng: -44.540661,
    lat: -23.2679187
  },
  {
    lng: -44.5407763,
    lat: -23.2681577
  },
  {
    lng: -44.5407414,
    lat: -23.2683499
  },
  {
    lng: -44.5410043,
    lat: -23.2686899
  },
  {
    lng: -44.5410875,
    lat: -23.2688255
  },
  {
    lng: -44.5412296,
    lat: -23.2689314
  },
  {
    lng: -44.5417608,
    lat: -23.2693947
  },
  {
    lng: -44.5423562,
    lat: -23.2701979
  },
  {
    lng: -44.5423831,
    lat: -23.2703581
  },
  {
    lng: -44.5424769,
    lat: -23.2704714
  },
  {
    lng: -44.5425922,
    lat: -23.2707942
  },
  {
    lng: -44.5427048,
    lat: -23.270878
  },
  {
    lng: -44.5428604,
    lat: -23.2707794
  },
  {
    lng: -44.5429516,
    lat: -23.2710308
  },
  {
    lng: -44.5431608,
    lat: -23.271218
  },
  {
    lng: -44.5432815,
    lat: -23.2713166
  },
  {
    lng: -44.5433271,
    lat: -23.2713856
  },
  {
    lng: -44.5435793,
    lat: -23.2718488
  },
  {
    lng: -44.5436565,
    lat: -23.2722823
  },
  {
    lng: -44.5440069,
    lat: -23.2725814
  },
  {
    lng: -44.5443258,
    lat: -23.2724099
  },
  {
    lng: -44.5443946,
    lat: -23.2725929
  },
  {
    lng: -44.5444295,
    lat: -23.2728763
  },
  {
    lng: -44.5447166,
    lat: -23.2731596
  },
  {
    lng: -44.5453194,
    lat: -23.2731286
  },
  {
    lng: -44.5456795,
    lat: -23.2730266
  },
  {
    lng: -44.5461687,
    lat: -23.2731624
  },
  {
    lng: -44.5467325,
    lat: -23.2735123
  },
  {
    lng: -44.5469159,
    lat: -23.2737658
  },
  {
    lng: -44.5469427,
    lat: -23.2742339
  },
  {
    lng: -44.5471609,
    lat: -23.2743517
  },
  {
    lng: -44.5476448,
    lat: -23.2743703
  },
  {
    lng: -44.5489984,
    lat: -23.2742253
  },
  {
    lng: -44.549668,
    lat: -23.2740648
  },
  {
    lng: -44.5500996,
    lat: -23.2738323
  },
  {
    lng: -44.550307,
    lat: -23.2736666
  },
  {
    lng: -44.5500179,
    lat: -23.2730124
  },
  {
    lng: -44.5502538,
    lat: -23.2725718
  },
  {
    lng: -44.550489,
    lat: -23.2723775
  },
  {
    lng: -44.5509124,
    lat: -23.2724254
  },
  {
    lng: -44.5513067,
    lat: -23.2726028
  },
  {
    lng: -44.5514597,
    lat: -23.2724845
  },
  {
    lng: -44.55154,
    lat: -23.2722923
  },
  {
    lng: -44.5516128,
    lat: -23.2721309
  },
  {
    lng: -44.5512077,
    lat: -23.2714456
  },
  {
    lng: -44.5512665,
    lat: -23.2712328
  },
  {
    lng: -44.5511968,
    lat: -23.2710677
  },
  {
    lng: -44.5511566,
    lat: -23.270979
  },
  {
    lng: -44.5511169,
    lat: -23.2707907
  },
  {
    lng: -44.5512343,
    lat: -23.2706217
  },
  {
    lng: -44.5514892,
    lat: -23.2705872
  },
  {
    lng: -44.5523003,
    lat: -23.2706334
  },
  {
    lng: -44.5529406,
    lat: -23.2707705
  },
  {
    lng: -44.5532401,
    lat: -23.2709158
  },
  {
    lng: -44.553297,
    lat: -23.27108
  },
  {
    lng: -44.553737,
    lat: -23.2714361
  },
  {
    lng: -44.5542974,
    lat: -23.2716908
  },
  {
    lng: -44.5553212,
    lat: -23.2718908
  },
  {
    lng: -44.556257,
    lat: -23.2722147
  },
  {
    lng: -44.557221,
    lat: -23.2726077
  },
  {
    lng: -44.5579805,
    lat: -23.2730928
  },
  {
    lng: -44.5583125,
    lat: -23.2729862
  },
  {
    lng: -44.5588545,
    lat: -23.2736167
  },
  {
    lng: -44.559855,
    lat: -23.2733913
  },
  {
    lng: -44.5610459,
    lat: -23.2730481
  },
  {
    lng: -44.5612846,
    lat: -23.2727851
  },
  {
    lng: -44.5615349,
    lat: -23.2726726
  },
  {
    lng: -44.5618933,
    lat: -23.2724106
  },
  {
    lng: -44.5621187,
    lat: -23.2720707
  },
  {
    lng: -44.56288,
    lat: -23.2718595
  },
  {
    lng: -44.5632426,
    lat: -23.2718045
  },
  {
    lng: -44.5636905,
    lat: -23.2718882
  },
  {
    lng: -44.5640192,
    lat: -23.2720214
  },
  {
    lng: -44.5641585,
    lat: -23.2721862
  },
  {
    lng: -44.5643371,
    lat: -23.2722873
  },
  {
    lng: -44.5647784,
    lat: -23.272244
  },
  {
    lng: -44.5654612,
    lat: -23.2721044
  },
  {
    lng: -44.5656104,
    lat: -23.271979
  },
  {
    lng: -44.5655913,
    lat: -23.2718463
  },
  {
    lng: -44.5657119,
    lat: -23.271727
  },
  {
    lng: -44.5659816,
    lat: -23.2716786
  },
  {
    lng: -44.5659811,
    lat: -23.2713733
  },
  {
    lng: -44.5660375,
    lat: -23.2712279
  },
  {
    lng: -44.5659865,
    lat: -23.2711269
  },
  {
    lng: -44.566051,
    lat: -23.2710546
  },
  {
    lng: -44.566185,
    lat: -23.2710184
  },
  {
    lng: -44.5663297,
    lat: -23.2710703
  },
  {
    lng: -44.5667469,
    lat: -23.2711067
  },
  {
    lng: -44.5670031,
    lat: -23.2710086
  },
  {
    lng: -44.5670513,
    lat: -23.2708509
  },
  {
    lng: -44.5673141,
    lat: -23.2709249
  },
  {
    lng: -44.5676602,
    lat: -23.2712476
  },
  {
    lng: -44.56762,
    lat: -23.2713954
  },
  {
    lng: -44.5677138,
    lat: -23.271457
  },
  {
    lng: -44.5679445,
    lat: -23.2713733
  },
  {
    lng: -44.5681805,
    lat: -23.2716197
  },
  {
    lng: -44.5681322,
    lat: -23.2716986
  },
  {
    lng: -44.5683495,
    lat: -23.2719006
  },
  {
    lng: -44.5685372,
    lat: -23.2719696
  },
  {
    lng: -44.5686633,
    lat: -23.2719991
  },
  {
    lng: -44.5688471,
    lat: -23.2718858
  },
  {
    lng: -44.5690661,
    lat: -23.2716972
  },
  {
    lng: -44.5696771,
    lat: -23.2711836
  },
  {
    lng: -44.5700955,
    lat: -23.2710999
  },
  {
    lng: -44.5706294,
    lat: -23.271149
  },
  {
    lng: -44.5711256,
    lat: -23.271425
  },
  {
    lng: -44.5712834,
    lat: -23.2715995
  },
  {
    lng: -44.5717956,
    lat: -23.2718524
  },
  {
    lng: -44.5719299,
    lat: -23.2718485
  },
  {
    lng: -44.5724585,
    lat: -23.2715842
  },
  {
    lng: -44.5733939,
    lat: -23.2710258
  },
  {
    lng: -44.5738931,
    lat: -23.2706817
  },
  {
    lng: -44.5744094,
    lat: -23.2703717
  },
  {
    lng: -44.5749986,
    lat: -23.2700009
  },
  {
    lng: -44.5748405,
    lat: -23.2698702
  },
  {
    lng: -44.5747894,
    lat: -23.2697619
  },
  {
    lng: -44.5748324,
    lat: -23.2696435
  },
  {
    lng: -44.5749384,
    lat: -23.2696017
  },
  {
    lng: -44.5750523,
    lat: -23.2692814
  },
  {
    lng: -44.575185,
    lat: -23.2692641
  },
  {
    lng: -44.5752455,
    lat: -23.2691483
  },
  {
    lng: -44.5751892,
    lat: -23.2689511
  },
  {
    lng: -44.5753474,
    lat: -23.2688131
  },
  {
    lng: -44.5754171,
    lat: -23.2686505
  },
  {
    lng: -44.5753366,
    lat: -23.2683574
  },
  {
    lng: -44.575763,
    lat: -23.2685471
  },
  {
    lng: -44.5762807,
    lat: -23.2689438
  },
  {
    lng: -44.5771268,
    lat: -23.2700778
  },
  {
    lng: -44.5775572,
    lat: -23.2702949
  },
  {
    lng: -44.5776782,
    lat: -23.2703951
  },
  {
    lng: -44.5779357,
    lat: -23.2703778
  },
  {
    lng: -44.5781637,
    lat: -23.2702866
  },
  {
    lng: -44.5785607,
    lat: -23.2704148
  },
  {
    lng: -44.5788584,
    lat: -23.2707006
  },
  {
    lng: -44.5790837,
    lat: -23.2706833
  },
  {
    lng: -44.579309,
    lat: -23.270841
  },
  {
    lng: -44.5795825,
    lat: -23.2708904
  },
  {
    lng: -44.5798266,
    lat: -23.2710629
  },
  {
    lng: -44.5804832,
    lat: -23.270653
  },
  {
    lng: -44.5813823,
    lat: -23.2699837
  },
  {
    lng: -44.5821109,
    lat: -23.269227
  },
  {
    lng: -44.5827895,
    lat: -23.2686626
  },
  {
    lng: -44.583526,
    lat: -23.2677383
  },
  {
    lng: -44.5838532,
    lat: -23.2672515
  },
  {
    lng: -44.5844037,
    lat: -23.2661785
  },
  {
    lng: -44.584732,
    lat: -23.2653549
  },
  {
    lng: -44.584979,
    lat: -23.2644955
  },
  {
    lng: -44.5849264,
    lat: -23.2639363
  },
  {
    lng: -44.5848245,
    lat: -23.2637291
  },
  {
    lng: -44.584569,
    lat: -23.263683
  },
  {
    lng: -44.5844649,
    lat: -23.2635145
  },
  {
    lng: -44.5841179,
    lat: -23.263349
  },
  {
    lng: -44.5838987,
    lat: -23.2633696
  },
  {
    lng: -44.5830593,
    lat: -23.2629504
  },
  {
    lng: -44.5829628,
    lat: -23.262768
  },
  {
    lng: -44.5826619,
    lat: -23.2626356
  },
  {
    lng: -44.5824609,
    lat: -23.2626314
  },
  {
    lng: -44.5818633,
    lat: -23.2623486
  },
  {
    lng: -44.5814544,
    lat: -23.2618434
  },
  {
    lng: -44.5815924,
    lat: -23.261552
  },
  {
    lng: -44.5813043,
    lat: -23.2612756
  },
  {
    lng: -44.5810992,
    lat: -23.2608119
  },
  {
    lng: -44.5812296,
    lat: -23.2605682
  },
  {
    lng: -44.5816065,
    lat: -23.2604504
  },
  {
    lng: -44.5817686,
    lat: -23.2598227
  },
  {
    lng: -44.5814159,
    lat: -23.2595762
  },
  {
    lng: -44.5814334,
    lat: -23.2593513
  },
  {
    lng: -44.5816279,
    lat: -23.2592098
  },
  {
    lng: -44.5816233,
    lat: -23.2588665
  },
  {
    lng: -44.5815111,
    lat: -23.25872
  },
  {
    lng: -44.5813891,
    lat: -23.2580374
  },
  {
    lng: -44.5814544,
    lat: -23.2576398
  },
  {
    lng: -44.5812444,
    lat: -23.2575114
  },
  {
    lng: -44.5807636,
    lat: -23.2575826
  },
  {
    lng: -44.5804973,
    lat: -23.2576604
  },
  {
    lng: -44.5803059,
    lat: -23.2573699
  },
  {
    lng: -44.5797355,
    lat: -23.2573943
  },
  {
    lng: -44.5797355,
    lat: -23.257069
  },
  {
    lng: -44.5794566,
    lat: -23.2567437
  },
  {
    lng: -44.5794566,
    lat: -23.2564973
  },
  {
    lng: -44.5793577,
    lat: -23.2563172
  },
  {
    lng: -44.5790839,
    lat: -23.2557233
  },
  {
    lng: -44.5783515,
    lat: -23.2552306
  },
  {
    lng: -44.5779116,
    lat: -23.2552109
  },
  {
    lng: -44.577815,
    lat: -23.2550434
  },
  {
    lng: -44.5774395,
    lat: -23.2549153
  },
  {
    lng: -44.5770748,
    lat: -23.2546146
  },
  {
    lng: -44.5766563,
    lat: -23.254388
  },
  {
    lng: -44.5764149,
    lat: -23.2540675
  },
  {
    lng: -44.5762164,
    lat: -23.2539591
  },
  {
    lng: -44.5759008,
    lat: -23.2535798
  },
  {
    lng: -44.5755995,
    lat: -23.2526727
  },
  {
    lng: -44.5757015,
    lat: -23.2526382
  },
  {
    lng: -44.5758731,
    lat: -23.2527664
  },
  {
    lng: -44.5757229,
    lat: -23.2524065
  },
  {
    lng: -44.5755191,
    lat: -23.2523277
  },
  {
    lng: -44.5754654,
    lat: -23.252101
  },
  {
    lng: -44.5751559,
    lat: -23.2517418
  },
  {
    lng: -44.5750265,
    lat: -23.2514228
  },
  {
    lng: -44.5750953,
    lat: -23.2513099
  },
  {
    lng: -44.5752133,
    lat: -23.2513148
  },
  {
    lng: -44.5751489,
    lat: -23.2512064
  },
  {
    lng: -44.5751704,
    lat: -23.2511029
  },
  {
    lng: -44.5751892,
    lat: -23.2508096
  },
  {
    lng: -44.57525,
    lat: -23.2506549
  },
  {
    lng: -44.5751591,
    lat: -23.2504033
  },
  {
    lng: -44.5751677,
    lat: -23.2502404
  },
  {
    lng: -44.5754225,
    lat: -23.2502429
  },
  {
    lng: -44.5757088,
    lat: -23.2503732
  },
  {
    lng: -44.5757259,
    lat: -23.2497779
  },
  {
    lng: -44.5754601,
    lat: -23.2497007
  },
  {
    lng: -44.5753742,
    lat: -23.2494345
  },
  {
    lng: -44.575222,
    lat: -23.2491274
  },
  {
    lng: -44.5750797,
    lat: -23.2489436
  },
  {
    lng: -44.5749129,
    lat: -23.2485769
  },
  {
    lng: -44.5749628,
    lat: -23.2484295
  },
  {
    lng: -44.5747841,
    lat: -23.248158
  },
  {
    lng: -44.5749665,
    lat: -23.2480151
  },
  {
    lng: -44.5753635,
    lat: -23.2481875
  },
  {
    lng: -44.5753742,
    lat: -23.2482368
  },
  {
    lng: -44.5755673,
    lat: -23.2482787
  },
  {
    lng: -44.5756103,
    lat: -23.2482245
  },
  {
    lng: -44.5757256,
    lat: -23.2482197
  },
  {
    lng: -44.5759107,
    lat: -23.2483033
  },
  {
    lng: -44.5759482,
    lat: -23.2482245
  },
  {
    lng: -44.5761347,
    lat: -23.2482899
  },
  {
    lng: -44.5761038,
    lat: -23.2481185
  },
  {
    lng: -44.5764391,
    lat: -23.2483429
  },
  {
    lng: -44.5770392,
    lat: -23.2485782
  },
  {
    lng: -44.5775435,
    lat: -23.2488204
  },
  {
    lng: -44.5777543,
    lat: -23.2486503
  },
  {
    lng: -44.5780065,
    lat: -23.2483074
  },
  {
    lng: -44.5783541,
    lat: -23.2475847
  },
  {
    lng: -44.5785222,
    lat: -23.2469406
  },
  {
    lng: -44.5784816,
    lat: -23.2467287
  },
  {
    lng: -44.5781888,
    lat: -23.246595
  },
  {
    lng: -44.5777622,
    lat: -23.2463157
  },
  {
    lng: -44.5777117,
    lat: -23.2461202
  },
  {
    lng: -44.5776723,
    lat: -23.245805
  },
  {
    lng: -44.5774201,
    lat: -23.2456433
  },
  {
    lng: -44.5772731,
    lat: -23.2455926
  },
  {
    lng: -44.5772725,
    lat: -23.2453014
  },
  {
    lng: -44.5770456,
    lat: -23.2447305
  },
  {
    lng: -44.5766987,
    lat: -23.2444651
  },
  {
    lng: -44.5761011,
    lat: -23.2441645
  },
  {
    lng: -44.5754505,
    lat: -23.2435855
  },
  {
    lng: -44.5746652,
    lat: -23.2427876
  },
  {
    lng: -44.5740631,
    lat: -23.2425239
  },
  {
    lng: -44.5736361,
    lat: -23.2420727
  },
  {
    lng: -44.5734248,
    lat: -23.241689
  },
  {
    lng: -44.5734238,
    lat: -23.2414472
  },
  {
    lng: -44.572472,
    lat: -23.2404716
  },
  {
    lng: -44.5722317,
    lat: -23.2403657
  },
  {
    lng: -44.5720536,
    lat: -23.2403689
  },
  {
    lng: -44.5719224,
    lat: -23.2402536
  },
  {
    lng: -44.5719742,
    lat: -23.2399179
  },
  {
    lng: -44.5716187,
    lat: -23.2392802
  },
  {
    lng: -44.5709293,
    lat: -23.2390107
  },
  {
    lng: -44.5700763,
    lat: -23.2386913
  },
  {
    lng: -44.5697186,
    lat: -23.238381
  },
  {
    lng: -44.5687575,
    lat: -23.2377534
  },
  {
    lng: -44.5682992,
    lat: -23.2374648
  },
  {
    lng: -44.5680815,
    lat: -23.2373573
  },
  {
    lng: -44.5678977,
    lat: -23.2370597
  },
  {
    lng: -44.5675381,
    lat: -23.236808
  },
  {
    lng: -44.5673486,
    lat: -23.2356776
  },
  {
    lng: -44.5670843,
    lat: -23.2355453
  },
  {
    lng: -44.5666484,
    lat: -23.2351359
  },
  {
    lng: -44.5655133,
    lat: -23.2347582
  },
  {
    lng: -44.5650036,
    lat: -23.2344699
  },
  {
    lng: -44.5648904,
    lat: -23.2342652
  },
  {
    lng: -44.5639447,
    lat: -23.2337262
  },
  {
    lng: -44.5636846,
    lat: -23.2333745
  },
  {
    lng: -44.5627386,
    lat: -23.2327657
  },
  {
    lng: -44.5624852,
    lat: -23.232672
  },
  {
    lng: -44.5623778,
    lat: -23.2325778
  },
  {
    lng: -44.5622455,
    lat: -23.2319596
  },
  {
    lng: -44.5620566,
    lat: -23.2320384
  },
  {
    lng: -44.5619007,
    lat: -23.2320283
  },
  {
    lng: -44.5615582,
    lat: -23.2316854
  },
  {
    lng: -44.5613746,
    lat: -23.2316547
  },
  {
    lng: -44.5612259,
    lat: -23.2314758
  },
  {
    lng: -44.5615792,
    lat: -23.231267
  },
  {
    lng: -44.5613172,
    lat: -23.2312166
  },
  {
    lng: -44.5606868,
    lat: -23.2312004
  },
  {
    lng: -44.5603482,
    lat: -23.23098
  },
  {
    lng: -44.5599362,
    lat: -23.2301827
  },
  {
    lng: -44.5597629,
    lat: -23.2302056
  },
  {
    lng: -44.5592636,
    lat: -23.2293345
  },
  {
    lng: -44.5593829,
    lat: -23.2292643
  },
  {
    lng: -44.559222,
    lat: -23.2288749
  },
  {
    lng: -44.5593468,
    lat: -23.2287341
  },
  {
    lng: -44.5591117,
    lat: -23.2278025
  },
  {
    lng: -44.5591254,
    lat: -23.2275981
  },
  {
    lng: -44.5590825,
    lat: -23.2271347
  },
  {
    lng: -44.5592712,
    lat: -23.2270175
  },
  {
    lng: -44.5600517,
    lat: -23.2266134
  },
  {
    lng: -44.5601669,
    lat: -23.2263921
  },
  {
    lng: -44.5610351,
    lat: -23.2265333
  },
  {
    lng: -44.5619256,
    lat: -23.2268686
  },
  {
    lng: -44.5627518,
    lat: -23.2273369
  },
  {
    lng: -44.5632596,
    lat: -23.228249
  },
  {
    lng: -44.5635248,
    lat: -23.2286204
  },
  {
    lng: -44.56386,
    lat: -23.2290899
  },
  {
    lng: -44.5645821,
    lat: -23.2296139
  },
  {
    lng: -44.5653871,
    lat: -23.2301544
  },
  {
    lng: -44.5657656,
    lat: -23.2303262
  },
  {
    lng: -44.5660088,
    lat: -23.2302395
  },
  {
    lng: -44.5660281,
    lat: -23.2299486
  },
  {
    lng: -44.566178,
    lat: -23.2298951
  },
  {
    lng: -44.5668166,
    lat: -23.2300112
  },
  {
    lng: -44.5676172,
    lat: -23.2299557
  },
  {
    lng: -44.5684047,
    lat: -23.2302197
  },
  {
    lng: -44.5685924,
    lat: -23.2301326
  },
  {
    lng: -44.5686204,
    lat: -23.2299594
  },
  {
    lng: -44.5684969,
    lat: -23.229565
  },
  {
    lng: -44.5690442,
    lat: -23.2295847
  },
  {
    lng: -44.5691515,
    lat: -23.2297425
  },
  {
    lng: -44.5701528,
    lat: -23.2299859
  },
  {
    lng: -44.5705999,
    lat: -23.2300925
  },
  {
    lng: -44.5709599,
    lat: -23.2303967
  },
  {
    lng: -44.5712436,
    lat: -23.2314333
  },
  {
    lng: -44.5716512,
    lat: -23.231814
  },
  {
    lng: -44.5720757,
    lat: -23.2322139
  },
  {
    lng: -44.5724848,
    lat: -23.2324604
  },
  {
    lng: -44.5735741,
    lat: -23.2332572
  },
  {
    lng: -44.5743529,
    lat: -23.2335749
  },
  {
    lng: -44.5749163,
    lat: -23.233751
  },
  {
    lng: -44.5763529,
    lat: -23.2333268
  },
  {
    lng: -44.5766558,
    lat: -23.2333844
  },
  {
    lng: -44.5770646,
    lat: -23.2330912
  },
  {
    lng: -44.5772393,
    lat: -23.2330728
  },
  {
    lng: -44.5772982,
    lat: -23.2329372
  },
  {
    lng: -44.5776004,
    lat: -23.2327051
  },
  {
    lng: -44.5780135,
    lat: -23.2327348
  },
  {
    lng: -44.5788614,
    lat: -23.2331094
  },
  {
    lng: -44.5792384,
    lat: -23.2331339
  },
  {
    lng: -44.57964,
    lat: -23.2329734
  },
  {
    lng: -44.5807729,
    lat: -23.233415
  },
  {
    lng: -44.5808381,
    lat: -23.2333278
  },
  {
    lng: -44.5808637,
    lat: -23.2329747
  },
  {
    lng: -44.5810808,
    lat: -23.2328655
  },
  {
    lng: -44.5813719,
    lat: -23.2328053
  },
  {
    lng: -44.5818062,
    lat: -23.2329269
  },
  {
    lng: -44.5822031,
    lat: -23.2329516
  },
  {
    lng: -44.5823909,
    lat: -23.2327544
  },
  {
    lng: -44.5827502,
    lat: -23.2325375
  },
  {
    lng: -44.5835174,
    lat: -23.2325227
  },
  {
    lng: -44.58407,
    lat: -23.2328579
  },
  {
    lng: -44.5844669,
    lat: -23.2327742
  },
  {
    lng: -44.5846922,
    lat: -23.2329171
  },
  {
    lng: -44.5850141,
    lat: -23.2329516
  },
  {
    lng: -44.5853145,
    lat: -23.2329171
  },
  {
    lng: -44.5858831,
    lat: -23.2329861
  },
  {
    lng: -44.5862425,
    lat: -23.233139
  },
  {
    lng: -44.5867468,
    lat: -23.2331537
  },
  {
    lng: -44.5868487,
    lat: -23.2329023
  },
  {
    lng: -44.5871277,
    lat: -23.2325474
  },
  {
    lng: -44.5873905,
    lat: -23.2322072
  },
  {
    lng: -44.5877285,
    lat: -23.2320446
  },
  {
    lng: -44.5880289,
    lat: -23.2323255
  },
  {
    lng: -44.5883454,
    lat: -23.2323305
  },
  {
    lng: -44.5886941,
    lat: -23.2326361
  },
  {
    lng: -44.5889623,
    lat: -23.232572
  },
  {
    lng: -44.5891393,
    lat: -23.2326361
  },
  {
    lng: -44.5894182,
    lat: -23.2328924
  },
  {
    lng: -44.5902873,
    lat: -23.2328283
  },
  {
    lng: -44.5905341,
    lat: -23.2325129
  },
  {
    lng: -44.5909792,
    lat: -23.2325917
  },
  {
    lng: -44.5915801,
    lat: -23.2329565
  },
  {
    lng: -44.59202,
    lat: -23.2328826
  },
  {
    lng: -44.5929642,
    lat: -23.2330255
  },
  {
    lng: -44.5934577,
    lat: -23.2332424
  },
  {
    lng: -44.5936079,
    lat: -23.2334741
  },
  {
    lng: -44.5932217,
    lat: -23.2335825
  },
  {
    lng: -44.5932217,
    lat: -23.2337501
  },
  {
    lng: -44.593463,
    lat: -23.2339325
  },
  {
    lng: -44.5938278,
    lat: -23.2339867
  },
  {
    lng: -44.5939727,
    lat: -23.2342086
  },
  {
    lng: -44.5942608,
    lat: -23.234323
  },
  {
    lng: -44.5946164,
    lat: -23.234317
  },
  {
    lng: -44.594831,
    lat: -23.2341691
  },
  {
    lng: -44.5955498,
    lat: -23.2341987
  },
  {
    lng: -44.5956946,
    lat: -23.2342579
  },
  {
    lng: -44.5961584,
    lat: -23.2341959
  },
  {
    lng: -44.59673,
    lat: -23.2345733
  },
  {
    lng: -44.5969714,
    lat: -23.2345487
  },
  {
    lng: -44.5971,
    lat: -23.2346374
  },
  {
    lng: -44.5968748,
    lat: -23.2349923
  },
  {
    lng: -44.5973201,
    lat: -23.2353177
  },
  {
    lng: -44.5976848,
    lat: -23.2353472
  },
  {
    lng: -44.5979584,
    lat: -23.2354508
  },
  {
    lng: -44.5980979,
    lat: -23.2355592
  },
  {
    lng: -44.5981408,
    lat: -23.2358944
  },
  {
    lng: -44.5984144,
    lat: -23.2362247
  },
  {
    lng: -44.59916,
    lat: -23.23655
  },
  {
    lng: -44.5993746,
    lat: -23.2366732
  },
  {
    lng: -44.5994949,
    lat: -23.2368459
  },
  {
    lng: -44.5994873,
    lat: -23.2372302
  },
  {
    lng: -44.5992781,
    lat: -23.238014
  },
  {
    lng: -44.598637,
    lat: -23.2381101
  },
  {
    lng: -44.5979209,
    lat: -23.2381372
  },
  {
    lng: -44.5976071,
    lat: -23.2380953
  },
  {
    lng: -44.5971645,
    lat: -23.2381644
  },
  {
    lng: -44.5970237,
    lat: -23.2383738
  },
  {
    lng: -44.5969714,
    lat: -23.2387855
  },
  {
    lng: -44.5971752,
    lat: -23.2390344
  },
  {
    lng: -44.5970358,
    lat: -23.2397293
  },
  {
    lng: -44.5968909,
    lat: -23.2399512
  },
  {
    lng: -44.5969767,
    lat: -23.2401779
  },
  {
    lng: -44.5969097,
    lat: -23.2406113
  },
  {
    lng: -44.596703,
    lat: -23.2409273
  },
  {
    lng: -44.5965771,
    lat: -23.2413733
  },
  {
    lng: -44.5965374,
    lat: -23.2416115
  },
  {
    lng: -44.5966226,
    lat: -23.2430466
  },
  {
    lng: -44.5967139,
    lat: -23.2434656
  },
  {
    lng: -44.5969821,
    lat: -23.243643
  },
  {
    lng: -44.5970197,
    lat: -23.2441704
  },
  {
    lng: -44.5970806,
    lat: -23.2443889
  },
  {
    lng: -44.5977504,
    lat: -23.2451146
  },
  {
    lng: -44.5979571,
    lat: -23.2452561
  },
  {
    lng: -44.5982267,
    lat: -23.2453387
  },
  {
    lng: -44.59835,
    lat: -23.2451784
  },
  {
    lng: -44.5986585,
    lat: -23.2451119
  },
  {
    lng: -44.5989911,
    lat: -23.2451365
  },
  {
    lng: -44.5990072,
    lat: -23.2454027
  },
  {
    lng: -44.5992834,
    lat: -23.245696
  },
  {
    lng: -44.5998847,
    lat: -23.2460793
  },
  {
    lng: -44.6003333,
    lat: -23.2474825
  },
  {
    lng: -44.6012745,
    lat: -23.2480636
  },
  {
    lng: -44.601624,
    lat: -23.2482294
  },
  {
    lng: -44.6025658,
    lat: -23.2483762
  },
  {
    lng: -44.6027795,
    lat: -23.2484097
  },
  {
    lng: -44.6031328,
    lat: -23.2483753
  },
  {
    lng: -44.6034161,
    lat: -23.2483479
  },
  {
    lng: -44.6039823,
    lat: -23.2484986
  },
  {
    lng: -44.6041555,
    lat: -23.2485637
  },
  {
    lng: -44.6041912,
    lat: -23.2486807
  },
  {
    lng: -44.6046339,
    lat: -23.2487947
  },
  {
    lng: -44.6049483,
    lat: -23.2489666
  },
  {
    lng: -44.6050341,
    lat: -23.2491191
  },
  {
    lng: -44.6050126,
    lat: -23.2493901
  },
  {
    lng: -44.6053184,
    lat: -23.249745
  },
  {
    lng: -44.6058105,
    lat: -23.2507038
  },
  {
    lng: -44.6062548,
    lat: -23.2508816
  },
  {
    lng: -44.606565,
    lat: -23.2509146
  },
  {
    lng: -44.6066949,
    lat: -23.2510854
  },
  {
    lng: -44.6072377,
    lat: -23.2513598
  },
  {
    lng: -44.6082464,
    lat: -23.2514566
  },
  {
    lng: -44.6086545,
    lat: -23.2514906
  },
  {
    lng: -44.608951,
    lat: -23.2516403
  },
  {
    lng: -44.6092966,
    lat: -23.2518611
  },
  {
    lng: -44.6094946,
    lat: -23.2520133
  },
  {
    lng: -44.6100084,
    lat: -23.2519673
  },
  {
    lng: -44.6105199,
    lat: -23.251983
  },
  {
    lng: -44.6108417,
    lat: -23.2522126
  },
  {
    lng: -44.6111964,
    lat: -23.2524451
  },
  {
    lng: -44.6114601,
    lat: -23.2524772
  },
  {
    lng: -44.6117343,
    lat: -23.2524484
  },
  {
    lng: -44.6119247,
    lat: -23.2523228
  },
  {
    lng: -44.612099,
    lat: -23.2524225
  },
  {
    lng: -44.6128661,
    lat: -23.2523376
  },
  {
    lng: -44.6136734,
    lat: -23.2521798
  },
  {
    lng: -44.6138425,
    lat: -23.2522046
  },
  {
    lng: -44.6139927,
    lat: -23.2522563
  },
  {
    lng: -44.6141315,
    lat: -23.2522844
  },
  {
    lng: -44.6141361,
    lat: -23.2523654
  },
  {
    lng: -44.6140662,
    lat: -23.2526157
  },
  {
    lng: -44.6140802,
    lat: -23.2527435
  },
  {
    lng: -44.6143912,
    lat: -23.2530682
  },
  {
    lng: -44.6144535,
    lat: -23.2531347
  },
  {
    lng: -44.6148638,
    lat: -23.2532319
  },
  {
    lng: -44.6151518,
    lat: -23.253251
  },
  {
    lng: -44.6153251,
    lat: -23.2534237
  },
  {
    lng: -44.6157898,
    lat: -23.2536832
  },
  {
    lng: -44.6161921,
    lat: -23.2540134
  },
  {
    lng: -44.6161927,
    lat: -23.2542044
  },
  {
    lng: -44.6164918,
    lat: -23.2543932
  },
  {
    lng: -44.6168314,
    lat: -23.2547247
  },
  {
    lng: -44.6167615,
    lat: -23.2549486
  },
  {
    lng: -44.6171817,
    lat: -23.25529
  },
  {
    lng: -44.6173995,
    lat: -23.2560311
  },
  {
    lng: -44.6176715,
    lat: -23.2562398
  },
  {
    lng: -44.6178779,
    lat: -23.2563983
  },
  {
    lng: -44.6179254,
    lat: -23.256771
  },
  {
    lng: -44.6180056,
    lat: -23.2569989
  },
  {
    lng: -44.6185659,
    lat: -23.2572251
  },
  {
    lng: -44.6190879,
    lat: -23.2573953
  },
  {
    lng: -44.6194403,
    lat: -23.257557
  },
  {
    lng: -44.6199823,
    lat: -23.2579273
  },
  {
    lng: -44.6206637,
    lat: -23.2580108
  },
  {
    lng: -44.620658,
    lat: -23.2583481
  },
  {
    lng: -44.6210342,
    lat: -23.2583756
  },
  {
    lng: -44.621176,
    lat: -23.2587593
  },
  {
    lng: -44.621171,
    lat: -23.259023
  },
  {
    lng: -44.6207913,
    lat: -23.2600526
  },
  {
    lng: -44.6206488,
    lat: -23.2603988
  },
  {
    lng: -44.6210702,
    lat: -23.2610182
  },
  {
    lng: -44.6209289,
    lat: -23.2618882
  },
  {
    lng: -44.6208652,
    lat: -23.2623351
  },
  {
    lng: -44.621319,
    lat: -23.2625786
  },
  {
    lng: -44.6215677,
    lat: -23.2629642
  },
  {
    lng: -44.6215628,
    lat: -23.2638074
  },
  {
    lng: -44.6214249,
    lat: -23.2639406
  },
  {
    lng: -44.6213723,
    lat: -23.2643002
  },
  {
    lng: -44.6214723,
    lat: -23.2644254
  },
  {
    lng: -44.6218543,
    lat: -23.2647565
  },
  {
    lng: -44.6221065,
    lat: -23.2649613
  },
  {
    lng: -44.6223608,
    lat: -23.2651326
  },
  {
    lng: -44.6225548,
    lat: -23.2653674
  },
  {
    lng: -44.6227969,
    lat: -23.2659031
  },
  {
    lng: -44.6229669,
    lat: -23.2660982
  },
  {
    lng: -44.6231246,
    lat: -23.2664279
  },
  {
    lng: -44.6231634,
    lat: -23.2667356
  },
  {
    lng: -44.623254,
    lat: -23.2669073
  },
  {
    lng: -44.6234109,
    lat: -23.2676255
  },
  {
    lng: -44.6234676,
    lat: -23.2686768
  },
  {
    lng: -44.6236562,
    lat: -23.2687504
  },
  {
    lng: -44.6240547,
    lat: -23.2688524
  },
  {
    lng: -44.6247165,
    lat: -23.269159
  },
  {
    lng: -44.6250286,
    lat: -23.2694728
  },
  {
    lng: -44.6252149,
    lat: -23.2698617
  },
  {
    lng: -44.6254108,
    lat: -23.2699736
  },
  {
    lng: -44.6254254,
    lat: -23.2703683
  },
  {
    lng: -44.6255627,
    lat: -23.2704258
  },
  {
    lng: -44.6257613,
    lat: -23.2703692
  },
  {
    lng: -44.6258971,
    lat: -23.2704278
  },
  {
    lng: -44.6261688,
    lat: -23.2706047
  },
  {
    lng: -44.6263776,
    lat: -23.2706705
  },
  {
    lng: -44.6266731,
    lat: -23.2705231
  },
  {
    lng: -44.6267355,
    lat: -23.2706861
  },
  {
    lng: -44.6266901,
    lat: -23.2708129
  },
  {
    lng: -44.6266065,
    lat: -23.2710015
  },
  {
    lng: -44.6265346,
    lat: -23.2718094
  },
  {
    lng: -44.6267439,
    lat: -23.2725781
  },
  {
    lng: -44.6272115,
    lat: -23.2728171
  },
  {
    lng: -44.6275959,
    lat: -23.2731971
  },
  {
    lng: -44.628101,
    lat: -23.2741206
  },
  {
    lng: -44.6281225,
    lat: -23.2747218
  },
  {
    lng: -44.6282834,
    lat: -23.2749583
  },
  {
    lng: -44.6287233,
    lat: -23.2751751
  },
  {
    lng: -44.6287772,
    lat: -23.275596
  },
  {
    lng: -44.6285571,
    lat: -23.2760424
  },
  {
    lng: -44.6286053,
    lat: -23.2771167
  },
  {
    lng: -44.6289294,
    lat: -23.2775784
  },
  {
    lng: -44.6291883,
    lat: -23.2779139
  },
  {
    lng: -44.6293964,
    lat: -23.2778385
  },
  {
    lng: -44.6296365,
    lat: -23.2779203
  },
  {
    lng: -44.6296549,
    lat: -23.27808
  },
  {
    lng: -44.6299207,
    lat: -23.2782308
  },
  {
    lng: -44.6300537,
    lat: -23.2786295
  },
  {
    lng: -44.629736,
    lat: -23.2793139
  },
  {
    lng: -44.6295656,
    lat: -23.2797481
  },
  {
    lng: -44.6298372,
    lat: -23.2808323
  },
  {
    lng: -44.6300285,
    lat: -23.2813901
  },
  {
    lng: -44.6301586,
    lat: -23.2813888
  },
  {
    lng: -44.6303522,
    lat: -23.2820885
  },
  {
    lng: -44.6305982,
    lat: -23.282308
  },
  {
    lng: -44.6307189,
    lat: -23.2822637
  },
  {
    lng: -44.6311503,
    lat: -23.2824941
  },
  {
    lng: -44.6312054,
    lat: -23.2827531
  },
  {
    lng: -44.6314034,
    lat: -23.2830679
  },
  {
    lng: -44.6315889,
    lat: -23.2832311
  },
  {
    lng: -44.6320048,
    lat: -23.2835157
  },
  {
    lng: -44.6323445,
    lat: -23.2838055
  },
  {
    lng: -44.6325378,
    lat: -23.2839762
  },
  {
    lng: -44.6326959,
    lat: -23.284133
  },
  {
    lng: -44.632756,
    lat: -23.2844379
  },
  {
    lng: -44.6328369,
    lat: -23.2846282
  },
  {
    lng: -44.6332026,
    lat: -23.2847927
  },
  {
    lng: -44.6333046,
    lat: -23.2846178
  },
  {
    lng: -44.6335285,
    lat: -23.284709
  },
  {
    lng: -44.633432,
    lat: -23.284879
  },
  {
    lng: -44.6336073,
    lat: -23.2849745
  },
  {
    lng: -44.6337036,
    lat: -23.284832
  },
  {
    lng: -44.6338871,
    lat: -23.2849067
  },
  {
    lng: -44.6340998,
    lat: -23.2850655
  },
  {
    lng: -44.6342555,
    lat: -23.2852298
  },
  {
    lng: -44.6343307,
    lat: -23.2853435
  },
  {
    lng: -44.6344643,
    lat: -23.2858052
  },
  {
    lng: -44.6345297,
    lat: -23.2858956
  },
  {
    lng: -44.6348812,
    lat: -23.2859956
  },
  {
    lng: -44.6350137,
    lat: -23.2860901
  },
  {
    lng: -44.635053,
    lat: -23.2862903
  },
  {
    lng: -44.63506,
    lat: -23.2866879
  },
  {
    lng: -44.6351541,
    lat: -23.286919
  },
  {
    lng: -44.6352752,
    lat: -23.2869078
  },
  {
    lng: -44.6357039,
    lat: -23.2873527
  },
  {
    lng: -44.6357784,
    lat: -23.2876431
  },
  {
    lng: -44.6358653,
    lat: -23.2879747
  },
  {
    lng: -44.6362648,
    lat: -23.2882115
  },
  {
    lng: -44.6364756,
    lat: -23.2884259
  },
  {
    lng: -44.6366296,
    lat: -23.2885911
  },
  {
    lng: -44.6369462,
    lat: -23.2891018
  },
  {
    lng: -44.6371333,
    lat: -23.2893978
  },
  {
    lng: -44.6374939,
    lat: -23.2897711
  },
  {
    lng: -44.6377772,
    lat: -23.2899683
  },
  {
    lng: -44.6380064,
    lat: -23.2900712
  },
  {
    lng: -44.6380982,
    lat: -23.2902409
  },
  {
    lng: -44.6382111,
    lat: -23.2902848
  },
  {
    lng: -44.6386009,
    lat: -23.29023
  },
  {
    lng: -44.6390783,
    lat: -23.2901058
  },
  {
    lng: -44.6394085,
    lat: -23.2899902
  },
  {
    lng: -44.6395471,
    lat: -23.289903
  },
  {
    lng: -44.6394703,
    lat: -23.2896593
  },
  {
    lng: -44.6394683,
    lat: -23.2895135
  },
  {
    lng: -44.6396035,
    lat: -23.2895486
  },
  {
    lng: -44.639919,
    lat: -23.2893878
  },
  {
    lng: -44.640424,
    lat: -23.2892704
  },
  {
    lng: -44.6409822,
    lat: -23.28932
  },
  {
    lng: -44.6417596,
    lat: -23.2893833
  },
  {
    lng: -44.6423897,
    lat: -23.2893233
  },
  {
    lng: -44.6426401,
    lat: -23.2891418
  },
  {
    lng: -44.6428363,
    lat: -23.2889344
  },
  {
    lng: -44.6429661,
    lat: -23.2887478
  },
  {
    lng: -44.6425402,
    lat: -23.2880619
  },
  {
    lng: -44.6420558,
    lat: -23.2873936
  },
  {
    lng: -44.6419968,
    lat: -23.28726
  },
  {
    lng: -44.6416979,
    lat: -23.2869545
  },
  {
    lng: -44.6418378,
    lat: -23.2866806
  },
  {
    lng: -44.642006,
    lat: -23.2867182
  },
  {
    lng: -44.6423951,
    lat: -23.2868617
  },
  {
    lng: -44.6427367,
    lat: -23.2869986
  },
  {
    lng: -44.6431969,
    lat: -23.2871315
  },
  {
    lng: -44.6436634,
    lat: -23.2872482
  },
  {
    lng: -44.6439347,
    lat: -23.2874351
  },
  {
    lng: -44.6440669,
    lat: -23.2876859
  },
  {
    lng: -44.644292,
    lat: -23.2878251
  },
  {
    lng: -44.6442545,
    lat: -23.2879059
  },
  {
    lng: -44.6444791,
    lat: -23.2880686
  },
  {
    lng: -44.6447805,
    lat: -23.2884145
  },
  {
    lng: -44.6449445,
    lat: -23.2888366
  },
  {
    lng: -44.6451052,
    lat: -23.28907
  },
  {
    lng: -44.6454931,
    lat: -23.289411
  },
  {
    lng: -44.6459631,
    lat: -23.2896662
  },
  {
    lng: -44.6462157,
    lat: -23.2898914
  },
  {
    lng: -44.6462853,
    lat: -23.2902025
  },
  {
    lng: -44.6463184,
    lat: -23.2904832
  },
  {
    lng: -44.6462669,
    lat: -23.2906128
  },
  {
    lng: -44.6457422,
    lat: -23.290681
  },
  {
    lng: -44.6453218,
    lat: -23.2910298
  },
  {
    lng: -44.6445917,
    lat: -23.291748
  },
  {
    lng: -44.6442036,
    lat: -23.2921094
  },
  {
    lng: -44.6441448,
    lat: -23.2922569
  },
  {
    lng: -44.6441726,
    lat: -23.2924589
  },
  {
    lng: -44.6441136,
    lat: -23.2927552
  },
  {
    lng: -44.643922,
    lat: -23.2929669
  },
  {
    lng: -44.6440166,
    lat: -23.2930743
  },
  {
    lng: -44.6440248,
    lat: -23.2934091
  },
  {
    lng: -44.6440366,
    lat: -23.2936281
  },
  {
    lng: -44.6437865,
    lat: -23.2938989
  },
  {
    lng: -44.6433891,
    lat: -23.2941064
  },
  {
    lng: -44.6429086,
    lat: -23.2944887
  },
  {
    lng: -44.6426006,
    lat: -23.2946068
  },
  {
    lng: -44.6424314,
    lat: -23.2946566
  },
  {
    lng: -44.6420963,
    lat: -23.2947809
  },
  {
    lng: -44.6419575,
    lat: -23.2948244
  },
  {
    lng: -44.6418289,
    lat: -23.2947747
  },
  {
    lng: -44.6417409,
    lat: -23.2946255
  },
  {
    lng: -44.6416969,
    lat: -23.2943954
  },
  {
    lng: -44.6415364,
    lat: -23.2942055
  },
  {
    lng: -44.641309,
    lat: -23.2941213
  },
  {
    lng: -44.6409418,
    lat: -23.2942105
  },
  {
    lng: -44.641275,
    lat: -23.2942139
  },
  {
    lng: -44.6413851,
    lat: -23.2942651
  },
  {
    lng: -44.6414268,
    lat: -23.2942771
  },
  {
    lng: -44.6414256,
    lat: -23.294375
  },
  {
    lng: -44.6414501,
    lat: -23.2945019
  },
  {
    lng: -44.64146,
    lat: -23.2946317
  },
  {
    lng: -44.6414431,
    lat: -23.2947964
  },
  {
    lng: -44.6414905,
    lat: -23.294983
  },
  {
    lng: -44.6414973,
    lat: -23.2952161
  },
  {
    lng: -44.6414025,
    lat: -23.2955829
  },
  {
    lng: -44.6413483,
    lat: -23.295757
  },
  {
    lng: -44.6411825,
    lat: -23.2960865
  },
  {
    lng: -44.6406139,
    lat: -23.2968511
  },
  {
    lng: -44.6403567,
    lat: -23.2970687
  },
  {
    lng: -44.6396426,
    lat: -23.2973174
  },
  {
    lng: -44.6388541,
    lat: -23.2977496
  },
  {
    lng: -44.6381738,
    lat: -23.2980976
  },
  {
    lng: -44.6379064,
    lat: -23.2983401
  },
  {
    lng: -44.6374157,
    lat: -23.2986851
  },
  {
    lng: -44.6372194,
    lat: -23.2988064
  },
  {
    lng: -44.6370096,
    lat: -23.2989773
  },
  {
    lng: -44.6368539,
    lat: -23.2991949
  },
  {
    lng: -44.6370265,
    lat: -23.2992664
  },
  {
    lng: -44.6371652,
    lat: -23.2993099
  },
  {
    lng: -44.6373108,
    lat: -23.2994995
  },
  {
    lng: -44.6374563,
    lat: -23.2997637
  },
  {
    lng: -44.6375781,
    lat: -23.3001336
  },
  {
    lng: -44.6375849,
    lat: -23.3002735
  },
  {
    lng: -44.6375612,
    lat: -23.3004445
  },
  {
    lng: -44.6375037,
    lat: -23.3005688
  },
  {
    lng: -44.6374597,
    lat: -23.3006621
  },
  {
    lng: -44.6374394,
    lat: -23.3006807
  },
  {
    lng: -44.6373243,
    lat: -23.300631
  },
  {
    lng: -44.6372532,
    lat: -23.3005377
  },
  {
    lng: -44.6372126,
    lat: -23.3005968
  },
  {
    lng: -44.6373006,
    lat: -23.3008237
  },
  {
    lng: -44.6374225,
    lat: -23.3010724
  },
  {
    lng: -44.6376357,
    lat: -23.3012931
  },
  {
    lng: -44.6379369,
    lat: -23.3015759
  },
  {
    lng: -44.6381772,
    lat: -23.3016878
  },
  {
    lng: -44.6384885,
    lat: -23.3018805
  },
  {
    lng: -44.6386408,
    lat: -23.3020826
  },
  {
    lng: -44.638783,
    lat: -23.3023033
  },
  {
    lng: -44.6388777,
    lat: -23.3023033
  },
  {
    lng: -44.6390267,
    lat: -23.3022069
  },
  {
    lng: -44.639179,
    lat: -23.3020826
  },
  {
    lng: -44.6392602,
    lat: -23.3019489
  },
  {
    lng: -44.6393346,
    lat: -23.3018184
  },
  {
    lng: -44.6393753,
    lat: -23.3016101
  },
  {
    lng: -44.6395005,
    lat: -23.3015262
  },
  {
    lng: -44.6397848,
    lat: -23.3014391
  },
  {
    lng: -44.6400352,
    lat: -23.3013397
  },
  {
    lng: -44.6403703,
    lat: -23.3013086
  },
  {
    lng: -44.6406985,
    lat: -23.3013397
  },
  {
    lng: -44.6410065,
    lat: -23.3013832
  },
  {
    lng: -44.6412197,
    lat: -23.3015231
  },
  {
    lng: -44.6414431,
    lat: -23.3014733
  },
  {
    lng: -44.641592,
    lat: -23.3014951
  },
  {
    lng: -44.6417782,
    lat: -23.3015852
  },
  {
    lng: -44.6419169,
    lat: -23.3016847
  },
  {
    lng: -44.6420929,
    lat: -23.3019862
  },
  {
    lng: -44.6421809,
    lat: -23.3020018
  },
  {
    lng: -44.6422012,
    lat: -23.301924
  },
  {
    lng: -44.6423027,
    lat: -23.3017904
  },
  {
    lng: -44.642384,
    lat: -23.3017655
  },
  {
    lng: -44.6424517,
    lat: -23.3018215
  },
  {
    lng: -44.6425193,
    lat: -23.3019178
  },
  {
    lng: -44.6425633,
    lat: -23.3020173
  },
  {
    lng: -44.6425295,
    lat: -23.3021478
  },
  {
    lng: -44.6424618,
    lat: -23.3023281
  },
  {
    lng: -44.6426107,
    lat: -23.302238
  },
  {
    lng: -44.6426683,
    lat: -23.3021851
  },
  {
    lng: -44.6427529,
    lat: -23.3021292
  },
  {
    lng: -44.6428409,
    lat: -23.3020391
  },
  {
    lng: -44.6429153,
    lat: -23.3019458
  },
  {
    lng: -44.6431522,
    lat: -23.301865
  },
  {
    lng: -44.6435584,
    lat: -23.3017096
  },
  {
    lng: -44.6439443,
    lat: -23.3017096
  },
  {
    lng: -44.6442014,
    lat: -23.301865
  },
  {
    lng: -44.6443571,
    lat: -23.3020018
  },
  {
    lng: -44.6445669,
    lat: -23.3018774
  },
  {
    lng: -44.6449053,
    lat: -23.3017966
  },
  {
    lng: -44.6452167,
    lat: -23.3017344
  },
  {
    lng: -44.6454198,
    lat: -23.3017158
  },
  {
    lng: -44.6456431,
    lat: -23.3018463
  },
  {
    lng: -44.6457785,
    lat: -23.3020453
  },
  {
    lng: -44.645941,
    lat: -23.3019147
  },
  {
    lng: -44.6461914,
    lat: -23.3017717
  },
  {
    lng: -44.6463945,
    lat: -23.301722
  },
  {
    lng: -44.6467194,
    lat: -23.3017034
  },
  {
    lng: -44.6470139,
    lat: -23.3016943
  },
  {
    lng: -44.6470858,
    lat: -23.3018597
  },
  {
    lng: -44.6473234,
    lat: -23.3017591
  },
  {
    lng: -44.647882,
    lat: -23.3018663
  },
  {
    lng: -44.6480351,
    lat: -23.301922
  },
  {
    lng: -44.6486666,
    lat: -23.3017612
  },
  {
    lng: -44.6490279,
    lat: -23.3017216
  },
  {
    lng: -44.6491324,
    lat: -23.3017892
  },
  {
    lng: -44.649266,
    lat: -23.3017972
  },
  {
    lng: -44.649097,
    lat: -23.3016059
  },
  {
    lng: -44.6485894,
    lat: -23.3015733
  },
  {
    lng: -44.6484117,
    lat: -23.3016637
  },
  {
    lng: -44.6481164,
    lat: -23.3016355
  },
  {
    lng: -44.6479816,
    lat: -23.3016148
  },
  {
    lng: -44.6478295,
    lat: -23.3013677
  },
  {
    lng: -44.6477144,
    lat: -23.3012185
  },
  {
    lng: -44.6475519,
    lat: -23.3009574
  },
  {
    lng: -44.6474572,
    lat: -23.3007087
  },
  {
    lng: -44.6473692,
    lat: -23.3001865
  },
  {
    lng: -44.6473218,
    lat: -23.2999689
  },
  {
    lng: -44.6473083,
    lat: -23.2997202
  },
  {
    lng: -44.6473556,
    lat: -23.2994218
  },
  {
    lng: -44.6474233,
    lat: -23.2992229
  },
  {
    lng: -44.6473624,
    lat: -23.2991172
  },
  {
    lng: -44.6472,
    lat: -23.2989245
  },
  {
    lng: -44.6469834,
    lat: -23.298738
  },
  {
    lng: -44.6467397,
    lat: -23.298396
  },
  {
    lng: -44.646672,
    lat: -23.2980541
  },
  {
    lng: -44.6466246,
    lat: -23.2977744
  },
  {
    lng: -44.646693,
    lat: -23.2973907
  },
  {
    lng: -44.6467464,
    lat: -23.2972521
  },
  {
    lng: -44.646848,
    lat: -23.2972211
  },
  {
    lng: -44.6469969,
    lat: -23.2972211
  },
  {
    lng: -44.647139,
    lat: -23.2973019
  },
  {
    lng: -44.6473083,
    lat: -23.2973454
  },
  {
    lng: -44.6475656,
    lat: -23.2974138
  },
  {
    lng: -44.6479784,
    lat: -23.29765
  },
  {
    lng: -44.6486146,
    lat: -23.2981847
  },
  {
    lng: -44.6486688,
    lat: -23.2981847
  },
  {
    lng: -44.6489192,
    lat: -23.2980852
  },
  {
    lng: -44.6491223,
    lat: -23.2981163
  },
  {
    lng: -44.6494269,
    lat: -23.2982966
  },
  {
    lng: -44.6497451,
    lat: -23.2982655
  },
  {
    lng: -44.650009,
    lat: -23.2981474
  },
  {
    lng: -44.6501579,
    lat: -23.2980168
  },
  {
    lng: -44.6502324,
    lat: -23.2978179
  },
  {
    lng: -44.6502865,
    lat: -23.2975132
  },
  {
    lng: -44.650097,
    lat: -23.2972957
  },
  {
    lng: -44.6499413,
    lat: -23.2971713
  },
  {
    lng: -44.6496209,
    lat: -23.2970116
  },
  {
    lng: -44.6495758,
    lat: -23.296848
  },
  {
    lng: -44.6494607,
    lat: -23.2965994
  },
  {
    lng: -44.6493863,
    lat: -23.2964377
  },
  {
    lng: -44.6493863,
    lat: -23.2962761
  },
  {
    lng: -44.6494337,
    lat: -23.2961766
  },
  {
    lng: -44.6494878,
    lat: -23.2960212
  },
  {
    lng: -44.6494607,
    lat: -23.2958782
  },
  {
    lng: -44.6495421,
    lat: -23.2955425
  },
  {
    lng: -44.6497586,
    lat: -23.295213
  },
  {
    lng: -44.6498195,
    lat: -23.2951632
  },
  {
    lng: -44.650009,
    lat: -23.2950886
  },
  {
    lng: -44.6501308,
    lat: -23.2951011
  },
  {
    lng: -44.6502933,
    lat: -23.2951508
  },
  {
    lng: -44.650537,
    lat: -23.2951259
  },
  {
    lng: -44.6506859,
    lat: -23.2950327
  },
  {
    lng: -44.6508551,
    lat: -23.2948959
  },
  {
    lng: -44.6509702,
    lat: -23.2949208
  },
  {
    lng: -44.6511055,
    lat: -23.2949892
  },
  {
    lng: -44.6514304,
    lat: -23.2950203
  },
  {
    lng: -44.6517111,
    lat: -23.2949248
  },
  {
    lng: -44.6519652,
    lat: -23.29484
  },
  {
    lng: -44.6521953,
    lat: -23.2949021
  },
  {
    lng: -44.6523646,
    lat: -23.2947716
  },
  {
    lng: -44.6528519,
    lat: -23.2946099
  },
  {
    lng: -44.6530414,
    lat: -23.2944234
  },
  {
    lng: -44.6533799,
    lat: -23.2941374
  },
  {
    lng: -44.6537387,
    lat: -23.293752
  },
  {
    lng: -44.6539349,
    lat: -23.2935717
  },
  {
    lng: -44.6540093,
    lat: -23.2935966
  },
  {
    lng: -44.6541853,
    lat: -23.2936836
  },
  {
    lng: -44.6544359,
    lat: -23.2938017
  },
  {
    lng: -44.6546185,
    lat: -23.2938328
  },
  {
    lng: -44.6547404,
    lat: -23.2938701
  },
  {
    lng: -44.6549231,
    lat: -23.2940691
  },
  {
    lng: -44.6550247,
    lat: -23.2943613
  },
  {
    lng: -44.6550517,
    lat: -23.2946037
  },
  {
    lng: -44.655133,
    lat: -23.2948586
  },
  {
    lng: -44.655221,
    lat: -23.2949457
  },
  {
    lng: -44.6553293,
    lat: -23.2950576
  },
  {
    lng: -44.6554037,
    lat: -23.2951695
  },
  {
    lng: -44.6553699,
    lat: -23.2953435
  },
  {
    lng: -44.6554579,
    lat: -23.2952814
  },
  {
    lng: -44.655512,
    lat: -23.2951632
  },
  {
    lng: -44.6556135,
    lat: -23.2948524
  },
  {
    lng: -44.6558369,
    lat: -23.2949021
  },
  {
    lng: -44.6560061,
    lat: -23.2949208
  },
  {
    lng: -44.6559453,
    lat: -23.2946037
  },
  {
    lng: -44.6564732,
    lat: -23.2942742
  },
  {
    lng: -44.6569808,
    lat: -23.2936587
  },
  {
    lng: -44.6572042,
    lat: -23.2932298
  },
  {
    lng: -44.6573396,
    lat: -23.2928692
  },
  {
    lng: -44.6574344,
    lat: -23.2926826
  },
  {
    lng: -44.6574073,
    lat: -23.292378
  },
  {
    lng: -44.6572719,
    lat: -23.2921169
  },
  {
    lng: -44.6570418,
    lat: -23.2918993
  },
  {
    lng: -44.6567778,
    lat: -23.2917563
  },
  {
    lng: -44.656392,
    lat: -23.291203
  },
  {
    lng: -44.6562972,
    lat: -23.2909356
  },
  {
    lng: -44.6560197,
    lat: -23.2905315
  },
  {
    lng: -44.6559723,
    lat: -23.2901771
  },
  {
    lng: -44.655837,
    lat: -23.2897917
  },
  {
    lng: -44.6554985,
    lat: -23.2895368
  },
  {
    lng: -44.6551533,
    lat: -23.2894249
  },
  {
    lng: -44.6550585,
    lat: -23.2892632
  },
  {
    lng: -44.6550788,
    lat: -23.2890456
  },
  {
    lng: -44.6548148,
    lat: -23.2889088
  },
  {
    lng: -44.6544696,
    lat: -23.2885109
  },
  {
    lng: -44.6541515,
    lat: -23.2882436
  },
  {
    lng: -44.6536168,
    lat: -23.2880322
  },
  {
    lng: -44.6532986,
    lat: -23.2879887
  },
  {
    lng: -44.6531429,
    lat: -23.2880446
  },
  {
    lng: -44.6528492,
    lat: -23.2878791
  },
  {
    lng: -44.6526082,
    lat: -23.2876654
  },
  {
    lng: -44.6523442,
    lat: -23.2876032
  },
  {
    lng: -44.651999,
    lat: -23.287597
  },
  {
    lng: -44.6516809,
    lat: -23.2876591
  },
  {
    lng: -44.6513628,
    lat: -23.2877524
  },
  {
    lng: -44.6510988,
    lat: -23.287914
  },
  {
    lng: -44.6508822,
    lat: -23.2879389
  },
  {
    lng: -44.650713,
    lat: -23.287914
  },
  {
    lng: -44.6504828,
    lat: -23.28774
  },
  {
    lng: -44.650159,
    lat: -23.2874211
  },
  {
    lng: -44.6499001,
    lat: -23.2873795
  },
  {
    lng: -44.6497725,
    lat: -23.2872666
  },
  {
    lng: -44.6497774,
    lat: -23.2872308
  },
  {
    lng: -44.6498029,
    lat: -23.2871276
  },
  {
    lng: -44.6498537,
    lat: -23.2869203
  },
  {
    lng: -44.649903,
    lat: -23.2865124
  },
  {
    lng: -44.6498771,
    lat: -23.2863758
  },
  {
    lng: -44.6497995,
    lat: -23.2862787
  },
  {
    lng: -44.6497241,
    lat: -23.2863083
  },
  {
    lng: -44.6497217,
    lat: -23.2861863
  },
  {
    lng: -44.6496987,
    lat: -23.2860009
  },
  {
    lng: -44.6496048,
    lat: -23.2859022
  },
  {
    lng: -44.6494836,
    lat: -23.2852375
  },
  {
    lng: -44.649336,
    lat: -23.284799
  },
  {
    lng: -44.6491511,
    lat: -23.2847448
  },
  {
    lng: -44.6491108,
    lat: -23.2846684
  },
  {
    lng: -44.6491323,
    lat: -23.2845502
  },
  {
    lng: -44.649194,
    lat: -23.2844984
  },
  {
    lng: -44.6493308,
    lat: -23.28456
  },
  {
    lng: -44.6493848,
    lat: -23.2842854
  },
  {
    lng: -44.6494354,
    lat: -23.2840254
  },
  {
    lng: -44.64932,
    lat: -23.2837371
  },
  {
    lng: -44.649114,
    lat: -23.2835721
  },
  {
    lng: -44.648956,
    lat: -23.2836113
  },
  {
    lng: -44.6488468,
    lat: -23.2835161
  },
  {
    lng: -44.6482953,
    lat: -23.2830886
  },
  {
    lng: -44.6480062,
    lat: -23.2827174
  },
  {
    lng: -44.6478872,
    lat: -23.2823609
  },
  {
    lng: -44.6474498,
    lat: -23.2818726
  },
  {
    lng: -44.6471743,
    lat: -23.28174
  },
  {
    lng: -44.6470359,
    lat: -23.281565
  },
  {
    lng: -44.6463888,
    lat: -23.2813313
  },
  {
    lng: -44.6463731,
    lat: -23.2808711
  },
  {
    lng: -44.6462759,
    lat: -23.2806595
  },
  {
    lng: -44.6458112,
    lat: -23.2802221
  },
  {
    lng: -44.6455193,
    lat: -23.2799012
  },
  {
    lng: -44.6455168,
    lat: -23.2797242
  },
  {
    lng: -44.6452697,
    lat: -23.2795932
  },
  {
    lng: -44.6452117,
    lat: -23.2792774
  },
  {
    lng: -44.6446976,
    lat: -23.2787957
  },
  {
    lng: -44.6441182,
    lat: -23.2783063
  },
  {
    lng: -44.6436499,
    lat: -23.2780419
  },
  {
    lng: -44.6435881,
    lat: -23.2780863
  },
  {
    lng: -44.643462,
    lat: -23.2781689
  },
  {
    lng: -44.6433976,
    lat: -23.2781258
  },
  {
    lng: -44.643399,
    lat: -23.2780543
  },
  {
    lng: -44.6434782,
    lat: -23.278005
  },
  {
    lng: -44.6435385,
    lat: -23.2779989
  },
  {
    lng: -44.6435009,
    lat: -23.2779225
  },
  {
    lng: -44.6434607,
    lat: -23.2777876
  },
  {
    lng: -44.6433011,
    lat: -23.277612
  },
  {
    lng: -44.6429256,
    lat: -23.2775209
  },
  {
    lng: -44.6426386,
    lat: -23.2775331
  },
  {
    lng: -44.6422766,
    lat: -23.2776218
  },
  {
    lng: -44.6421425,
    lat: -23.2775947
  },
  {
    lng: -44.6420485,
    lat: -23.2774839
  },
  {
    lng: -44.6418285,
    lat: -23.2770848
  },
  {
    lng: -44.6417187,
    lat: -23.2769172
  },
  {
    lng: -44.6415898,
    lat: -23.2768433
  },
  {
    lng: -44.6413834,
    lat: -23.2764984
  },
  {
    lng: -44.6411822,
    lat: -23.2762667
  },
  {
    lng: -44.6410618,
    lat: -23.27588
  },
  {
    lng: -44.6411034,
    lat: -23.2757839
  },
  {
    lng: -44.6410374,
    lat: -23.2753255
  },
  {
    lng: -44.6411527,
    lat: -23.2749781
  },
  {
    lng: -44.6412627,
    lat: -23.2749018
  },
  {
    lng: -44.6413137,
    lat: -23.2748154
  },
  {
    lng: -44.641193,
    lat: -23.2746677
  },
  {
    lng: -44.6411044,
    lat: -23.2744336
  },
  {
    lng: -44.6408416,
    lat: -23.2743104
  },
  {
    lng: -44.6406029,
    lat: -23.2738201
  },
  {
    lng: -44.6404473,
    lat: -23.2736451
  },
  {
    lng: -44.6402803,
    lat: -23.2732901
  },
  {
    lng: -44.640273,
    lat: -23.2731252
  },
  {
    lng: -44.6403962,
    lat: -23.2729256
  },
  {
    lng: -44.6404849,
    lat: -23.2728221
  },
  {
    lng: -44.6406243,
    lat: -23.2725462
  },
  {
    lng: -44.6407638,
    lat: -23.2721323
  },
  {
    lng: -44.6408791,
    lat: -23.2717602
  },
  {
    lng: -44.6408604,
    lat: -23.2716198
  },
  {
    lng: -44.6408067,
    lat: -23.2714744
  },
  {
    lng: -44.6408389,
    lat: -23.2713783
  },
  {
    lng: -44.6406002,
    lat: -23.2711935
  },
  {
    lng: -44.6405948,
    lat: -23.2709642
  },
  {
    lng: -44.6405166,
    lat: -23.2708256
  },
  {
    lng: -44.639938,
    lat: -23.2706324
  },
  {
    lng: -44.6394404,
    lat: -23.270429
  },
  {
    lng: -44.6391084,
    lat: -23.2702547
  },
  {
    lng: -44.6387623,
    lat: -23.2700714
  },
  {
    lng: -44.6386088,
    lat: -23.2699274
  },
  {
    lng: -44.6384441,
    lat: -23.2698497
  },
  {
    lng: -44.6381425,
    lat: -23.2697075
  },
  {
    lng: -44.6380691,
    lat: -23.2696205
  },
  {
    lng: -44.6378482,
    lat: -23.2693677
  },
  {
    lng: -44.637695,
    lat: -23.2692204
  },
  {
    lng: -44.6372742,
    lat: -23.2689956
  },
  {
    lng: -44.637108,
    lat: -23.2687763
  },
  {
    lng: -44.63707,
    lat: -23.2686198
  },
  {
    lng: -44.6368284,
    lat: -23.2683781
  },
  {
    lng: -44.6363669,
    lat: -23.2681057
  },
  {
    lng: -44.6359546,
    lat: -23.2671303
  },
  {
    lng: -44.635968,
    lat: -23.2663566
  },
  {
    lng: -44.6360217,
    lat: -23.26595
  },
  {
    lng: -44.6359082,
    lat: -23.2649598
  },
  {
    lng: -44.6359174,
    lat: -23.2646964
  },
  {
    lng: -44.6358044,
    lat: -23.2644888
  },
  {
    lng: -44.6359519,
    lat: -23.2640132
  },
  {
    lng: -44.6358324,
    lat: -23.2636491
  },
  {
    lng: -44.6356854,
    lat: -23.2633313
  },
  {
    lng: -44.6355478,
    lat: -23.26315
  },
  {
    lng: -44.6352515,
    lat: -23.26283
  },
  {
    lng: -44.6347752,
    lat: -23.2622709
  },
  {
    lng: -44.6345837,
    lat: -23.2622936
  },
  {
    lng: -44.6342822,
    lat: -23.2623208
  },
  {
    lng: -44.6341572,
    lat: -23.2622879
  },
  {
    lng: -44.6338971,
    lat: -23.2621382
  },
  {
    lng: -44.6338049,
    lat: -23.2618174
  },
  {
    lng: -44.6338625,
    lat: -23.2616353
  },
  {
    lng: -44.6342541,
    lat: -23.2611326
  },
  {
    lng: -44.6343453,
    lat: -23.2609182
  },
  {
    lng: -44.6343041,
    lat: -23.2607103
  },
  {
    lng: -44.6344613,
    lat: -23.2601185
  },
  {
    lng: -44.6344311,
    lat: -23.2599079
  },
  {
    lng: -44.6344486,
    lat: -23.2589029
  },
  {
    lng: -44.6344046,
    lat: -23.2587295
  },
  {
    lng: -44.6342477,
    lat: -23.2586426
  },
  {
    lng: -44.633534,
    lat: -23.2584036
  },
  {
    lng: -44.6331266,
    lat: -23.2581226
  },
  {
    lng: -44.633023,
    lat: -23.2573165
  },
  {
    lng: -44.6327922,
    lat: -23.2566821
  },
  {
    lng: -44.6326767,
    lat: -23.2565099
  },
  {
    lng: -44.6323014,
    lat: -23.2561549
  },
  {
    lng: -44.6320144,
    lat: -23.2560365
  },
  {
    lng: -44.6312566,
    lat: -23.255265
  },
  {
    lng: -44.631064,
    lat: -23.2551983
  },
  {
    lng: -44.6307287,
    lat: -23.2545601
  },
  {
    lng: -44.6303972,
    lat: -23.2543041
  },
  {
    lng: -44.6300767,
    lat: -23.2537987
  },
  {
    lng: -44.6296058,
    lat: -23.2532685
  },
  {
    lng: -44.6287991,
    lat: -23.2524309
  },
  {
    lng: -44.6285124,
    lat: -23.2520365
  },
  {
    lng: -44.6283752,
    lat: -23.2514873
  },
  {
    lng: -44.6281492,
    lat: -23.2511485
  },
  {
    lng: -44.6274441,
    lat: -23.250875
  },
  {
    lng: -44.6272186,
    lat: -23.2504655
  },
  {
    lng: -44.6268257,
    lat: -23.2501511
  },
  {
    lng: -44.6261481,
    lat: -23.2497173
  },
  {
    lng: -44.6260854,
    lat: -23.2494977
  },
  {
    lng: -44.6262235,
    lat: -23.2492669
  },
  {
    lng: -44.6261914,
    lat: -23.2489738
  },
  {
    lng: -44.6260438,
    lat: -23.2488703
  },
  {
    lng: -44.6260009,
    lat: -23.2487027
  },
  {
    lng: -44.6260707,
    lat: -23.2485622
  },
  {
    lng: -44.6260669,
    lat: -23.2484387
  },
  {
    lng: -44.6259929,
    lat: -23.2482887
  },
  {
    lng: -44.6258936,
    lat: -23.2481901
  },
  {
    lng: -44.6259446,
    lat: -23.2478376
  },
  {
    lng: -44.6262546,
    lat: -23.2476212
  },
  {
    lng: -44.6265647,
    lat: -23.2474281
  },
  {
    lng: -44.6267497,
    lat: -23.2472634
  },
  {
    lng: -44.6267843,
    lat: -23.2466732
  },
  {
    lng: -44.6267278,
    lat: -23.2465685
  },
  {
    lng: -44.6265959,
    lat: -23.2464393
  },
  {
    lng: -44.6252953,
    lat: -23.2456202
  },
  {
    lng: -44.625137,
    lat: -23.2454233
  },
  {
    lng: -44.6251881,
    lat: -23.2453142
  },
  {
    lng: -44.625312,
    lat: -23.245104
  },
  {
    lng: -44.6256222,
    lat: -23.2449716
  },
  {
    lng: -44.6256393,
    lat: -23.2448937
  },
  {
    lng: -44.6255766,
    lat: -23.244675
  },
  {
    lng: -44.6255128,
    lat: -23.2445437
  },
  {
    lng: -44.6253915,
    lat: -23.2445842
  },
  {
    lng: -44.6252339,
    lat: -23.244639
  },
  {
    lng: -44.6251571,
    lat: -23.2445444
  },
  {
    lng: -44.6251891,
    lat: -23.2443363
  },
  {
    lng: -44.625077,
    lat: -23.2440652
  },
  {
    lng: -44.6249497,
    lat: -23.2439837
  },
  {
    lng: -44.6247079,
    lat: -23.2440589
  },
  {
    lng: -44.6245747,
    lat: -23.2439697
  },
  {
    lng: -44.6241207,
    lat: -23.2437131
  },
  {
    lng: -44.6235652,
    lat: -23.2435029
  },
  {
    lng: -44.6232905,
    lat: -23.2435335
  },
  {
    lng: -44.6229951,
    lat: -23.2435718
  },
  {
    lng: -44.6227095,
    lat: -23.2435201
  },
  {
    lng: -44.6223164,
    lat: -23.2433289
  },
  {
    lng: -44.6218745,
    lat: -23.2430312
  },
  {
    lng: -44.6213953,
    lat: -23.2422775
  },
  {
    lng: -44.6212143,
    lat: -23.242051
  },
  {
    lng: -44.6211017,
    lat: -23.2420546
  },
  {
    lng: -44.6209649,
    lat: -23.2421341
  },
  {
    lng: -44.6205741,
    lat: -23.2423277
  },
  {
    lng: -44.6200799,
    lat: -23.2424737
  },
  {
    lng: -44.6197275,
    lat: -23.2424021
  },
  {
    lng: -44.6195797,
    lat: -23.2421538
  },
  {
    lng: -44.6195557,
    lat: -23.2420462
  },
  {
    lng: -44.6193352,
    lat: -23.2419615
  },
  {
    lng: -44.6192092,
    lat: -23.2417818
  },
  {
    lng: -44.6188874,
    lat: -23.2403585
  },
  {
    lng: -44.6189417,
    lat: -23.2402064
  },
  {
    lng: -44.6190749,
    lat: -23.2400262
  },
  {
    lng: -44.6191036,
    lat: -23.2397942
  },
  {
    lng: -44.6189858,
    lat: -23.2395366
  },
  {
    lng: -44.6188004,
    lat: -23.2393313
  },
  {
    lng: -44.6185294,
    lat: -23.2389287
  },
  {
    lng: -44.6183447,
    lat: -23.2388505
  },
  {
    lng: -44.618146,
    lat: -23.2388461
  },
  {
    lng: -44.6181445,
    lat: -23.238888
  },
  {
    lng: -44.6180716,
    lat: -23.238926
  },
  {
    lng: -44.6180091,
    lat: -23.2389173
  },
  {
    lng: -44.6179412,
    lat: -23.2388753
  },
  {
    lng: -44.6178743,
    lat: -23.2388251
  },
  {
    lng: -44.6179408,
    lat: -23.2387708
  },
  {
    lng: -44.6178429,
    lat: -23.2385921
  },
  {
    lng: -44.617638,
    lat: -23.238518
  },
  {
    lng: -44.6174954,
    lat: -23.2384901
  },
  {
    lng: -44.6173883,
    lat: -23.2385273
  },
  {
    lng: -44.6173034,
    lat: -23.2385208
  },
  {
    lng: -44.617264,
    lat: -23.238478
  },
  {
    lng: -44.6172681,
    lat: -23.2384331
  },
  {
    lng: -44.6164516,
    lat: -23.2380577
  },
  {
    lng: -44.6162817,
    lat: -23.2378768
  },
  {
    lng: -44.6159272,
    lat: -23.2377214
  },
  {
    lng: -44.6157855,
    lat: -23.2374441
  },
  {
    lng: -44.61568,
    lat: -23.2371522
  },
  {
    lng: -44.6155835,
    lat: -23.2369787
  },
  {
    lng: -44.6146964,
    lat: -23.2360681
  },
  {
    lng: -44.6142351,
    lat: -23.2356961
  },
  {
    lng: -44.6140249,
    lat: -23.2355396
  },
  {
    lng: -44.6138725,
    lat: -23.2353108
  },
  {
    lng: -44.6137293,
    lat: -23.2352631
  },
  {
    lng: -44.6133334,
    lat: -23.2351757
  },
  {
    lng: -44.613208,
    lat: -23.2349984
  },
  {
    lng: -44.6131296,
    lat: -23.2349642
  },
  {
    lng: -44.6129044,
    lat: -23.2345759
  },
  {
    lng: -44.612954,
    lat: -23.2344483
  },
  {
    lng: -44.6131079,
    lat: -23.2342867
  },
  {
    lng: -44.6130947,
    lat: -23.2341286
  },
  {
    lng: -44.6134837,
    lat: -23.2335473
  },
  {
    lng: -44.613873,
    lat: -23.2334022
  },
  {
    lng: -44.6142605,
    lat: -23.2333756
  },
  {
    lng: -44.6147972,
    lat: -23.2333523
  },
  {
    lng: -44.6153739,
    lat: -23.233327
  },
  {
    lng: -44.6158182,
    lat: -23.2332593
  },
  {
    lng: -44.6162079,
    lat: -23.2332518
  },
  {
    lng: -44.6169878,
    lat: -23.2334929
  },
  {
    lng: -44.6173871,
    lat: -23.2337989
  },
  {
    lng: -44.6177992,
    lat: -23.2340638
  },
  {
    lng: -44.6183267,
    lat: -23.2342901
  },
  {
    lng: -44.6188226,
    lat: -23.2342853
  },
  {
    lng: -44.6190884,
    lat: -23.2343126
  },
  {
    lng: -44.6195243,
    lat: -23.2344508
  },
  {
    lng: -44.6204646,
    lat: -23.2348719
  },
  {
    lng: -44.6207891,
    lat: -23.2351277
  },
  {
    lng: -44.6214091,
    lat: -23.2360942
  },
  {
    lng: -44.6218139,
    lat: -23.2365341
  },
  {
    lng: -44.6222034,
    lat: -23.2367275
  },
  {
    lng: -44.6225384,
    lat: -23.2371162
  },
  {
    lng: -44.6230202,
    lat: -23.2374113
  },
  {
    lng: -44.6235397,
    lat: -23.237554
  },
  {
    lng: -44.623912,
    lat: -23.2373658
  },
  {
    lng: -44.6243657,
    lat: -23.237167
  },
  {
    lng: -44.6246328,
    lat: -23.2371554
  },
  {
    lng: -44.6248498,
    lat: -23.2369689
  },
  {
    lng: -44.6250438,
    lat: -23.2366984
  },
  {
    lng: -44.6251848,
    lat: -23.2366448
  },
  {
    lng: -44.6253646,
    lat: -23.2367065
  },
  {
    lng: -44.6258105,
    lat: -23.2367511
  },
  {
    lng: -44.6262084,
    lat: -23.236711
  },
  {
    lng: -44.6266651,
    lat: -23.2366021
  },
  {
    lng: -44.6268362,
    lat: -23.2366156
  },
  {
    lng: -44.6272331,
    lat: -23.2367454
  },
  {
    lng: -44.6274718,
    lat: -23.2369018
  },
  {
    lng: -44.6278048,
    lat: -23.2371408
  },
  {
    lng: -44.6278117,
    lat: -23.2373115
  },
  {
    lng: -44.6284447,
    lat: -23.2377998
  },
  {
    lng: -44.6286072,
    lat: -23.2379129
  },
  {
    lng: -44.6287461,
    lat: -23.2382053
  },
  {
    lng: -44.6291359,
    lat: -23.2384251
  },
  {
    lng: -44.6294939,
    lat: -23.2386044
  },
  {
    lng: -44.6296067,
    lat: -23.2386808
  },
  {
    lng: -44.6297245,
    lat: -23.2387398
  },
  {
    lng: -44.6296315,
    lat: -23.2388607
  },
  {
    lng: -44.6299764,
    lat: -23.2390277
  },
  {
    lng: -44.6303161,
    lat: -23.2392645
  },
  {
    lng: -44.6303404,
    lat: -23.2394431
  },
  {
    lng: -44.6303227,
    lat: -23.2396717
  },
  {
    lng: -44.6303973,
    lat: -23.2399691
  },
  {
    lng: -44.6306753,
    lat: -23.2403227
  },
  {
    lng: -44.6310658,
    lat: -23.2404379
  },
  {
    lng: -44.6314995,
    lat: -23.2405035
  },
  {
    lng: -44.6319959,
    lat: -23.2404407
  },
  {
    lng: -44.632181,
    lat: -23.2403469
  },
  {
    lng: -44.6323454,
    lat: -23.2401731
  },
  {
    lng: -44.6324012,
    lat: -23.240137
  },
  {
    lng: -44.6325573,
    lat: -23.2401198
  },
  {
    lng: -44.6328492,
    lat: -23.2402678
  },
  {
    lng: -44.6330369,
    lat: -23.2402067
  },
  {
    lng: -44.6333377,
    lat: -23.2400122
  },
  {
    lng: -44.633495,
    lat: -23.2398881
  },
  {
    lng: -44.633585,
    lat: -23.2398248
  },
  {
    lng: -44.633767,
    lat: -23.2397085
  },
  {
    lng: -44.6339003,
    lat: -23.2395809
  },
  {
    lng: -44.6338867,
    lat: -23.2394823
  },
  {
    lng: -44.6339223,
    lat: -23.2393023
  },
  {
    lng: -44.6339934,
    lat: -23.2393222
  },
  {
    lng: -44.6341845,
    lat: -23.2396039
  },
  {
    lng: -44.6346014,
    lat: -23.2395454
  },
  {
    lng: -44.6348094,
    lat: -23.2392453
  },
  {
    lng: -44.6348694,
    lat: -23.2391508
  },
  {
    lng: -44.6349471,
    lat: -23.2390981
  },
  {
    lng: -44.6350756,
    lat: -23.2389939
  },
  {
    lng: -44.6352004,
    lat: -23.2388819
  },
  {
    lng: -44.6355598,
    lat: -23.2386265
  },
  {
    lng: -44.635953,
    lat: -23.2383097
  },
  {
    lng: -44.6367075,
    lat: -23.2378665
  },
  {
    lng: -44.6373324,
    lat: -23.2373107
  },
  {
    lng: -44.6379231,
    lat: -23.2363354
  },
  {
    lng: -44.6383649,
    lat: -23.2354984
  },
  {
    lng: -44.6381718,
    lat: -23.2353844
  },
  {
    lng: -44.6377479,
    lat: -23.235039
  },
  {
    lng: -44.6376072,
    lat: -23.2346313
  },
  {
    lng: -44.6376935,
    lat: -23.2344519
  },
  {
    lng: -44.6378055,
    lat: -23.2344265
  },
  {
    lng: -44.6378322,
    lat: -23.2341693
  },
  {
    lng: -44.6382446,
    lat: -23.2339658
  },
  {
    lng: -44.6387862,
    lat: -23.2341043
  },
  {
    lng: -44.6389695,
    lat: -23.2343037
  },
  {
    lng: -44.6395059,
    lat: -23.2344293
  },
  {
    lng: -44.6398057,
    lat: -23.2346889
  },
  {
    lng: -44.6400549,
    lat: -23.2347972
  },
  {
    lng: -44.6402485,
    lat: -23.2347856
  },
  {
    lng: -44.6404798,
    lat: -23.2344838
  },
  {
    lng: -44.6406123,
    lat: -23.2341802
  },
  {
    lng: -44.641136,
    lat: -23.2342641
  },
  {
    lng: -44.6412917,
    lat: -23.2344794
  },
  {
    lng: -44.6416707,
    lat: -23.2346164
  },
  {
    lng: -44.6422542,
    lat: -23.2345665
  },
  {
    lng: -44.6428242,
    lat: -23.2345073
  },
  {
    lng: -44.6431833,
    lat: -23.2346669
  },
  {
    lng: -44.6434667,
    lat: -23.2349043
  },
  {
    lng: -44.6438506,
    lat: -23.2352982
  },
  {
    lng: -44.6441972,
    lat: -23.2357061
  },
  {
    lng: -44.6442398,
    lat: -23.2360438
  },
  {
    lng: -44.6445759,
    lat: -23.2361869
  },
  {
    lng: -44.6449474,
    lat: -23.2360993
  },
  {
    lng: -44.6449487,
    lat: -23.2362091
  },
  {
    lng: -44.6450685,
    lat: -23.2362102
  },
  {
    lng: -44.645447,
    lat: -23.2362543
  },
  {
    lng: -44.6456483,
    lat: -23.236005
  },
  {
    lng: -44.6460876,
    lat: -23.2359533
  },
  {
    lng: -44.64678,
    lat: -23.2357624
  },
  {
    lng: -44.647024,
    lat: -23.2358402
  },
  {
    lng: -44.6471099,
    lat: -23.2357597
  },
  {
    lng: -44.6471595,
    lat: -23.2354132
  },
  {
    lng: -44.6470146,
    lat: -23.2351766
  },
  {
    lng: -44.6471434,
    lat: -23.2349696
  },
  {
    lng: -44.647358,
    lat: -23.2348698
  },
  {
    lng: -44.6480768,
    lat: -23.2350731
  },
  {
    lng: -44.6487044,
    lat: -23.2352949
  },
  {
    lng: -44.6490746,
    lat: -23.2352505
  },
  {
    lng: -44.6499288,
    lat: -23.2351747
  },
  {
    lng: -44.6506034,
    lat: -23.2351174
  },
  {
    lng: -44.6513531,
    lat: -23.234964
  },
  {
    lng: -44.6516897,
    lat: -23.2349492
  },
  {
    lng: -44.6519686,
    lat: -23.2350219
  },
  {
    lng: -44.6522544,
    lat: -23.2352289
  },
  {
    lng: -44.6526673,
    lat: -23.2357219
  },
  {
    lng: -44.6528927,
    lat: -23.235887
  },
  {
    lng: -44.6529141,
    lat: -23.2359585
  },
  {
    lng: -44.653178,
    lat: -23.2363899
  },
  {
    lng: -44.6534197,
    lat: -23.2370916
  },
  {
    lng: -44.6534908,
    lat: -23.2377795
  },
  {
    lng: -44.6538931,
    lat: -23.2387115
  },
  {
    lng: -44.6543465,
    lat: -23.2390775
  },
  {
    lng: -44.6544383,
    lat: -23.2390898
  },
  {
    lng: -44.6544862,
    lat: -23.239136
  },
  {
    lng: -44.6545114,
    lat: -23.2391637
  },
  {
    lng: -44.6551001,
    lat: -23.2393227
  },
  {
    lng: -44.6559316,
    lat: -23.2397047
  },
  {
    lng: -44.6561864,
    lat: -23.2396924
  },
  {
    lng: -44.6567515,
    lat: -23.239797
  },
  {
    lng: -44.6570045,
    lat: -23.2397515
  },
  {
    lng: -44.657211,
    lat: -23.2396874
  },
  {
    lng: -44.6576804,
    lat: -23.2393991
  },
  {
    lng: -44.6577622,
    lat: -23.2392457
  },
  {
    lng: -44.6575222,
    lat: -23.23899
  },
  {
    lng: -44.657557,
    lat: -23.2386819
  },
  {
    lng: -44.6577422,
    lat: -23.2386868
  },
  {
    lng: -44.6579379,
    lat: -23.2389086
  },
  {
    lng: -44.6581351,
    lat: -23.2391545
  },
  {
    lng: -44.6584347,
    lat: -23.2394692
  },
  {
    lng: -44.6587552,
    lat: -23.2395848
  },
  {
    lng: -44.6590431,
    lat: -23.2400448
  },
  {
    lng: -44.6591503,
    lat: -23.2406314
  },
  {
    lng: -44.6594775,
    lat: -23.2412229
  },
  {
    lng: -44.6600193,
    lat: -23.2416073
  },
  {
    lng: -44.6602876,
    lat: -23.2418587
  },
  {
    lng: -44.6604538,
    lat: -23.2423418
  },
  {
    lng: -44.6606792,
    lat: -23.2429678
  },
  {
    lng: -44.6608722,
    lat: -23.2433079
  },
  {
    lng: -44.6607274,
    lat: -23.2436578
  },
  {
    lng: -44.6605806,
    lat: -23.2440903
  },
  {
    lng: -44.6605235,
    lat: -23.2443528
  },
  {
    lng: -44.6606147,
    lat: -23.2445993
  },
  {
    lng: -44.6606846,
    lat: -23.2448901
  },
  {
    lng: -44.6612853,
    lat: -23.2452302
  },
  {
    lng: -44.6619558,
    lat: -23.2453583
  },
  {
    lng: -44.6622509,
    lat: -23.2453731
  },
  {
    lng: -44.6625651,
    lat: -23.2456111
  },
  {
    lng: -44.6625442,
    lat: -23.245964
  },
  {
    lng: -44.662683,
    lat: -23.2465023
  },
  {
    lng: -44.6628701,
    lat: -23.2470636
  },
  {
    lng: -44.6631244,
    lat: -23.2473784
  },
  {
    lng: -44.6633654,
    lat: -23.2477414
  },
  {
    lng: -44.6636582,
    lat: -23.2479185
  },
  {
    lng: -44.6640967,
    lat: -23.2480001
  },
  {
    lng: -44.6646287,
    lat: -23.2479381
  },
  {
    lng: -44.6646521,
    lat: -23.2477785
  },
  {
    lng: -44.6647233,
    lat: -23.2477078
  },
  {
    lng: -44.6650069,
    lat: -23.2476941
  },
  {
    lng: -44.6659242,
    lat: -23.2477163
  },
  {
    lng: -44.6668013,
    lat: -23.2479067
  },
  {
    lng: -44.6674057,
    lat: -23.2479737
  },
  {
    lng: -44.6678636,
    lat: -23.2479414
  },
  {
    lng: -44.6694475,
    lat: -23.249188
  },
  {
    lng: -44.6695119,
    lat: -23.249278
  },
  {
    lng: -44.6694964,
    lat: -23.2493853
  },
  {
    lng: -44.6693526,
    lat: -23.2494834
  },
  {
    lng: -44.6691837,
    lat: -23.2496498
  },
  {
    lng: -44.6691325,
    lat: -23.2497211
  },
  {
    lng: -44.6692171,
    lat: -23.249856
  },
  {
    lng: -44.6692499,
    lat: -23.2500379
  },
  {
    lng: -44.6690648,
    lat: -23.250486
  },
  {
    lng: -44.6695153,
    lat: -23.2502774
  },
  {
    lng: -44.6695217,
    lat: -23.2501538
  },
  {
    lng: -44.6695549,
    lat: -23.2500411
  },
  {
    lng: -44.6695615,
    lat: -23.2498134
  },
  {
    lng: -44.6696546,
    lat: -23.2497349
  },
  {
    lng: -44.6696787,
    lat: -23.2496201
  },
  {
    lng: -44.669816,
    lat: -23.249554
  },
  {
    lng: -44.6699262,
    lat: -23.2495119
  },
  {
    lng: -44.6700015,
    lat: -23.2492588
  },
  {
    lng: -44.6701437,
    lat: -23.24908
  },
  {
    lng: -44.6702775,
    lat: -23.2490101
  },
  {
    lng: -44.6703629,
    lat: -23.2486098
  },
  {
    lng: -44.6703758,
    lat: -23.2484239
  },
  {
    lng: -44.6702453,
    lat: -23.2481155
  },
  {
    lng: -44.670016,
    lat: -23.2479238
  },
  {
    lng: -44.6697223,
    lat: -23.2477458
  },
  {
    lng: -44.6697142,
    lat: -23.2474723
  },
  {
    lng: -44.669497,
    lat: -23.247317
  },
  {
    lng: -44.6693208,
    lat: -23.2470211
  },
  {
    lng: -44.6689766,
    lat: -23.2469178
  },
  {
    lng: -44.6687426,
    lat: -23.2465668
  },
  {
    lng: -44.6684152,
    lat: -23.2462695
  },
  {
    lng: -44.6680387,
    lat: -23.2459404
  },
  {
    lng: -44.6678514,
    lat: -23.2456862
  },
  {
    lng: -44.6676716,
    lat: -23.245595
  },
  {
    lng: -44.6676126,
    lat: -23.2454988
  },
  {
    lng: -44.6676877,
    lat: -23.2453831
  },
  {
    lng: -44.667685,
    lat: -23.245277
  },
  {
    lng: -44.6678326,
    lat: -23.2451661
  },
  {
    lng: -44.6682082,
    lat: -23.2450798
  },
  {
    lng: -44.6685138,
    lat: -23.2446362
  },
  {
    lng: -44.6687888,
    lat: -23.2444779
  },
  {
    lng: -44.6694586,
    lat: -23.2442662
  },
  {
    lng: -44.6700186,
    lat: -23.2445056
  },
  {
    lng: -44.670657,
    lat: -23.2448999
  },
  {
    lng: -44.6708018,
    lat: -23.2452302
  },
  {
    lng: -44.6711612,
    lat: -23.2456393
  },
  {
    lng: -44.6709895,
    lat: -23.2464131
  },
  {
    lng: -44.670999,
    lat: -23.2468579
  },
  {
    lng: -44.6712189,
    lat: -23.247073
  },
  {
    lng: -44.6716613,
    lat: -23.2474138
  },
  {
    lng: -44.6719673,
    lat: -23.2473466
  },
  {
    lng: -44.6722308,
    lat: -23.2471331
  },
  {
    lng: -44.6726436,
    lat: -23.2467873
  },
  {
    lng: -44.6729618,
    lat: -23.2466249
  },
  {
    lng: -44.6729409,
    lat: -23.2465259
  },
  {
    lng: -44.6731622,
    lat: -23.246354
  },
  {
    lng: -44.673602,
    lat: -23.2464082
  },
  {
    lng: -44.6738962,
    lat: -23.2466662
  },
  {
    lng: -44.6739526,
    lat: -23.2467782
  },
  {
    lng: -44.6738876,
    lat: -23.2470724
  },
  {
    lng: -44.6739969,
    lat: -23.2473362
  },
  {
    lng: -44.6737858,
    lat: -23.2476497
  },
  {
    lng: -44.6736985,
    lat: -23.2482664
  },
  {
    lng: -44.6734947,
    lat: -23.248572
  },
  {
    lng: -44.6735444,
    lat: -23.2488548
  },
  {
    lng: -44.6735913,
    lat: -23.249193
  },
  {
    lng: -44.673844,
    lat: -23.2496945
  },
  {
    lng: -44.6742548,
    lat: -23.2500613
  },
  {
    lng: -44.6745228,
    lat: -23.250372
  },
  {
    lng: -44.6747392,
    lat: -23.2504344
  },
  {
    lng: -44.6750026,
    lat: -23.2506826
  },
  {
    lng: -44.6753886,
    lat: -23.2507585
  },
  {
    lng: -44.6756978,
    lat: -23.2507731
  },
  {
    lng: -44.6760973,
    lat: -23.2507386
  },
  {
    lng: -44.676254,
    lat: -23.25081
  },
  {
    lng: -44.6766678,
    lat: -23.2510251
  },
  {
    lng: -44.6769989,
    lat: -23.2510461
  },
  {
    lng: -44.6773735,
    lat: -23.2508855
  },
  {
    lng: -44.677571,
    lat: -23.2507082
  },
  {
    lng: -44.6774788,
    lat: -23.2504919
  },
  {
    lng: -44.6776114,
    lat: -23.2503261
  },
  {
    lng: -44.6778243,
    lat: -23.250317
  },
  {
    lng: -44.6780692,
    lat: -23.2503186
  },
  {
    lng: -44.6783592,
    lat: -23.2504636
  },
  {
    lng: -44.6786118,
    lat: -23.2505073
  },
  {
    lng: -44.6788608,
    lat: -23.2504939
  },
  {
    lng: -44.6792198,
    lat: -23.2508122
  },
  {
    lng: -44.6791136,
    lat: -23.2511463
  },
  {
    lng: -44.6789316,
    lat: -23.2512853
  },
  {
    lng: -44.6788845,
    lat: -23.2515265
  },
  {
    lng: -44.6790089,
    lat: -23.2519084
  },
  {
    lng: -44.67918,
    lat: -23.2520747
  },
  {
    lng: -44.6796511,
    lat: -23.2522697
  },
  {
    lng: -44.6800262,
    lat: -23.2525677
  },
  {
    lng: -44.680742,
    lat: -23.2526925
  },
  {
    lng: -44.6820778,
    lat: -23.2528403
  },
  {
    lng: -44.6830312,
    lat: -23.2526735
  },
  {
    lng: -44.6836778,
    lat: -23.2522741
  },
  {
    lng: -44.6840358,
    lat: -23.252195
  },
  {
    lng: -44.684389,
    lat: -23.2521416
  },
  {
    lng: -44.6850244,
    lat: -23.2519223
  },
  {
    lng: -44.6861404,
    lat: -23.2520472
  },
  {
    lng: -44.6867913,
    lat: -23.2522013
  },
  {
    lng: -44.6875803,
    lat: -23.2524858
  },
  {
    lng: -44.687966,
    lat: -23.2525683
  },
  {
    lng: -44.6882545,
    lat: -23.25256
  },
  {
    lng: -44.6884463,
    lat: -23.2526267
  },
  {
    lng: -44.6886289,
    lat: -23.2527477
  },
  {
    lng: -44.6888335,
    lat: -23.253073
  },
  {
    lng: -44.6890416,
    lat: -23.253072
  },
  {
    lng: -44.6891848,
    lat: -23.253242
  },
  {
    lng: -44.6893289,
    lat: -23.2533519
  },
  {
    lng: -44.6898508,
    lat: -23.2534909
  },
  {
    lng: -44.6904141,
    lat: -23.2536367
  },
  {
    lng: -44.6906662,
    lat: -23.2536339
  },
  {
    lng: -44.6909544,
    lat: -23.2534712
  },
  {
    lng: -44.6912426,
    lat: -23.2532838
  },
  {
    lng: -44.6913905,
    lat: -23.253067
  },
  {
    lng: -44.6916302,
    lat: -23.252658
  },
  {
    lng: -44.6918356,
    lat: -23.252499
  },
  {
    lng: -44.6920079,
    lat: -23.2524456
  },
  {
    lng: -44.6922336,
    lat: -23.2524351
  },
  {
    lng: -44.6926055,
    lat: -23.2523052
  },
  {
    lng: -44.6922739,
    lat: -23.2522128
  },
  {
    lng: -44.6922096,
    lat: -23.2521496
  },
  {
    lng: -44.6920261,
    lat: -23.2519841
  },
  {
    lng: -44.6916734,
    lat: -23.2520184
  },
  {
    lng: -44.6915059,
    lat: -23.2520342
  },
  {
    lng: -44.6911636,
    lat: -23.2519805
  },
  {
    lng: -44.6909455,
    lat: -23.25199
  },
  {
    lng: -44.6906799,
    lat: -23.2521213
  },
  {
    lng: -44.6900741,
    lat: -23.252406
  },
  {
    lng: -44.6898277,
    lat: -23.2524544
  },
  {
    lng: -44.6895567,
    lat: -23.2523942
  },
  {
    lng: -44.6891015,
    lat: -23.2522228
  },
  {
    lng: -44.6880434,
    lat: -23.2516144
  },
  {
    lng: -44.6864552,
    lat: -23.2508541
  },
  {
    lng: -44.6857019,
    lat: -23.2505247
  },
  {
    lng: -44.6849043,
    lat: -23.2503743
  },
  {
    lng: -44.6837897,
    lat: -23.2493785
  },
  {
    lng: -44.681848,
    lat: -23.2473026
  },
  {
    lng: -44.6813333,
    lat: -23.2469193
  },
  {
    lng: -44.6809002,
    lat: -23.2468332
  },
  {
    lng: -44.6805373,
    lat: -23.2468765
  },
  {
    lng: -44.6800969,
    lat: -23.2469526
  },
  {
    lng: -44.6797067,
    lat: -23.2467976
  },
  {
    lng: -44.6795137,
    lat: -23.2466103
  },
  {
    lng: -44.6793635,
    lat: -23.2462209
  },
  {
    lng: -44.6791543,
    lat: -23.2460385
  },
  {
    lng: -44.6789075,
    lat: -23.2458907
  },
  {
    lng: -44.6787914,
    lat: -23.2457015
  },
  {
    lng: -44.6789762,
    lat: -23.2454711
  },
  {
    lng: -44.6792351,
    lat: -23.2452196
  },
  {
    lng: -44.6794666,
    lat: -23.2451661
  },
  {
    lng: -44.6798034,
    lat: -23.2451193
  },
  {
    lng: -44.6801186,
    lat: -23.2446141
  },
  {
    lng: -44.6804193,
    lat: -23.2441928
  },
  {
    lng: -44.6808284,
    lat: -23.2438622
  },
  {
    lng: -44.6810371,
    lat: -23.2435149
  },
  {
    lng: -44.6812579,
    lat: -23.2431152
  },
  {
    lng: -44.6817324,
    lat: -23.2429668
  },
  {
    lng: -44.6819736,
    lat: -23.2431857
  },
  {
    lng: -44.6824747,
    lat: -23.243087
  },
  {
    lng: -44.6829092,
    lat: -23.2432045
  },
  {
    lng: -44.6832313,
    lat: -23.2431293
  },
  {
    lng: -44.6838616,
    lat: -23.2428389
  },
  {
    lng: -44.6842564,
    lat: -23.2425636
  },
  {
    lng: -44.6850228,
    lat: -23.2420068
  },
  {
    lng: -44.6842692,
    lat: -23.2416214
  },
  {
    lng: -44.6836716,
    lat: -23.2411485
  },
  {
    lng: -44.6833967,
    lat: -23.2409295
  },
  {
    lng: -44.6833257,
    lat: -23.240661
  },
  {
    lng: -44.683277,
    lat: -23.2405143
  },
  {
    lng: -44.6831281,
    lat: -23.2404315
  },
  {
    lng: -44.682894,
    lat: -23.2404509
  },
  {
    lng: -44.6827481,
    lat: -23.2404857
  },
  {
    lng: -44.6825806,
    lat: -23.2404318
  },
  {
    lng: -44.6823018,
    lat: -23.2401497
  },
  {
    lng: -44.6820021,
    lat: -23.2400024
  },
  {
    lng: -44.6818671,
    lat: -23.2399487
  },
  {
    lng: -44.6817742,
    lat: -23.2399893
  },
  {
    lng: -44.6817666,
    lat: -23.2400396
  },
  {
    lng: -44.6817545,
    lat: -23.2401553
  },
  {
    lng: -44.6817634,
    lat: -23.2404009
  },
  {
    lng: -44.6816987,
    lat: -23.2407323
  },
  {
    lng: -44.6816007,
    lat: -23.2409524
  },
  {
    lng: -44.6813222,
    lat: -23.2410699
  },
  {
    lng: -44.6812067,
    lat: -23.2409889
  },
  {
    lng: -44.6810687,
    lat: -23.2410055
  },
  {
    lng: -44.6810036,
    lat: -23.2413111
  },
  {
    lng: -44.6808879,
    lat: -23.24163
  },
  {
    lng: -44.6806063,
    lat: -23.2418571
  },
  {
    lng: -44.6802734,
    lat: -23.2421283
  },
  {
    lng: -44.6795937,
    lat: -23.2421814
  },
  {
    lng: -44.6786538,
    lat: -23.2426465
  },
  {
    lng: -44.677656,
    lat: -23.2427262
  },
  {
    lng: -44.6771701,
    lat: -23.2426906
  },
  {
    lng: -44.6767938,
    lat: -23.2426622
  },
  {
    lng: -44.6753987,
    lat: -23.2423254
  },
  {
    lng: -44.6740175,
    lat: -23.2413812
  },
  {
    lng: -44.6737119,
    lat: -23.2410542
  },
  {
    lng: -44.6720913,
    lat: -23.2398137
  },
  {
    lng: -44.6719175,
    lat: -23.2395197
  },
  {
    lng: -44.6718537,
    lat: -23.2391394
  },
  {
    lng: -44.6722802,
    lat: -23.2388847
  },
  {
    lng: -44.6727229,
    lat: -23.23882
  },
  {
    lng: -44.6733261,
    lat: -23.2389985
  },
  {
    lng: -44.6739805,
    lat: -23.2391112
  },
  {
    lng: -44.6743638,
    lat: -23.2389111
  },
  {
    lng: -44.6747662,
    lat: -23.2388519
  },
  {
    lng: -44.675088,
    lat: -23.2388865
  },
  {
    lng: -44.6753616,
    lat: -23.2389752
  },
  {
    lng: -44.6755601,
    lat: -23.2390935
  },
  {
    lng: -44.6756674,
    lat: -23.2394139
  },
  {
    lng: -44.6759087,
    lat: -23.2395471
  },
  {
    lng: -44.6760857,
    lat: -23.2397245
  },
  {
    lng: -44.6761448,
    lat: -23.2399512
  },
  {
    lng: -44.6765149,
    lat: -23.2400103
  },
  {
    lng: -44.6767348,
    lat: -23.2399463
  },
  {
    lng: -44.6769388,
    lat: -23.2398674
  },
  {
    lng: -44.6774698,
    lat: -23.2398378
  },
  {
    lng: -44.6776682,
    lat: -23.2396999
  },
  {
    lng: -44.6780063,
    lat: -23.2392759
  },
  {
    lng: -44.6781833,
    lat: -23.238847
  },
  {
    lng: -44.67832,
    lat: -23.2379722
  },
  {
    lng: -44.6782664,
    lat: -23.2376295
  },
  {
    lng: -44.6779929,
    lat: -23.2374077
  },
  {
    lng: -44.6777263,
    lat: -23.2373942
  },
  {
    lng: -44.6769392,
    lat: -23.2370863
  },
  {
    lng: -44.6761609,
    lat: -23.2364017
  },
  {
    lng: -44.6761032,
    lat: -23.2360589
  },
  {
    lng: -44.6759541,
    lat: -23.2356973
  },
  {
    lng: -44.6756885,
    lat: -23.2352826
  },
  {
    lng: -44.6753064,
    lat: -23.2349347
  },
  {
    lng: -44.6742857,
    lat: -23.2342555
  },
  {
    lng: -44.6741595,
    lat: -23.2339716
  },
  {
    lng: -44.6742828,
    lat: -23.2335977
  },
  {
    lng: -44.6742601,
    lat: -23.2331845
  },
  {
    lng: -44.6741493,
    lat: -23.2329368
  },
  {
    lng: -44.6738864,
    lat: -23.2326164
  },
  {
    lng: -44.6735484,
    lat: -23.2320495
  },
  {
    lng: -44.6735859,
    lat: -23.2317587
  },
  {
    lng: -44.6735699,
    lat: -23.2313692
  },
  {
    lng: -44.6733123,
    lat: -23.2310982
  },
  {
    lng: -44.6731567,
    lat: -23.2308516
  },
  {
    lng: -44.6726203,
    lat: -23.2307284
  },
  {
    lng: -44.6722126,
    lat: -23.2307875
  },
  {
    lng: -44.6717353,
    lat: -23.2310094
  },
  {
    lng: -44.6714563,
    lat: -23.2312953
  },
  {
    lng: -44.6712203,
    lat: -23.2316453
  },
  {
    lng: -44.6707642,
    lat: -23.2317489
  },
  {
    lng: -44.6700562,
    lat: -23.2321432
  },
  {
    lng: -44.6697022,
    lat: -23.2324389
  },
  {
    lng: -44.6694769,
    lat: -23.2327841
  },
  {
    lng: -44.6694929,
    lat: -23.2333953
  },
  {
    lng: -44.6697718,
    lat: -23.2334987
  },
  {
    lng: -44.6699918,
    lat: -23.2337403
  },
  {
    lng: -44.6700455,
    lat: -23.2339622
  },
  {
    lng: -44.6700079,
    lat: -23.2341938
  },
  {
    lng: -44.6698148,
    lat: -23.2344698
  },
  {
    lng: -44.6695351,
    lat: -23.2348838
  },
  {
    lng: -44.6693281,
    lat: -23.2350631
  },
  {
    lng: -44.6690169,
    lat: -23.2350251
  },
  {
    lng: -44.6684257,
    lat: -23.2351044
  },
  {
    lng: -44.6678835,
    lat: -23.2352103
  },
  {
    lng: -44.6673928,
    lat: -23.2352049
  },
  {
    lng: -44.6669276,
    lat: -23.2351408
  },
  {
    lng: -44.6661122,
    lat: -23.2349297
  },
  {
    lng: -44.6656898,
    lat: -23.2347395
  },
  {
    lng: -44.6652523,
    lat: -23.2344887
  },
  {
    lng: -44.6648309,
    lat: -23.234079
  },
  {
    lng: -44.6645549,
    lat: -23.2336047
  },
  {
    lng: -44.6643262,
    lat: -23.2331929
  },
  {
    lng: -44.6640738,
    lat: -23.2330917
  },
  {
    lng: -44.6638101,
    lat: -23.2328586
  },
  {
    lng: -44.6633802,
    lat: -23.2326391
  },
  {
    lng: -44.6630779,
    lat: -23.2325867
  },
  {
    lng: -44.6629804,
    lat: -23.2327142
  },
  {
    lng: -44.6624665,
    lat: -23.2326609
  },
  {
    lng: -44.6624639,
    lat: -23.2325501
  },
  {
    lng: -44.6615173,
    lat: -23.2317843
  },
  {
    lng: -44.6596026,
    lat: -23.2310244
  },
  {
    lng: -44.6592623,
    lat: -23.230794
  },
  {
    lng: -44.6591438,
    lat: -23.2306957
  },
  {
    lng: -44.6585398,
    lat: -23.2296895
  },
  {
    lng: -44.6580717,
    lat: -23.2291854
  },
  {
    lng: -44.657655,
    lat: -23.2288553
  },
  {
    lng: -44.6575694,
    lat: -23.2285656
  },
  {
    lng: -44.6573153,
    lat: -23.228067
  },
  {
    lng: -44.6565458,
    lat: -23.2275994
  },
  {
    lng: -44.6562384,
    lat: -23.2275494
  },
  {
    lng: -44.6560272,
    lat: -23.22767
  },
  {
    lng: -44.6555416,
    lat: -23.2275695
  },
  {
    lng: -44.6552075,
    lat: -23.2273512
  },
  {
    lng: -44.6549295,
    lat: -23.2272959
  },
  {
    lng: -44.6548075,
    lat: -23.2271624
  },
  {
    lng: -44.6546613,
    lat: -23.226963
  },
  {
    lng: -44.6548937,
    lat: -23.2261181
  },
  {
    lng: -44.6550578,
    lat: -23.2259107
  },
  {
    lng: -44.6548217,
    lat: -23.2257219
  },
  {
    lng: -44.6550765,
    lat: -23.2255848
  },
  {
    lng: -44.6550844,
    lat: -23.2255415
  },
  {
    lng: -44.6549768,
    lat: -23.2253264
  },
  {
    lng: -44.6549339,
    lat: -23.2251073
  },
  {
    lng: -44.654987,
    lat: -23.224836
  },
  {
    lng: -44.6551663,
    lat: -23.2248149
  },
  {
    lng: -44.6555404,
    lat: -23.2246839
  },
  {
    lng: -44.6561178,
    lat: -23.2246351
  },
  {
    lng: -44.6562394,
    lat: -23.2245828
  },
  {
    lng: -44.6563273,
    lat: -23.2243061
  },
  {
    lng: -44.656393,
    lat: -23.2240477
  },
  {
    lng: -44.6565048,
    lat: -23.2237528
  },
  {
    lng: -44.6567176,
    lat: -23.2237248
  },
  {
    lng: -44.6569087,
    lat: -23.2237302
  },
  {
    lng: -44.6572139,
    lat: -23.2238391
  },
  {
    lng: -44.6577073,
    lat: -23.2242362
  },
  {
    lng: -44.6583564,
    lat: -23.2248425
  },
  {
    lng: -44.6584691,
    lat: -23.2250644
  },
  {
    lng: -44.6584795,
    lat: -23.2253406
  },
  {
    lng: -44.658174,
    lat: -23.2256806
  },
  {
    lng: -44.6581097,
    lat: -23.2260405
  },
  {
    lng: -44.6580936,
    lat: -23.2263658
  },
  {
    lng: -44.6582812,
    lat: -23.2267305
  },
  {
    lng: -44.6587427,
    lat: -23.2271003
  },
  {
    lng: -44.6589572,
    lat: -23.22712
  },
  {
    lng: -44.6592897,
    lat: -23.2268981
  },
  {
    lng: -44.659806,
    lat: -23.2267861
  },
  {
    lng: -44.6602392,
    lat: -23.2268883
  },
  {
    lng: -44.6607542,
    lat: -23.2273467
  },
  {
    lng: -44.6617627,
    lat: -23.2277805
  },
  {
    lng: -44.6622563,
    lat: -23.22782
  },
  {
    lng: -44.6630321,
    lat: -23.2281748
  },
  {
    lng: -44.663474,
    lat: -23.2282193
  },
  {
    lng: -44.6638227,
    lat: -23.2281108
  },
  {
    lng: -44.6639515,
    lat: -23.2277312
  },
  {
    lng: -44.6637476,
    lat: -23.2271495
  },
  {
    lng: -44.6636832,
    lat: -23.2265481
  },
  {
    lng: -44.6635223,
    lat: -23.2258382
  },
  {
    lng: -44.6632862,
    lat: -23.2252565
  },
  {
    lng: -44.6630403,
    lat: -23.2249572
  },
  {
    lng: -44.6605718,
    lat: -23.2232649
  },
  {
    lng: -44.659896,
    lat: -23.2230579
  },
  {
    lng: -44.6595527,
    lat: -23.2226241
  },
  {
    lng: -44.6589089,
    lat: -23.2214902
  },
  {
    lng: -44.6590135,
    lat: -23.2203147
  },
  {
    lng: -44.6606901,
    lat: -23.2182587
  },
  {
    lng: -44.6611445,
    lat: -23.2179396
  },
  {
    lng: -44.6613038,
    lat: -23.2177468
  },
  {
    lng: -44.6617749,
    lat: -23.2176425
  },
  {
    lng: -44.6623363,
    lat: -23.2171355
  },
  {
    lng: -44.6624705,
    lat: -23.2168396
  },
  {
    lng: -44.6623253,
    lat: -23.2165676
  },
  {
    lng: -44.6621791,
    lat: -23.2163255
  },
  {
    lng: -44.6618581,
    lat: -23.2157669
  },
  {
    lng: -44.6621924,
    lat: -23.2154156
  },
  {
    lng: -44.6626958,
    lat: -23.2145504
  },
  {
    lng: -44.6627895,
    lat: -23.2138868
  },
  {
    lng: -44.6626277,
    lat: -23.2138865
  },
  {
    lng: -44.662533,
    lat: -23.2141228
  },
  {
    lng: -44.6620019,
    lat: -23.2141845
  },
  {
    lng: -44.6608863,
    lat: -23.214491
  },
  {
    lng: -44.6603957,
    lat: -23.2147442
  },
  {
    lng: -44.659584,
    lat: -23.2149198
  },
  {
    lng: -44.6593702,
    lat: -23.2148791
  },
  {
    lng: -44.6589419,
    lat: -23.2148079
  },
  {
    lng: -44.6586399,
    lat: -23.2148522
  },
  {
    lng: -44.6580898,
    lat: -23.2146256
  },
  {
    lng: -44.6579546,
    lat: -23.2147138
  },
  {
    lng: -44.6575896,
    lat: -23.2146543
  },
  {
    lng: -44.657531,
    lat: -23.2145216
  },
  {
    lng: -44.6572677,
    lat: -23.2145803
  },
  {
    lng: -44.6571135,
    lat: -23.2146607
  },
  {
    lng: -44.6571372,
    lat: -23.2147666
  },
  {
    lng: -44.656972,
    lat: -23.2149242
  },
  {
    lng: -44.6570099,
    lat: -23.2153019
  },
  {
    lng: -44.6568023,
    lat: -23.2154919
  },
  {
    lng: -44.6567374,
    lat: -23.2158915
  },
  {
    lng: -44.6569238,
    lat: -23.2164229
  },
  {
    lng: -44.6568917,
    lat: -23.2168785
  },
  {
    lng: -44.6565162,
    lat: -23.2173623
  },
  {
    lng: -44.6562946,
    lat: -23.2176508
  },
  {
    lng: -44.6559447,
    lat: -23.217831
  },
  {
    lng: -44.6558855,
    lat: -23.2179646
  },
  {
    lng: -44.655843,
    lat: -23.2181472
  },
  {
    lng: -44.6557535,
    lat: -23.2182336
  },
  {
    lng: -44.6556241,
    lat: -23.2182479
  },
  {
    lng: -44.6552965,
    lat: -23.2181593
  },
  {
    lng: -44.6547184,
    lat: -23.2179342
  },
  {
    lng: -44.6536175,
    lat: -23.2172478
  },
  {
    lng: -44.6535304,
    lat: -23.2171315
  },
  {
    lng: -44.6529701,
    lat: -23.2170171
  },
  {
    lng: -44.6527329,
    lat: -23.2170601
  },
  {
    lng: -44.6525837,
    lat: -23.2171916
  },
  {
    lng: -44.652629,
    lat: -23.2174794
  },
  {
    lng: -44.6523616,
    lat: -23.217943
  },
  {
    lng: -44.6524686,
    lat: -23.2191303
  },
  {
    lng: -44.6524826,
    lat: -23.2194123
  },
  {
    lng: -44.6525972,
    lat: -23.2196791
  },
  {
    lng: -44.6526629,
    lat: -23.2201352
  },
  {
    lng: -44.6525539,
    lat: -23.2209051
  },
  {
    lng: -44.6522643,
    lat: -23.2210942
  },
  {
    lng: -44.6520968,
    lat: -23.2214574
  },
  {
    lng: -44.6517799,
    lat: -23.2217837
  },
  {
    lng: -44.6517402,
    lat: -23.2220779
  },
  {
    lng: -44.651585,
    lat: -23.2221437
  },
  {
    lng: -44.6512666,
    lat: -23.2221145
  },
  {
    lng: -44.6505093,
    lat: -23.2218112
  },
  {
    lng: -44.6502032,
    lat: -23.2215655
  },
  {
    lng: -44.6496128,
    lat: -23.2210304
  },
  {
    lng: -44.6488506,
    lat: -23.2205025
  },
  {
    lng: -44.6488493,
    lat: -23.2203367
  },
  {
    lng: -44.6486517,
    lat: -23.2202365
  },
  {
    lng: -44.6484354,
    lat: -23.2198538
  },
  {
    lng: -44.6483614,
    lat: -23.2194379
  },
  {
    lng: -44.6473981,
    lat: -23.2185663
  },
  {
    lng: -44.6468349,
    lat: -23.217752
  },
  {
    lng: -44.6468397,
    lat: -23.2176139
  },
  {
    lng: -44.6469609,
    lat: -23.2175727
  },
  {
    lng: -44.6468511,
    lat: -23.217395
  },
  {
    lng: -44.6465761,
    lat: -23.2173278
  },
  {
    lng: -44.6452989,
    lat: -23.2169694
  },
  {
    lng: -44.6449647,
    lat: -23.2168606
  },
  {
    lng: -44.6442623,
    lat: -23.2164311
  },
  {
    lng: -44.6439321,
    lat: -23.2160699
  },
  {
    lng: -44.6428347,
    lat: -23.2156249
  },
  {
    lng: -44.6425336,
    lat: -23.2154129
  },
  {
    lng: -44.6417624,
    lat: -23.2150285
  },
  {
    lng: -44.6404841,
    lat: -23.2145904
  },
  {
    lng: -44.6403059,
    lat: -23.2143763
  },
  {
    lng: -44.6402423,
    lat: -23.2141917
  },
  {
    lng: -44.6402537,
    lat: -23.2138989
  },
  {
    lng: -44.6401019,
    lat: -23.2136698
  },
  {
    lng: -44.640197,
    lat: -23.2135531
  },
  {
    lng: -44.6402774,
    lat: -23.2132828
  },
  {
    lng: -44.6400857,
    lat: -23.213045
  },
  {
    lng: -44.6397326,
    lat: -23.2128187
  },
  {
    lng: -44.6391497,
    lat: -23.2123631
  },
  {
    lng: -44.6380129,
    lat: -23.2118992
  },
  {
    lng: -44.637672,
    lat: -23.2117735
  },
  {
    lng: -44.637271,
    lat: -23.211449
  },
  {
    lng: -44.6369552,
    lat: -23.2106752
  },
  {
    lng: -44.6365608,
    lat: -23.2110829
  },
  {
    lng: -44.6365124,
    lat: -23.211295
  },
  {
    lng: -44.6365554,
    lat: -23.2115316
  },
  {
    lng: -44.637042,
    lat: -23.2121573
  },
  {
    lng: -44.6375274,
    lat: -23.2126549
  },
  {
    lng: -44.6381042,
    lat: -23.212894
  },
  {
    lng: -44.638446,
    lat: -23.2129867
  },
  {
    lng: -44.6385838,
    lat: -23.2130886
  },
  {
    lng: -44.6388161,
    lat: -23.2136014
  },
  {
    lng: -44.6390502,
    lat: -23.2143943
  },
  {
    lng: -44.6389302,
    lat: -23.2145347
  },
  {
    lng: -44.6388838,
    lat: -23.2148746
  },
  {
    lng: -44.6391065,
    lat: -23.2151164
  },
  {
    lng: -44.6396022,
    lat: -23.2153236
  },
  {
    lng: -44.6399437,
    lat: -23.2155465
  },
  {
    lng: -44.6399637,
    lat: -23.2156384
  },
  {
    lng: -44.6400628,
    lat: -23.2157249
  },
  {
    lng: -44.6402084,
    lat: -23.2160045
  },
  {
    lng: -44.6402851,
    lat: -23.216455
  },
  {
    lng: -44.6402739,
    lat: -23.2168285
  },
  {
    lng: -44.64034,
    lat: -23.2171474
  },
  {
    lng: -44.6407453,
    lat: -23.2183303
  },
  {
    lng: -44.6410195,
    lat: -23.2187491
  },
  {
    lng: -44.6409412,
    lat: -23.2188402
  },
  {
    lng: -44.6410493,
    lat: -23.2192449
  },
  {
    lng: -44.6404459,
    lat: -23.2191632
  },
  {
    lng: -44.6401675,
    lat: -23.2190389
  },
  {
    lng: -44.6399976,
    lat: -23.218938
  },
  {
    lng: -44.6398164,
    lat: -23.2188553
  },
  {
    lng: -44.6397779,
    lat: -23.2185724
  },
  {
    lng: -44.639068,
    lat: -23.2176964
  },
  {
    lng: -44.6386023,
    lat: -23.2172664
  },
  {
    lng: -44.6385826,
    lat: -23.2170542
  },
  {
    lng: -44.6382948,
    lat: -23.2166452
  },
  {
    lng: -44.6381208,
    lat: -23.2165541
  },
  {
    lng: -44.6378085,
    lat: -23.2165828
  },
  {
    lng: -44.6374345,
    lat: -23.2167241
  },
  {
    lng: -44.6373648,
    lat: -23.2168902
  },
  {
    lng: -44.6370116,
    lat: -23.2169783
  },
  {
    lng: -44.6366371,
    lat: -23.2169513
  },
  {
    lng: -44.636255,
    lat: -23.2169882
  },
  {
    lng: -44.6354363,
    lat: -23.2169459
  },
  {
    lng: -44.634971,
    lat: -23.2169582
  },
  {
    lng: -44.6347014,
    lat: -23.2168967
  },
  {
    lng: -44.6343731,
    lat: -23.2169433
  },
  {
    lng: -44.6342186,
    lat: -23.2169606
  },
  {
    lng: -44.6338282,
    lat: -23.2173532
  },
  {
    lng: -44.6335416,
    lat: -23.2175887
  },
  {
    lng: -44.6332023,
    lat: -23.2177428
  },
  {
    lng: -44.6328168,
    lat: -23.2182664
  },
  {
    lng: -44.6324921,
    lat: -23.2185319
  },
  {
    lng: -44.6323538,
    lat: -23.2190202
  },
  {
    lng: -44.6323235,
    lat: -23.2194513
  },
  {
    lng: -44.6324302,
    lat: -23.2199589
  },
  {
    lng: -44.6325918,
    lat: -23.2204188
  },
  {
    lng: -44.6329716,
    lat: -23.220799
  },
  {
    lng: -44.6335544,
    lat: -23.2211431
  },
  {
    lng: -44.6338508,
    lat: -23.2214098
  },
  {
    lng: -44.6338476,
    lat: -23.2216271
  },
  {
    lng: -44.6336838,
    lat: -23.2219822
  },
  {
    lng: -44.6333465,
    lat: -23.2222673
  },
  {
    lng: -44.6321264,
    lat: -23.2228506
  },
  {
    lng: -44.630695,
    lat: -23.2229635
  },
  {
    lng: -44.6302456,
    lat: -23.2230924
  },
  {
    lng: -44.6302146,
    lat: -23.223295
  },
  {
    lng: -44.6300771,
    lat: -23.2234633
  },
  {
    lng: -44.6298242,
    lat: -23.2234496
  },
  {
    lng: -44.6291393,
    lat: -23.2239716
  },
  {
    lng: -44.6282231,
    lat: -23.2239773
  },
  {
    lng: -44.6275901,
    lat: -23.2236841
  },
  {
    lng: -44.6270092,
    lat: -23.2232635
  },
  {
    lng: -44.6268412,
    lat: -23.2233176
  },
  {
    lng: -44.6261759,
    lat: -23.2231713
  },
  {
    lng: -44.6257186,
    lat: -23.2227963
  },
  {
    lng: -44.6250124,
    lat: -23.222296
  },
  {
    lng: -44.6246961,
    lat: -23.2217898
  },
  {
    lng: -44.6246188,
    lat: -23.2213426
  },
  {
    lng: -44.6248297,
    lat: -23.2208678
  },
  {
    lng: -44.6249336,
    lat: -23.2208408
  },
  {
    lng: -44.6250984,
    lat: -23.2188544
  },
  {
    lng: -44.6245937,
    lat: -23.2181969
  },
  {
    lng: -44.624382,
    lat: -23.2177999
  },
  {
    lng: -44.6240173,
    lat: -23.2169781
  },
  {
    lng: -44.6242214,
    lat: -23.2167193
  },
  {
    lng: -44.6237707,
    lat: -23.2160498
  },
  {
    lng: -44.6236456,
    lat: -23.2155817
  },
  {
    lng: -44.6239237,
    lat: -23.2151679
  },
  {
    lng: -44.6241811,
    lat: -23.214756
  },
  {
    lng: -44.6247586,
    lat: -23.2145391
  },
  {
    lng: -44.6249256,
    lat: -23.2146574
  },
  {
    lng: -44.6256954,
    lat: -23.2153038
  },
  {
    lng: -44.6260374,
    lat: -23.2151891
  },
  {
    lng: -44.6264632,
    lat: -23.2148563
  },
  {
    lng: -44.6269299,
    lat: -23.2144538
  },
  {
    lng: -44.6272886,
    lat: -23.2139611
  },
  {
    lng: -44.6273621,
    lat: -23.2135294
  },
  {
    lng: -44.6271627,
    lat: -23.2131616
  },
  {
    lng: -44.6269246,
    lat: -23.2128818
  },
  {
    lng: -44.6268623,
    lat: -23.2125184
  },
  {
    lng: -44.6255527,
    lat: -23.2115854
  },
  {
    lng: -44.6253789,
    lat: -23.2112709
  },
  {
    lng: -44.6250824,
    lat: -23.2106687
  },
  {
    lng: -44.62483,
    lat: -23.210367
  },
  {
    lng: -44.6243649,
    lat: -23.2102456
  },
  {
    lng: -44.6242144,
    lat: -23.2099162
  },
  {
    lng: -44.6242804,
    lat: -23.209467
  },
  {
    lng: -44.6239722,
    lat: -23.2083014
  },
  {
    lng: -44.6237075,
    lat: -23.2081533
  },
  {
    lng: -44.6236823,
    lat: -23.2075499
  },
  {
    lng: -44.6235342,
    lat: -23.2073615
  },
  {
    lng: -44.622641,
    lat: -23.2071915
  },
  {
    lng: -44.6201862,
    lat: -23.2061126
  },
  {
    lng: -44.6197788,
    lat: -23.2060436
  },
  {
    lng: -44.618523,
    lat: -23.2065772
  },
  {
    lng: -44.617674,
    lat: -23.2067876
  },
  {
    lng: -44.6170345,
    lat: -23.2066869
  },
  {
    lng: -44.6160821,
    lat: -23.2064134
  },
  {
    lng: -44.6157767,
    lat: -23.2064111
  },
  {
    lng: -44.6157866,
    lat: -23.2063411
  },
  {
    lng: -44.6156851,
    lat: -23.2062827
  },
  {
    lng: -44.6156902,
    lat: -23.2062027
  },
  {
    lng: -44.6157604,
    lat: -23.2061731
  },
  {
    lng: -44.6157277,
    lat: -23.2060608
  },
  {
    lng: -44.6156492,
    lat: -23.2060716
  },
  {
    lng: -44.6155362,
    lat: -23.2059947
  },
  {
    lng: -44.6156347,
    lat: -23.2059137
  },
  {
    lng: -44.6157442,
    lat: -23.2058875
  },
  {
    lng: -44.6157423,
    lat: -23.2054787
  },
  {
    lng: -44.6157797,
    lat: -23.2052203
  },
  {
    lng: -44.6161126,
    lat: -23.2051278
  },
  {
    lng: -44.6159128,
    lat: -23.2047094
  },
  {
    lng: -44.6158723,
    lat: -23.2042359
  },
  {
    lng: -44.6161884,
    lat: -23.2038068
  },
  {
    lng: -44.6163977,
    lat: -23.2036154
  },
  {
    lng: -44.617166,
    lat: -23.2036749
  },
  {
    lng: -44.6174793,
    lat: -23.2035469
  },
  {
    lng: -44.6176398,
    lat: -23.2036074
  },
  {
    lng: -44.6178165,
    lat: -23.2039184
  },
  {
    lng: -44.6186065,
    lat: -23.2041926
  },
  {
    lng: -44.6190869,
    lat: -23.2042619
  },
  {
    lng: -44.6203826,
    lat: -23.2048721
  },
  {
    lng: -44.6211495,
    lat: -23.2048617
  },
  {
    lng: -44.622017,
    lat: -23.2046834
  },
  {
    lng: -44.6224227,
    lat: -23.204623
  },
  {
    lng: -44.6224197,
    lat: -23.2043484
  },
  {
    lng: -44.6222903,
    lat: -23.2041888
  },
  {
    lng: -44.6223355,
    lat: -23.2039802
  },
  {
    lng: -44.6227085,
    lat: -23.2038836
  },
  {
    lng: -44.6230719,
    lat: -23.2039686
  },
  {
    lng: -44.6235653,
    lat: -23.2039886
  },
  {
    lng: -44.6237863,
    lat: -23.2038198
  },
  {
    lng: -44.6240487,
    lat: -23.2031121
  },
  {
    lng: -44.6243787,
    lat: -23.2026184
  },
  {
    lng: -44.624655,
    lat: -23.2023167
  },
  {
    lng: -44.6250609,
    lat: -23.2023028
  },
  {
    lng: -44.6253406,
    lat: -23.2024621
  },
  {
    lng: -44.6257903,
    lat: -23.2028204
  },
  {
    lng: -44.6261317,
    lat: -23.2029624
  },
  {
    lng: -44.6262984,
    lat: -23.2029489
  },
  {
    lng: -44.6267526,
    lat: -23.2023549
  },
  {
    lng: -44.6267101,
    lat: -23.2022363
  },
  {
    lng: -44.6266111,
    lat: -23.2020901
  },
  {
    lng: -44.62666,
    lat: -23.2020545
  },
  {
    lng: -44.6267401,
    lat: -23.2020522
  },
  {
    lng: -44.6267915,
    lat: -23.2020971
  },
  {
    lng: -44.6269468,
    lat: -23.2021783
  },
  {
    lng: -44.6272531,
    lat: -23.2018322
  },
  {
    lng: -44.6272194,
    lat: -23.2010341
  },
  {
    lng: -44.6273228,
    lat: -23.2008603
  },
  {
    lng: -44.6274968,
    lat: -23.200896
  },
  {
    lng: -44.6275891,
    lat: -23.2005669
  },
  {
    lng: -44.6272356,
    lat: -23.1999963
  },
  {
    lng: -44.6266327,
    lat: -23.1992116
  },
  {
    lng: -44.626512,
    lat: -23.1986802
  },
  {
    lng: -44.626296,
    lat: -23.1983508
  },
  {
    lng: -44.6268219,
    lat: -23.1977669
  },
  {
    lng: -44.626902,
    lat: -23.1975879
  },
  {
    lng: -44.626846,
    lat: -23.1970023
  },
  {
    lng: -44.6266566,
    lat: -23.1968687
  },
  {
    lng: -44.6262028,
    lat: -23.1964283
  },
  {
    lng: -44.6261628,
    lat: -23.1962417
  },
  {
    lng: -44.6268862,
    lat: -23.1962951
  },
  {
    lng: -44.6279319,
    lat: -23.1963427
  },
  {
    lng: -44.6286567,
    lat: -23.1966758
  },
  {
    lng: -44.6289691,
    lat: -23.1967765
  },
  {
    lng: -44.6303566,
    lat: -23.1963705
  },
  {
    lng: -44.6306112,
    lat: -23.1959197
  },
  {
    lng: -44.6310863,
    lat: -23.1956653
  },
  {
    lng: -44.631513,
    lat: -23.1956807
  },
  {
    lng: -44.6318712,
    lat: -23.1958713
  },
  {
    lng: -44.6326387,
    lat: -23.1961338
  },
  {
    lng: -44.6329297,
    lat: -23.1962806
  },
  {
    lng: -44.633261,
    lat: -23.1961632
  },
  {
    lng: -44.6334864,
    lat: -23.1965751
  },
  {
    lng: -44.6340098,
    lat: -23.1966203
  },
  {
    lng: -44.6342356,
    lat: -23.1967909
  },
  {
    lng: -44.6344813,
    lat: -23.1968893
  },
  {
    lng: -44.6347365,
    lat: -23.1969153
  },
  {
    lng: -44.6349827,
    lat: -23.1967042
  },
  {
    lng: -44.6352218,
    lat: -23.1964877
  },
  {
    lng: -44.6353838,
    lat: -23.1963563
  },
  {
    lng: -44.6357263,
    lat: -23.1963505
  },
  {
    lng: -44.6360513,
    lat: -23.1961493
  },
  {
    lng: -44.6361403,
    lat: -23.195986
  },
  {
    lng: -44.6363194,
    lat: -23.1959367
  },
  {
    lng: -44.6368348,
    lat: -23.1955947
  },
  {
    lng: -44.6370248,
    lat: -23.1955938
  },
  {
    lng: -44.6371099,
    lat: -23.1957617
  },
  {
    lng: -44.6378722,
    lat: -23.1958864
  },
  {
    lng: -44.6382638,
    lat: -23.1958431
  },
  {
    lng: -44.6389734,
    lat: -23.1961936
  },
  {
    lng: -44.6395075,
    lat: -23.1964028
  },
  {
    lng: -44.6401309,
    lat: -23.1962488
  },
  {
    lng: -44.640277,
    lat: -23.1965188
  },
  {
    lng: -44.6406274,
    lat: -23.196658
  },
  {
    lng: -44.6410507,
    lat: -23.1966073
  },
  {
    lng: -44.6412961,
    lat: -23.1968515
  },
  {
    lng: -44.641584,
    lat: -23.1969807
  },
  {
    lng: -44.6419873,
    lat: -23.1970799
  },
  {
    lng: -44.6421706,
    lat: -23.1972174
  },
  {
    lng: -44.6423892,
    lat: -23.1971594
  },
  {
    lng: -44.6429672,
    lat: -23.196877
  },
  {
    lng: -44.6433252,
    lat: -23.1967007
  },
  {
    lng: -44.6436123,
    lat: -23.1965257
  },
  {
    lng: -44.6438549,
    lat: -23.1963383
  },
  {
    lng: -44.6443563,
    lat: -23.1959477
  },
  {
    lng: -44.6447736,
    lat: -23.1956147
  },
  {
    lng: -44.6449479,
    lat: -23.1954421
  },
  {
    lng: -44.6451143,
    lat: -23.1952646
  },
  {
    lng: -44.6454254,
    lat: -23.1948677
  },
  {
    lng: -44.645518,
    lat: -23.1946803
  },
  {
    lng: -44.6455541,
    lat: -23.1946064
  },
  {
    lng: -44.6455877,
    lat: -23.1945244
  },
  {
    lng: -44.6456092,
    lat: -23.1944436
  },
  {
    lng: -44.6454256,
    lat: -23.1940991
  },
  {
    lng: -44.6451418,
    lat: -23.193803
  },
  {
    lng: -44.6448783,
    lat: -23.1935092
  },
  {
    lng: -44.6446528,
    lat: -23.1923817
  },
  {
    lng: -44.6446896,
    lat: -23.1921555
  },
  {
    lng: -44.6448938,
    lat: -23.1920578
  },
  {
    lng: -44.6451108,
    lat: -23.1920436
  },
  {
    lng: -44.6454618,
    lat: -23.1918671
  },
  {
    lng: -44.6455183,
    lat: -23.1916752
  },
  {
    lng: -44.6456424,
    lat: -23.1913111
  },
  {
    lng: -44.6456063,
    lat: -23.1910816
  },
  {
    lng: -44.6453524,
    lat: -23.1909205
  },
  {
    lng: -44.6443497,
    lat: -23.1898889
  },
  {
    lng: -44.6441602,
    lat: -23.1895546
  },
  {
    lng: -44.6439792,
    lat: -23.1890465
  },
  {
    lng: -44.6442698,
    lat: -23.1885082
  },
  {
    lng: -44.6441746,
    lat: -23.1882108
  },
  {
    lng: -44.6443437,
    lat: -23.1880967
  },
  {
    lng: -44.6443339,
    lat: -23.1877234
  },
  {
    lng: -44.644096,
    lat: -23.1868598
  },
  {
    lng: -44.6435546,
    lat: -23.1859888
  },
  {
    lng: -44.643394,
    lat: -23.1857661
  },
  {
    lng: -44.6435451,
    lat: -23.1854681
  },
  {
    lng: -44.6436463,
    lat: -23.1852682
  },
  {
    lng: -44.6441427,
    lat: -23.1855132
  },
  {
    lng: -44.6443308,
    lat: -23.1856012
  },
  {
    lng: -44.6445587,
    lat: -23.1856578
  },
  {
    lng: -44.6446003,
    lat: -23.1857289
  },
  {
    lng: -44.6443764,
    lat: -23.1859202
  },
  {
    lng: -44.6444423,
    lat: -23.1861019
  },
  {
    lng: -44.644625,
    lat: -23.1863805
  },
  {
    lng: -44.644899,
    lat: -23.1864868
  },
  {
    lng: -44.6453325,
    lat: -23.1868522
  },
  {
    lng: -44.6456711,
    lat: -23.1872076
  },
  {
    lng: -44.6459581,
    lat: -23.1875124
  },
  {
    lng: -44.6463094,
    lat: -23.1877225
  },
  {
    lng: -44.6468224,
    lat: -23.1878169
  },
  {
    lng: -44.6471178,
    lat: -23.1880103
  },
  {
    lng: -44.6472252,
    lat: -23.1881239
  },
  {
    lng: -44.6474187,
    lat: -23.1881479
  },
  {
    lng: -44.6478295,
    lat: -23.187951
  },
  {
    lng: -44.6480245,
    lat: -23.1877838
  },
  {
    lng: -44.6482357,
    lat: -23.1876029
  },
  {
    lng: -44.6484397,
    lat: -23.1875139
  },
  {
    lng: -44.6489746,
    lat: -23.1877555
  },
  {
    lng: -44.6498836,
    lat: -23.1880112
  },
  {
    lng: -44.6502952,
    lat: -23.1880677
  },
  {
    lng: -44.6511913,
    lat: -23.1880864
  },
  {
    lng: -44.6514408,
    lat: -23.1881225
  },
  {
    lng: -44.6517192,
    lat: -23.188301
  },
  {
    lng: -44.6521608,
    lat: -23.1883208
  },
  {
    lng: -44.6526686,
    lat: -23.1885013
  },
  {
    lng: -44.6530671,
    lat: -23.1893343
  },
  {
    lng: -44.6530979,
    lat: -23.1896089
  },
  {
    lng: -44.6535939,
    lat: -23.19027
  },
  {
    lng: -44.6539585,
    lat: -23.1904626
  },
  {
    lng: -44.6541345,
    lat: -23.190478
  },
  {
    lng: -44.6543063,
    lat: -23.1906604
  },
  {
    lng: -44.654274,
    lat: -23.1908527
  },
  {
    lng: -44.6540812,
    lat: -23.1909598
  },
  {
    lng: -44.6541113,
    lat: -23.1912276
  },
  {
    lng: -44.6541936,
    lat: -23.1919622
  },
  {
    lng: -44.654346,
    lat: -23.192288
  },
  {
    lng: -44.6545956,
    lat: -23.1925014
  },
  {
    lng: -44.6546227,
    lat: -23.1928338
  },
  {
    lng: -44.6545525,
    lat: -23.1930397
  },
  {
    lng: -44.6551966,
    lat: -23.1939441
  },
  {
    lng: -44.6554786,
    lat: -23.1944347
  },
  {
    lng: -44.6554578,
    lat: -23.1948201
  },
  {
    lng: -44.6553192,
    lat: -23.1951607
  },
  {
    lng: -44.6550474,
    lat: -23.1953004
  },
  {
    lng: -44.6552155,
    lat: -23.1955888
  },
  {
    lng: -44.6552584,
    lat: -23.1960005
  },
  {
    lng: -44.6553263,
    lat: -23.1965239
  },
  {
    lng: -44.6556241,
    lat: -23.1968626
  },
  {
    lng: -44.6558551,
    lat: -23.197468
  },
  {
    lng: -44.6560946,
    lat: -23.1977567
  },
  {
    lng: -44.6570541,
    lat: -23.1981684
  },
  {
    lng: -44.6575035,
    lat: -23.198456
  },
  {
    lng: -44.6577716,
    lat: -23.198752
  },
  {
    lng: -44.6579647,
    lat: -23.1993585
  },
  {
    lng: -44.6577877,
    lat: -23.1994521
  },
  {
    lng: -44.6578574,
    lat: -23.2002903
  },
  {
    lng: -44.6577288,
    lat: -23.2005121
  },
  {
    lng: -44.6573746,
    lat: -23.2006799
  },
  {
    lng: -44.6569295,
    lat: -23.2007784
  },
  {
    lng: -44.6566719,
    lat: -23.2009953
  },
  {
    lng: -44.6566558,
    lat: -23.2013898
  },
  {
    lng: -44.6566135,
    lat: -23.2019264
  },
  {
    lng: -44.6571765,
    lat: -23.2032132
  },
  {
    lng: -44.657354,
    lat: -23.2041576
  },
  {
    lng: -44.6572927,
    lat: -23.2045066
  },
  {
    lng: -44.657104,
    lat: -23.204895
  },
  {
    lng: -44.6569824,
    lat: -23.2051246
  },
  {
    lng: -44.6571657,
    lat: -23.205582
  },
  {
    lng: -44.657638,
    lat: -23.206162
  },
  {
    lng: -44.6583106,
    lat: -23.2065143
  },
  {
    lng: -44.6584018,
    lat: -23.2069631
  },
  {
    lng: -44.6585993,
    lat: -23.2071441
  },
  {
    lng: -44.6587783,
    lat: -23.2073822
  },
  {
    lng: -44.6587829,
    lat: -23.2074803
  },
  {
    lng: -44.6587117,
    lat: -23.2075586
  },
  {
    lng: -44.658668,
    lat: -23.2077013
  },
  {
    lng: -44.65879,
    lat: -23.2078264
  },
  {
    lng: -44.6592118,
    lat: -23.208121
  },
  {
    lng: -44.6598488,
    lat: -23.208314
  },
  {
    lng: -44.6606159,
    lat: -23.2084673
  },
  {
    lng: -44.6609122,
    lat: -23.208172
  },
  {
    lng: -44.6612257,
    lat: -23.2079142
  },
  {
    lng: -44.6612648,
    lat: -23.2077046
  },
  {
    lng: -44.6617663,
    lat: -23.2071165
  },
  {
    lng: -44.6620451,
    lat: -23.2067581
  },
  {
    lng: -44.6621736,
    lat: -23.206625
  },
  {
    lng: -44.6622819,
    lat: -23.2063735
  },
  {
    lng: -44.6624439,
    lat: -23.2062174
  },
  {
    lng: -44.6628031,
    lat: -23.2059843
  },
  {
    lng: -44.6631042,
    lat: -23.2059111
  },
  {
    lng: -44.6635606,
    lat: -23.2061113
  },
  {
    lng: -44.6637468,
    lat: -23.2061248
  },
  {
    lng: -44.6640586,
    lat: -23.2063119
  },
  {
    lng: -44.6642341,
    lat: -23.2067406
  },
  {
    lng: -44.664616,
    lat: -23.2069881
  },
  {
    lng: -44.6645878,
    lat: -23.2072834
  },
  {
    lng: -44.6647273,
    lat: -23.2074996
  },
  {
    lng: -44.6647197,
    lat: -23.2077602
  },
  {
    lng: -44.6648778,
    lat: -23.2078917
  },
  {
    lng: -44.6648527,
    lat: -23.2081262
  },
  {
    lng: -44.6649704,
    lat: -23.2083313
  },
  {
    lng: -44.6650688,
    lat: -23.2086691
  },
  {
    lng: -44.6648449,
    lat: -23.208954
  },
  {
    lng: -44.6649404,
    lat: -23.2097133
  },
  {
    lng: -44.6653139,
    lat: -23.209879
  },
  {
    lng: -44.6655996,
    lat: -23.2099411
  },
  {
    lng: -44.6660295,
    lat: -23.2099748
  },
  {
    lng: -44.6663973,
    lat: -23.2099203
  },
  {
    lng: -44.6667156,
    lat: -23.209733
  },
  {
    lng: -44.6666833,
    lat: -23.2094965
  },
  {
    lng: -44.6666532,
    lat: -23.2091626
  },
  {
    lng: -44.666654,
    lat: -23.209029
  },
  {
    lng: -44.6666857,
    lat: -23.2088418
  },
  {
    lng: -44.6665459,
    lat: -23.208752
  },
  {
    lng: -44.666507,
    lat: -23.2082481
  },
  {
    lng: -44.6665691,
    lat: -23.208117
  },
  {
    lng: -44.6665687,
    lat: -23.2075606
  },
  {
    lng: -44.6664466,
    lat: -23.2074224
  },
  {
    lng: -44.6665194,
    lat: -23.2067049
  },
  {
    lng: -44.6667194,
    lat: -23.2064384
  },
  {
    lng: -44.6668148,
    lat: -23.2060024
  },
  {
    lng: -44.6666759,
    lat: -23.2057918
  },
  {
    lng: -44.6665566,
    lat: -23.2056139
  },
  {
    lng: -44.6665669,
    lat: -23.2053145
  },
  {
    lng: -44.6666496,
    lat: -23.2052577
  },
  {
    lng: -44.666894,
    lat: -23.2051425
  },
  {
    lng: -44.6671374,
    lat: -23.2050509
  },
  {
    lng: -44.6673906,
    lat: -23.2050124
  },
  {
    lng: -44.6677053,
    lat: -23.2051911
  },
  {
    lng: -44.6679592,
    lat: -23.2053003
  },
  {
    lng: -44.6682005,
    lat: -23.2054768
  },
  {
    lng: -44.6684913,
    lat: -23.2056708
  },
  {
    lng: -44.6687257,
    lat: -23.2059154
  },
  {
    lng: -44.6688105,
    lat: -23.2060172
  },
  {
    lng: -44.6688422,
    lat: -23.2060789
  },
  {
    lng: -44.668847,
    lat: -23.2060295
  },
  {
    lng: -44.6689199,
    lat: -23.2060393
  },
  {
    lng: -44.6690108,
    lat: -23.2060919
  },
  {
    lng: -44.6689853,
    lat: -23.2061842
  },
  {
    lng: -44.668923,
    lat: -23.2061728
  },
  {
    lng: -44.6688718,
    lat: -23.2062118
  },
  {
    lng: -44.6688896,
    lat: -23.2062578
  },
  {
    lng: -44.6689621,
    lat: -23.2064206
  },
  {
    lng: -44.6689335,
    lat: -23.2065698
  },
  {
    lng: -44.6687941,
    lat: -23.2066269
  },
  {
    lng: -44.6687477,
    lat: -23.2069262
  },
  {
    lng: -44.6688405,
    lat: -23.2069798
  },
  {
    lng: -44.6689528,
    lat: -23.2069904
  },
  {
    lng: -44.66899,
    lat: -23.2070953
  },
  {
    lng: -44.6690654,
    lat: -23.207193
  },
  {
    lng: -44.6689395,
    lat: -23.2074606
  },
  {
    lng: -44.6689285,
    lat: -23.2075579
  },
  {
    lng: -44.6690459,
    lat: -23.2077546
  },
  {
    lng: -44.6691054,
    lat: -23.207837
  },
  {
    lng: -44.6692705,
    lat: -23.2078952
  },
  {
    lng: -44.6692278,
    lat: -23.2080498
  },
  {
    lng: -44.6694594,
    lat: -23.2083612
  },
  {
    lng: -44.6698228,
    lat: -23.2086928
  },
  {
    lng: -44.6699234,
    lat: -23.20896
  },
  {
    lng: -44.6699929,
    lat: -23.2091952
  },
  {
    lng: -44.6701075,
    lat: -23.2093232
  },
  {
    lng: -44.6702255,
    lat: -23.209394
  },
  {
    lng: -44.6703048,
    lat: -23.2095872
  },
  {
    lng: -44.6701756,
    lat: -23.209838
  },
  {
    lng: -44.6702605,
    lat: -23.2099107
  },
  {
    lng: -44.6702152,
    lat: -23.2100552
  },
  {
    lng: -44.6702806,
    lat: -23.2102029
  },
  {
    lng: -44.6703868,
    lat: -23.210401
  },
  {
    lng: -44.6706078,
    lat: -23.2105187
  },
  {
    lng: -44.6709547,
    lat: -23.2106972
  },
  {
    lng: -44.6710044,
    lat: -23.2107602
  },
  {
    lng: -44.6711171,
    lat: -23.2107942
  },
  {
    lng: -44.6710834,
    lat: -23.2110329
  },
  {
    lng: -44.6709824,
    lat: -23.2111635
  },
  {
    lng: -44.6709195,
    lat: -23.2112079
  },
  {
    lng: -44.6709614,
    lat: -23.2112947
  },
  {
    lng: -44.6711528,
    lat: -23.2114201
  },
  {
    lng: -44.6713453,
    lat: -23.2114893
  },
  {
    lng: -44.6715656,
    lat: -23.2115029
  },
  {
    lng: -44.6717728,
    lat: -23.2114957
  },
  {
    lng: -44.6719315,
    lat: -23.2114592
  },
  {
    lng: -44.6719418,
    lat: -23.2111601
  },
  {
    lng: -44.6720324,
    lat: -23.2110509
  },
  {
    lng: -44.6720498,
    lat: -23.2107943
  },
  {
    lng: -44.6722108,
    lat: -23.2107888
  },
  {
    lng: -44.67229,
    lat: -23.2107046
  },
  {
    lng: -44.6721738,
    lat: -23.2105025
  },
  {
    lng: -44.6722807,
    lat: -23.2103747
  },
  {
    lng: -44.6725468,
    lat: -23.2104313
  },
  {
    lng: -44.6731037,
    lat: -23.2104326
  },
  {
    lng: -44.6732928,
    lat: -23.2105636
  },
  {
    lng: -44.6733553,
    lat: -23.2107398
  },
  {
    lng: -44.6733147,
    lat: -23.2108581
  },
  {
    lng: -44.6735489,
    lat: -23.2110667
  },
  {
    lng: -44.6737208,
    lat: -23.2111721
  },
  {
    lng: -44.673682,
    lat: -23.2113981
  },
  {
    lng: -44.6748228,
    lat: -23.2119278
  },
  {
    lng: -44.6752887,
    lat: -23.2120837
  },
  {
    lng: -44.6759833,
    lat: -23.2123992
  },
  {
    lng: -44.6760761,
    lat: -23.2125169
  },
  {
    lng: -44.6761365,
    lat: -23.2125409
  },
  {
    lng: -44.6761757,
    lat: -23.2125193
  },
  {
    lng: -44.6762016,
    lat: -23.2124596
  },
  {
    lng: -44.6762223,
    lat: -23.2123416
  },
  {
    lng: -44.6762846,
    lat: -23.2123168
  },
  {
    lng: -44.6763993,
    lat: -23.2123451
  },
  {
    lng: -44.6764661,
    lat: -23.2122509
  },
  {
    lng: -44.6766099,
    lat: -23.2123163
  },
  {
    lng: -44.6767621,
    lat: -23.2123085
  },
  {
    lng: -44.6767853,
    lat: -23.2122084
  },
  {
    lng: -44.6768702,
    lat: -23.2120692
  },
  {
    lng: -44.6769958,
    lat: -23.2120611
  },
  {
    lng: -44.6772879,
    lat: -23.2121068
  },
  {
    lng: -44.6775423,
    lat: -23.2122358
  },
  {
    lng: -44.677694,
    lat: -23.2123798
  },
  {
    lng: -44.6777481,
    lat: -23.2125551
  },
  {
    lng: -44.6776462,
    lat: -23.21258
  },
  {
    lng: -44.6774055,
    lat: -23.212601
  },
  {
    lng: -44.6773773,
    lat: -23.212659
  },
  {
    lng: -44.6774634,
    lat: -23.212835
  },
  {
    lng: -44.6773242,
    lat: -23.2129061
  },
  {
    lng: -44.6772751,
    lat: -23.2131447
  },
  {
    lng: -44.6774296,
    lat: -23.2131314
  },
  {
    lng: -44.6775181,
    lat: -23.2131955
  },
  {
    lng: -44.6773894,
    lat: -23.2132867
  },
  {
    lng: -44.6772821,
    lat: -23.2132818
  },
  {
    lng: -44.6772235,
    lat: -23.2132292
  },
  {
    lng: -44.6771022,
    lat: -23.2132693
  },
  {
    lng: -44.6768224,
    lat: -23.2132658
  },
  {
    lng: -44.6765408,
    lat: -23.2131544
  },
  {
    lng: -44.6763717,
    lat: -23.2131566
  },
  {
    lng: -44.6762087,
    lat: -23.21313
  },
  {
    lng: -44.6761692,
    lat: -23.212994
  },
  {
    lng: -44.6761064,
    lat: -23.2129854
  },
  {
    lng: -44.6758599,
    lat: -23.2132293
  },
  {
    lng: -44.6754934,
    lat: -23.2134299
  },
  {
    lng: -44.6752999,
    lat: -23.2139047
  },
  {
    lng: -44.675041,
    lat: -23.2139903
  },
  {
    lng: -44.6747291,
    lat: -23.2139971
  },
  {
    lng: -44.6745284,
    lat: -23.2139053
  },
  {
    lng: -44.6742076,
    lat: -23.2137031
  },
  {
    lng: -44.6740544,
    lat: -23.213692
  },
  {
    lng: -44.6739148,
    lat: -23.2136819
  },
  {
    lng: -44.6736251,
    lat: -23.2138202
  },
  {
    lng: -44.673467,
    lat: -23.2138085
  },
  {
    lng: -44.6731336,
    lat: -23.213873
  },
  {
    lng: -44.6729318,
    lat: -23.2140928
  },
  {
    lng: -44.6727939,
    lat: -23.2144873
  },
  {
    lng: -44.6727847,
    lat: -23.2146895
  },
  {
    lng: -44.6728277,
    lat: -23.2150787
  },
  {
    lng: -44.6728679,
    lat: -23.2154419
  },
  {
    lng: -44.672856,
    lat: -23.2155357
  },
  {
    lng: -44.6728739,
    lat: -23.2157288
  },
  {
    lng: -44.6729278,
    lat: -23.2160121
  },
  {
    lng: -44.6730766,
    lat: -23.2162256
  },
  {
    lng: -44.6734502,
    lat: -23.2166654
  },
  {
    lng: -44.6743115,
    lat: -23.2177299
  },
  {
    lng: -44.6746517,
    lat: -23.2178979
  },
  {
    lng: -44.6747771,
    lat: -23.217945
  },
  {
    lng: -44.6750397,
    lat: -23.2181979
  },
  {
    lng: -44.6756072,
    lat: -23.2186021
  },
  {
    lng: -44.6756558,
    lat: -23.21867
  },
  {
    lng: -44.6757683,
    lat: -23.2188271
  },
  {
    lng: -44.676014,
    lat: -23.2189454
  },
  {
    lng: -44.6762939,
    lat: -23.2191149
  },
  {
    lng: -44.6764962,
    lat: -23.2193185
  },
  {
    lng: -44.6771846,
    lat: -23.2199437
  },
  {
    lng: -44.6776559,
    lat: -23.2199526
  },
  {
    lng: -44.6783551,
    lat: -23.2201848
  },
  {
    lng: -44.6787479,
    lat: -23.2201867
  },
  {
    lng: -44.6790003,
    lat: -23.2201487
  },
  {
    lng: -44.6793321,
    lat: -23.220122
  },
  {
    lng: -44.6801771,
    lat: -23.2203742
  },
  {
    lng: -44.6803339,
    lat: -23.2206624
  },
  {
    lng: -44.680625,
    lat: -23.2208077
  },
  {
    lng: -44.6809322,
    lat: -23.2208447
  },
  {
    lng: -44.6814041,
    lat: -23.2207447
  },
  {
    lng: -44.6817318,
    lat: -23.2208469
  },
  {
    lng: -44.6817988,
    lat: -23.2213053
  },
  {
    lng: -44.6818898,
    lat: -23.2218628
  },
  {
    lng: -44.6820135,
    lat: -23.221998
  },
  {
    lng: -44.6820126,
    lat: -23.2222765
  },
  {
    lng: -44.6823703,
    lat: -23.222547
  },
  {
    lng: -44.682396,
    lat: -23.2228119
  },
  {
    lng: -44.6824744,
    lat: -23.2230502
  },
  {
    lng: -44.6826846,
    lat: -23.2235333
  },
  {
    lng: -44.6830642,
    lat: -23.2238812
  },
  {
    lng: -44.6833295,
    lat: -23.2239836
  },
  {
    lng: -44.6846315,
    lat: -23.2239168
  },
  {
    lng: -44.6848924,
    lat: -23.2238592
  },
  {
    lng: -44.685617,
    lat: -23.223117
  },
  {
    lng: -44.6858158,
    lat: -23.2229792
  },
  {
    lng: -44.6860928,
    lat: -23.2225539
  },
  {
    lng: -44.6861391,
    lat: -23.2225167
  },
  {
    lng: -44.6863439,
    lat: -23.2224035
  },
  {
    lng: -44.6865397,
    lat: -23.2222309
  },
  {
    lng: -44.6866296,
    lat: -23.2220448
  },
  {
    lng: -44.6866832,
    lat: -23.2220423
  },
  {
    lng: -44.6867677,
    lat: -23.2218735
  },
  {
    lng: -44.6869313,
    lat: -23.2218033
  },
  {
    lng: -44.6875085,
    lat: -23.2216281
  },
  {
    lng: -44.6880426,
    lat: -23.2216423
  },
  {
    lng: -44.6883328,
    lat: -23.2216652
  },
  {
    lng: -44.6886117,
    lat: -23.2216603
  },
  {
    lng: -44.6888209,
    lat: -23.221574
  },
  {
    lng: -44.6890385,
    lat: -23.2215916
  },
  {
    lng: -44.6892671,
    lat: -23.2215403
  },
  {
    lng: -44.6893968,
    lat: -23.2215732
  },
  {
    lng: -44.6894274,
    lat: -23.2215977
  },
  {
    lng: -44.6894107,
    lat: -23.2216783
  },
  {
    lng: -44.6901853,
    lat: -23.2218697
  },
  {
    lng: -44.6906521,
    lat: -23.2223676
  },
  {
    lng: -44.6907003,
    lat: -23.2225845
  },
  {
    lng: -44.6906735,
    lat: -23.222831
  },
  {
    lng: -44.6906252,
    lat: -23.2229493
  },
  {
    lng: -44.690675,
    lat: -23.223301
  },
  {
    lng: -44.6906255,
    lat: -23.2235629
  },
  {
    lng: -44.6905415,
    lat: -23.2236633
  },
  {
    lng: -44.6904601,
    lat: -23.2237974
  },
  {
    lng: -44.6904632,
    lat: -23.2239461
  },
  {
    lng: -44.6910579,
    lat: -23.2246847
  },
  {
    lng: -44.6912141,
    lat: -23.2250383
  },
  {
    lng: -44.6917207,
    lat: -23.2255128
  },
  {
    lng: -44.6922595,
    lat: -23.225996
  },
  {
    lng: -44.6927584,
    lat: -23.2263067
  },
  {
    lng: -44.6931392,
    lat: -23.2267601
  },
  {
    lng: -44.6931554,
    lat: -23.2269425
  },
  {
    lng: -44.6930642,
    lat: -23.2270756
  },
  {
    lng: -44.6929558,
    lat: -23.2271949
  },
  {
    lng: -44.6929087,
    lat: -23.2277031
  },
  {
    lng: -44.6929142,
    lat: -23.228015
  },
  {
    lng: -44.6931694,
    lat: -23.2283935
  },
  {
    lng: -44.6933202,
    lat: -23.2285444
  },
  {
    lng: -44.6934567,
    lat: -23.2285967
  },
  {
    lng: -44.6938305,
    lat: -23.2286505
  },
  {
    lng: -44.6938198,
    lat: -23.2287917
  },
  {
    lng: -44.6941819,
    lat: -23.2287942
  },
  {
    lng: -44.6942088,
    lat: -23.2288927
  },
  {
    lng: -44.6940398,
    lat: -23.2291959
  },
  {
    lng: -44.6940183,
    lat: -23.2293364
  },
  {
    lng: -44.6940531,
    lat: -23.2294769
  },
  {
    lng: -44.6942866,
    lat: -23.2299156
  },
  {
    lng: -44.6942678,
    lat: -23.2300611
  },
  {
    lng: -44.6943375,
    lat: -23.2302163
  },
  {
    lng: -44.6941498,
    lat: -23.2302311
  },
  {
    lng: -44.6939808,
    lat: -23.230586
  },
  {
    lng: -44.6940747,
    lat: -23.2309237
  },
  {
    lng: -44.694364,
    lat: -23.2314573
  },
  {
    lng: -44.6944663,
    lat: -23.2318332
  },
  {
    lng: -44.694437,
    lat: -23.2319704
  },
  {
    lng: -44.6944149,
    lat: -23.2321145
  },
  {
    lng: -44.6944979,
    lat: -23.232234
  },
  {
    lng: -44.6946227,
    lat: -23.2323334
  },
  {
    lng: -44.6948994,
    lat: -23.2324668
  },
  {
    lng: -44.6948327,
    lat: -23.2325753
  },
  {
    lng: -44.6948451,
    lat: -23.2326485
  },
  {
    lng: -44.6950137,
    lat: -23.2327322
  },
  {
    lng: -44.6951476,
    lat: -23.2327939
  },
  {
    lng: -44.6953991,
    lat: -23.23288
  },
  {
    lng: -44.6956183,
    lat: -23.232929
  },
  {
    lng: -44.6962809,
    lat: -23.2330572
  },
  {
    lng: -44.6964965,
    lat: -23.2330573
  },
  {
    lng: -44.6973473,
    lat: -23.2330577
  },
  {
    lng: -44.6973661,
    lat: -23.2330315
  },
  {
    lng: -44.6975873,
    lat: -23.2329932
  },
  {
    lng: -44.6981685,
    lat: -23.232707
  },
  {
    lng: -44.6984341,
    lat: -23.2325624
  },
  {
    lng: -44.698665,
    lat: -23.2323865
  },
  {
    lng: -44.6987056,
    lat: -23.2323967
  },
  {
    lng: -44.6987537,
    lat: -23.2325697
  },
  {
    lng: -44.6995034,
    lat: -23.2327385
  },
  {
    lng: -44.6998648,
    lat: -23.2328505
  },
  {
    lng: -44.6999541,
    lat: -23.2328583
  },
  {
    lng: -44.70023,
    lat: -23.2328554
  },
  {
    lng: -44.7003554,
    lat: -23.2328847
  },
  {
    lng: -44.7004432,
    lat: -23.2330615
  },
  {
    lng: -44.7005384,
    lat: -23.233159
  },
  {
    lng: -44.7006687,
    lat: -23.2333305
  },
  {
    lng: -44.701062,
    lat: -23.2333392
  },
  {
    lng: -44.7015046,
    lat: -23.2333241
  },
  {
    lng: -44.7017477,
    lat: -23.233301
  },
  {
    lng: -44.7017746,
    lat: -23.2332561
  },
  {
    lng: -44.7023512,
    lat: -23.2333159
  },
  {
    lng: -44.7023376,
    lat: -23.2334454
  },
  {
    lng: -44.7028176,
    lat: -23.2334718
  },
  {
    lng: -44.7032761,
    lat: -23.233497
  },
  {
    lng: -44.7041629,
    lat: -23.2335457
  },
  {
    lng: -44.704399,
    lat: -23.2335587
  },
  {
    lng: -44.7046653,
    lat: -23.2336354
  },
  {
    lng: -44.7050443,
    lat: -23.2337474
  },
  {
    lng: -44.7050157,
    lat: -23.2338935
  },
  {
    lng: -44.7054291,
    lat: -23.2339079
  },
  {
    lng: -44.7058322,
    lat: -23.2338926
  },
  {
    lng: -44.7058253,
    lat: -23.2339592
  },
  {
    lng: -44.7060906,
    lat: -23.2340053
  },
  {
    lng: -44.7061613,
    lat: -23.2339119
  },
  {
    lng: -44.7067101,
    lat: -23.2339595
  },
  {
    lng: -44.7066708,
    lat: -23.2342858
  },
  {
    lng: -44.7080938,
    lat: -23.2342868
  },
  {
    lng: -44.7083075,
    lat: -23.2343192
  },
  {
    lng: -44.7084952,
    lat: -23.2342798
  },
  {
    lng: -44.7087332,
    lat: -23.2342298
  },
  {
    lng: -44.7087644,
    lat: -23.2342973
  },
  {
    lng: -44.7087771,
    lat: -23.2343745
  },
  {
    lng: -44.7088121,
    lat: -23.2343771
  },
  {
    lng: -44.7088163,
    lat: -23.2344431
  },
  {
    lng: -44.7089076,
    lat: -23.2344307
  },
  {
    lng: -44.7089252,
    lat: -23.2341862
  },
  {
    lng: -44.7091488,
    lat: -23.2342072
  },
  {
    lng: -44.70925,
    lat: -23.2342167
  },
  {
    lng: -44.7094615,
    lat: -23.2343877
  },
  {
    lng: -44.7095543,
    lat: -23.2346128
  },
  {
    lng: -44.7097351,
    lat: -23.2347479
  },
  {
    lng: -44.7102563,
    lat: -23.2349951
  },
  {
    lng: -44.7111128,
    lat: -23.2346492
  },
  {
    lng: -44.7116854,
    lat: -23.2347232
  },
  {
    lng: -44.7122797,
    lat: -23.2345938
  },
  {
    lng: -44.7128127,
    lat: -23.2342711
  },
  {
    lng: -44.713269,
    lat: -23.2339915
  },
  {
    lng: -44.7135236,
    lat: -23.2334742
  },
  {
    lng: -44.7134837,
    lat: -23.2332105
  },
  {
    lng: -44.7131563,
    lat: -23.2329437
  },
  {
    lng: -44.7151409,
    lat: -23.2309867
  },
  {
    lng: -44.7152657,
    lat: -23.2308636
  },
  {
    lng: -44.7150797,
    lat: -23.2305928
  },
  {
    lng: -44.7149081,
    lat: -23.2303809
  },
  {
    lng: -44.7148946,
    lat: -23.2302083
  },
  {
    lng: -44.7148061,
    lat: -23.2299298
  },
  {
    lng: -44.7146612,
    lat: -23.2298412
  },
  {
    lng: -44.7146093,
    lat: -23.2295661
  },
  {
    lng: -44.7148483,
    lat: -23.2295665
  },
  {
    lng: -44.7147892,
    lat: -23.2292662
  },
  {
    lng: -44.7146174,
    lat: -23.22871
  },
  {
    lng: -44.7145899,
    lat: -23.2282133
  },
  {
    lng: -44.7144253,
    lat: -23.2283097
  },
  {
    lng: -44.7140592,
    lat: -23.2284022
  },
  {
    lng: -44.7135668,
    lat: -23.2281528
  },
  {
    lng: -44.7132991,
    lat: -23.2276416
  },
  {
    lng: -44.7130529,
    lat: -23.2275411
  },
  {
    lng: -44.7127913,
    lat: -23.2276373
  },
  {
    lng: -44.7125394,
    lat: -23.2275184
  },
  {
    lng: -44.7125456,
    lat: -23.2273158
  },
  {
    lng: -44.7117252,
    lat: -23.2268588
  },
  {
    lng: -44.7117279,
    lat: -23.2267263
  },
  {
    lng: -44.7117334,
    lat: -23.2264723
  },
  {
    lng: -44.7117116,
    lat: -23.2261653
  },
  {
    lng: -44.7117853,
    lat: -23.2248955
  },
  {
    lng: -44.7120997,
    lat: -23.2245062
  },
  {
    lng: -44.7122905,
    lat: -23.2243443
  },
  {
    lng: -44.712442,
    lat: -23.2242732
  },
  {
    lng: -44.7136206,
    lat: -23.222751
  },
  {
    lng: -44.7137144,
    lat: -23.2227905
  },
  {
    lng: -44.713752,
    lat: -23.2226598
  },
  {
    lng: -44.7138311,
    lat: -23.2225477
  },
  {
    lng: -44.7138922,
    lat: -23.2225393
  },
  {
    lng: -44.7140348,
    lat: -23.2225336
  },
  {
    lng: -44.7140387,
    lat: -23.2224778
  },
  {
    lng: -44.714006,
    lat: -23.2224429
  },
  {
    lng: -44.7140279,
    lat: -23.2222804
  },
  {
    lng: -44.7139527,
    lat: -23.2222308
  },
  {
    lng: -44.7139827,
    lat: -23.2221232
  },
  {
    lng: -44.7143498,
    lat: -23.221722
  },
  {
    lng: -44.7146969,
    lat: -23.2217917
  },
  {
    lng: -44.714787,
    lat: -23.2215295
  },
  {
    lng: -44.714491,
    lat: -23.2214619
  },
  {
    lng: -44.7143623,
    lat: -23.2214434
  },
  {
    lng: -44.7142818,
    lat: -23.2214495
  },
  {
    lng: -44.7139309,
    lat: -23.2214949
  },
  {
    lng: -44.7138656,
    lat: -23.2214445
  },
  {
    lng: -44.7134214,
    lat: -23.2211027
  },
  {
    lng: -44.7126566,
    lat: -23.2208287
  },
  {
    lng: -44.7120692,
    lat: -23.2209743
  },
  {
    lng: -44.7119349,
    lat: -23.2205862
  },
  {
    lng: -44.7117448,
    lat: -23.2205177
  },
  {
    lng: -44.7113841,
    lat: -23.2203845
  },
  {
    lng: -44.7101042,
    lat: -23.2210834
  },
  {
    lng: -44.709988,
    lat: -23.2211111
  },
  {
    lng: -44.709955,
    lat: -23.221102
  },
  {
    lng: -44.7098727,
    lat: -23.2210792
  },
  {
    lng: -44.709836,
    lat: -23.2210033
  },
  {
    lng: -44.7097655,
    lat: -23.2208567
  },
  {
    lng: -44.7096616,
    lat: -23.220662
  },
  {
    lng: -44.7095275,
    lat: -23.2204796
  },
  {
    lng: -44.7093612,
    lat: -23.2203563
  },
  {
    lng: -44.7091466,
    lat: -23.2201197
  },
  {
    lng: -44.7089723,
    lat: -23.2198831
  },
  {
    lng: -44.708924,
    lat: -23.2196563
  },
  {
    lng: -44.7090152,
    lat: -23.2194295
  },
  {
    lng: -44.7090715,
    lat: -23.2192742
  },
  {
    lng: -44.7090903,
    lat: -23.219183
  },
  {
    lng: -44.709152,
    lat: -23.2190918
  },
  {
    lng: -44.7092432,
    lat: -23.2189661
  },
  {
    lng: -44.7094846,
    lat: -23.2186654
  },
  {
    lng: -44.7096831,
    lat: -23.2183844
  },
  {
    lng: -44.709903,
    lat: -23.2180393
  },
  {
    lng: -44.7102544,
    lat: -23.2177336
  },
  {
    lng: -44.7106621,
    lat: -23.217603
  },
  {
    lng: -44.7108043,
    lat: -23.2175931
  },
  {
    lng: -44.7110101,
    lat: -23.2175795
  },
  {
    lng: -44.7110645,
    lat: -23.2175759
  },
  {
    lng: -44.7111262,
    lat: -23.2174304
  },
  {
    lng: -44.7111879,
    lat: -23.2173047
  },
  {
    lng: -44.711228,
    lat: -23.2172465
  },
  {
    lng: -44.7112764,
    lat: -23.2171765
  },
  {
    lng: -44.7113595,
    lat: -23.2171174
  },
  {
    lng: -44.7116889,
    lat: -23.2171081
  },
  {
    lng: -44.7118852,
    lat: -23.2171026
  },
  {
    lng: -44.7140085,
    lat: -23.217354
  },
  {
    lng: -44.7140036,
    lat: -23.2171958
  },
  {
    lng: -44.7140035,
    lat: -23.2171931
  },
  {
    lng: -44.7140016,
    lat: -23.2170356
  },
  {
    lng: -44.7127806,
    lat: -23.2167543
  },
  {
    lng: -44.7125503,
    lat: -23.2167057
  },
  {
    lng: -44.711376,
    lat: -23.2164585
  },
  {
    lng: -44.7105718,
    lat: -23.2162893
  },
  {
    lng: -44.7103495,
    lat: -23.2162447
  },
  {
    lng: -44.7103912,
    lat: -23.2162078
  },
  {
    lng: -44.7109304,
    lat: -23.2155299
  },
  {
    lng: -44.7111771,
    lat: -23.2152703
  },
  {
    lng: -44.711524,
    lat: -23.214957
  },
  {
    lng: -44.7118224,
    lat: -23.2146571
  },
  {
    lng: -44.7120656,
    lat: -23.2143918
  },
  {
    lng: -44.7121037,
    lat: -23.2142439
  },
  {
    lng: -44.7121223,
    lat: -23.2141289
  },
  {
    lng: -44.7121309,
    lat: -23.2141109
  },
  {
    lng: -44.7119389,
    lat: -23.2139621
  },
  {
    lng: -44.7117966,
    lat: -23.2138142
  },
  {
    lng: -44.7115245,
    lat: -23.2136737
  },
  {
    lng: -44.7111409,
    lat: -23.2133693
  },
  {
    lng: -44.7109004,
    lat: -23.2131179
  },
  {
    lng: -44.7106959,
    lat: -23.2127196
  },
  {
    lng: -44.7108321,
    lat: -23.2123957
  },
  {
    lng: -44.710986,
    lat: -23.2122015
  },
  {
    lng: -44.7109438,
    lat: -23.2120985
  },
  {
    lng: -44.7103416,
    lat: -23.2116105
  },
  {
    lng: -44.7102778,
    lat: -23.2114234
  },
  {
    lng: -44.7102727,
    lat: -23.2112296
  },
  {
    lng: -44.7102887,
    lat: -23.2110243
  },
  {
    lng: -44.7103395,
    lat: -23.2109553
  },
  {
    lng: -44.7104497,
    lat: -23.2109187
  },
  {
    lng: -44.7104386,
    lat: -23.2108652
  },
  {
    lng: -44.710463,
    lat: -23.210855
  },
  {
    lng: -44.710799,
    lat: -23.2110826
  },
  {
    lng: -44.71085,
    lat: -23.2110192
  },
  {
    lng: -44.7108042,
    lat: -23.2109132
  },
  {
    lng: -44.7107972,
    lat: -23.2108241
  },
  {
    lng: -44.7111978,
    lat: -23.210801
  },
  {
    lng: -44.7115573,
    lat: -23.2109767
  },
  {
    lng: -44.7118814,
    lat: -23.2112001
  },
  {
    lng: -44.7119914,
    lat: -23.2113792
  },
  {
    lng: -44.7121728,
    lat: -23.21153
  },
  {
    lng: -44.7124995,
    lat: -23.2115119
  },
  {
    lng: -44.7137169,
    lat: -23.2116236
  },
  {
    lng: -44.714165,
    lat: -23.2112852
  },
  {
    lng: -44.7145193,
    lat: -23.2109159
  },
  {
    lng: -44.7147793,
    lat: -23.2109252
  },
  {
    lng: -44.7149379,
    lat: -23.210985
  },
  {
    lng: -44.7149775,
    lat: -23.2109873
  },
  {
    lng: -44.7150332,
    lat: -23.2109964
  },
  {
    lng: -44.7150906,
    lat: -23.2110177
  },
  {
    lng: -44.7150945,
    lat: -23.2109498
  },
  {
    lng: -44.7151334,
    lat: -23.2108131
  },
  {
    lng: -44.7152045,
    lat: -23.2106478
  },
  {
    lng: -44.7152769,
    lat: -23.2104679
  },
  {
    lng: -44.7153828,
    lat: -23.2102855
  },
  {
    lng: -44.715482,
    lat: -23.2100661
  },
  {
    lng: -44.7155571,
    lat: -23.2098319
  },
  {
    lng: -44.7156054,
    lat: -23.2097333
  },
  {
    lng: -44.715659,
    lat: -23.2096545
  },
  {
    lng: -44.7156966,
    lat: -23.2096026
  },
  {
    lng: -44.7157221,
    lat: -23.2095558
  },
  {
    lng: -44.7157355,
    lat: -23.2094905
  },
  {
    lng: -44.7157489,
    lat: -23.2094165
  },
  {
    lng: -44.715777,
    lat: -23.2093228
  },
  {
    lng: -44.7158615,
    lat: -23.2091675
  },
  {
    lng: -44.7159353,
    lat: -23.2089296
  },
  {
    lng: -44.7160386,
    lat: -23.2086351
  },
  {
    lng: -44.7161512,
    lat: -23.2082813
  },
  {
    lng: -44.7162826,
    lat: -23.2079522
  },
  {
    lng: -44.7164691,
    lat: -23.2077008
  },
  {
    lng: -44.7166515,
    lat: -23.207453
  },
  {
    lng: -44.7169586,
    lat: -23.2071103
  },
  {
    lng: -44.7173207,
    lat: -23.2067369
  },
  {
    lng: -44.7177364,
    lat: -23.2062599
  },
  {
    lng: -44.7179858,
    lat: -23.2060183
  },
  {
    lng: -44.7181897,
    lat: -23.2058396
  },
  {
    lng: -44.7184713,
    lat: -23.2055782
  },
  {
    lng: -44.7186698,
    lat: -23.2053625
  },
  {
    lng: -44.7188522,
    lat: -23.2051148
  },
  {
    lng: -44.719221,
    lat: -23.2046303
  },
  {
    lng: -44.7194932,
    lat: -23.2042889
  },
  {
    lng: -44.7197106,
    lat: -23.2039154
  },
  {
    lng: -44.7200552,
    lat: -23.2033361
  },
  {
    lng: -44.7203275,
    lat: -23.2028652
  },
  {
    lng: -44.7205313,
    lat: -23.202546
  },
  {
    lng: -44.7206688,
    lat: -23.2023211
  },
  {
    lng: -44.7208397,
    lat: -23.2020381
  },
  {
    lng: -44.7209157,
    lat: -23.2018814
  },
  {
    lng: -44.7209853,
    lat: -23.2017017
  },
  {
    lng: -44.7210067,
    lat: -23.2015693
  },
  {
    lng: -44.7209863,
    lat: -23.2006906
  },
  {
    lng: -44.7209996,
    lat: -23.2004216
  },
  {
    lng: -44.7210448,
    lat: -23.1995105
  },
  {
    lng: -44.7210354,
    lat: -23.1985541
  },
  {
    lng: -44.7206802,
    lat: -23.1963629
  },
  {
    lng: -44.720015,
    lat: -23.1949527
  },
  {
    lng: -44.7194035,
    lat: -23.1943216
  },
  {
    lng: -44.7188171,
    lat: -23.1939163
  },
  {
    lng: -44.7181804,
    lat: -23.1936855
  },
  {
    lng: -44.7176872,
    lat: -23.19386
  },
  {
    lng: -44.7174071,
    lat: -23.194429
  },
  {
    lng: -44.716909,
    lat: -23.1949922
  },
  {
    lng: -44.7159287,
    lat: -23.1946781
  },
  {
    lng: -44.7154937,
    lat: -23.1943787
  },
  {
    lng: -44.715246,
    lat: -23.1942082
  },
  {
    lng: -44.7142646,
    lat: -23.1940518
  },
  {
    lng: -44.7141248,
    lat: -23.1938754
  },
  {
    lng: -44.7141356,
    lat: -23.1936165
  },
  {
    lng: -44.7141946,
    lat: -23.1928719
  },
  {
    lng: -44.7138942,
    lat: -23.1924084
  },
  {
    lng: -44.7140712,
    lat: -23.1916145
  },
  {
    lng: -44.715011,
    lat: -23.1913579
  },
  {
    lng: -44.7151922,
    lat: -23.1908999
  },
  {
    lng: -44.7148122,
    lat: -23.1903212
  },
  {
    lng: -44.7142133,
    lat: -23.1897467
  },
  {
    lng: -44.7137264,
    lat: -23.1895031
  },
  {
    lng: -44.7135025,
    lat: -23.1893415
  },
  {
    lng: -44.7131592,
    lat: -23.1895042
  },
  {
    lng: -44.7127212,
    lat: -23.1894912
  },
  {
    lng: -44.7124082,
    lat: -23.1899455
  },
  {
    lng: -44.7121796,
    lat: -23.1901069
  },
  {
    lng: -44.7118885,
    lat: -23.190206
  },
  {
    lng: -44.7113406,
    lat: -23.1902617
  },
  {
    lng: -44.7111542,
    lat: -23.1903652
  },
  {
    lng: -44.710751,
    lat: -23.1901559
  },
  {
    lng: -44.7098856,
    lat: -23.1893838
  },
  {
    lng: -44.7092707,
    lat: -23.1889291
  },
  {
    lng: -44.7088614,
    lat: -23.1886256
  },
  {
    lng: -44.7087516,
    lat: -23.1882301
  },
  {
    lng: -44.708356,
    lat: -23.187917
  },
  {
    lng: -44.7083913,
    lat: -23.1874663
  },
  {
    lng: -44.7078089,
    lat: -23.1871693
  },
  {
    lng: -44.7075206,
    lat: -23.1867877
  },
  {
    lng: -44.7071933,
    lat: -23.1865967
  },
  {
    lng: -44.7068822,
    lat: -23.1861047
  },
  {
    lng: -44.7068822,
    lat: -23.1858483
  },
  {
    lng: -44.706555,
    lat: -23.1855204
  },
  {
    lng: -44.7064814,
    lat: -23.1853068
  },
  {
    lng: -44.7066435,
    lat: -23.1850396
  },
  {
    lng: -44.7069587,
    lat: -23.1849767
  },
  {
    lng: -44.7074428,
    lat: -23.1849792
  },
  {
    lng: -44.7079758,
    lat: -23.1847568
  },
  {
    lng: -44.7088925,
    lat: -23.1849152
  },
  {
    lng: -44.7098581,
    lat: -23.1848338
  },
  {
    lng: -44.7103963,
    lat: -23.1850488
  },
  {
    lng: -44.7106618,
    lat: -23.1849428
  },
  {
    lng: -44.7113592,
    lat: -23.1849699
  },
  {
    lng: -44.7119654,
    lat: -23.1851918
  },
  {
    lng: -44.7124826,
    lat: -23.1852176
  },
  {
    lng: -44.7129041,
    lat: -23.1858329
  },
  {
    lng: -44.7131244,
    lat: -23.18644
  },
  {
    lng: -44.7135747,
    lat: -23.1866761
  },
  {
    lng: -44.7137303,
    lat: -23.1869498
  },
  {
    lng: -44.7138268,
    lat: -23.1873393
  },
  {
    lng: -44.7144278,
    lat: -23.1879119
  },
  {
    lng: -44.7149452,
    lat: -23.1876365
  },
  {
    lng: -44.7154308,
    lat: -23.1870435
  },
  {
    lng: -44.7159788,
    lat: -23.1865754
  },
  {
    lng: -44.7165152,
    lat: -23.1860149
  },
  {
    lng: -44.717244,
    lat: -23.184928
  },
  {
    lng: -44.717064,
    lat: -23.1835232
  },
  {
    lng: -44.7166682,
    lat: -23.1833639
  },
  {
    lng: -44.7164414,
    lat: -23.1827208
  },
  {
    lng: -44.7159726,
    lat: -23.1823441
  },
  {
    lng: -44.7153499,
    lat: -23.1821393
  },
  {
    lng: -44.7150687,
    lat: -23.1821074
  },
  {
    lng: -44.7147441,
    lat: -23.1823219
  },
  {
    lng: -44.7140763,
    lat: -23.1823761
  },
  {
    lng: -44.7132344,
    lat: -23.1825899
  },
  {
    lng: -44.7127532,
    lat: -23.183048
  },
  {
    lng: -44.7123037,
    lat: -23.1828578
  },
  {
    lng: -44.7116495,
    lat: -23.1821332
  },
  {
    lng: -44.710814,
    lat: -23.1813198
  },
  {
    lng: -44.7107876,
    lat: -23.1810913
  },
  {
    lng: -44.7108002,
    lat: -23.1808207
  },
  {
    lng: -44.7108722,
    lat: -23.1806213
  },
  {
    lng: -44.7111093,
    lat: -23.1804603
  },
  {
    lng: -44.7111118,
    lat: -23.1803557
  },
  {
    lng: -44.7109193,
    lat: -23.1799129
  },
  {
    lng: -44.7110534,
    lat: -23.1794789
  },
  {
    lng: -44.7113377,
    lat: -23.1793458
  },
  {
    lng: -44.7108735,
    lat: -23.1778753
  },
  {
    lng: -44.7108016,
    lat: -23.1775605
  },
  {
    lng: -44.7106055,
    lat: -23.1775877
  },
  {
    lng: -44.7105506,
    lat: -23.1774895
  },
  {
    lng: -44.710517,
    lat: -23.1773683
  },
  {
    lng: -44.7105664,
    lat: -23.1771246
  },
  {
    lng: -44.7105097,
    lat: -23.1770336
  },
  {
    lng: -44.7097702,
    lat: -23.1762847
  },
  {
    lng: -44.7096493,
    lat: -23.176193
  },
  {
    lng: -44.7095896,
    lat: -23.1761952
  },
  {
    lng: -44.709308,
    lat: -23.1762055
  },
  {
    lng: -44.7091557,
    lat: -23.1761255
  },
  {
    lng: -44.7088401,
    lat: -23.1756048
  },
  {
    lng: -44.7088239,
    lat: -23.1754175
  },
  {
    lng: -44.708769,
    lat: -23.1753441
  },
  {
    lng: -44.7086717,
    lat: -23.1752805
  },
  {
    lng: -44.7086458,
    lat: -23.1746581
  },
  {
    lng: -44.7080289,
    lat: -23.1742594
  },
  {
    lng: -44.7074544,
    lat: -23.1739972
  },
  {
    lng: -44.7066441,
    lat: -23.1738054
  },
  {
    lng: -44.7061883,
    lat: -23.173782
  },
  {
    lng: -44.7058542,
    lat: -23.1738705
  },
  {
    lng: -44.705608,
    lat: -23.1741615
  },
  {
    lng: -44.7053271,
    lat: -23.1746737
  },
  {
    lng: -44.7048466,
    lat: -23.1750029
  },
  {
    lng: -44.7044998,
    lat: -23.1751761
  },
  {
    lng: -44.703428,
    lat: -23.1766115
  },
  {
    lng: -44.7032925,
    lat: -23.1766971
  },
  {
    lng: -44.7029528,
    lat: -23.1766936
  },
  {
    lng: -44.7022543,
    lat: -23.1762861
  },
  {
    lng: -44.7019993,
    lat: -23.1756628
  },
  {
    lng: -44.7016609,
    lat: -23.1752719
  },
  {
    lng: -44.7010357,
    lat: -23.1749607
  },
  {
    lng: -44.7006517,
    lat: -23.1748643
  },
  {
    lng: -44.7000485,
    lat: -23.1749257
  },
  {
    lng: -44.6997648,
    lat: -23.1748761
  },
  {
    lng: -44.6995356,
    lat: -23.1747583
  },
  {
    lng: -44.6994236,
    lat: -23.1747385
  },
  {
    lng: -44.6994204,
    lat: -23.1748711
  },
  {
    lng: -44.6993304,
    lat: -23.1749243
  },
  {
    lng: -44.6989327,
    lat: -23.1748693
  },
  {
    lng: -44.6987337,
    lat: -23.1747578
  },
  {
    lng: -44.6984888,
    lat: -23.1747338
  },
  {
    lng: -44.6983582,
    lat: -23.174633
  },
  {
    lng: -44.6983312,
    lat: -23.1744355
  },
  {
    lng: -44.6984443,
    lat: -23.1742963
  },
  {
    lng: -44.6984614,
    lat: -23.1741171
  },
  {
    lng: -44.698761,
    lat: -23.1738221
  },
  {
    lng: -44.6988804,
    lat: -23.1736097
  },
  {
    lng: -44.6989775,
    lat: -23.1728097
  },
  {
    lng: -44.6990854,
    lat: -23.1726565
  },
  {
    lng: -44.6992168,
    lat: -23.1724958
  },
  {
    lng: -44.6993103,
    lat: -23.1724249
  },
  {
    lng: -44.6995201,
    lat: -23.1723653
  },
  {
    lng: -44.6993739,
    lat: -23.1716067
  },
  {
    lng: -44.6990063,
    lat: -23.1706641
  },
  {
    lng: -44.6985798,
    lat: -23.17053
  },
  {
    lng: -44.6984534,
    lat: -23.1704716
  },
  {
    lng: -44.6983081,
    lat: -23.170487
  },
  {
    lng: -44.6981821,
    lat: -23.1705955
  },
  {
    lng: -44.69801,
    lat: -23.1705609
  },
  {
    lng: -44.6979827,
    lat: -23.17045
  },
  {
    lng: -44.6979165,
    lat: -23.1702895
  },
  {
    lng: -44.6980145,
    lat: -23.1701083
  },
  {
    lng: -44.6980452,
    lat: -23.1700237
  },
  {
    lng: -44.698107,
    lat: -23.1700738
  },
  {
    lng: -44.6981892,
    lat: -23.1698056
  },
  {
    lng: -44.6981421,
    lat: -23.1697328
  },
  {
    lng: -44.6980942,
    lat: -23.1696425
  },
  {
    lng: -44.6981455,
    lat: -23.1692894
  },
  {
    lng: -44.6984569,
    lat: -23.1691
  },
  {
    lng: -44.6985639,
    lat: -23.1689694
  },
  {
    lng: -44.6987063,
    lat: -23.1686453
  },
  {
    lng: -44.6989431,
    lat: -23.168531
  },
  {
    lng: -44.6992097,
    lat: -23.1684985
  },
  {
    lng: -44.6994532,
    lat: -23.1685407
  },
  {
    lng: -44.6995846,
    lat: -23.1685185
  },
  {
    lng: -44.700176,
    lat: -23.1685439
  },
  {
    lng: -44.7004707,
    lat: -23.1686987
  },
  {
    lng: -44.7006713,
    lat: -23.1688779
  },
  {
    lng: -44.7007729,
    lat: -23.1688667
  },
  {
    lng: -44.7010701,
    lat: -23.1688246
  },
  {
    lng: -44.7012726,
    lat: -23.1687426
  },
  {
    lng: -44.7014388,
    lat: -23.1686119
  },
  {
    lng: -44.7015039,
    lat: -23.1685118
  },
  {
    lng: -44.7016616,
    lat: -23.1683916
  },
  {
    lng: -44.7021637,
    lat: -23.1685268
  },
  {
    lng: -44.7023017,
    lat: -23.168775
  },
  {
    lng: -44.7023607,
    lat: -23.1689081
  },
  {
    lng: -44.7028327,
    lat: -23.1691843
  },
  {
    lng: -44.7031316,
    lat: -23.1694884
  },
  {
    lng: -44.7035536,
    lat: -23.1695591
  },
  {
    lng: -44.7044259,
    lat: -23.1688711
  },
  {
    lng: -44.7055043,
    lat: -23.1680451
  },
  {
    lng: -44.7066589,
    lat: -23.1667831
  },
  {
    lng: -44.707047,
    lat: -23.1658493
  },
  {
    lng: -44.7070517,
    lat: -23.1653451
  },
  {
    lng: -44.7068402,
    lat: -23.1651392
  },
  {
    lng: -44.7068083,
    lat: -23.1648326
  },
  {
    lng: -44.7073253,
    lat: -23.1646207
  },
  {
    lng: -44.7075481,
    lat: -23.1634853
  },
  {
    lng: -44.706622,
    lat: -23.1626663
  },
  {
    lng: -44.7060744,
    lat: -23.162742
  },
  {
    lng: -44.7056652,
    lat: -23.1623249
  },
  {
    lng: -44.7058237,
    lat: -23.1619079
  },
  {
    lng: -44.7068189,
    lat: -23.1614164
  },
  {
    lng: -44.7069076,
    lat: -23.1584218
  },
  {
    lng: -44.7062897,
    lat: -23.1568375
  },
  {
    lng: -44.7054014,
    lat: -23.1557255
  },
  {
    lng: -44.7046545,
    lat: -23.1554444
  },
  {
    lng: -44.7042356,
    lat: -23.1553117
  },
  {
    lng: -44.7039595,
    lat: -23.1553433
  },
  {
    lng: -44.7028354,
    lat: -23.1552646
  },
  {
    lng: -44.7025239,
    lat: -23.1552334
  },
  {
    lng: -44.7021568,
    lat: -23.1552676
  },
  {
    lng: -44.7018511,
    lat: -23.1553286
  },
  {
    lng: -44.701645,
    lat: -23.155309
  },
  {
    lng: -44.7015523,
    lat: -23.1554768
  },
  {
    lng: -44.7012118,
    lat: -23.1554825
  },
  {
    lng: -44.7010334,
    lat: -23.1555225
  },
  {
    lng: -44.7005578,
    lat: -23.1557011
  },
  {
    lng: -44.700422,
    lat: -23.1557542
  },
  {
    lng: -44.7003477,
    lat: -23.1557495
  },
  {
    lng: -44.7003109,
    lat: -23.1556991
  },
  {
    lng: -44.7001167,
    lat: -23.1556773
  },
  {
    lng: -44.7001101,
    lat: -23.1557218
  },
  {
    lng: -44.6995116,
    lat: -23.1556177
  },
  {
    lng: -44.699326,
    lat: -23.1555402
  },
  {
    lng: -44.6992538,
    lat: -23.1554852
  },
  {
    lng: -44.699185,
    lat: -23.1554049
  },
  {
    lng: -44.6990541,
    lat: -23.1553684
  },
  {
    lng: -44.6990053,
    lat: -23.1554835
  },
  {
    lng: -44.6988974,
    lat: -23.1554484
  },
  {
    lng: -44.6989421,
    lat: -23.1552688
  },
  {
    lng: -44.6989103,
    lat: -23.1552486
  },
  {
    lng: -44.6985423,
    lat: -23.1550729
  },
  {
    lng: -44.6982131,
    lat: -23.1547477
  },
  {
    lng: -44.6979218,
    lat: -23.1545776
  },
  {
    lng: -44.6975265,
    lat: -23.1544038
  },
  {
    lng: -44.6969333,
    lat: -23.1541618
  },
  {
    lng: -44.6968417,
    lat: -23.1541938
  },
  {
    lng: -44.6964981,
    lat: -23.1540063
  },
  {
    lng: -44.6964459,
    lat: -23.1539317
  },
  {
    lng: -44.69646,
    lat: -23.1538169
  },
  {
    lng: -44.6962677,
    lat: -23.1538893
  },
  {
    lng: -44.6962335,
    lat: -23.1538355
  },
  {
    lng: -44.6962918,
    lat: -23.1537891
  },
  {
    lng: -44.696265,
    lat: -23.1537391
  },
  {
    lng: -44.6962056,
    lat: -23.153747
  },
  {
    lng: -44.6960177,
    lat: -23.1534233
  },
  {
    lng: -44.6962372,
    lat: -23.1532517
  },
  {
    lng: -44.6962035,
    lat: -23.1532144
  },
  {
    lng: -44.6963529,
    lat: -23.1530273
  },
  {
    lng: -44.6964318,
    lat: -23.1528623
  },
  {
    lng: -44.6964798,
    lat: -23.1528859
  },
  {
    lng: -44.6966189,
    lat: -23.1529459
  },
  {
    lng: -44.6967999,
    lat: -23.1529705
  },
  {
    lng: -44.6972124,
    lat: -23.1531192
  },
  {
    lng: -44.6974052,
    lat: -23.1529289
  },
  {
    lng: -44.6975293,
    lat: -23.1526381
  },
  {
    lng: -44.6974153,
    lat: -23.1520617
  },
  {
    lng: -44.6973291,
    lat: -23.1517325
  },
  {
    lng: -44.6972126,
    lat: -23.1514742
  },
  {
    lng: -44.6971423,
    lat: -23.1513664
  },
  {
    lng: -44.6970567,
    lat: -23.1512533
  },
  {
    lng: -44.6968612,
    lat: -23.1511605
  },
  {
    lng: -44.6967375,
    lat: -23.1511471
  },
  {
    lng: -44.6965807,
    lat: -23.1511279
  },
  {
    lng: -44.6964613,
    lat: -23.151257
  },
  {
    lng: -44.6959007,
    lat: -23.1514757
  },
  {
    lng: -44.6956439,
    lat: -23.1514156
  },
  {
    lng: -44.6954921,
    lat: -23.1514077
  },
  {
    lng: -44.6953351,
    lat: -23.1514734
  },
  {
    lng: -44.695267,
    lat: -23.1514448
  },
  {
    lng: -44.6952333,
    lat: -23.1513748
  },
  {
    lng: -44.6952101,
    lat: -23.1513109
  },
  {
    lng: -44.6949804,
    lat: -23.151109
  },
  {
    lng: -44.6948899,
    lat: -23.1510064
  },
  {
    lng: -44.6948771,
    lat: -23.1507276
  },
  {
    lng: -44.6948345,
    lat: -23.1505743
  },
  {
    lng: -44.6947696,
    lat: -23.1505121
  },
  {
    lng: -44.69474,
    lat: -23.150445
  },
  {
    lng: -44.6947646,
    lat: -23.1503109
  },
  {
    lng: -44.6946944,
    lat: -23.1502787
  },
  {
    lng: -44.6946823,
    lat: -23.1501518
  },
  {
    lng: -44.6947527,
    lat: -23.1500191
  },
  {
    lng: -44.6948841,
    lat: -23.1499818
  },
  {
    lng: -44.6949145,
    lat: -23.1499027
  },
  {
    lng: -44.695254,
    lat: -23.1497157
  },
  {
    lng: -44.6954247,
    lat: -23.1497939
  },
  {
    lng: -44.6954449,
    lat: -23.1497675
  },
  {
    lng: -44.6953672,
    lat: -23.1495544
  },
  {
    lng: -44.6954288,
    lat: -23.1493932
  },
  {
    lng: -44.6955529,
    lat: -23.1486752
  },
  {
    lng: -44.6955179,
    lat: -23.1483478
  },
  {
    lng: -44.6954538,
    lat: -23.1482084
  },
  {
    lng: -44.6954489,
    lat: -23.1481572
  },
  {
    lng: -44.695305,
    lat: -23.1479189
  },
  {
    lng: -44.6950215,
    lat: -23.147667
  },
  {
    lng: -44.694705,
    lat: -23.1474316
  },
  {
    lng: -44.6940977,
    lat: -23.1472994
  },
  {
    lng: -44.6937649,
    lat: -23.147188
  },
  {
    lng: -44.6934205,
    lat: -23.1469836
  },
  {
    lng: -44.6930974,
    lat: -23.1467494
  },
  {
    lng: -44.6929193,
    lat: -23.1463642
  },
  {
    lng: -44.6930603,
    lat: -23.1458825
  },
  {
    lng: -44.6932065,
    lat: -23.1456691
  },
  {
    lng: -44.6933607,
    lat: -23.1455628
  },
  {
    lng: -44.69348,
    lat: -23.1455173
  },
  {
    lng: -44.6937114,
    lat: -23.1454964
  },
  {
    lng: -44.6938536,
    lat: -23.1455147
  },
  {
    lng: -44.6940408,
    lat: -23.1455909
  },
  {
    lng: -44.6941794,
    lat: -23.1456019
  },
  {
    lng: -44.6944147,
    lat: -23.1457071
  },
  {
    lng: -44.6946591,
    lat: -23.1457041
  },
  {
    lng: -44.6947885,
    lat: -23.1456542
  },
  {
    lng: -44.6948791,
    lat: -23.1456429
  },
  {
    lng: -44.6950503,
    lat: -23.1457568
  },
  {
    lng: -44.6952018,
    lat: -23.1457802
  },
  {
    lng: -44.6952963,
    lat: -23.1457671
  },
  {
    lng: -44.6954426,
    lat: -23.1457088
  },
  {
    lng: -44.69556,
    lat: -23.1456196
  },
  {
    lng: -44.6956812,
    lat: -23.1455124
  },
  {
    lng: -44.6958156,
    lat: -23.1453564
  },
  {
    lng: -44.695935,
    lat: -23.1452629
  },
  {
    lng: -44.6961204,
    lat: -23.1450798
  },
  {
    lng: -44.6961595,
    lat: -23.1449415
  },
  {
    lng: -44.6962979,
    lat: -23.1448304
  },
  {
    lng: -44.6963458,
    lat: -23.1447347
  },
  {
    lng: -44.6963815,
    lat: -23.1446346
  },
  {
    lng: -44.6963898,
    lat: -23.1443831
  },
  {
    lng: -44.6964047,
    lat: -23.1442949
  },
  {
    lng: -44.6964751,
    lat: -23.1442235
  },
  {
    lng: -44.6965595,
    lat: -23.1441494
  },
  {
    lng: -44.6967689,
    lat: -23.144046
  },
  {
    lng: -44.6968424,
    lat: -23.1439652
  },
  {
    lng: -44.6969653,
    lat: -23.1437605
  },
  {
    lng: -44.6969978,
    lat: -23.1436364
  },
  {
    lng: -44.6971198,
    lat: -23.1433758
  },
  {
    lng: -44.6972365,
    lat: -23.1431874
  },
  {
    lng: -44.6973781,
    lat: -23.143109
  },
  {
    lng: -44.6974957,
    lat: -23.1431002
  },
  {
    lng: -44.6983678,
    lat: -23.1422211
  },
  {
    lng: -44.6981943,
    lat: -23.141505
  },
  {
    lng: -44.6980922,
    lat: -23.1408304
  },
  {
    lng: -44.6980047,
    lat: -23.1404374
  },
  {
    lng: -44.6980286,
    lat: -23.1393359
  },
  {
    lng: -44.6979969,
    lat: -23.1381028
  },
  {
    lng: -44.6980474,
    lat: -23.1380056
  },
  {
    lng: -44.6980885,
    lat: -23.1378212
  },
  {
    lng: -44.6978841,
    lat: -23.137626
  },
  {
    lng: -44.6976178,
    lat: -23.1372701
  },
  {
    lng: -44.6972186,
    lat: -23.1365158
  },
  {
    lng: -44.6974016,
    lat: -23.1360003
  },
  {
    lng: -44.6977358,
    lat: -23.1358854
  },
  {
    lng: -44.6991482,
    lat: -23.1362343
  },
  {
    lng: -44.6992477,
    lat: -23.1359059
  },
  {
    lng: -44.6995113,
    lat: -23.1357424
  },
  {
    lng: -44.6996418,
    lat: -23.1358134
  },
  {
    lng: -44.6998903,
    lat: -23.135539
  },
  {
    lng: -44.6998842,
    lat: -23.1354284
  },
  {
    lng: -44.6998777,
    lat: -23.1353109
  },
  {
    lng: -44.6998947,
    lat: -23.1342053
  },
  {
    lng: -44.6996684,
    lat: -23.1330625
  },
  {
    lng: -44.6994624,
    lat: -23.131932
  },
  {
    lng: -44.69926,
    lat: -23.1315196
  },
  {
    lng: -44.6991651,
    lat: -23.1312514
  },
  {
    lng: -44.6992134,
    lat: -23.1310416
  },
  {
    lng: -44.6992819,
    lat: -23.130703
  },
  {
    lng: -44.699228,
    lat: -23.1306361
  },
  {
    lng: -44.6990689,
    lat: -23.1306748
  },
  {
    lng: -44.6986561,
    lat: -23.1309095
  },
  {
    lng: -44.6983112,
    lat: -23.1309898
  },
  {
    lng: -44.698081,
    lat: -23.1310083
  },
  {
    lng: -44.6964213,
    lat: -23.1305458
  },
  {
    lng: -44.6958622,
    lat: -23.130659
  },
  {
    lng: -44.6952124,
    lat: -23.1310505
  },
  {
    lng: -44.6949222,
    lat: -23.1312761
  },
  {
    lng: -44.6946191,
    lat: -23.1313652
  },
  {
    lng: -44.6943337,
    lat: -23.1313435
  },
  {
    lng: -44.6939429,
    lat: -23.1311178
  },
  {
    lng: -44.6938151,
    lat: -23.1310402
  },
  {
    lng: -44.6937026,
    lat: -23.1310682
  },
  {
    lng: -44.6935137,
    lat: -23.1311603
  },
  {
    lng: -44.693308,
    lat: -23.1312415
  },
  {
    lng: -44.6931268,
    lat: -23.1312119
  },
  {
    lng: -44.6929051,
    lat: -23.1311609
  },
  {
    lng: -44.6926502,
    lat: -23.1311559
  },
  {
    lng: -44.6920217,
    lat: -23.130947
  },
  {
    lng: -44.6913923,
    lat: -23.1306286
  },
  {
    lng: -44.6900689,
    lat: -23.1296286
  },
  {
    lng: -44.689795,
    lat: -23.1295866
  },
  {
    lng: -44.6892736,
    lat: -23.1289942
  },
  {
    lng: -44.6893693,
    lat: -23.1288769
  },
  {
    lng: -44.6887305,
    lat: -23.1280406
  },
  {
    lng: -44.6885005,
    lat: -23.1274828
  },
  {
    lng: -44.6884102,
    lat: -23.1272147
  },
  {
    lng: -44.6884808,
    lat: -23.1270747
  },
  {
    lng: -44.6883987,
    lat: -23.1268758
  },
  {
    lng: -44.6883302,
    lat: -23.1268298
  },
  {
    lng: -44.6882822,
    lat: -23.1265991
  },
  {
    lng: -44.688251,
    lat: -23.1260336
  },
  {
    lng: -44.6881579,
    lat: -23.125915
  },
  {
    lng: -44.6882254,
    lat: -23.1256813
  },
  {
    lng: -44.6883996,
    lat: -23.1252661
  },
  {
    lng: -44.688429,
    lat: -23.125149
  },
  {
    lng: -44.6886049,
    lat: -23.1250244
  },
  {
    lng: -44.6887796,
    lat: -23.1249504
  },
  {
    lng: -44.6889481,
    lat: -23.1249527
  },
  {
    lng: -44.6894628,
    lat: -23.1246509
  },
  {
    lng: -44.6896925,
    lat: -23.1245161
  },
  {
    lng: -44.6897618,
    lat: -23.124468
  },
  {
    lng: -44.6899274,
    lat: -23.1244717
  },
  {
    lng: -44.6899945,
    lat: -23.1245299
  },
  {
    lng: -44.6900592,
    lat: -23.1244903
  },
  {
    lng: -44.6900332,
    lat: -23.124416
  },
  {
    lng: -44.6900932,
    lat: -23.1243984
  },
  {
    lng: -44.6903495,
    lat: -23.1243823
  },
  {
    lng: -44.6904374,
    lat: -23.1243643
  },
  {
    lng: -44.6905305,
    lat: -23.1242456
  },
  {
    lng: -44.6905712,
    lat: -23.1240955
  },
  {
    lng: -44.6909062,
    lat: -23.1239642
  },
  {
    lng: -44.6911692,
    lat: -23.1238464
  },
  {
    lng: -44.6914236,
    lat: -23.1235141
  },
  {
    lng: -44.6916419,
    lat: -23.1233358
  },
  {
    lng: -44.6917436,
    lat: -23.1230107
  },
  {
    lng: -44.6923367,
    lat: -23.1227884
  },
  {
    lng: -44.6925766,
    lat: -23.1227751
  },
  {
    lng: -44.6931289,
    lat: -23.1228703
  },
  {
    lng: -44.6934213,
    lat: -23.1228944
  },
  {
    lng: -44.6938977,
    lat: -23.122978
  },
  {
    lng: -44.6947415,
    lat: -23.1233012
  },
  {
    lng: -44.6957301,
    lat: -23.1238244
  },
  {
    lng: -44.6967304,
    lat: -23.1241219
  },
  {
    lng: -44.6970955,
    lat: -23.1243343
  },
  {
    lng: -44.6973756,
    lat: -23.1245636
  },
  {
    lng: -44.6975127,
    lat: -23.1245904
  },
  {
    lng: -44.6974923,
    lat: -23.1244468
  },
  {
    lng: -44.6974806,
    lat: -23.1242661
  },
  {
    lng: -44.6976345,
    lat: -23.1238387
  },
  {
    lng: -44.6984411,
    lat: -23.1226152
  },
  {
    lng: -44.6987879,
    lat: -23.121525
  },
  {
    lng: -44.6989988,
    lat: -23.1189938
  },
  {
    lng: -44.6986739,
    lat: -23.1153732
  },
  {
    lng: -44.6975434,
    lat: -23.112313
  },
  {
    lng: -44.6958414,
    lat: -23.1091604
  },
  {
    lng: -44.6954311,
    lat: -23.1088241
  },
  {
    lng: -44.6952337,
    lat: -23.1086018
  },
  {
    lng: -44.6942799,
    lat: -23.1073227
  },
  {
    lng: -44.6941536,
    lat: -23.1066836
  },
  {
    lng: -44.6941504,
    lat: -23.1061792
  },
  {
    lng: -44.6947409,
    lat: -23.1046436
  },
  {
    lng: -44.6951883,
    lat: -23.103283
  },
  {
    lng: -44.695284,
    lat: -23.1025489
  },
  {
    lng: -44.6955572,
    lat: -23.1022088
  },
  {
    lng: -44.6957702,
    lat: -23.1018382
  },
  {
    lng: -44.6955592,
    lat: -23.1016535
  },
  {
    lng: -44.6953364,
    lat: -23.1014053
  },
  {
    lng: -44.6952324,
    lat: -23.0997873
  },
  {
    lng: -44.6949213,
    lat: -23.0983157
  },
  {
    lng: -44.6946675,
    lat: -23.0975864
  },
  {
    lng: -44.6935028,
    lat: -23.0948153
  },
  {
    lng: -44.6916911,
    lat: -23.0914802
  },
  {
    lng: -44.690838,
    lat: -23.0905415
  },
  {
    lng: -44.6902923,
    lat: -23.0901661
  },
  {
    lng: -44.6896786,
    lat: -23.0901354
  },
  {
    lng: -44.6893466,
    lat: -23.0907345
  },
  {
    lng: -44.6892516,
    lat: -23.0910981
  },
  {
    lng: -44.6888326,
    lat: -23.0912987
  },
  {
    lng: -44.6883229,
    lat: -23.0914091
  },
  {
    lng: -44.6875849,
    lat: -23.0913938
  },
  {
    lng: -44.6872175,
    lat: -23.0908106
  },
  {
    lng: -44.6875107,
    lat: -23.090049
  },
  {
    lng: -44.6879042,
    lat: -23.0896746
  },
  {
    lng: -44.6879682,
    lat: -23.0894806
  },
  {
    lng: -44.6878029,
    lat: -23.0888231
  },
  {
    lng: -44.6874748,
    lat: -23.0882503
  },
  {
    lng: -44.6878148,
    lat: -23.088009
  },
  {
    lng: -44.687879,
    lat: -23.0878564
  },
  {
    lng: -44.6883442,
    lat: -23.0876364
  },
  {
    lng: -44.6891914,
    lat: -23.0878049
  },
  {
    lng: -44.6895486,
    lat: -23.0878786
  },
  {
    lng: -44.6898011,
    lat: -23.0873331
  },
  {
    lng: -44.6902421,
    lat: -23.0857351
  },
  {
    lng: -44.6901858,
    lat: -23.0852537
  },
  {
    lng: -44.6911333,
    lat: -23.0828725
  },
  {
    lng: -44.6913068,
    lat: -23.0827701
  },
  {
    lng: -44.6914264,
    lat: -23.0826577
  },
  {
    lng: -44.6911896,
    lat: -23.0826298
  },
  {
    lng: -44.6907118,
    lat: -23.0828682
  },
  {
    lng: -44.689656,
    lat: -23.0825873
  },
  {
    lng: -44.6878851,
    lat: -23.0818688
  },
  {
    lng: -44.6877841,
    lat: -23.0816673
  },
  {
    lng: -44.6867674,
    lat: -23.0812259
  },
  {
    lng: -44.6860513,
    lat: -23.080775
  },
  {
    lng: -44.6856621,
    lat: -23.0805771
  },
  {
    lng: -44.6853205,
    lat: -23.0800524
  },
  {
    lng: -44.6849686,
    lat: -23.0797262
  },
  {
    lng: -44.6846928,
    lat: -23.0793254
  },
  {
    lng: -44.6846585,
    lat: -23.0792089
  },
  {
    lng: -44.6847597,
    lat: -23.0790893
  },
  {
    lng: -44.6846441,
    lat: -23.0790449
  },
  {
    lng: -44.684676,
    lat: -23.0789924
  },
  {
    lng: -44.6849789,
    lat: -23.0789839
  },
  {
    lng: -44.6853071,
    lat: -23.0789482
  },
  {
    lng: -44.6855287,
    lat: -23.0788386
  },
  {
    lng: -44.6857162,
    lat: -23.07872
  },
  {
    lng: -44.6865902,
    lat: -23.078918
  },
  {
    lng: -44.6871562,
    lat: -23.0789724
  },
  {
    lng: -44.6873361,
    lat: -23.0786491
  },
  {
    lng: -44.6874654,
    lat: -23.078233
  },
  {
    lng: -44.6875511,
    lat: -23.0779805
  },
  {
    lng: -44.6875098,
    lat: -23.077818
  },
  {
    lng: -44.6872531,
    lat: -23.076321
  },
  {
    lng: -44.686895,
    lat: -23.075527
  },
  {
    lng: -44.6863859,
    lat: -23.074683
  },
  {
    lng: -44.6856703,
    lat: -23.0736376
  },
  {
    lng: -44.6848465,
    lat: -23.0723373
  },
  {
    lng: -44.6845139,
    lat: -23.0718065
  },
  {
    lng: -44.6842709,
    lat: -23.071621
  },
  {
    lng: -44.6835083,
    lat: -23.0712846
  },
  {
    lng: -44.6826594,
    lat: -23.0710983
  },
  {
    lng: -44.6821474,
    lat: -23.0711457
  },
  {
    lng: -44.6818446,
    lat: -23.0712991
  },
  {
    lng: -44.6814402,
    lat: -23.0715615
  },
  {
    lng: -44.6812438,
    lat: -23.0718472
  },
  {
    lng: -44.6813105,
    lat: -23.0719982
  },
  {
    lng: -44.6818232,
    lat: -23.0723188
  },
  {
    lng: -44.681762,
    lat: -23.0724509
  },
  {
    lng: -44.6818591,
    lat: -23.0726084
  },
  {
    lng: -44.6815753,
    lat: -23.0726126
  },
  {
    lng: -44.6812211,
    lat: -23.0726113
  },
  {
    lng: -44.6806575,
    lat: -23.0725052
  },
  {
    lng: -44.6796906,
    lat: -23.0723307
  },
  {
    lng: -44.6786548,
    lat: -23.0720324
  },
  {
    lng: -44.6777276,
    lat: -23.0717194
  },
  {
    lng: -44.6771995,
    lat: -23.0714442
  },
  {
    lng: -44.6766873,
    lat: -23.0711932
  },
  {
    lng: -44.6765212,
    lat: -23.0710795
  },
  {
    lng: -44.676507,
    lat: -23.0709643
  },
  {
    lng: -44.6763183,
    lat: -23.0708338
  },
  {
    lng: -44.6763015,
    lat: -23.0706842
  },
  {
    lng: -44.6761746,
    lat: -23.070507
  },
  {
    lng: -44.6760497,
    lat: -23.0703547
  },
  {
    lng: -44.6760473,
    lat: -23.0701904
  },
  {
    lng: -44.6762617,
    lat: -23.0700084
  },
  {
    lng: -44.6766351,
    lat: -23.0697177
  },
  {
    lng: -44.6771343,
    lat: -23.0697127
  },
  {
    lng: -44.6776188,
    lat: -23.0696668
  },
  {
    lng: -44.677619,
    lat: -23.0694908
  },
  {
    lng: -44.6775773,
    lat: -23.0690963
  },
  {
    lng: -44.677514,
    lat: -23.0688437
  },
  {
    lng: -44.6773694,
    lat: -23.0684635
  },
  {
    lng: -44.6771666,
    lat: -23.0680797
  },
  {
    lng: -44.6768309,
    lat: -23.0676851
  },
  {
    lng: -44.6760545,
    lat: -23.0675016
  },
  {
    lng: -44.6758415,
    lat: -23.0673932
  },
  {
    lng: -44.6758286,
    lat: -23.0674972
  },
  {
    lng: -44.6758716,
    lat: -23.0675638
  },
  {
    lng: -44.6758114,
    lat: -23.0675841
  },
  {
    lng: -44.6756171,
    lat: -23.0675701
  },
  {
    lng: -44.6754183,
    lat: -23.0675158
  },
  {
    lng: -44.6753908,
    lat: -23.0673597
  },
  {
    lng: -44.675477,
    lat: -23.0672631
  },
  {
    lng: -44.6754967,
    lat: -23.0672062
  },
  {
    lng: -44.67547,
    lat: -23.0671608
  },
  {
    lng: -44.6755274,
    lat: -23.0671249
  },
  {
    lng: -44.6756224,
    lat: -23.0671138
  },
  {
    lng: -44.6757423,
    lat: -23.0669009
  },
  {
    lng: -44.6758079,
    lat: -23.0665711
  },
  {
    lng: -44.6759273,
    lat: -23.06643
  },
  {
    lng: -44.6763642,
    lat: -23.0662742
  },
  {
    lng: -44.6766459,
    lat: -23.0661867
  },
  {
    lng: -44.6772841,
    lat: -23.0657656
  },
  {
    lng: -44.6773501,
    lat: -23.0654993
  },
  {
    lng: -44.6775989,
    lat: -23.0654315
  },
  {
    lng: -44.6780826,
    lat: -23.0655106
  },
  {
    lng: -44.678231,
    lat: -23.0655564
  },
  {
    lng: -44.6783414,
    lat: -23.0657522
  },
  {
    lng: -44.6783486,
    lat: -23.0660516
  },
  {
    lng: -44.6784312,
    lat: -23.0660794
  },
  {
    lng: -44.6785602,
    lat: -23.0655533
  },
  {
    lng: -44.6786313,
    lat: -23.0652163
  },
  {
    lng: -44.6786072,
    lat: -23.0646554
  },
  {
    lng: -44.6781893,
    lat: -23.0635705
  },
  {
    lng: -44.6778629,
    lat: -23.0631855
  },
  {
    lng: -44.6773572,
    lat: -23.0625392
  },
  {
    lng: -44.6765776,
    lat: -23.0617869
  },
  {
    lng: -44.6756922,
    lat: -23.0608118
  },
  {
    lng: -44.6752755,
    lat: -23.060396
  },
  {
    lng: -44.6747913,
    lat: -23.0599595
  },
  {
    lng: -44.6744903,
    lat: -23.0596842
  },
  {
    lng: -44.6739415,
    lat: -23.0591853
  },
  {
    lng: -44.673448,
    lat: -23.0587773
  },
  {
    lng: -44.6725586,
    lat: -23.0580827
  },
  {
    lng: -44.6717042,
    lat: -23.0574365
  },
  {
    lng: -44.6704733,
    lat: -23.0565235
  },
  {
    lng: -44.6704076,
    lat: -23.0563697
  },
  {
    lng: -44.6704718,
    lat: -23.056229
  },
  {
    lng: -44.669995,
    lat: -23.0559047
  },
  {
    lng: -44.669884,
    lat: -23.0558153
  },
  {
    lng: -44.6697773,
    lat: -23.0558247
  },
  {
    lng: -44.6696046,
    lat: -23.0558122
  },
  {
    lng: -44.6694983,
    lat: -23.055732
  },
  {
    lng: -44.669458,
    lat: -23.0557144
  },
  {
    lng: -44.6692075,
    lat: -23.0554704
  },
  {
    lng: -44.6680946,
    lat: -23.0549138
  },
  {
    lng: -44.6675056,
    lat: -23.0547404
  },
  {
    lng: -44.666982,
    lat: -23.0545494
  },
  {
    lng: -44.6662871,
    lat: -23.0541548
  },
  {
    lng: -44.6658377,
    lat: -23.0539546
  },
  {
    lng: -44.6657532,
    lat: -23.053935
  },
  {
    lng: -44.664701,
    lat: -23.053689
  },
  {
    lng: -44.6641711,
    lat: -23.0535734
  },
  {
    lng: -44.6635886,
    lat: -23.0535484
  },
  {
    lng: -44.6631136,
    lat: -23.0538959
  },
  {
    lng: -44.6619361,
    lat: -23.0547206
  },
  {
    lng: -44.6614056,
    lat: -23.0555341
  },
  {
    lng: -44.6613765,
    lat: -23.0556937
  },
  {
    lng: -44.6614273,
    lat: -23.055781
  },
  {
    lng: -44.6615284,
    lat: -23.0558228
  },
  {
    lng: -44.6616344,
    lat: -23.0559038
  },
  {
    lng: -44.6617195,
    lat: -23.0559642
  },
  {
    lng: -44.6617649,
    lat: -23.0560194
  },
  {
    lng: -44.6616707,
    lat: -23.0560352
  },
  {
    lng: -44.6616431,
    lat: -23.0561014
  },
  {
    lng: -44.6615847,
    lat: -23.0561836
  },
  {
    lng: -44.6615328,
    lat: -23.0562506
  },
  {
    lng: -44.661508,
    lat: -23.0564699
  },
  {
    lng: -44.6614019,
    lat: -23.0565666
  },
  {
    lng: -44.6613136,
    lat: -23.0566057
  },
  {
    lng: -44.660867,
    lat: -23.0565303
  },
  {
    lng: -44.6607302,
    lat: -23.0564218
  },
  {
    lng: -44.6608145,
    lat: -23.0560623
  },
  {
    lng: -44.6606419,
    lat: -23.0559104
  },
  {
    lng: -44.6605475,
    lat: -23.0553914
  },
  {
    lng: -44.6607103,
    lat: -23.0552995
  },
  {
    lng: -44.6606887,
    lat: -23.0548823
  },
  {
    lng: -44.6590967,
    lat: -23.0538795
  },
  {
    lng: -44.6589937,
    lat: -23.0537514
  },
  {
    lng: -44.6589733,
    lat: -23.0536905
  },
  {
    lng: -44.6589771,
    lat: -23.0536315
  },
  {
    lng: -44.659006,
    lat: -23.0535899
  },
  {
    lng: -44.6589722,
    lat: -23.0535295
  },
  {
    lng: -44.6585955,
    lat: -23.0533268
  },
  {
    lng: -44.6584018,
    lat: -23.0531861
  },
  {
    lng: -44.6582414,
    lat: -23.0531129
  },
  {
    lng: -44.6581354,
    lat: -23.0530188
  },
  {
    lng: -44.6580436,
    lat: -23.0528971
  },
  {
    lng: -44.6580016,
    lat: -23.052805
  },
  {
    lng: -44.6573152,
    lat: -23.0527459
  },
  {
    lng: -44.656395,
    lat: -23.0526111
  },
  {
    lng: -44.6563198,
    lat: -23.0525876
  },
  {
    lng: -44.6562732,
    lat: -23.052573
  },
  {
    lng: -44.6560732,
    lat: -23.0526641
  },
  {
    lng: -44.6549891,
    lat: -23.0540467
  },
  {
    lng: -44.655119,
    lat: -23.0547686
  },
  {
    lng: -44.6544105,
    lat: -23.0552688
  },
  {
    lng: -44.6538171,
    lat: -23.055102
  },
  {
    lng: -44.6535274,
    lat: -23.0551852
  },
  {
    lng: -44.6528908,
    lat: -23.0557936
  },
  {
    lng: -44.6523413,
    lat: -23.0561932
  },
  {
    lng: -44.6522313,
    lat: -23.0562732
  },
  {
    lng: -44.6520184,
    lat: -23.0561451
  },
  {
    lng: -44.6518521,
    lat: -23.0561253
  },
  {
    lng: -44.6517609,
    lat: -23.0561253
  },
  {
    lng: -44.6514283,
    lat: -23.0563326
  },
  {
    lng: -44.6509723,
    lat: -23.0565967
  },
  {
    lng: -44.6506504,
    lat: -23.0564906
  },
  {
    lng: -44.6503795,
    lat: -23.0562413
  },
  {
    lng: -44.6504519,
    lat: -23.0559995
  },
  {
    lng: -44.6501568,
    lat: -23.0557083
  },
  {
    lng: -44.6499185,
    lat: -23.0556964
  },
  {
    lng: -44.6496124,
    lat: -23.055612
  },
  {
    lng: -44.6494371,
    lat: -23.055466
  },
  {
    lng: -44.6493817,
    lat: -23.0552196
  },
  {
    lng: -44.6492497,
    lat: -23.0550792
  },
  {
    lng: -44.6487216,
    lat: -23.0545505
  },
  {
    lng: -44.6485547,
    lat: -23.0544283
  },
  {
    lng: -44.647887,
    lat: -23.0539394
  },
  {
    lng: -44.6475851,
    lat: -23.0537144
  },
  {
    lng: -44.6474724,
    lat: -23.0534645
  },
  {
    lng: -44.647362,
    lat: -23.053223
  },
  {
    lng: -44.6474291,
    lat: -23.0530947
  },
  {
    lng: -44.6476678,
    lat: -23.0529589
  },
  {
    lng: -44.6476705,
    lat: -23.0528059
  },
  {
    lng: -44.6477671,
    lat: -23.0526134
  },
  {
    lng: -44.6478958,
    lat: -23.0524085
  },
  {
    lng: -44.6476838,
    lat: -23.0514605
  },
  {
    lng: -44.6478395,
    lat: -23.0507821
  },
  {
    lng: -44.6477805,
    lat: -23.0504119
  },
  {
    lng: -44.6477536,
    lat: -23.0501305
  },
  {
    lng: -44.6477402,
    lat: -23.0498813
  },
  {
    lng: -44.6478663,
    lat: -23.0494321
  },
  {
    lng: -44.6480843,
    lat: -23.0491828
  },
  {
    lng: -44.6480941,
    lat: -23.0491142
  },
  {
    lng: -44.6480586,
    lat: -23.0489507
  },
  {
    lng: -44.6471287,
    lat: -23.0481561
  },
  {
    lng: -44.6460156,
    lat: -23.0473292
  },
  {
    lng: -44.6454577,
    lat: -23.0469689
  },
  {
    lng: -44.6451787,
    lat: -23.0468438
  },
  {
    lng: -44.6446547,
    lat: -23.0467813
  },
  {
    lng: -44.6446005,
    lat: -23.0467743
  },
  {
    lng: -44.643909,
    lat: -23.0471538
  },
  {
    lng: -44.6437412,
    lat: -23.0479941
  },
  {
    lng: -44.6435951,
    lat: -23.0482723
  },
  {
    lng: -44.6432417,
    lat: -23.0482748
  },
  {
    lng: -44.6429686,
    lat: -23.0482054
  },
  {
    lng: -44.6427968,
    lat: -23.0480549
  },
  {
    lng: -44.641786,
    lat: -23.0478431
  },
  {
    lng: -44.6404526,
    lat: -23.0476057
  },
  {
    lng: -44.6398877,
    lat: -23.0477718
  },
  {
    lng: -44.6387595,
    lat: -23.0481322
  },
  {
    lng: -44.6382295,
    lat: -23.0483566
  },
  {
    lng: -44.6378336,
    lat: -23.0486635
  },
  {
    lng: -44.637495,
    lat: -23.0490446
  },
  {
    lng: -44.6372192,
    lat: -23.0494706
  },
  {
    lng: -44.6370884,
    lat: -23.0496421
  },
  {
    lng: -44.6370891,
    lat: -23.0498558
  },
  {
    lng: -44.6374646,
    lat: -23.05061
  },
  {
    lng: -44.6376419,
    lat: -23.0509345
  },
  {
    lng: -44.6379418,
    lat: -23.0510802
  },
  {
    lng: -44.6379569,
    lat: -23.0514926
  },
  {
    lng: -44.6380034,
    lat: -23.051737
  },
  {
    lng: -44.6384934,
    lat: -23.0520956
  },
  {
    lng: -44.6384714,
    lat: -23.0523751
  },
  {
    lng: -44.638239,
    lat: -23.0525226
  },
  {
    lng: -44.6378738,
    lat: -23.0525279
  },
  {
    lng: -44.6374908,
    lat: -23.0523153
  },
  {
    lng: -44.6373351,
    lat: -23.0521362
  },
  {
    lng: -44.6371643,
    lat: -23.0520801
  },
  {
    lng: -44.635666,
    lat: -23.0515558
  },
  {
    lng: -44.6338967,
    lat: -23.0508944
  },
  {
    lng: -44.6320558,
    lat: -23.0502229
  },
  {
    lng: -44.6306354,
    lat: -23.0498039
  },
  {
    lng: -44.6299117,
    lat: -23.0495886
  },
  {
    lng: -44.6277471,
    lat: -23.0484371
  },
  {
    lng: -44.6271974,
    lat: -23.0481279
  },
  {
    lng: -44.6264523,
    lat: -23.0476583
  },
  {
    lng: -44.6253737,
    lat: -23.0469497
  },
  {
    lng: -44.6245976,
    lat: -23.0465031
  },
  {
    lng: -44.6232265,
    lat: -23.0458644
  },
  {
    lng: -44.6220625,
    lat: -23.0453648
  },
  {
    lng: -44.6205507,
    lat: -23.0448328
  },
  {
    lng: -44.6195836,
    lat: -23.0445769
  },
  {
    lng: -44.6183374,
    lat: -23.0442661
  },
  {
    lng: -44.6177666,
    lat: -23.0441959
  },
  {
    lng: -44.617298,
    lat: -23.0442175
  },
  {
    lng: -44.6171486,
    lat: -23.0442403
  },
  {
    lng: -44.6170723,
    lat: -23.0442572
  },
  {
    lng: -44.6165407,
    lat: -23.0443897
  },
  {
    lng: -44.6155966,
    lat: -23.0450092
  },
  {
    lng: -44.6146398,
    lat: -23.0454691
  },
  {
    lng: -44.6139444,
    lat: -23.0458953
  },
  {
    lng: -44.6136815,
    lat: -23.0461643
  },
  {
    lng: -44.6135582,
    lat: -23.0462531
  },
  {
    lng: -44.6133865,
    lat: -23.0464827
  },
  {
    lng: -44.6133114,
    lat: -23.0466604
  },
  {
    lng: -44.6133704,
    lat: -23.0468233
  },
  {
    lng: -44.6135515,
    lat: -23.0469761
  },
  {
    lng: -44.613817,
    lat: -23.0475029
  },
  {
    lng: -44.6140459,
    lat: -23.0480433
  },
  {
    lng: -44.6140481,
    lat: -23.0487343
  },
  {
    lng: -44.6141971,
    lat: -23.0490887
  },
  {
    lng: -44.6140267,
    lat: -23.0496008
  },
  {
    lng: -44.6140673,
    lat: -23.049809
  },
  {
    lng: -44.6141885,
    lat: -23.0497945
  },
  {
    lng: -44.6141853,
    lat: -23.0499142
  },
  {
    lng: -44.6142124,
    lat: -23.0500342
  },
  {
    lng: -44.6139951,
    lat: -23.0500534
  },
  {
    lng: -44.613767,
    lat: -23.0502202
  },
  {
    lng: -44.613778,
    lat: -23.0504655
  },
  {
    lng: -44.6138786,
    lat: -23.0506086
  },
  {
    lng: -44.6139591,
    lat: -23.0506601
  },
  {
    lng: -44.6141066,
    lat: -23.0507516
  },
  {
    lng: -44.6142151,
    lat: -23.0510735
  },
  {
    lng: -44.6142577,
    lat: -23.0513624
  },
  {
    lng: -44.6141431,
    lat: -23.051485
  },
  {
    lng: -44.6139517,
    lat: -23.0515159
  },
  {
    lng: -44.6135418,
    lat: -23.0512394
  },
  {
    lng: -44.6133019,
    lat: -23.0508141
  },
  {
    lng: -44.6126562,
    lat: -23.0504834
  },
  {
    lng: -44.612305,
    lat: -23.0501356
  },
  {
    lng: -44.6122015,
    lat: -23.0495472
  },
  {
    lng: -44.611875,
    lat: -23.0490045
  },
  {
    lng: -44.6117103,
    lat: -23.0486658
  },
  {
    lng: -44.6118504,
    lat: -23.0484629
  },
  {
    lng: -44.6118573,
    lat: -23.0483575
  },
  {
    lng: -44.6119459,
    lat: -23.0483224
  },
  {
    lng: -44.6118339,
    lat: -23.0481124
  },
  {
    lng: -44.6116884,
    lat: -23.047993
  },
  {
    lng: -44.6115431,
    lat: -23.0479017
  },
  {
    lng: -44.6112595,
    lat: -23.0478203
  },
  {
    lng: -44.6107133,
    lat: -23.047844
  },
  {
    lng: -44.6099753,
    lat: -23.0480005
  },
  {
    lng: -44.6094233,
    lat: -23.04828
  },
  {
    lng: -44.6088701,
    lat: -23.0488257
  },
  {
    lng: -44.6090094,
    lat: -23.0488296
  },
  {
    lng: -44.6092249,
    lat: -23.0490606
  },
  {
    lng: -44.608925,
    lat: -23.0494329
  },
  {
    lng: -44.6086016,
    lat: -23.0497096
  },
  {
    lng: -44.6084424,
    lat: -23.0498128
  },
  {
    lng: -44.6081807,
    lat: -23.0498351
  },
  {
    lng: -44.6079873,
    lat: -23.0498396
  },
  {
    lng: -44.6077113,
    lat: -23.0496866
  },
  {
    lng: -44.6076049,
    lat: -23.0496072
  },
  {
    lng: -44.6072519,
    lat: -23.0493432
  },
  {
    lng: -44.6070954,
    lat: -23.0491612
  },
  {
    lng: -44.6069488,
    lat: -23.0489764
  },
  {
    lng: -44.6067539,
    lat: -23.0488395
  },
  {
    lng: -44.6064482,
    lat: -23.0488077
  },
  {
    lng: -44.6058832,
    lat: -23.048831
  },
  {
    lng: -44.6057166,
    lat: -23.0487347
  },
  {
    lng: -44.6055831,
    lat: -23.0487851
  },
  {
    lng: -44.6053464,
    lat: -23.048721
  },
  {
    lng: -44.6051522,
    lat: -23.0486328
  },
  {
    lng: -44.6051107,
    lat: -23.0485045
  },
  {
    lng: -44.6045874,
    lat: -23.0480959
  },
  {
    lng: -44.6043697,
    lat: -23.0479042
  },
  {
    lng: -44.6038919,
    lat: -23.0477652
  },
  {
    lng: -44.6030626,
    lat: -23.0476621
  },
  {
    lng: -44.6026736,
    lat: -23.0477337
  },
  {
    lng: -44.6017102,
    lat: -23.0478251
  },
  {
    lng: -44.6009136,
    lat: -23.0476566
  },
  {
    lng: -44.6001172,
    lat: -23.0475478
  },
  {
    lng: -44.5995812,
    lat: -23.0475048
  },
  {
    lng: -44.5989438,
    lat: -23.0471702
  },
  {
    lng: -44.5983393,
    lat: -23.0464682
  },
  {
    lng: -44.5977091,
    lat: -23.0458927
  },
  {
    lng: -44.5972323,
    lat: -23.0453329
  },
  {
    lng: -44.5968211,
    lat: -23.0452166
  },
  {
    lng: -44.5961961,
    lat: -23.0452313
  },
  {
    lng: -44.5954435,
    lat: -23.0454623
  },
  {
    lng: -44.5954066,
    lat: -23.0454736
  },
  {
    lng: -44.5953029,
    lat: -23.0455054
  },
  {
    lng: -44.5949664,
    lat: -23.0455855
  },
  {
    lng: -44.5947156,
    lat: -23.0457422
  },
  {
    lng: -44.5933568,
    lat: -23.0464855
  },
  {
    lng: -44.5929025,
    lat: -23.0468085
  },
  {
    lng: -44.5928843,
    lat: -23.0472957
  },
  {
    lng: -44.5923537,
    lat: -23.0484128
  },
  {
    lng: -44.591907,
    lat: -23.0486352
  },
  {
    lng: -44.5917496,
    lat: -23.0487209
  },
  {
    lng: -44.5910948,
    lat: -23.0488168
  },
  {
    lng: -44.5907778,
    lat: -23.0488803
  },
  {
    lng: -44.590595,
    lat: -23.048992
  },
  {
    lng: -44.5903303,
    lat: -23.049326
  },
  {
    lng: -44.5902687,
    lat: -23.0496155
  },
  {
    lng: -44.5897916,
    lat: -23.0502691
  },
  {
    lng: -44.5896638,
    lat: -23.0505883
  },
  {
    lng: -44.5896686,
    lat: -23.0507647
  },
  {
    lng: -44.5896559,
    lat: -23.0510887
  },
  {
    lng: -44.5895754,
    lat: -23.0513879
  },
  {
    lng: -44.5899119,
    lat: -23.0514459
  },
  {
    lng: -44.5900543,
    lat: -23.0517773
  },
  {
    lng: -44.5899114,
    lat: -23.0525549
  },
  {
    lng: -44.5901604,
    lat: -23.053027
  },
  {
    lng: -44.5913615,
    lat: -23.0541692
  },
  {
    lng: -44.5922449,
    lat: -23.0546189
  },
  {
    lng: -44.592987,
    lat: -23.0550244
  },
  {
    lng: -44.5932502,
    lat: -23.0554442
  },
  {
    lng: -44.5932135,
    lat: -23.0564237
  },
  {
    lng: -44.5946934,
    lat: -23.0583014
  },
  {
    lng: -44.5952461,
    lat: -23.0585509
  },
  {
    lng: -44.5960984,
    lat: -23.0585667
  },
  {
    lng: -44.5965634,
    lat: -23.0586733
  },
  {
    lng: -44.5965314,
    lat: -23.0588549
  },
  {
    lng: -44.5969504,
    lat: -23.0590591
  },
  {
    lng: -44.5970151,
    lat: -23.0590515
  },
  {
    lng: -44.5972051,
    lat: -23.0589123
  },
  {
    lng: -44.5972496,
    lat: -23.0589533
  },
  {
    lng: -44.5974119,
    lat: -23.058943
  },
  {
    lng: -44.5975343,
    lat: -23.0589932
  },
  {
    lng: -44.5976025,
    lat: -23.059177
  },
  {
    lng: -44.5977734,
    lat: -23.0593267
  },
  {
    lng: -44.5981796,
    lat: -23.0596093
  },
  {
    lng: -44.5983383,
    lat: -23.0596351
  },
  {
    lng: -44.5985739,
    lat: -23.0599939
  },
  {
    lng: -44.5985413,
    lat: -23.0601655
  },
  {
    lng: -44.599173,
    lat: -23.0610841
  },
  {
    lng: -44.5991886,
    lat: -23.0611655
  },
  {
    lng: -44.5991451,
    lat: -23.0612705
  },
  {
    lng: -44.5989942,
    lat: -23.0613989
  },
  {
    lng: -44.5990762,
    lat: -23.061437
  },
  {
    lng: -44.5999036,
    lat: -23.0620566
  },
  {
    lng: -44.6000522,
    lat: -23.0621428
  },
  {
    lng: -44.6000694,
    lat: -23.0621966
  },
  {
    lng: -44.5999855,
    lat: -23.062347
  },
  {
    lng: -44.6000336,
    lat: -23.0624123
  },
  {
    lng: -44.6000108,
    lat: -23.0626197
  },
  {
    lng: -44.6000247,
    lat: -23.0629138
  },
  {
    lng: -44.5998295,
    lat: -23.0630018
  },
  {
    lng: -44.5994143,
    lat: -23.0629455
  },
  {
    lng: -44.5990793,
    lat: -23.0630687
  },
  {
    lng: -44.5987272,
    lat: -23.0631992
  },
  {
    lng: -44.5982724,
    lat: -23.0632178
  },
  {
    lng: -44.5977804,
    lat: -23.0634194
  },
  {
    lng: -44.597689,
    lat: -23.0633812
  },
  {
    lng: -44.5974787,
    lat: -23.0634768
  },
  {
    lng: -44.5974109,
    lat: -23.0636516
  },
  {
    lng: -44.5973963,
    lat: -23.0637734
  },
  {
    lng: -44.5974428,
    lat: -23.0638692
  },
  {
    lng: -44.5974097,
    lat: -23.0639254
  },
  {
    lng: -44.5969889,
    lat: -23.064094
  },
  {
    lng: -44.5967222,
    lat: -23.0641568
  },
  {
    lng: -44.5961305,
    lat: -23.0640148
  },
  {
    lng: -44.5952781,
    lat: -23.0636374
  },
  {
    lng: -44.5948627,
    lat: -23.0632973
  },
  {
    lng: -44.5946222,
    lat: -23.0630315
  },
  {
    lng: -44.5946291,
    lat: -23.0629158
  },
  {
    lng: -44.5946712,
    lat: -23.0628459
  },
  {
    lng: -44.5947637,
    lat: -23.0628426
  },
  {
    lng: -44.5947656,
    lat: -23.0627953
  },
  {
    lng: -44.5947252,
    lat: -23.062733
  },
  {
    lng: -44.5947219,
    lat: -23.0626537
  },
  {
    lng: -44.5949189,
    lat: -23.0625406
  },
  {
    lng: -44.5951134,
    lat: -23.0624726
  },
  {
    lng: -44.5952055,
    lat: -23.062431
  },
  {
    lng: -44.5951266,
    lat: -23.0623944
  },
  {
    lng: -44.5950918,
    lat: -23.0623198
  },
  {
    lng: -44.5949391,
    lat: -23.0620644
  },
  {
    lng: -44.5947294,
    lat: -23.0618464
  },
  {
    lng: -44.5943221,
    lat: -23.0615385
  },
  {
    lng: -44.5940265,
    lat: -23.0613744
  },
  {
    lng: -44.5936716,
    lat: -23.0610318
  },
  {
    lng: -44.5935791,
    lat: -23.0608429
  },
  {
    lng: -44.5934846,
    lat: -23.0604217
  },
  {
    lng: -44.5935023,
    lat: -23.0603076
  },
  {
    lng: -44.5934729,
    lat: -23.0602294
  },
  {
    lng: -44.5928999,
    lat: -23.0598612
  },
  {
    lng: -44.5923268,
    lat: -23.0594084
  },
  {
    lng: -44.5918817,
    lat: -23.0593375
  },
  {
    lng: -44.5915204,
    lat: -23.058982
  },
  {
    lng: -44.5908414,
    lat: -23.0587346
  },
  {
    lng: -44.5900046,
    lat: -23.0584462
  },
  {
    lng: -44.5893768,
    lat: -23.0583798
  },
  {
    lng: -44.5889968,
    lat: -23.0584407
  },
  {
    lng: -44.5887316,
    lat: -23.0586597
  },
  {
    lng: -44.5883366,
    lat: -23.0589854
  },
  {
    lng: -44.588222,
    lat: -23.0591588
  },
  {
    lng: -44.5882094,
    lat: -23.0594051
  },
  {
    lng: -44.5877832,
    lat: -23.0594815
  },
  {
    lng: -44.5871451,
    lat: -23.0594204
  },
  {
    lng: -44.58616,
    lat: -23.0591861
  },
  {
    lng: -44.5847253,
    lat: -23.0587118
  },
  {
    lng: -44.5842888,
    lat: -23.0584928
  },
  {
    lng: -44.5837644,
    lat: -23.0583313
  },
  {
    lng: -44.5834948,
    lat: -23.0581672
  },
  {
    lng: -44.58323,
    lat: -23.0580482
  },
  {
    lng: -44.5833816,
    lat: -23.0579061
  },
  {
    lng: -44.5837226,
    lat: -23.0579331
  },
  {
    lng: -44.583911,
    lat: -23.0576062
  },
  {
    lng: -44.5838333,
    lat: -23.0575806
  },
  {
    lng: -44.5837764,
    lat: -23.0575539
  },
  {
    lng: -44.5838181,
    lat: -23.0574886
  },
  {
    lng: -44.5838995,
    lat: -23.0574519
  },
  {
    lng: -44.5840015,
    lat: -23.0575
  },
  {
    lng: -44.5840589,
    lat: -23.0574767
  },
  {
    lng: -44.5842429,
    lat: -23.0574977
  },
  {
    lng: -44.5847245,
    lat: -23.0575084
  },
  {
    lng: -44.5850231,
    lat: -23.0574453
  },
  {
    lng: -44.5853737,
    lat: -23.0572019
  },
  {
    lng: -44.5854646,
    lat: -23.0569355
  },
  {
    lng: -44.5854033,
    lat: -23.0566907
  },
  {
    lng: -44.5850385,
    lat: -23.0566078
  },
  {
    lng: -44.5845464,
    lat: -23.0565044
  },
  {
    lng: -44.583901,
    lat: -23.0564771
  },
  {
    lng: -44.5831143,
    lat: -23.0560774
  },
  {
    lng: -44.5814877,
    lat: -23.0554642
  },
  {
    lng: -44.5808817,
    lat: -23.0554263
  },
  {
    lng: -44.5803302,
    lat: -23.0551615
  },
  {
    lng: -44.5802512,
    lat: -23.055039
  },
  {
    lng: -44.5800378,
    lat: -23.0549642
  },
  {
    lng: -44.57993,
    lat: -23.0550652
  },
  {
    lng: -44.5795391,
    lat: -23.0548355
  },
  {
    lng: -44.5788699,
    lat: -23.0546925
  },
  {
    lng: -44.5784157,
    lat: -23.0543703
  },
  {
    lng: -44.5777798,
    lat: -23.0540661
  },
  {
    lng: -44.5777166,
    lat: -23.0534847
  },
  {
    lng: -44.5768178,
    lat: -23.0504594
  },
  {
    lng: -44.5766163,
    lat: -23.0499133
  },
  {
    lng: -44.5768276,
    lat: -23.0496305
  },
  {
    lng: -44.577218,
    lat: -23.049522
  },
  {
    lng: -44.5772686,
    lat: -23.0494229
  },
  {
    lng: -44.5769211,
    lat: -23.0489159
  },
  {
    lng: -44.5766226,
    lat: -23.0486958
  },
  {
    lng: -44.5759077,
    lat: -23.0484317
  },
  {
    lng: -44.5750543,
    lat: -23.0481842
  },
  {
    lng: -44.5741833,
    lat: -23.0480055
  },
  {
    lng: -44.5732105,
    lat: -23.0479603
  },
  {
    lng: -44.5719303,
    lat: -23.0480376
  },
  {
    lng: -44.5709539,
    lat: -23.0479511
  },
  {
    lng: -44.5702271,
    lat: -23.047697
  },
  {
    lng: -44.569386,
    lat: -23.047305
  },
  {
    lng: -44.5686659,
    lat: -23.0467466
  },
  {
    lng: -44.5683952,
    lat: -23.0464532
  },
  {
    lng: -44.568171,
    lat: -23.0462104
  },
  {
    lng: -44.5677375,
    lat: -23.0455538
  },
  {
    lng: -44.5665344,
    lat: -23.0436493
  },
  {
    lng: -44.5658428,
    lat: -23.0433439
  },
  {
    lng: -44.5651199,
    lat: -23.0433559
  },
  {
    lng: -44.5645031,
    lat: -23.0433285
  },
  {
    lng: -44.5632019,
    lat: -23.0430596
  },
  {
    lng: -44.5626295,
    lat: -23.0428386
  },
  {
    lng: -44.5621983,
    lat: -23.0426892
  },
  {
    lng: -44.5619018,
    lat: -23.0425118
  },
  {
    lng: -44.5616656,
    lat: -23.0422145
  },
  {
    lng: -44.5614022,
    lat: -23.0417957
  },
  {
    lng: -44.561607,
    lat: -23.0417042
  },
  {
    lng: -44.5615561,
    lat: -23.0415229
  },
  {
    lng: -44.5612763,
    lat: -23.0410167
  },
  {
    lng: -44.5614318,
    lat: -23.0409644
  },
  {
    lng: -44.5614907,
    lat: -23.0408824
  },
  {
    lng: -44.5615986,
    lat: -23.0408784
  },
  {
    lng: -44.5617062,
    lat: -23.0408633
  },
  {
    lng: -44.5616547,
    lat: -23.0405689
  },
  {
    lng: -44.5613187,
    lat: -23.0401642
  },
  {
    lng: -44.5603905,
    lat: -23.0393807
  },
  {
    lng: -44.5591528,
    lat: -23.0383977
  },
  {
    lng: -44.5578172,
    lat: -23.0375386
  },
  {
    lng: -44.5563052,
    lat: -23.0368068
  },
  {
    lng: -44.5558234,
    lat: -23.0366203
  },
  {
    lng: -44.55524,
    lat: -23.0365039
  },
  {
    lng: -44.5547681,
    lat: -23.036461
  },
  {
    lng: -44.5542305,
    lat: -23.0365324
  },
  {
    lng: -44.5537563,
    lat: -23.0366888
  },
  {
    lng: -44.5534173,
    lat: -23.0369187
  },
  {
    lng: -44.5532999,
    lat: -23.0372288
  },
  {
    lng: -44.5533159,
    lat: -23.0373376
  },
  {
    lng: -44.5532346,
    lat: -23.0374618
  },
  {
    lng: -44.5531772,
    lat: -23.037459
  },
  {
    lng: -44.5529988,
    lat: -23.0374503
  },
  {
    lng: -44.5529672,
    lat: -23.0375109
  },
  {
    lng: -44.552835,
    lat: -23.0377654
  },
  {
    lng: -44.552808,
    lat: -23.0378175
  },
  {
    lng: -44.5526661,
    lat: -23.0378239
  },
  {
    lng: -44.5526025,
    lat: -23.0379787
  },
  {
    lng: -44.5524584,
    lat: -23.0380773
  },
  {
    lng: -44.5522863,
    lat: -23.0380318
  },
  {
    lng: -44.5520925,
    lat: -23.0381329
  },
  {
    lng: -44.5518679,
    lat: -23.0381256
  },
  {
    lng: -44.5518754,
    lat: -23.0380135
  },
  {
    lng: -44.5517807,
    lat: -23.037905
  },
  {
    lng: -44.5517206,
    lat: -23.0379691
  },
  {
    lng: -44.55163,
    lat: -23.0379826
  },
  {
    lng: -44.5516221,
    lat: -23.0378012
  },
  {
    lng: -44.5517585,
    lat: -23.0376638
  },
  {
    lng: -44.5517654,
    lat: -23.0375547
  },
  {
    lng: -44.5518236,
    lat: -23.0375177
  },
  {
    lng: -44.5517523,
    lat: -23.0373302
  },
  {
    lng: -44.549441,
    lat: -23.0361719
  },
  {
    lng: -44.5478147,
    lat: -23.0349061
  },
  {
    lng: -44.546747,
    lat: -23.034139
  },
  {
    lng: -44.5455561,
    lat: -23.0334947
  },
  {
    lng: -44.5392476,
    lat: -23.0307696
  },
  {
    lng: -44.5358975,
    lat: -23.02937
  },
  {
    lng: -44.5305778,
    lat: -23.0272869
  },
  {
    lng: -44.5291114,
    lat: -23.0269336
  },
  {
    lng: -44.5279955,
    lat: -23.0267781
  },
  {
    lng: -44.5269121,
    lat: -23.0266967
  },
  {
    lng: -44.525976,
    lat: -23.0267213
  },
  {
    lng: -44.5244418,
    lat: -23.0269213
  },
  {
    lng: -44.5231758,
    lat: -23.027178
  },
  {
    lng: -44.5227688,
    lat: -23.0272282
  },
  {
    lng: -44.5224194,
    lat: -23.0272286
  },
  {
    lng: -44.5221662,
    lat: -23.0272118
  },
  {
    lng: -44.5217362,
    lat: -23.0272311
  },
  {
    lng: -44.5210809,
    lat: -23.0272923
  },
  {
    lng: -44.5210813,
    lat: -23.0272618
  },
  {
    lng: -44.5210943,
    lat: -23.0262877
  },
  {
    lng: -44.5217674,
    lat: -23.0258803
  },
  {
    lng: -44.5222989,
    lat: -23.0257209
  },
  {
    lng: -44.5231492,
    lat: -23.02565
  },
  {
    lng: -44.5232543,
    lat: -23.0256582
  },
  {
    lng: -44.5233321,
    lat: -23.0256643
  },
  {
    lng: -44.5242829,
    lat: -23.0257386
  },
  {
    lng: -44.5249156,
    lat: -23.0256886
  },
  {
    lng: -44.5250455,
    lat: -23.0256783
  },
  {
    lng: -44.5253285,
    lat: -23.0256559
  },
  {
    lng: -44.5264263,
    lat: -23.0254197
  },
  {
    lng: -44.5273829,
    lat: -23.0250123
  },
  {
    lng: -44.5279321,
    lat: -23.0246048
  },
  {
    lng: -44.5283749,
    lat: -23.0240203
  },
  {
    lng: -44.5287115,
    lat: -23.02317
  },
  {
    lng: -44.5289064,
    lat: -23.0222665
  },
  {
    lng: -44.5289418,
    lat: -23.0214517
  },
  {
    lng: -44.5291721,
    lat: -23.0209911
  },
  {
    lng: -44.529491,
    lat: -23.0205305
  },
  {
    lng: -44.5298984,
    lat: -23.0202116
  },
  {
    lng: -44.5305007,
    lat: -23.0200522
  },
  {
    lng: -44.5312447,
    lat: -23.0200522
  },
  {
    lng: -44.5320596,
    lat: -23.0202648
  },
  {
    lng: -44.5331047,
    lat: -23.0209379
  },
  {
    lng: -44.5338133,
    lat: -23.0216288
  },
  {
    lng: -44.5341499,
    lat: -23.0222665
  },
  {
    lng: -44.5344333,
    lat: -23.0230814
  },
  {
    lng: -44.5345004,
    lat: -23.0232902
  },
  {
    lng: -44.5346547,
    lat: -23.0237703
  },
  {
    lng: -44.5347522,
    lat: -23.0240734
  },
  {
    lng: -44.5350002,
    lat: -23.0245694
  },
  {
    lng: -44.5353722,
    lat: -23.0249237
  },
  {
    lng: -44.5360099,
    lat: -23.0250831
  },
  {
    lng: -44.5368602,
    lat: -23.024782
  },
  {
    lng: -44.5370905,
    lat: -23.0246757
  },
  {
    lng: -44.5371582,
    lat: -23.0246445
  },
  {
    lng: -44.5372669,
    lat: -23.0245943
  },
  {
    lng: -44.5372938,
    lat: -23.0245818
  },
  {
    lng: -44.5373208,
    lat: -23.0245694
  },
  {
    lng: -44.5373627,
    lat: -23.0245135
  },
  {
    lng: -44.5375334,
    lat: -23.024286
  },
  {
    lng: -44.5375688,
    lat: -23.0239937
  },
  {
    lng: -44.5376042,
    lat: -23.0237014
  },
  {
    lng: -44.5379231,
    lat: -23.0231877
  },
  {
    lng: -44.5384368,
    lat: -23.0230282
  },
  {
    lng: -44.5390391,
    lat: -23.0229397
  },
  {
    lng: -44.5399071,
    lat: -23.0230814
  },
  {
    lng: -44.5405271,
    lat: -23.0232762
  },
  {
    lng: -44.5412888,
    lat: -23.0234357
  },
  {
    lng: -44.5418203,
    lat: -23.02348
  },
  {
    lng: -44.5422341,
    lat: -23.023416
  },
  {
    lng: -44.5423554,
    lat: -23.0233972
  },
  {
    lng: -44.5429483,
    lat: -23.0233053
  },
  {
    lng: -44.5434649,
    lat: -23.0230915
  },
  {
    lng: -44.5439814,
    lat: -23.0228777
  },
  {
    lng: -44.5446723,
    lat: -23.0225677
  },
  {
    lng: -44.5449823,
    lat: -23.0223728
  },
  {
    lng: -44.5450797,
    lat: -23.0221957
  },
  {
    lng: -44.5451417,
    lat: -23.0220628
  },
  {
    lng: -44.5450665,
    lat: -23.0219875
  },
  {
    lng: -44.5449912,
    lat: -23.0219122
  },
  {
    lng: -44.5445306,
    lat: -23.0216465
  },
  {
    lng: -44.5441763,
    lat: -23.0214694
  },
  {
    lng: -44.5440966,
    lat: -23.0212391
  },
  {
    lng: -44.5441232,
    lat: -23.0209734
  },
  {
    lng: -44.5443712,
    lat: -23.0207076
  },
  {
    lng: -44.5449026,
    lat: -23.0206102
  },
  {
    lng: -44.5452923,
    lat: -23.0206545
  },
  {
    lng: -44.5457352,
    lat: -23.0205836
  },
  {
    lng: -44.5459529,
    lat: -23.0205079
  },
  {
    lng: -44.5461426,
    lat: -23.0204419
  },
  {
    lng: -44.5463375,
    lat: -23.0200876
  },
  {
    lng: -44.5462454,
    lat: -23.0199437
  },
  {
    lng: -44.5461533,
    lat: -23.0197999
  },
  {
    lng: -44.5458218,
    lat: -23.0194499
  },
  {
    lng: -44.5454695,
    lat: -23.0190779
  },
  {
    lng: -44.5452215,
    lat: -23.0185642
  },
  {
    lng: -44.5450443,
    lat: -23.0179087
  },
  {
    lng: -44.5449557,
    lat: -23.0175722
  },
  {
    lng: -44.5447184,
    lat: -23.0170759
  },
  {
    lng: -44.544566,
    lat: -23.0167573
  },
  {
    lng: -44.5445129,
    lat: -23.0164562
  },
  {
    lng: -44.5446015,
    lat: -23.0161373
  },
  {
    lng: -44.5449203,
    lat: -23.0158893
  },
  {
    lng: -44.5452215,
    lat: -23.0157653
  },
  {
    lng: -44.5461072,
    lat: -23.0157476
  },
  {
    lng: -44.5465146,
    lat: -23.0156767
  },
  {
    lng: -44.5469398,
    lat: -23.0155527
  },
  {
    lng: -44.5474535,
    lat: -23.0152516
  },
  {
    lng: -44.547772,
    lat: -23.0148374
  },
  {
    lng: -44.5478078,
    lat: -23.014791
  },
  {
    lng: -44.5477015,
    lat: -23.0144721
  },
  {
    lng: -44.5473295,
    lat: -23.0142418
  },
  {
    lng: -44.5468377,
    lat: -23.0141486
  },
  {
    lng: -44.5463906,
    lat: -23.0142418
  },
  {
    lng: -44.5459123,
    lat: -23.0144367
  },
  {
    lng: -44.5455403,
    lat: -23.0143481
  },
  {
    lng: -44.5450975,
    lat: -23.0141001
  },
  {
    lng: -44.5447786,
    lat: -23.0137104
  },
  {
    lng: -44.5446944,
    lat: -23.0134579
  },
  {
    lng: -44.5446818,
    lat: -23.0134199
  },
  {
    lng: -44.5446015,
    lat: -23.013179
  },
  {
    lng: -44.5446172,
    lat: -23.0131004
  },
  {
    lng: -44.5446723,
    lat: -23.0128247
  },
  {
    lng: -44.5449203,
    lat: -23.0124704
  },
  {
    lng: -44.5456643,
    lat: -23.0121072
  },
  {
    lng: -44.5464083,
    lat: -23.0117441
  },
  {
    lng: -44.5466563,
    lat: -23.0114784
  },
  {
    lng: -44.5468512,
    lat: -23.0111772
  },
  {
    lng: -44.5468158,
    lat: -23.0108584
  },
  {
    lng: -44.5466032,
    lat: -23.0105218
  },
  {
    lng: -44.5462489,
    lat: -23.0103624
  },
  {
    lng: -44.5452746,
    lat: -23.0102915
  },
  {
    lng: -44.5444944,
    lat: -23.0099606
  },
  {
    lng: -44.5437157,
    lat: -23.0095475
  },
  {
    lng: -44.5434677,
    lat: -23.0091578
  },
  {
    lng: -44.5434459,
    lat: -23.0090543
  },
  {
    lng: -44.5433969,
    lat: -23.0088212
  },
  {
    lng: -44.5433791,
    lat: -23.0083606
  },
  {
    lng: -44.5435032,
    lat: -23.0079
  },
  {
    lng: -44.5437246,
    lat: -23.0077849
  },
  {
    lng: -44.543946,
    lat: -23.0076697
  },
  {
    lng: -44.5444243,
    lat: -23.0076875
  },
  {
    lng: -44.5455226,
    lat: -23.0083252
  },
  {
    lng: -44.5460009,
    lat: -23.0086618
  },
  {
    lng: -44.5466386,
    lat: -23.0085732
  },
  {
    lng: -44.5468158,
    lat: -23.0083075
  },
  {
    lng: -44.5467449,
    lat: -23.008024
  },
  {
    lng: -44.5465678,
    lat: -23.0075812
  },
  {
    lng: -44.5463375,
    lat: -23.0072446
  },
  {
    lng: -44.5462843,
    lat: -23.007032
  },
  {
    lng: -44.5463552,
    lat: -23.0068195
  },
  {
    lng: -44.5465323,
    lat: -23.006722
  },
  {
    lng: -44.5467819,
    lat: -23.0066913
  },
  {
    lng: -44.5473472,
    lat: -23.0067575
  },
  {
    lng: -44.5477724,
    lat: -23.0067132
  },
  {
    lng: -44.5480204,
    lat: -23.0064474
  },
  {
    lng: -44.5481621,
    lat: -23.0061109
  },
  {
    lng: -44.5480469,
    lat: -23.0057654
  },
  {
    lng: -44.5479229,
    lat: -23.0054023
  },
  {
    lng: -44.5479672,
    lat: -23.0050569
  },
  {
    lng: -44.5480914,
    lat: -23.0046849
  },
  {
    lng: -44.5482241,
    lat: -23.0042243
  },
  {
    lng: -44.5481709,
    lat: -23.0039231
  },
  {
    lng: -44.5480646,
    lat: -23.0037416
  },
  {
    lng: -44.5479584,
    lat: -23.00356
  },
  {
    lng: -44.5476395,
    lat: -23.0034714
  },
  {
    lng: -44.5473561,
    lat: -23.0035866
  },
  {
    lng: -44.5470903,
    lat: -23.0038966
  },
  {
    lng: -44.5467006,
    lat: -23.0045431
  },
  {
    lng: -44.5465589,
    lat: -23.0047823
  },
  {
    lng: -44.5463198,
    lat: -23.0050834
  },
  {
    lng: -44.5462578,
    lat: -23.0055351
  },
  {
    lng: -44.5461426,
    lat: -23.0059603
  },
  {
    lng: -44.5459035,
    lat: -23.0063057
  },
  {
    lng: -44.5456466,
    lat: -23.0065449
  },
  {
    lng: -44.5453277,
    lat: -23.0066689
  },
  {
    lng: -44.5450089,
    lat: -23.0066777
  },
  {
    lng: -44.5447432,
    lat: -23.0064297
  },
  {
    lng: -44.5443889,
    lat: -23.0059337
  },
  {
    lng: -44.5442294,
    lat: -23.005668
  },
  {
    lng: -44.5442472,
    lat: -23.0054909
  },
  {
    lng: -44.5444154,
    lat: -23.0051454
  },
  {
    lng: -44.5446325,
    lat: -23.0050259
  },
  {
    lng: -44.5448495,
    lat: -23.0049063
  },
  {
    lng: -44.5452835,
    lat: -23.0048443
  },
  {
    lng: -44.5457706,
    lat: -23.0045609
  },
  {
    lng: -44.5460098,
    lat: -23.0041977
  },
  {
    lng: -44.5460718,
    lat: -23.00387
  },
  {
    lng: -44.5460629,
    lat: -23.003498
  },
  {
    lng: -44.5459566,
    lat: -23.00325
  },
  {
    lng: -44.5456732,
    lat: -23.0030994
  },
  {
    lng: -44.5453809,
    lat: -23.0030817
  },
  {
    lng: -44.5450266,
    lat: -23.00325
  },
  {
    lng: -44.5448061,
    lat: -23.0034544
  },
  {
    lng: -44.5445749,
    lat: -23.0037726
  },
  {
    lng: -44.5443446,
    lat: -23.0039674
  },
  {
    lng: -44.5440169,
    lat: -23.0041091
  },
  {
    lng: -44.5437269,
    lat: -23.004135
  },
  {
    lng: -44.543709,
    lat: -23.0041366
  },
  {
    lng: -44.5435209,
    lat: -23.0041534
  },
  {
    lng: -44.5432551,
    lat: -23.0041091
  },
  {
    lng: -44.5430249,
    lat: -23.0039497
  },
  {
    lng: -44.5429806,
    lat: -23.0037017
  },
  {
    lng: -44.5430337,
    lat: -23.0034005
  },
  {
    lng: -44.5432109,
    lat: -23.0030463
  },
  {
    lng: -44.5434057,
    lat: -23.0028337
  },
  {
    lng: -44.5434816,
    lat: -23.002769
  },
  {
    lng: -44.5434994,
    lat: -23.0027539
  },
  {
    lng: -44.5435171,
    lat: -23.0027388
  },
  {
    lng: -44.54454,
    lat: -23.0018672
  },
  {
    lng: -44.5445855,
    lat: -23.0018291
  },
  {
    lng: -44.5451417,
    lat: -23.0013634
  },
  {
    lng: -44.5452923,
    lat: -23.0010091
  },
  {
    lng: -44.5452392,
    lat: -23.0007079
  },
  {
    lng: -44.5451417,
    lat: -23.0004511
  },
  {
    lng: -44.5448229,
    lat: -23.0003537
  },
  {
    lng: -44.5446015,
    lat: -23.0004954
  },
  {
    lng: -44.5444066,
    lat: -23.0006991
  },
  {
    lng: -44.5442788,
    lat: -23.0009763
  },
  {
    lng: -44.5436183,
    lat: -23.0017708
  },
  {
    lng: -44.5430869,
    lat: -23.0020454
  },
  {
    lng: -44.54283,
    lat: -23.002134
  },
  {
    lng: -44.5425647,
    lat: -23.0020197
  },
  {
    lng: -44.5425055,
    lat: -23.0019525
  },
  {
    lng: -44.542402,
    lat: -23.0018351
  },
  {
    lng: -44.5422986,
    lat: -23.0017177
  },
  {
    lng: -44.5420683,
    lat: -23.0012482
  },
  {
    lng: -44.5418026,
    lat: -23.0009471
  },
  {
    lng: -44.5415368,
    lat: -23.0006459
  },
  {
    lng: -44.5414394,
    lat: -23.0004511
  },
  {
    lng: -44.5414926,
    lat: -23.0003182
  },
  {
    lng: -44.5416343,
    lat: -23.0001234
  },
  {
    lng: -44.5419255,
    lat: -23.0000143
  },
  {
    lng: -44.5423517,
    lat: -22.9999196
  },
  {
    lng: -44.5428034,
    lat: -22.9999196
  },
  {
    lng: -44.5432729,
    lat: -22.9998399
  },
  {
    lng: -44.5436626,
    lat: -22.9996185
  },
  {
    lng: -44.54407,
    lat: -22.9992111
  },
  {
    lng: -44.544318,
    lat: -22.9988922
  },
  {
    lng: -44.5445129,
    lat: -22.9985379
  },
  {
    lng: -44.5445483,
    lat: -22.9983076
  },
  {
    lng: -44.5444332,
    lat: -22.9980773
  },
  {
    lng: -44.5440966,
    lat: -22.9979799
  },
  {
    lng: -44.5436537,
    lat: -22.9980773
  },
  {
    lng: -44.5426529,
    lat: -22.9984759
  },
  {
    lng: -44.5418557,
    lat: -22.9986885
  },
  {
    lng: -44.5415988,
    lat: -22.9987239
  },
  {
    lng: -44.5413685,
    lat: -22.9986442
  },
  {
    lng: -44.5412003,
    lat: -22.9985025
  },
  {
    lng: -44.5410851,
    lat: -22.9981748
  },
  {
    lng: -44.5410763,
    lat: -22.9977053
  },
  {
    lng: -44.5410754,
    lat: -22.9976599
  },
  {
    lng: -44.5410674,
    lat: -22.9972359
  },
  {
    lng: -44.5407456,
    lat: -22.9967032
  },
  {
    lng: -44.5406159,
    lat: -22.9963612
  },
  {
    lng: -44.5405703,
    lat: -22.9959967
  },
  {
    lng: -44.5405687,
    lat: -22.9959837
  },
  {
    lng: -44.5406395,
    lat: -22.9957242
  },
  {
    lng: -44.5408636,
    lat: -22.9955355
  },
  {
    lng: -44.5412882,
    lat: -22.9955237
  },
  {
    lng: -44.541663,
    lat: -22.9956166
  },
  {
    lng: -44.5420785,
    lat: -22.9958304
  },
  {
    lng: -44.5429986,
    lat: -22.9963965
  },
  {
    lng: -44.5434586,
    lat: -22.9964673
  },
  {
    lng: -44.5438596,
    lat: -22.9964083
  },
  {
    lng: -44.5440483,
    lat: -22.9962078
  },
  {
    lng: -44.5440483,
    lat: -22.9958658
  },
  {
    lng: -44.5437535,
    lat: -22.9954057
  },
  {
    lng: -44.5431451,
    lat: -22.9948893
  },
  {
    lng: -44.54286,
    lat: -22.9945901
  },
  {
    lng: -44.5421965,
    lat: -22.9944149
  },
  {
    lng: -44.5418072,
    lat: -22.99412
  },
  {
    lng: -44.5415949,
    lat: -22.9936718
  },
  {
    lng: -44.5415241,
    lat: -22.9931882
  },
  {
    lng: -44.5418544,
    lat: -22.9929405
  },
  {
    lng: -44.5423144,
    lat: -22.9928933
  },
  {
    lng: -44.5432109,
    lat: -22.9931292
  },
  {
    lng: -44.5438473,
    lat: -22.9932325
  },
  {
    lng: -44.5444022,
    lat: -22.9932708
  },
  {
    lng: -44.544815,
    lat: -22.9933062
  },
  {
    lng: -44.5454048,
    lat: -22.9930938
  },
  {
    lng: -44.5457469,
    lat: -22.9927518
  },
  {
    lng: -44.5459592,
    lat: -22.9922918
  },
  {
    lng: -44.545853,
    lat: -22.9915369
  },
  {
    lng: -44.5456289,
    lat: -22.9910297
  },
  {
    lng: -44.5448268,
    lat: -22.9897912
  },
  {
    lng: -44.5436591,
    lat: -22.9879511
  },
  {
    lng: -44.5432934,
    lat: -22.9865828
  },
  {
    lng: -44.5433919,
    lat: -22.9855591
  },
  {
    lng: -44.5434468,
    lat: -22.9848725
  },
  {
    lng: -44.5445849,
    lat: -22.9842079
  },
  {
    lng: -44.5450627,
    lat: -22.9839289
  },
  {
    lng: -44.5456381,
    lat: -22.9835365
  },
  {
    lng: -44.5456997,
    lat: -22.9830796
  },
  {
    lng: -44.5445909,
    lat: -22.9821124
  },
  {
    lng: -44.5444775,
    lat: -22.9815088
  },
  {
    lng: -44.5445041,
    lat: -22.9809085
  },
  {
    lng: -44.5446909,
    lat: -22.9805083
  },
  {
    lng: -44.5450644,
    lat: -22.9802415
  },
  {
    lng: -44.5453446,
    lat: -22.9798547
  },
  {
    lng: -44.5456448,
    lat: -22.9793084
  },
  {
    lng: -44.5457181,
    lat: -22.9786274
  },
  {
    lng: -44.5461316,
    lat: -22.978614
  },
  {
    lng: -44.5463451,
    lat: -22.9786807
  },
  {
    lng: -44.5465452,
    lat: -22.9789209
  },
  {
    lng: -44.5467586,
    lat: -22.9791076
  },
  {
    lng: -44.5471722,
    lat: -22.979121
  },
  {
    lng: -44.5475651,
    lat: -22.9789967
  },
  {
    lng: -44.5481994,
    lat: -22.978574
  },
  {
    lng: -44.5488263,
    lat: -22.9783206
  },
  {
    lng: -44.5493199,
    lat: -22.9780137
  },
  {
    lng: -44.5493599,
    lat: -22.9776936
  },
  {
    lng: -44.5491598,
    lat: -22.9773067
  },
  {
    lng: -44.5486396,
    lat: -22.9765863
  },
  {
    lng: -44.5485862,
    lat: -22.9762528
  },
  {
    lng: -44.548733,
    lat: -22.9758126
  },
  {
    lng: -44.5489464,
    lat: -22.9755592
  },
  {
    lng: -44.5492799,
    lat: -22.9753991
  },
  {
    lng: -44.54956,
    lat: -22.9752523
  },
  {
    lng: -44.5498135,
    lat: -22.9750522
  },
  {
    lng: -44.5500051,
    lat: -22.9747748
  },
  {
    lng: -44.5503447,
    lat: -22.9732641
  },
  {
    lng: -44.5504405,
    lat: -22.9728378
  },
  {
    lng: -44.5507021,
    lat: -22.9726363
  },
  {
    lng: -44.5511455,
    lat: -22.9725238
  },
  {
    lng: -44.5516411,
    lat: -22.9725977
  },
  {
    lng: -44.5520947,
    lat: -22.9729045
  },
  {
    lng: -44.5524415,
    lat: -22.9735181
  },
  {
    lng: -44.5527216,
    lat: -22.9742251
  },
  {
    lng: -44.5530551,
    lat: -22.9747854
  },
  {
    lng: -44.5534287,
    lat: -22.975239
  },
  {
    lng: -44.5538689,
    lat: -22.9755725
  },
  {
    lng: -44.5544158,
    lat: -22.9757326
  },
  {
    lng: -44.5548961,
    lat: -22.9756392
  },
  {
    lng: -44.5551495,
    lat: -22.9753057
  },
  {
    lng: -44.555323,
    lat: -22.9749188
  },
  {
    lng: -44.5552563,
    lat: -22.9744786
  },
  {
    lng: -44.5550028,
    lat: -22.9740384
  },
  {
    lng: -44.5546797,
    lat: -22.9736641
  },
  {
    lng: -44.5544692,
    lat: -22.973278
  },
  {
    lng: -44.5545226,
    lat: -22.9729045
  },
  {
    lng: -44.5546693,
    lat: -22.9725176
  },
  {
    lng: -44.5550161,
    lat: -22.9722375
  },
  {
    lng: -44.5554297,
    lat: -22.9720107
  },
  {
    lng: -44.5558832,
    lat: -22.972024
  },
  {
    lng: -44.5564569,
    lat: -22.9720507
  },
  {
    lng: -44.5571639,
    lat: -22.9721841
  },
  {
    lng: -44.5580977,
    lat: -22.9722842
  },
  {
    lng: -44.5589648,
    lat: -22.9721841
  },
  {
    lng: -44.5596585,
    lat: -22.9721174
  },
  {
    lng: -44.5603789,
    lat: -22.9720174
  },
  {
    lng: -44.5605656,
    lat: -22.9718039
  },
  {
    lng: -44.5605189,
    lat: -22.9714504
  },
  {
    lng: -44.5603922,
    lat: -22.9705166
  },
  {
    lng: -44.5603989,
    lat: -22.969983
  },
  {
    lng: -44.5602188,
    lat: -22.9695228
  },
  {
    lng: -44.559903,
    lat: -22.9689909
  },
  {
    lng: -44.5596067,
    lat: -22.9682469
  },
  {
    lng: -44.5595409,
    lat: -22.9671079
  },
  {
    lng: -44.5591459,
    lat: -22.9661796
  },
  {
    lng: -44.5584348,
    lat: -22.9653764
  },
  {
    lng: -44.5579344,
    lat: -22.9643559
  },
  {
    lng: -44.5574209,
    lat: -22.9631642
  },
  {
    lng: -44.5574999,
    lat: -22.9620779
  },
  {
    lng: -44.5578093,
    lat: -22.9613208
  },
  {
    lng: -44.5584414,
    lat: -22.9609191
  },
  {
    lng: -44.5592183,
    lat: -22.9605241
  },
  {
    lng: -44.5597366,
    lat: -22.9604441
  },
  {
    lng: -44.5604824,
    lat: -22.9601488
  },
  {
    lng: -44.5613119,
    lat: -22.9597275
  },
  {
    lng: -44.5617662,
    lat: -22.9592271
  },
  {
    lng: -44.561786,
    lat: -22.9585292
  },
  {
    lng: -44.561516,
    lat: -22.957884
  },
  {
    lng: -44.5614107,
    lat: -22.9572454
  },
  {
    lng: -44.5614436,
    lat: -22.9561261
  },
  {
    lng: -44.5614812,
    lat: -22.9554044
  },
  {
    lng: -44.5613778,
    lat: -22.9549147
  },
  {
    lng: -44.5614765,
    lat: -22.9546843
  },
  {
    lng: -44.561674,
    lat: -22.9544341
  },
  {
    lng: -44.5619045,
    lat: -22.9542959
  },
  {
    lng: -44.5622007,
    lat: -22.9541181
  },
  {
    lng: -44.5626813,
    lat: -22.9540391
  },
  {
    lng: -44.5630566,
    lat: -22.9541181
  },
  {
    lng: -44.5633463,
    lat: -22.9542498
  },
  {
    lng: -44.5636623,
    lat: -22.9545592
  },
  {
    lng: -44.5639257,
    lat: -22.9549411
  },
  {
    lng: -44.5642244,
    lat: -22.9551178
  },
  {
    lng: -44.5650866,
    lat: -22.9549718
  },
  {
    lng: -44.5668357,
    lat: -22.9541115
  },
  {
    lng: -44.567969,
    lat: -22.9535124
  },
  {
    lng: -44.5690634,
    lat: -22.952737
  },
  {
    lng: -44.569426,
    lat: -22.9523022
  },
  {
    lng: -44.5703581,
    lat: -22.9515307
  },
  {
    lng: -44.570865,
    lat: -22.9512541
  },
  {
    lng: -44.5711927,
    lat: -22.9512617
  },
  {
    lng: -44.5717999,
    lat: -22.9513266
  },
  {
    lng: -44.5728119,
    lat: -22.9512482
  },
  {
    lng: -44.5736105,
    lat: -22.9508657
  },
  {
    lng: -44.5742557,
    lat: -22.9506813
  },
  {
    lng: -44.5745717,
    lat: -22.9504575
  },
  {
    lng: -44.574835,
    lat: -22.9502205
  },
  {
    lng: -44.5753486,
    lat: -22.950023
  },
  {
    lng: -44.575737,
    lat: -22.9497596
  },
  {
    lng: -44.5759082,
    lat: -22.9494765
  },
  {
    lng: -44.5763296,
    lat: -22.9487786
  },
  {
    lng: -44.5764086,
    lat: -22.9484034
  },
  {
    lng: -44.5765863,
    lat: -22.9481729
  },
  {
    lng: -44.5766148,
    lat: -22.9479291
  },
  {
    lng: -44.5765998,
    lat: -22.9476489
  },
  {
    lng: -44.5765566,
    lat: -22.9474989
  },
  {
    lng: -44.5765741,
    lat: -22.9471675
  },
  {
    lng: -44.5766322,
    lat: -22.9468128
  },
  {
    lng: -44.5766787,
    lat: -22.946621
  },
  {
    lng: -44.5768008,
    lat: -22.9464873
  },
  {
    lng: -44.5770275,
    lat: -22.9464117
  },
  {
    lng: -44.5774229,
    lat: -22.9464931
  },
  {
    lng: -44.5780761,
    lat: -22.94673
  },
  {
    lng: -44.578481,
    lat: -22.9468593
  },
  {
    lng: -44.5790972,
    lat: -22.9471965
  },
  {
    lng: -44.5799925,
    lat: -22.9474756
  },
  {
    lng: -44.5806415,
    lat: -22.9476718
  },
  {
    lng: -44.5814808,
    lat: -22.9477547
  },
  {
    lng: -44.5822134,
    lat: -22.9476558
  },
  {
    lng: -44.5826843,
    lat: -22.9474756
  },
  {
    lng: -44.5828994,
    lat: -22.9473186
  },
  {
    lng: -44.5832366,
    lat: -22.9470454
  },
  {
    lng: -44.5835447,
    lat: -22.9468768
  },
  {
    lng: -44.5836959,
    lat: -22.946557
  },
  {
    lng: -44.5839226,
    lat: -22.946278
  },
  {
    lng: -44.5842191,
    lat: -22.945935
  },
  {
    lng: -44.5844342,
    lat: -22.9457664
  },
  {
    lng: -44.5846726,
    lat: -22.9456559
  },
  {
    lng: -44.584876,
    lat: -22.9456617
  },
  {
    lng: -44.5851784,
    lat: -22.9459001
  },
  {
    lng: -44.5856376,
    lat: -22.9462722
  },
  {
    lng: -44.585876,
    lat: -22.9464001
  },
  {
    lng: -44.5861202,
    lat: -22.9465803
  },
  {
    lng: -44.5864109,
    lat: -22.9469931
  },
  {
    lng: -44.5865207,
    lat: -22.9472095
  },
  {
    lng: -44.5867655,
    lat: -22.9473303
  },
  {
    lng: -44.5871666,
    lat: -22.9474058
  },
  {
    lng: -44.587405,
    lat: -22.9472954
  },
  {
    lng: -44.5875969,
    lat: -22.9472547
  },
  {
    lng: -44.5879166,
    lat: -22.9472896
  },
  {
    lng: -44.5883992,
    lat: -22.9471791
  },
  {
    lng: -44.5886957,
    lat: -22.9471733
  },
  {
    lng: -44.5889747,
    lat: -22.9472256
  },
  {
    lng: -44.5892131,
    lat: -22.9472372
  },
  {
    lng: -44.5894979,
    lat: -22.9470977
  },
  {
    lng: -44.5897072,
    lat: -22.9471035
  },
  {
    lng: -44.5899979,
    lat: -22.9471791
  },
  {
    lng: -44.5901316,
    lat: -22.9470803
  },
  {
    lng: -44.5902296,
    lat: -22.9468725
  },
  {
    lng: -44.5903933,
    lat: -22.9467838
  },
  {
    lng: -44.5906607,
    lat: -22.9468419
  },
  {
    lng: -44.5912421,
    lat: -22.9471093
  },
  {
    lng: -44.5915502,
    lat: -22.9473244
  },
  {
    lng: -44.5917188,
    lat: -22.9474756
  },
  {
    lng: -44.5920153,
    lat: -22.9475744
  },
  {
    lng: -44.5925908,
    lat: -22.9475279
  },
  {
    lng: -44.593149,
    lat: -22.9475047
  },
  {
    lng: -44.5934644,
    lat: -22.947481
  },
  {
    lng: -44.5938466,
    lat: -22.9475279
  },
  {
    lng: -44.5942477,
    lat: -22.9476791
  },
  {
    lng: -44.5945326,
    lat: -22.9476674
  },
  {
    lng: -44.5952128,
    lat: -22.9471617
  },
  {
    lng: -44.5956837,
    lat: -22.9466035
  },
  {
    lng: -44.5961663,
    lat: -22.9461617
  },
  {
    lng: -44.596579,
    lat: -22.9458536
  },
  {
    lng: -44.5969337,
    lat: -22.9457199
  },
  {
    lng: -44.5974046,
    lat: -22.9456792
  },
  {
    lng: -44.5978813,
    lat: -22.9456617
  },
  {
    lng: -44.5982941,
    lat: -22.9456617
  },
  {
    lng: -44.5986778,
    lat: -22.9456966
  },
  {
    lng: -44.599015,
    lat: -22.9457722
  },
  {
    lng: -44.5992353,
    lat: -22.9458446
  },
  {
    lng: -44.599422,
    lat: -22.9459059
  },
  {
    lng: -44.5997269,
    lat: -22.9460059
  },
  {
    lng: -44.6000033,
    lat: -22.945964
  },
  {
    lng: -44.6006962,
    lat: -22.9457729
  },
  {
    lng: -44.6011191,
    lat: -22.9456562
  },
  {
    lng: -44.601359,
    lat: -22.9455842
  },
  {
    lng: -44.6023404,
    lat: -22.9452896
  },
  {
    lng: -44.6030405,
    lat: -22.9450277
  },
  {
    lng: -44.6037415,
    lat: -22.9447199
  },
  {
    lng: -44.6043927,
    lat: -22.9443711
  },
  {
    lng: -44.6047938,
    lat: -22.9440164
  },
  {
    lng: -44.6049392,
    lat: -22.9437258
  },
  {
    lng: -44.6050583,
    lat: -22.9436676
  },
  {
    lng: -44.6051775,
    lat: -22.9436095
  },
  {
    lng: -44.6054159,
    lat: -22.9434932
  },
  {
    lng: -44.6056659,
    lat: -22.943249
  },
  {
    lng: -44.6061193,
    lat: -22.943063
  },
  {
    lng: -44.6065786,
    lat: -22.9429816
  },
  {
    lng: -44.6069507,
    lat: -22.9429932
  },
  {
    lng: -44.6073867,
    lat: -22.9429002
  },
  {
    lng: -44.6077704,
    lat: -22.9427025
  },
  {
    lng: -44.6082704,
    lat: -22.9421619
  },
  {
    lng: -44.6087937,
    lat: -22.9414642
  },
  {
    lng: -44.6091018,
    lat: -22.9407898
  },
  {
    lng: -44.6098384,
    lat: -22.9404022
  },
  {
    lng: -44.610282,
    lat: -22.9400224
  },
  {
    lng: -44.6104474,
    lat: -22.9397351
  },
  {
    lng: -44.6105029,
    lat: -22.9396387
  },
  {
    lng: -44.6107064,
    lat: -22.939255
  },
  {
    lng: -44.6110402,
    lat: -22.9387367
  },
  {
    lng: -44.6113517,
    lat: -22.9384818
  },
  {
    lng: -44.6115319,
    lat: -22.9381039
  },
  {
    lng: -44.6116656,
    lat: -22.93779
  },
  {
    lng: -44.6117993,
    lat: -22.9375109
  },
  {
    lng: -44.6119679,
    lat: -22.9373597
  },
  {
    lng: -44.6122005,
    lat: -22.9372783
  },
  {
    lng: -44.6125086,
    lat: -22.9372377
  },
  {
    lng: -44.6128981,
    lat: -22.9372609
  },
  {
    lng: -44.6131714,
    lat: -22.9372551
  },
  {
    lng: -44.6134446,
    lat: -22.9374237
  },
  {
    lng: -44.6135783,
    lat: -22.9376621
  },
  {
    lng: -44.6136074,
    lat: -22.9378946
  },
  {
    lng: -44.6136423,
    lat: -22.9383655
  },
  {
    lng: -44.6136597,
    lat: -22.9385457
  },
  {
    lng: -44.6137818,
    lat: -22.9387725
  },
  {
    lng: -44.6140144,
    lat: -22.9388074
  },
  {
    lng: -44.6143283,
    lat: -22.9388422
  },
  {
    lng: -44.6147888,
    lat: -22.9388618
  },
  {
    lng: -44.6159213,
    lat: -22.938755
  },
  {
    lng: -44.6167759,
    lat: -22.9386388
  },
  {
    lng: -44.6171654,
    lat: -22.9385574
  },
  {
    lng: -44.6175026,
    lat: -22.9386504
  },
  {
    lng: -44.61777,
    lat: -22.9388422
  },
  {
    lng: -44.618113,
    lat: -22.9390341
  },
  {
    lng: -44.6185026,
    lat: -22.9393131
  },
  {
    lng: -44.6188688,
    lat: -22.9395224
  },
  {
    lng: -44.6193862,
    lat: -22.9396387
  },
  {
    lng: -44.6197118,
    lat: -22.9397724
  },
  {
    lng: -44.6199792,
    lat: -22.9397434
  },
  {
    lng: -44.6202641,
    lat: -22.9396155
  },
  {
    lng: -44.6205498,
    lat: -22.939451
  },
  {
    lng: -44.620735,
    lat: -22.939255
  },
  {
    lng: -44.621049,
    lat: -22.9388539
  },
  {
    lng: -44.6212794,
    lat: -22.9386005
  },
  {
    lng: -44.6215795,
    lat: -22.9384167
  },
  {
    lng: -44.6217265,
    lat: -22.9379512
  },
  {
    lng: -44.6218674,
    lat: -22.9375898
  },
  {
    lng: -44.6220573,
    lat: -22.9372406
  },
  {
    lng: -44.6225045,
    lat: -22.9369404
  },
  {
    lng: -44.6226549,
    lat: -22.9366952
  },
  {
    lng: -44.6226472,
    lat: -22.936364
  },
  {
    lng: -44.6225596,
    lat: -22.9361012
  },
  {
    lng: -44.6224678,
    lat: -22.9358255
  },
  {
    lng: -44.6223514,
    lat: -22.9355131
  },
  {
    lng: -44.6223825,
    lat: -22.9353156
  },
  {
    lng: -44.6224765,
    lat: -22.9350452
  },
  {
    lng: -44.6226176,
    lat: -22.9347983
  },
  {
    lng: -44.6231701,
    lat: -22.9341753
  },
  {
    lng: -44.6235463,
    lat: -22.933517
  },
  {
    lng: -44.6236982,
    lat: -22.9327397
  },
  {
    lng: -44.6241272,
    lat: -22.9322867
  },
  {
    lng: -44.6247512,
    lat: -22.9314951
  },
  {
    lng: -44.6249812,
    lat: -22.9312371
  },
  {
    lng: -44.625145,
    lat: -22.9311189
  },
  {
    lng: -44.6250824,
    lat: -22.9308523
  },
  {
    lng: -44.6250152,
    lat: -22.9306873
  },
  {
    lng: -44.6248219,
    lat: -22.9302134
  },
  {
    lng: -44.6250173,
    lat: -22.9295513
  },
  {
    lng: -44.6252126,
    lat: -22.9293233
  },
  {
    lng: -44.6255383,
    lat: -22.9290628
  },
  {
    lng: -44.6258856,
    lat: -22.9287697
  },
  {
    lng: -44.6262656,
    lat: -22.9283356
  },
  {
    lng: -44.6264827,
    lat: -22.9279339
  },
  {
    lng: -44.6263741,
    lat: -22.9273912
  },
  {
    lng: -44.6264172,
    lat: -22.9268674
  },
  {
    lng: -44.6264371,
    lat: -22.9263505
  },
  {
    lng: -44.6266238,
    lat: -22.9260561
  },
  {
    lng: -44.6265369,
    lat: -22.9257304
  },
  {
    lng: -44.6268448,
    lat: -22.92529
  },
  {
    lng: -44.6271665,
    lat: -22.9247101
  },
  {
    lng: -44.6271882,
    lat: -22.924113
  },
  {
    lng: -44.6274976,
    lat: -22.9237928
  },
  {
    lng: -44.6276115,
    lat: -22.9234401
  },
  {
    lng: -44.6278399,
    lat: -22.9231009
  },
  {
    lng: -44.6280674,
    lat: -22.922588
  },
  {
    lng: -44.6284745,
    lat: -22.9220832
  },
  {
    lng: -44.6291638,
    lat: -22.9215242
  },
  {
    lng: -44.6296088,
    lat: -22.9213342
  },
  {
    lng: -44.6297771,
    lat: -22.9205635
  },
  {
    lng: -44.6298096,
    lat: -22.9200805
  },
  {
    lng: -44.6294582,
    lat: -22.9189427
  },
  {
    lng: -44.6295195,
    lat: -22.9185547
  },
  {
    lng: -44.6296902,
    lat: -22.9181972
  },
  {
    lng: -44.6299585,
    lat: -22.9175985
  },
  {
    lng: -44.6300376,
    lat: -22.9173668
  },
  {
    lng: -44.6302384,
    lat: -22.9170846
  },
  {
    lng: -44.6304392,
    lat: -22.9169923
  },
  {
    lng: -44.630716,
    lat: -22.916759
  },
  {
    lng: -44.6309928,
    lat: -22.916645
  },
  {
    lng: -44.6310199,
    lat: -22.9164876
  },
  {
    lng: -44.6311828,
    lat: -22.9163302
  },
  {
    lng: -44.6317364,
    lat: -22.9159448
  },
  {
    lng: -44.6322411,
    lat: -22.9157495
  },
  {
    lng: -44.6326156,
    lat: -22.9154889
  },
  {
    lng: -44.6328598,
    lat: -22.9154401
  },
  {
    lng: -44.6329955,
    lat: -22.9153207
  },
  {
    lng: -44.6330777,
    lat: -22.9151175
  },
  {
    lng: -44.6331029,
    lat: -22.9148356
  },
  {
    lng: -44.6333166,
    lat: -22.9145372
  },
  {
    lng: -44.6335471,
    lat: -22.9143751
  },
  {
    lng: -44.6339311,
    lat: -22.9142983
  },
  {
    lng: -44.6343066,
    lat: -22.9143495
  },
  {
    lng: -44.6346354,
    lat: -22.9144678
  },
  {
    lng: -44.6349495,
    lat: -22.9147258
  },
  {
    lng: -44.635185,
    lat: -22.9151072
  },
  {
    lng: -44.6354655,
    lat: -22.9153988
  },
  {
    lng: -44.6358244,
    lat: -22.915354
  },
  {
    lng: -44.6362731,
    lat: -22.9150287
  },
  {
    lng: -44.6365255,
    lat: -22.9148268
  },
  {
    lng: -44.6367778,
    lat: -22.9146249
  },
  {
    lng: -44.6372377,
    lat: -22.9143893
  },
  {
    lng: -44.6377201,
    lat: -22.914322
  },
  {
    lng: -44.6381799,
    lat: -22.9141874
  },
  {
    lng: -44.6386735,
    lat: -22.9141538
  },
  {
    lng: -44.6386733,
    lat: -22.9142883
  },
  {
    lng: -44.6392465,
    lat: -22.9151252
  },
  {
    lng: -44.6396724,
    lat: -22.9155878
  },
  {
    lng: -44.6398749,
    lat: -22.9157174
  },
  {
    lng: -44.64017,
    lat: -22.915841
  },
  {
    lng: -44.6410793,
    lat: -22.9160372
  },
  {
    lng: -44.6414588,
    lat: -22.9163552
  },
  {
    lng: -44.6418077,
    lat: -22.9168167
  },
  {
    lng: -44.642118,
    lat: -22.9170983
  },
  {
    lng: -44.6428395,
    lat: -22.9175245
  },
  {
    lng: -44.6434142,
    lat: -22.9177706
  },
  {
    lng: -44.644378,
    lat: -22.9180392
  },
  {
    lng: -44.6446262,
    lat: -22.918206
  },
  {
    lng: -44.6448434,
    lat: -22.9182995
  },
  {
    lng: -44.6451935,
    lat: -22.9183148
  },
  {
    lng: -44.645785,
    lat: -22.9181147
  },
  {
    lng: -44.6461155,
    lat: -22.9181308
  },
  {
    lng: -44.6463991,
    lat: -22.9181955
  },
  {
    lng: -44.6465081,
    lat: -22.9182816
  },
  {
    lng: -44.6466082,
    lat: -22.9184336
  },
  {
    lng: -44.6466469,
    lat: -22.9185484
  },
  {
    lng: -44.6466387,
    lat: -22.9186857
  },
  {
    lng: -44.6464983,
    lat: -22.9190458
  },
  {
    lng: -44.6464666,
    lat: -22.9192616
  },
  {
    lng: -44.6465588,
    lat: -22.9194416
  },
  {
    lng: -44.6466444,
    lat: -22.9195213
  },
  {
    lng: -44.6468226,
    lat: -22.9196147
  },
  {
    lng: -44.6469863,
    lat: -22.9196512
  },
  {
    lng: -44.647826,
    lat: -22.9196314
  },
  {
    lng: -44.6479966,
    lat: -22.9196535
  },
  {
    lng: -44.6483007,
    lat: -22.9197192
  },
  {
    lng: -44.6485101,
    lat: -22.9198208
  },
  {
    lng: -44.6492081,
    lat: -22.9202903
  },
  {
    lng: -44.6504665,
    lat: -22.9209127
  },
  {
    lng: -44.6518161,
    lat: -22.9217233
  },
  {
    lng: -44.6522744,
    lat: -22.9221191
  },
  {
    lng: -44.652461,
    lat: -22.9223715
  },
  {
    lng: -44.6527091,
    lat: -22.9225879
  },
  {
    lng: -44.6534072,
    lat: -22.9230222
  },
  {
    lng: -44.6537258,
    lat: -22.9231448
  },
  {
    lng: -44.6539208,
    lat: -22.9231669
  },
  {
    lng: -44.6541069,
    lat: -22.9232179
  },
  {
    lng: -44.6547525,
    lat: -22.9232193
  },
  {
    lng: -44.6550633,
    lat: -22.9233564
  },
  {
    lng: -44.6564912,
    lat: -22.9244399
  },
  {
    lng: -44.6568937,
    lat: -22.9249313
  },
  {
    lng: -44.6571219,
    lat: -22.9253247
  },
  {
    lng: -44.657343,
    lat: -22.925832
  },
  {
    lng: -44.657637,
    lat: -22.9264017
  },
  {
    lng: -44.6580471,
    lat: -22.9270151
  },
  {
    lng: -44.6584817,
    lat: -22.9275345
  },
  {
    lng: -44.658994,
    lat: -22.9279312
  },
  {
    lng: -44.6595068,
    lat: -22.9278818
  },
  {
    lng: -44.6603471,
    lat: -22.9276388
  },
  {
    lng: -44.6616,
    lat: -22.9273533
  },
  {
    lng: -44.662603,
    lat: -22.9271974
  },
  {
    lng: -44.6629924,
    lat: -22.9270753
  },
  {
    lng: -44.6634435,
    lat: -22.9268748
  },
  {
    lng: -44.6640271,
    lat: -22.9266891
  },
  {
    lng: -44.6643388,
    lat: -22.9264738
  },
  {
    lng: -44.664635,
    lat: -22.9261863
  },
  {
    lng: -44.6653282,
    lat: -22.9258562
  },
  {
    lng: -44.6657017,
    lat: -22.9254614
  },
  {
    lng: -44.6662948,
    lat: -22.924627
  },
  {
    lng: -44.6665059,
    lat: -22.9244323
  },
  {
    lng: -44.6667626,
    lat: -22.9243543
  },
  {
    lng: -44.667213,
    lat: -22.9243841
  },
  {
    lng: -44.6676256,
    lat: -22.9243705
  },
  {
    lng: -44.6679986,
    lat: -22.9241907
  },
  {
    lng: -44.6682789,
    lat: -22.9240043
  },
  {
    lng: -44.66849,
    lat: -22.9238105
  },
  {
    lng: -44.6686456,
    lat: -22.9236021
  },
  {
    lng: -44.6688647,
    lat: -22.9229468
  },
  {
    lng: -44.6691227,
    lat: -22.9227242
  },
  {
    lng: -44.6695581,
    lat: -22.9225453
  },
  {
    lng: -44.6701572,
    lat: -22.9224165
  },
  {
    lng: -44.6707874,
    lat: -22.9223311
  },
  {
    lng: -44.6712382,
    lat: -22.92221
  },
  {
    lng: -44.6716121,
    lat: -22.9220663
  },
  {
    lng: -44.6717285,
    lat: -22.9219012
  },
  {
    lng: -44.6717373,
    lat: -22.9214694
  },
  {
    lng: -44.6718239,
    lat: -22.9211742
  },
  {
    lng: -44.6719569,
    lat: -22.921001
  },
  {
    lng: -44.6722372,
    lat: -22.9208291
  },
  {
    lng: -44.6723614,
    lat: -22.9206785
  },
  {
    lng: -44.6723539,
    lat: -22.9205701
  },
  {
    lng: -44.6722305,
    lat: -22.9203828
  },
  {
    lng: -44.671602,
    lat: -22.9197835
  },
  {
    lng: -44.6715869,
    lat: -22.9195387
  },
  {
    lng: -44.6716578,
    lat: -22.9192868
  },
  {
    lng: -44.6717204,
    lat: -22.9191785
  },
  {
    lng: -44.6718993,
    lat: -22.9189991
  },
  {
    lng: -44.6723507,
    lat: -22.9186541
  },
  {
    lng: -44.6730289,
    lat: -22.9180656
  },
  {
    lng: -44.6731841,
    lat: -22.9179864
  },
  {
    lng: -44.6733324,
    lat: -22.9179578
  },
  {
    lng: -44.6735577,
    lat: -22.9179727
  },
  {
    lng: -44.6738374,
    lat: -22.9180456
  },
  {
    lng: -44.6744112,
    lat: -22.9186944
  },
  {
    lng: -44.6749697,
    lat: -22.9191996
  },
  {
    lng: -44.675241,
    lat: -22.9195462
  },
  {
    lng: -44.6754041,
    lat: -22.9198419
  },
  {
    lng: -44.6754962,
    lat: -22.9200941
  },
  {
    lng: -44.6755653,
    lat: -22.9205261
  },
  {
    lng: -44.675666,
    lat: -22.9208289
  },
  {
    lng: -44.6759604,
    lat: -22.9213119
  },
  {
    lng: -44.6762095,
    lat: -22.9215274
  },
  {
    lng: -44.6762085,
    lat: -22.9215644
  },
  {
    lng: -44.6764176,
    lat: -22.9217889
  },
  {
    lng: -44.6766265,
    lat: -22.9221055
  },
  {
    lng: -44.676743,
    lat: -22.9223505
  },
  {
    lng: -44.6769666,
    lat: -22.9230429
  },
  {
    lng: -44.6771531,
    lat: -22.9233748
  },
  {
    lng: -44.6775401,
    lat: -22.9238508
  },
  {
    lng: -44.677866,
    lat: -22.9241757
  },
  {
    lng: -44.6782225,
    lat: -22.924788
  },
  {
    lng: -44.6784388,
    lat: -22.9252853
  },
  {
    lng: -44.678516,
    lat: -22.9256315
  },
  {
    lng: -44.6784841,
    lat: -22.9259196
  },
  {
    lng: -44.6786072,
    lat: -22.9262152
  },
  {
    lng: -44.6788252,
    lat: -22.9264388
  },
  {
    lng: -44.6789949,
    lat: -22.9264093
  },
  {
    lng: -44.6797131,
    lat: -22.9258498
  },
  {
    lng: -44.6799151,
    lat: -22.9257788
  },
  {
    lng: -44.6801482,
    lat: -22.9257721
  },
  {
    lng: -44.6808769,
    lat: -22.9265224
  },
  {
    lng: -44.680939,
    lat: -22.9266734
  },
  {
    lng: -44.680969,
    lat: -22.9271775
  },
  {
    lng: -44.6811399,
    lat: -22.9275094
  },
  {
    lng: -44.6815504,
    lat: -22.9279348
  },
  {
    lng: -44.6821248,
    lat: -22.9283831
  },
  {
    lng: -44.6822564,
    lat: -22.9288079
  },
  {
    lng: -44.6823158,
    lat: -22.9297087
  },
  {
    lng: -44.6824396,
    lat: -22.9301335
  },
  {
    lng: -44.682564,
    lat: -22.9303352
  },
  {
    lng: -44.6827188,
    lat: -22.9304583
  },
  {
    lng: -44.6830142,
    lat: -22.9304878
  },
  {
    lng: -44.6833335,
    lat: -22.9303006
  },
  {
    lng: -44.6836681,
    lat: -22.9302949
  },
  {
    lng: -44.6842511,
    lat: -22.9303964
  },
  {
    lng: -44.6851829,
    lat: -22.930643
  },
  {
    lng: -44.6854625,
    lat: -22.9307664
  },
  {
    lng: -44.685812,
    lat: -22.9310119
  },
  {
    lng: -44.6864327,
    lat: -22.9317114
  },
  {
    lng: -44.6869057,
    lat: -22.9321234
  },
  {
    lng: -44.6872237,
    lat: -22.9325197
  },
  {
    lng: -44.6873013,
    lat: -22.932686
  },
  {
    lng: -44.687402,
    lat: -22.9330096
  },
  {
    lng: -44.6874779,
    lat: -22.9335428
  },
  {
    lng: -44.6874694,
    lat: -22.9338309
  },
  {
    lng: -44.6873363,
    lat: -22.9344503
  },
  {
    lng: -44.687031,
    lat: -22.935307
  },
  {
    lng: -44.6869679,
    lat: -22.9356231
  },
  {
    lng: -44.686975,
    lat: -22.9359185
  },
  {
    lng: -44.6871837,
    lat: -22.9363516
  },
  {
    lng: -44.6874785,
    lat: -22.9366548
  },
  {
    lng: -44.6877348,
    lat: -22.93677
  },
  {
    lng: -44.6880536,
    lat: -22.9368068
  },
  {
    lng: -44.6884904,
    lat: -22.9364616
  },
  {
    lng: -44.6887711,
    lat: -22.9360954
  },
  {
    lng: -44.6890203,
    lat: -22.9358864
  },
  {
    lng: -44.6893708,
    lat: -22.9357506
  },
  {
    lng: -44.6899777,
    lat: -22.935629
  },
  {
    lng: -44.690476,
    lat: -22.9352343
  },
  {
    lng: -44.6909896,
    lat: -22.9349832
  },
  {
    lng: -44.6924604,
    lat: -22.9345326
  },
  {
    lng: -44.6929193,
    lat: -22.9343023
  },
  {
    lng: -44.6940345,
    lat: -22.9332457
  },
  {
    lng: -44.6946965,
    lat: -22.9324692
  },
  {
    lng: -44.6952414,
    lat: -22.9321613
  },
  {
    lng: -44.6957166,
    lat: -22.9320538
  },
  {
    lng: -44.696369,
    lat: -22.9320767
  },
  {
    lng: -44.698685,
    lat: -22.9326792
  },
  {
    lng: -44.6996264,
    lat: -22.9325725
  },
  {
    lng: -44.7003103,
    lat: -22.9328909
  },
  {
    lng: -44.700527,
    lat: -22.9332517
  },
  {
    lng: -44.7006425,
    lat: -22.9335248
  },
  {
    lng: -44.7007043,
    lat: -22.9338058
  },
  {
    lng: -44.7007881,
    lat: -22.93475
  },
  {
    lng: -44.7008734,
    lat: -22.9349733
  },
  {
    lng: -44.701028,
    lat: -22.9351967
  },
  {
    lng: -44.7012295,
    lat: -22.9353768
  },
  {
    lng: -44.7017729,
    lat: -22.9357527
  },
  {
    lng: -44.7022786,
    lat: -22.9359696
  },
  {
    lng: -44.7026051,
    lat: -22.9360641
  },
  {
    lng: -44.702923,
    lat: -22.9361081
  },
  {
    lng: -44.7035452,
    lat: -22.936102
  },
  {
    lng: -44.7037046,
    lat: -22.9359325
  },
  {
    lng: -44.703779,
    lat: -22.9357854
  },
  {
    lng: -44.7038183,
    lat: -22.9356626
  },
  {
    lng: -44.7038585,
    lat: -22.9351369
  },
  {
    lng: -44.7040229,
    lat: -22.9348563
  },
  {
    lng: -44.7047472,
    lat: -22.9341232
  },
  {
    lng: -44.7053323,
    lat: -22.9332887
  },
  {
    lng: -44.7059402,
    lat: -22.9327
  },
  {
    lng: -44.7064083,
    lat: -22.9317785
  },
  {
    lng: -44.706635,
    lat: -22.9316064
  },
  {
    lng: -44.7069922,
    lat: -22.9314951
  },
  {
    lng: -44.7073579,
    lat: -22.9314993
  },
  {
    lng: -44.7078626,
    lat: -22.9317234
  },
  {
    lng: -44.708361,
    lat: -22.9317243
  },
  {
    lng: -44.7088665,
    lat: -22.9315527
  },
  {
    lng: -44.7090373,
    lat: -22.9315241
  },
  {
    lng: -44.7094888,
    lat: -22.9315394
  },
  {
    lng: -44.709978,
    lat: -22.9317273
  },
  {
    lng: -44.7101331,
    lat: -22.9317203
  },
  {
    lng: -44.7102347,
    lat: -22.9316347
  },
  {
    lng: -44.7102662,
    lat: -22.9314975
  },
  {
    lng: -44.7101501,
    lat: -22.9310365
  },
  {
    lng: -44.7102131,
    lat: -22.9307702
  },
  {
    lng: -44.7104627,
    lat: -22.9303813
  },
  {
    lng: -44.7108212,
    lat: -22.930101
  },
  {
    lng: -44.7112799,
    lat: -22.9299437
  },
  {
    lng: -44.7115062,
    lat: -22.9299297
  },
  {
    lng: -44.7122675,
    lat: -22.9300828
  },
  {
    lng: -44.7127259,
    lat: -22.9301044
  },
  {
    lng: -44.7128586,
    lat: -22.9300695
  },
  {
    lng: -44.7130921,
    lat: -22.9298892
  },
  {
    lng: -44.7132473,
    lat: -22.9298254
  },
  {
    lng: -44.7134344,
    lat: -22.9298754
  },
  {
    lng: -44.7136127,
    lat: -22.9299696
  },
  {
    lng: -44.7137605,
    lat: -22.9301569
  },
  {
    lng: -44.7139306,
    lat: -22.9304598
  },
  {
    lng: -44.7142713,
    lat: -22.9312455
  },
  {
    lng: -44.7143636,
    lat: -22.9318653
  },
  {
    lng: -44.7145335,
    lat: -22.9322324
  },
  {
    lng: -44.7148518,
    lat: -22.9325356
  },
  {
    lng: -44.7153331,
    lat: -22.9327812
  },
  {
    lng: -44.7154499,
    lat: -22.9328754
  },
  {
    lng: -44.7155344,
    lat: -22.9330264
  },
  {
    lng: -44.7156352,
    lat: -22.9333581
  },
  {
    lng: -44.7157984,
    lat: -22.9336683
  },
  {
    lng: -44.7167203,
    lat: -22.9349301
  },
  {
    lng: -44.7168139,
    lat: -22.9349591
  },
  {
    lng: -44.7169921,
    lat: -22.9351184
  },
  {
    lng: -44.7172337,
    lat: -22.9352481
  },
  {
    lng: -44.7174276,
    lat: -22.9353134
  },
  {
    lng: -44.7176685,
    lat: -22.9353283
  },
  {
    lng: -44.7179799,
    lat: -22.9351916
  },
  {
    lng: -44.7183776,
    lat: -22.9348111
  },
  {
    lng: -44.7186656,
    lat: -22.9346959
  },
  {
    lng: -44.7188909,
    lat: -22.9346963
  },
  {
    lng: -44.7192418,
    lat: -22.9348054
  },
  {
    lng: -44.7194737,
    lat: -22.9349205
  },
  {
    lng: -44.7199078,
    lat: -22.9353178
  },
  {
    lng: -44.7203039,
    lat: -22.9357576
  },
  {
    lng: -44.7210106,
    lat: -22.9359603
  },
  {
    lng: -44.7213292,
    lat: -22.9360981
  },
  {
    lng: -44.7219582,
    lat: -22.9366033
  },
  {
    lng: -44.7221985,
    lat: -22.9369136
  },
  {
    lng: -44.7223151,
    lat: -22.9371297
  },
  {
    lng: -44.7227417,
    lat: -22.9374186
  },
  {
    lng: -44.7234336,
    lat: -22.9377224
  },
  {
    lng: -44.7240157,
    lat: -22.9382998
  },
  {
    lng: -44.7242407,
    lat: -22.9388936
  },
  {
    lng: -44.7244957,
    lat: -22.9391859
  },
  {
    lng: -44.7245763,
    lat: -22.939336
  },
  {
    lng: -44.7253728,
    lat: -22.9400085
  },
  {
    lng: -44.7258515,
    lat: -22.9401286
  },
  {
    lng: -44.7262123,
    lat: -22.9401473
  },
  {
    lng: -44.7269592,
    lat: -22.9402488
  },
  {
    lng: -44.7272309,
    lat: -22.9404444
  },
  {
    lng: -44.7273078,
    lat: -22.9405376
  },
  {
    lng: -44.7274165,
    lat: -22.9408187
  },
  {
    lng: -44.727509,
    lat: -22.9413952
  },
  {
    lng: -44.7276246,
    lat: -22.9415896
  },
  {
    lng: -44.7278651,
    lat: -22.9418204
  },
  {
    lng: -44.7281067,
    lat: -22.9419581
  },
  {
    lng: -44.7286428,
    lat: -22.9421244
  },
  {
    lng: -44.7289751,
    lat: -22.9422785
  },
  {
    lng: -44.7293805,
    lat: -22.9424571
  },
  {
    lng: -44.7299166,
    lat: -22.9426451
  },
  {
    lng: -44.730989,
    lat: -22.9428917
  },
  {
    lng: -44.731634,
    lat: -22.9432677
  },
  {
    lng: -44.7326904,
    lat: -22.9442198
  },
  {
    lng: -44.7331415,
    lat: -22.944487
  },
  {
    lng: -44.7346013,
    lat: -22.9451308
  },
  {
    lng: -44.7351307,
    lat: -22.9452609
  },
  {
    lng: -44.7353159,
    lat: -22.9453407
  },
  {
    lng: -44.736693,
    lat: -22.9453936
  },
  {
    lng: -44.7375937,
    lat: -22.9456977
  },
  {
    lng: -44.7377732,
    lat: -22.9456763
  },
  {
    lng: -44.7380999,
    lat: -22.9457202
  },
  {
    lng: -44.7384106,
    lat: -22.9459438
  },
  {
    lng: -44.7385497,
    lat: -22.9461094
  },
  {
    lng: -44.7386745,
    lat: -22.946153
  },
  {
    lng: -44.7392571,
    lat: -22.9465433
  },
  {
    lng: -44.7396216,
    lat: -22.9466803
  },
  {
    lng: -44.7400886,
    lat: -22.9467677
  },
  {
    lng: -44.7406952,
    lat: -22.9468266
  },
  {
    lng: -44.7413946,
    lat: -22.9468132
  },
  {
    lng: -44.741496,
    lat: -22.9468495
  },
  {
    lng: -44.7419544,
    lat: -22.9468575
  },
  {
    lng: -44.7424438,
    lat: -22.946973
  },
  {
    lng: -44.7427783,
    lat: -22.9470169
  },
  {
    lng: -44.7431441,
    lat: -22.9469886
  },
  {
    lng: -44.7431783,
    lat: -22.9469901
  },
  {
    lng: -44.7435021,
    lat: -22.9470037
  },
  {
    lng: -44.7436805,
    lat: -22.9470618
  },
  {
    lng: -44.7438207,
    lat: -22.9472056
  },
  {
    lng: -44.7438819,
    lat: -22.9473411
  },
  {
    lng: -44.7438802,
    lat: -22.9482211
  },
  {
    lng: -44.7439421,
    lat: -22.9484949
  },
  {
    lng: -44.7440431,
    lat: -22.9487254
  },
  {
    lng: -44.7441365,
    lat: -22.9488629
  },
  {
    lng: -44.744268,
    lat: -22.9489787
  },
  {
    lng: -44.7449052,
    lat: -22.949332
  },
  {
    lng: -44.745052,
    lat: -22.9495843
  },
  {
    lng: -44.7450589,
    lat: -22.9500956
  },
  {
    lng: -44.7452298,
    lat: -22.9505141
  },
  {
    lng: -44.7452292,
    lat: -22.9508023
  },
  {
    lng: -44.7450099,
    lat: -22.9512337
  },
  {
    lng: -44.7449712,
    lat: -22.9515869
  },
  {
    lng: -44.7450867,
    lat: -22.9518897
  },
  {
    lng: -44.7452736,
    lat: -22.9520914
  },
  {
    lng: -44.7456226,
    lat: -22.9522212
  },
  {
    lng: -44.7468669,
    lat: -22.9524111
  },
  {
    lng: -44.7472871,
    lat: -22.9525048
  },
  {
    lng: -44.7477296,
    lat: -22.9527286
  },
  {
    lng: -44.7480635,
    lat: -22.953104
  },
  {
    lng: -44.7485606,
    lat: -22.9532701
  },
  {
    lng: -44.7488949,
    lat: -22.9534296
  },
  {
    lng: -44.7490332,
    lat: -22.9535446
  },
  {
    lng: -44.7491275,
    lat: -22.9537254
  },
  {
    lng: -44.7491268,
    lat: -22.9541066
  },
  {
    lng: -44.7492355,
    lat: -22.9544193
  },
  {
    lng: -44.7494056,
    lat: -22.9547267
  },
  {
    lng: -44.7494453,
    lat: -22.9548804
  },
  {
    lng: -44.7494362,
    lat: -22.9550439
  },
  {
    lng: -44.7493736,
    lat: -22.9551802
  },
  {
    lng: -44.7491869,
    lat: -22.9553813
  },
  {
    lng: -44.7488576,
    lat: -22.9556256
  },
  {
    lng: -44.7483988,
    lat: -22.9559522
  },
  {
    lng: -44.7482352,
    lat: -22.9561535
  },
  {
    lng: -44.7482101,
    lat: -22.95638
  },
  {
    lng: -44.7482352,
    lat: -22.9566191
  },
  {
    lng: -44.7482478,
    lat: -22.9568707
  },
  {
    lng: -44.748361,
    lat: -22.9571097
  },
  {
    lng: -44.7484617,
    lat: -22.9574117
  },
  {
    lng: -44.7487511,
    lat: -22.9574998
  },
  {
    lng: -44.7488203,
    lat: -22.9575815
  },
  {
    lng: -44.7488203,
    lat: -22.9576916
  },
  {
    lng: -44.7487731,
    lat: -22.9577545
  },
  {
    lng: -44.7487322,
    lat: -22.9578332
  },
  {
    lng: -44.748792,
    lat: -22.9578772
  },
  {
    lng: -44.7489052,
    lat: -22.9578929
  },
  {
    lng: -44.7488895,
    lat: -22.9579621
  },
  {
    lng: -44.7488643,
    lat: -22.9580156
  },
  {
    lng: -44.7488171,
    lat: -22.9580282
  },
  {
    lng: -44.7487888,
    lat: -22.9580974
  },
  {
    lng: -44.7488423,
    lat: -22.9581446
  },
  {
    lng: -44.7489269,
    lat: -22.9581609
  },
  {
    lng: -44.7489524,
    lat: -22.9582201
  },
  {
    lng: -44.7489241,
    lat: -22.9583113
  },
  {
    lng: -44.7489712,
    lat: -22.9583616
  },
  {
    lng: -44.7490058,
    lat: -22.958327
  },
  {
    lng: -44.7490499,
    lat: -22.9582609
  },
  {
    lng: -44.7491285,
    lat: -22.9582484
  },
  {
    lng: -44.7492167,
    lat: -22.9582672
  },
  {
    lng: -44.7492134,
    lat: -22.9583301
  },
  {
    lng: -44.74916,
    lat: -22.9584025
  },
  {
    lng: -44.7491757,
    lat: -22.958522
  },
  {
    lng: -44.7492587,
    lat: -22.9587599
  },
  {
    lng: -44.7494281,
    lat: -22.9589328
  },
  {
    lng: -44.7496425,
    lat: -22.9590541
  },
  {
    lng: -44.7497632,
    lat: -22.9591998
  },
  {
    lng: -44.7498786,
    lat: -22.9595459
  },
  {
    lng: -44.7498777,
    lat: -22.9600428
  },
  {
    lng: -44.7499419,
    lat: -22.9601359
  },
  {
    lng: -44.7501134,
    lat: -22.960231
  },
  {
    lng: -44.7501981,
    lat: -22.9603287
  },
  {
    lng: -44.7502388,
    lat: -22.9604869
  },
  {
    lng: -44.7503202,
    lat: -22.9606308
  },
  {
    lng: -44.7504711,
    lat: -22.9607261
  },
  {
    lng: -44.7505251,
    lat: -22.9609031
  },
  {
    lng: -44.750437,
    lat: -22.961161
  },
  {
    lng: -44.7504265,
    lat: -22.9614179
  },
  {
    lng: -44.7505387,
    lat: -22.9616057
  },
  {
    lng: -44.7506509,
    lat: -22.9617397
  },
  {
    lng: -44.7508648,
    lat: -22.9620291
  },
  {
    lng: -44.7515788,
    lat: -22.96246
  },
  {
    lng: -44.7517661,
    lat: -22.9626382
  },
  {
    lng: -44.7517675,
    lat: -22.9629633
  },
  {
    lng: -44.7516794,
    lat: -22.9632023
  },
  {
    lng: -44.7514781,
    lat: -22.9635294
  },
  {
    lng: -44.7509245,
    lat: -22.9643095
  },
  {
    lng: -44.7508355,
    lat: -22.9644109
  },
  {
    lng: -44.7507014,
    lat: -22.9645467
  },
  {
    lng: -44.7506379,
    lat: -22.9645944
  },
  {
    lng: -44.7502427,
    lat: -22.9647344
  },
  {
    lng: -44.7500651,
    lat: -22.9647802
  },
  {
    lng: -44.7499313,
    lat: -22.9648252
  },
  {
    lng: -44.7498893,
    lat: -22.9648531
  },
  {
    lng: -44.7498532,
    lat: -22.9648973
  },
  {
    lng: -44.7498394,
    lat: -22.9649686
  },
  {
    lng: -44.7498529,
    lat: -22.9650554
  },
  {
    lng: -44.7500165,
    lat: -22.9652282
  },
  {
    lng: -44.750024,
    lat: -22.9653872
  },
  {
    lng: -44.7499065,
    lat: -22.9655884
  },
  {
    lng: -44.7495333,
    lat: -22.965876
  },
  {
    lng: -44.7494238,
    lat: -22.9660556
  },
  {
    lng: -44.7493688,
    lat: -22.966257
  },
  {
    lng: -44.7493996,
    lat: -22.9664738
  },
  {
    lng: -44.7493837,
    lat: -22.9666319
  },
  {
    lng: -44.7491965,
    lat: -22.967114
  },
  {
    lng: -44.7491645,
    lat: -22.9674888
  },
  {
    lng: -44.7491953,
    lat: -22.9677337
  },
  {
    lng: -44.7493109,
    lat: -22.968022
  },
  {
    lng: -44.7493728,
    lat: -22.968303
  },
  {
    lng: -44.7492233,
    lat: -22.9694618
  },
  {
    lng: -44.7492997,
    lat: -22.9698224
  },
  {
    lng: -44.7493697,
    lat: -22.9699733
  },
  {
    lng: -44.7494476,
    lat: -22.9700529
  },
  {
    lng: -44.7495948,
    lat: -22.9701182
  },
  {
    lng: -44.7498211,
    lat: -22.9701475
  },
  {
    lng: -44.7499994,
    lat: -22.970248
  },
  {
    lng: -44.7501385,
    lat: -22.9704651
  },
  {
    lng: -44.7502434,
    lat: -22.9707444
  },
  {
    lng: -44.7503837,
    lat: -22.9711395
  },
  {
    lng: -44.751031,
    lat: -22.972011
  },
  {
    lng: -44.7515498,
    lat: -22.9721926
  },
  {
    lng: -44.7522243,
    lat: -22.9722964
  },
  {
    lng: -44.752821,
    lat: -22.9724002
  },
  {
    lng: -44.7533917,
    lat: -22.9725818
  },
  {
    lng: -44.753729,
    lat: -22.9728412
  },
  {
    lng: -44.7545332,
    lat: -22.9731006
  },
  {
    lng: -44.7550261,
    lat: -22.9733341
  },
  {
    lng: -44.7562453,
    lat: -22.973827
  },
  {
    lng: -44.7567901,
    lat: -22.9743199
  },
  {
    lng: -44.7567901,
    lat: -22.9753316
  },
  {
    lng: -44.7566345,
    lat: -22.9761618
  },
  {
    lng: -44.7566085,
    lat: -22.9765509
  },
  {
    lng: -44.7561416,
    lat: -22.9770179
  },
  {
    lng: -44.7552595,
    lat: -22.9776405
  },
  {
    lng: -44.7545591,
    lat: -22.9780815
  },
  {
    lng: -44.7543516,
    lat: -22.9786003
  },
  {
    lng: -44.7544553,
    lat: -22.9790413
  },
  {
    lng: -44.754611,
    lat: -22.9794564
  },
  {
    lng: -44.7552595,
    lat: -22.9803125
  },
  {
    lng: -44.75596,
    lat: -22.9803903
  },
  {
    lng: -44.756842,
    lat: -22.9799234
  },
  {
    lng: -44.7577065,
    lat: -22.9794994
  },
  {
    lng: -44.7585166,
    lat: -22.9792116
  },
  {
    lng: -44.7593433,
    lat: -22.9790954
  },
  {
    lng: -44.7599508,
    lat: -22.9793145
  },
  {
    lng: -44.7607333,
    lat: -22.9800271
  },
  {
    lng: -44.7617487,
    lat: -22.9807806
  },
  {
    lng: -44.7621018,
    lat: -22.9815051
  },
  {
    lng: -44.7628605,
    lat: -22.9822063
  },
  {
    lng: -44.7633016,
    lat: -22.9828029
  },
  {
    lng: -44.7642735,
    lat: -22.9831817
  },
  {
    lng: -44.7648405,
    lat: -22.9833138
  },
  {
    lng: -44.7653995,
    lat: -22.9831628
  },
  {
    lng: -44.7664103,
    lat: -22.982484
  },
  {
    lng: -44.7667166,
    lat: -22.9822338
  },
  {
    lng: -44.7670345,
    lat: -22.982045
  },
  {
    lng: -44.7675841,
    lat: -22.9818827
  },
  {
    lng: -44.7680357,
    lat: -22.9817018
  },
  {
    lng: -44.7684082,
    lat: -22.9815276
  },
  {
    lng: -44.7688754,
    lat: -22.9812893
  },
  {
    lng: -44.7699122,
    lat: -22.9808834
  },
  {
    lng: -44.7704736,
    lat: -22.9808123
  },
  {
    lng: -44.7709872,
    lat: -22.9808229
  },
  {
    lng: -44.7716549,
    lat: -22.9809351
  },
  {
    lng: -44.772022,
    lat: -22.9812207
  },
  {
    lng: -44.7725629,
    lat: -22.9814021
  },
  {
    lng: -44.7730654,
    lat: -22.9814553
  },
  {
    lng: -44.7731698,
    lat: -22.9813204
  },
  {
    lng: -44.7736353,
    lat: -22.9806056
  },
  {
    lng: -44.773742,
    lat: -22.9800972
  },
  {
    lng: -44.7742533,
    lat: -22.979466
  },
  {
    lng: -44.7749167,
    lat: -22.9788784
  },
  {
    lng: -44.7757037,
    lat: -22.9783315
  },
  {
    lng: -44.7763502,
    lat: -22.9781402
  },
  {
    lng: -44.7767395,
    lat: -22.9780555
  },
  {
    lng: -44.7773696,
    lat: -22.9781922
  },
  {
    lng: -44.7778031,
    lat: -22.9784187
  },
  {
    lng: -44.7785773,
    lat: -22.9785787
  },
  {
    lng: -44.7791521,
    lat: -22.9786003
  },
  {
    lng: -44.7795413,
    lat: -22.9786003
  },
  {
    lng: -44.7804369,
    lat: -22.9785375
  },
  {
    lng: -44.7812534,
    lat: -22.9784706
  },
  {
    lng: -44.7817204,
    lat: -22.9785225
  },
  {
    lng: -44.7827157,
    lat: -22.9786716
  },
  {
    lng: -44.7834684,
    lat: -22.978955
  },
  {
    lng: -44.7841856,
    lat: -22.9792621
  },
  {
    lng: -44.7846557,
    lat: -22.9796444
  },
  {
    lng: -44.7851453,
    lat: -22.9800295
  },
  {
    lng: -44.7856871,
    lat: -22.9805018
  },
  {
    lng: -44.7862375,
    lat: -22.9817723
  },
  {
    lng: -44.7864327,
    lat: -22.9821852
  },
  {
    lng: -44.7875625,
    lat: -22.9825454
  },
  {
    lng: -44.7881115,
    lat: -22.9824201
  },
  {
    lng: -44.7885117,
    lat: -22.9821815
  },
  {
    lng: -44.789103,
    lat: -22.9817285
  },
  {
    lng: -44.7897624,
    lat: -22.9813502
  },
  {
    lng: -44.7905147,
    lat: -22.9812983
  },
  {
    lng: -44.7912867,
    lat: -22.9814582
  },
  {
    lng: -44.7918982,
    lat: -22.9818759
  },
  {
    lng: -44.7922529,
    lat: -22.9824657
  },
  {
    lng: -44.7925036,
    lat: -22.98321
  },
  {
    lng: -44.7925901,
    lat: -22.9836071
  },
  {
    lng: -44.7927726,
    lat: -22.9843585
  },
  {
    lng: -44.7928236,
    lat: -22.9847486
  },
  {
    lng: -44.7930656,
    lat: -22.9851502
  },
  {
    lng: -44.793328,
    lat: -22.9854672
  },
  {
    lng: -44.7935195,
    lat: -22.9859647
  },
  {
    lng: -44.7936473,
    lat: -22.9863291
  },
  {
    lng: -44.7934311,
    lat: -22.9872867
  },
  {
    lng: -44.7930029,
    lat: -22.987577
  },
  {
    lng: -44.7928186,
    lat: -22.9878093
  },
  {
    lng: -44.7927591,
    lat: -22.9880816
  },
  {
    lng: -44.7925344,
    lat: -22.9886292
  },
  {
    lng: -44.7925642,
    lat: -22.9889512
  },
  {
    lng: -44.7924705,
    lat: -22.9895442
  },
  {
    lng: -44.7926679,
    lat: -22.9899889
  },
  {
    lng: -44.793254,
    lat: -22.990519
  },
  {
    lng: -44.7932434,
    lat: -22.9907228
  },
  {
    lng: -44.7929033,
    lat: -22.9911216
  },
  {
    lng: -44.7929382,
    lat: -22.9915719
  },
  {
    lng: -44.7926686,
    lat: -22.9920619
  },
  {
    lng: -44.7927878,
    lat: -22.992625
  },
  {
    lng: -44.7929014,
    lat: -22.9934132
  },
  {
    lng: -44.7950546,
    lat: -22.9939321
  },
  {
    lng: -44.7958363,
    lat: -22.9950481
  },
  {
    lng: -44.7965424,
    lat: -22.9956423
  },
  {
    lng: -44.7974123,
    lat: -22.995971
  },
  {
    lng: -44.798118,
    lat: -22.996075
  },
  {
    lng: -44.7987124,
    lat: -22.9962928
  },
  {
    lng: -44.7990277,
    lat: -22.9965192
  },
  {
    lng: -44.7995615,
    lat: -22.996491
  },
  {
    lng: -44.8008397,
    lat: -22.9965035
  },
  {
    lng: -44.8012564,
    lat: -22.9965004
  },
  {
    lng: -44.8014338,
    lat: -22.996649
  },
  {
    lng: -44.8015923,
    lat: -22.9970835
  },
  {
    lng: -44.8018617,
    lat: -22.9978923
  },
  {
    lng: -44.8025363,
    lat: -22.9987771
  },
  {
    lng: -44.8026359,
    lat: -22.999662
  },
  {
    lng: -44.8023886,
    lat: -23.0003982
  },
  {
    lng: -44.8023395,
    lat: -23.0005621
  },
  {
    lng: -44.8020936,
    lat: -23.0011193
  },
  {
    lng: -44.8016839,
    lat: -23.0015291
  },
  {
    lng: -44.8013397,
    lat: -23.0018405
  },
  {
    lng: -44.8008972,
    lat: -23.0021027
  },
  {
    lng: -44.8004055,
    lat: -23.0025944
  },
  {
    lng: -44.8001269,
    lat: -23.0034139
  },
  {
    lng: -44.8000122,
    lat: -23.0043645
  },
  {
    lng: -44.8002744,
    lat: -23.0054462
  },
  {
    lng: -44.8001433,
    lat: -23.0058395
  },
  {
    lng: -44.7996352,
    lat: -23.0064787
  },
  {
    lng: -44.7989468,
    lat: -23.0069212
  },
  {
    lng: -44.798619,
    lat: -23.0067901
  },
  {
    lng: -44.7982421,
    lat: -23.0065607
  },
  {
    lng: -44.7977012,
    lat: -23.0064459
  },
  {
    lng: -44.7966359,
    lat: -23.0066754
  },
  {
    lng: -44.7954886,
    lat: -23.0070524
  },
  {
    lng: -44.7953248,
    lat: -23.0072162
  },
  {
    lng: -44.7943086,
    lat: -23.0080685
  },
  {
    lng: -44.7938333,
    lat: -23.0084127
  },
  {
    lng: -44.7930302,
    lat: -23.0093633
  },
  {
    lng: -44.7928172,
    lat: -23.0098386
  },
  {
    lng: -44.7928499,
    lat: -23.0103139
  },
  {
    lng: -44.7929647,
    lat: -23.0108219
  },
  {
    lng: -44.7933252,
    lat: -23.0116414
  },
  {
    lng: -44.7938169,
    lat: -23.0121823
  },
  {
    lng: -44.794243,
    lat: -23.0125428
  },
  {
    lng: -44.7945053,
    lat: -23.0129034
  },
  {
    lng: -44.7947183,
    lat: -23.0130673
  },
  {
    lng: -44.7954395,
    lat: -23.0138704
  },
  {
    lng: -44.7958328,
    lat: -23.0142801
  },
  {
    lng: -44.796177,
    lat: -23.0148046
  },
  {
    lng: -44.7962426,
    lat: -23.0150013
  },
  {
    lng: -44.7965376,
    lat: -23.0155257
  },
  {
    lng: -44.7968981,
    lat: -23.0159355
  },
  {
    lng: -44.7970457,
    lat: -23.0161485
  },
  {
    lng: -44.7970457,
    lat: -23.0177711
  },
  {
    lng: -44.7970948,
    lat: -23.0182464
  },
  {
    lng: -44.7972095,
    lat: -23.0187381
  },
  {
    lng: -44.797456,
    lat: -23.0190049
  },
  {
    lng: -44.7977668,
    lat: -23.0192789
  },
  {
    lng: -44.7986354,
    lat: -23.0202623
  },
  {
    lng: -44.7984224,
    lat: -23.0208523
  },
  {
    lng: -44.7980123,
    lat: -23.0213334
  },
  {
    lng: -44.7973407,
    lat: -23.0221471
  },
  {
    lng: -44.7969136,
    lat: -23.0226884
  },
  {
    lng: -44.7966195,
    lat: -23.0230485
  },
  {
    lng: -44.7962098,
    lat: -23.023573
  },
  {
    lng: -44.795963,
    lat: -23.0239995
  },
  {
    lng: -44.7958984,
    lat: -23.0241138
  },
  {
    lng: -44.7958987,
    lat: -23.0243454
  },
  {
    lng: -44.7958984,
    lat: -23.0246875
  },
  {
    lng: -44.7958656,
    lat: -23.0253103
  },
  {
    lng: -44.7963901,
    lat: -23.0261953
  },
  {
    lng: -44.796554,
    lat: -23.0269492
  },
  {
    lng: -44.7967015,
    lat: -23.0272442
  },
  {
    lng: -44.7969473,
    lat: -23.0276048
  },
  {
    lng: -44.7974718,
    lat: -23.0283915
  },
  {
    lng: -44.7978815,
    lat: -23.0289488
  },
  {
    lng: -44.7984388,
    lat: -23.0297191
  },
  {
    lng: -44.799078,
    lat: -23.0302435
  },
  {
    lng: -44.799668,
    lat: -23.0304074
  },
  {
    lng: -44.8007661,
    lat: -23.0304566
  },
  {
    lng: -44.8015528,
    lat: -23.0308335
  },
  {
    lng: -44.8019625,
    lat: -23.0315875
  },
  {
    lng: -44.8020608,
    lat: -23.0324725
  },
  {
    lng: -44.801815,
    lat: -23.0333411
  },
  {
    lng: -44.8014217,
    lat: -23.0340623
  },
  {
    lng: -44.8008152,
    lat: -23.0346687
  },
  {
    lng: -44.8005858,
    lat: -23.0352915
  },
  {
    lng: -44.8007825,
    lat: -23.035816
  },
  {
    lng: -44.8012414,
    lat: -23.0368157
  },
  {
    lng: -44.8018478,
    lat: -23.0379466
  },
  {
    lng: -44.8019789,
    lat: -23.0385038
  },
  {
    lng: -44.8021756,
    lat: -23.0391758
  },
  {
    lng: -44.8026181,
    lat: -23.0396839
  },
  {
    lng: -44.8030442,
    lat: -23.0400608
  },
  {
    lng: -44.8030278,
    lat: -23.0407328
  },
  {
    lng: -44.8024706,
    lat: -23.0416179
  },
  {
    lng: -44.8021264,
    lat: -23.0420932
  },
  {
    lng: -44.8012742,
    lat: -23.0445188
  },
  {
    lng: -44.8012578,
    lat: -23.0453711
  },
  {
    lng: -44.8013233,
    lat: -23.0458627
  },
  {
    lng: -44.8013561,
    lat: -23.0465839
  },
  {
    lng: -44.8022084,
    lat: -23.0487801
  },
  {
    lng: -44.8027492,
    lat: -23.0491243
  },
  {
    lng: -44.8029951,
    lat: -23.049452
  },
  {
    lng: -44.8034048,
    lat: -23.0508124
  },
  {
    lng: -44.8039293,
    lat: -23.0495504
  },
  {
    lng: -44.8046176,
    lat: -23.0492882
  },
  {
    lng: -44.8055846,
    lat: -23.0494848
  },
  {
    lng: -44.8060763,
    lat: -23.0496815
  },
  {
    lng: -44.8065024,
    lat: -23.0498618
  },
  {
    lng: -44.8081414,
    lat: -23.0511238
  },
  {
    lng: -44.809092,
    lat: -23.0516155
  },
  {
    lng: -44.809682,
    lat: -23.0520908
  },
  {
    lng: -44.8100917,
    lat: -23.0525497
  },
  {
    lng: -44.8109276,
    lat: -23.0536314
  },
  {
    lng: -44.8118946,
    lat: -23.0545164
  },
  {
    lng: -44.8110095,
    lat: -23.0558603
  },
  {
    lng: -44.8104687,
    lat: -23.0563356
  },
  {
    lng: -44.8099934,
    lat: -23.057106
  },
  {
    lng: -44.8097148,
    lat: -23.0575157
  },
  {
    lng: -44.8095017,
    lat: -23.0582204
  },
  {
    lng: -44.8095181,
    lat: -23.0591055
  },
  {
    lng: -44.8094689,
    lat: -23.0598266
  },
  {
    lng: -44.8092558,
    lat: -23.0602364
  },
  {
    lng: -44.8086167,
    lat: -23.0604002
  },
  {
    lng: -44.8076169,
    lat: -23.0612853
  },
  {
    lng: -44.8073219,
    lat: -23.061695
  },
  {
    lng: -44.8071744,
    lat: -23.0622359
  },
  {
    lng: -44.8069941,
    lat: -23.0630554
  },
  {
    lng: -44.8072727,
    lat: -23.0637929
  },
  {
    lng: -44.8078463,
    lat: -23.0644321
  },
  {
    lng: -44.80842,
    lat: -23.0647599
  },
  {
    lng: -44.8098786,
    lat: -23.0649565
  },
  {
    lng: -44.8103048,
    lat: -23.065481
  },
  {
    lng: -44.8105998,
    lat: -23.0656121
  },
  {
    lng: -44.8112881,
    lat: -23.065481
  },
  {
    lng: -44.8119601,
    lat: -23.0653827
  },
  {
    lng: -44.812419,
    lat: -23.0655957
  },
  {
    lng: -44.813091,
    lat: -23.0661038
  },
  {
    lng: -44.8133204,
    lat: -23.0662677
  },
  {
    lng: -44.8147955,
    lat: -23.0676772
  },
  {
    lng: -44.8143038,
    lat: -23.0682344
  },
  {
    lng: -44.8139433,
    lat: -23.0684967
  },
  {
    lng: -44.8132221,
    lat: -23.0689884
  },
  {
    lng: -44.8125829,
    lat: -23.0693653
  },
  {
    lng: -44.8119765,
    lat: -23.0698734
  },
  {
    lng: -44.8116487,
    lat: -23.0703159
  },
  {
    lng: -44.8114193,
    lat: -23.0708895
  },
  {
    lng: -44.8114029,
    lat: -23.0713648
  },
  {
    lng: -44.8114848,
    lat: -23.0715779
  },
  {
    lng: -44.811829,
    lat: -23.0727579
  },
  {
    lng: -44.8119437,
    lat: -23.0732169
  },
  {
    lng: -44.8121896,
    lat: -23.0740036
  },
  {
    lng: -44.8125665,
    lat: -23.0750197
  },
  {
    lng: -44.8126649,
    lat: -23.0754131
  },
  {
    lng: -44.8128779,
    lat: -23.0765767
  },
  {
    lng: -44.8129271,
    lat: -23.0776584
  },
  {
    lng: -44.8129927,
    lat: -23.0785434
  },
  {
    lng: -44.8133041,
    lat: -23.0793301
  },
  {
    lng: -44.8135499,
    lat: -23.0798054
  },
  {
    lng: -44.8138777,
    lat: -23.0805921
  },
  {
    lng: -44.8140744,
    lat: -23.0813788
  },
  {
    lng: -44.8145005,
    lat: -23.0818869
  },
  {
    lng: -44.8149102,
    lat: -23.0822639
  },
  {
    lng: -44.8159264,
    lat: -23.082985
  },
  {
    lng: -44.8165492,
    lat: -23.0836242
  },
  {
    lng: -44.8169589,
    lat: -23.0844109
  },
  {
    lng: -44.8174998,
    lat: -23.0849845
  },
  {
    lng: -44.8179259,
    lat: -23.0857221
  },
  {
    lng: -44.8179587,
    lat: -23.086099
  },
  {
    lng: -44.8179587,
    lat: -23.086476
  },
  {
    lng: -44.8178603,
    lat: -23.0867382
  },
  {
    lng: -44.8186634,
    lat: -23.0899997
  },
  {
    lng: -44.8188437,
    lat: -23.0914584
  },
  {
    lng: -44.8185487,
    lat: -23.0916878
  },
  {
    lng: -44.818057,
    lat: -23.0914584
  },
  {
    lng: -44.8168934,
    lat: -23.0912617
  },
  {
    lng: -44.8150577,
    lat: -23.091065
  },
  {
    lng: -44.8144841,
    lat: -23.091065
  },
  {
    lng: -44.8139924,
    lat: -23.0910978
  },
  {
    lng: -44.8134188,
    lat: -23.0912126
  },
  {
    lng: -44.8125338,
    lat: -23.0916878
  },
  {
    lng: -44.8122715,
    lat: -23.0919501
  },
  {
    lng: -44.8122551,
    lat: -23.0926876
  },
  {
    lng: -44.812124,
    lat: -23.0935726
  },
  {
    lng: -44.8119929,
    lat: -23.0940152
  },
  {
    lng: -44.8116979,
    lat: -23.0944085
  },
  {
    lng: -44.8111734,
    lat: -23.0948838
  },
  {
    lng: -44.811157,
    lat: -23.0955722
  },
  {
    lng: -44.8111898,
    lat: -23.0962933
  },
  {
    lng: -44.8114684,
    lat: -23.0967194
  },
  {
    lng: -44.8122715,
    lat: -23.0972931
  },
  {
    lng: -44.8127796,
    lat: -23.0977847
  },
  {
    lng: -44.8128779,
    lat: -23.0982273
  },
  {
    lng: -44.812501,
    lat: -23.0987026
  },
  {
    lng: -44.8123535,
    lat: -23.0988173
  },
  {
    lng: -44.8117798,
    lat: -23.0989976
  },
  {
    lng: -44.8112226,
    lat: -23.0991942
  },
  {
    lng: -44.8110751,
    lat: -23.0992106
  },
  {
    lng: -44.8105015,
    lat: -23.0990467
  },
  {
    lng: -44.8098131,
    lat: -23.0988992
  },
  {
    lng: -44.8089608,
    lat: -23.0992434
  },
  {
    lng: -44.8088133,
    lat: -23.0994729
  },
  {
    lng: -44.8088789,
    lat: -23.1000629
  },
  {
    lng: -44.8089772,
    lat: -23.1006365
  },
  {
    lng: -44.8093378,
    lat: -23.1013904
  },
  {
    lng: -44.8095672,
    lat: -23.1015543
  },
  {
    lng: -44.810649,
    lat: -23.1024066
  },
  {
    lng: -44.812206,
    lat: -23.1035539
  },
  {
    lng: -44.813009,
    lat: -23.1040292
  },
  {
    lng: -44.8135991,
    lat: -23.1042586
  },
  {
    lng: -44.8137138,
    lat: -23.1043569
  },
  {
    lng: -44.8139105,
    lat: -23.1047667
  },
  {
    lng: -44.8140088,
    lat: -23.1051109
  },
  {
    lng: -44.8140088,
    lat: -23.1054714
  },
  {
    lng: -44.8140252,
    lat: -23.1058156
  },
  {
    lng: -44.8144349,
    lat: -23.1063565
  },
  {
    lng: -44.8156805,
    lat: -23.1071432
  },
  {
    lng: -44.8162706,
    lat: -23.1075693
  },
  {
    lng: -44.8164345,
    lat: -23.1078315
  },
  {
    lng: -44.8164685,
    lat: -23.1083916
  },
  {
    lng: -44.8164181,
    lat: -23.1090607
  },
  {
    lng: -44.8161722,
    lat: -23.110585
  },
  {
    lng: -44.8157133,
    lat: -23.1116503
  },
  {
    lng: -44.8152708,
    lat: -23.1124534
  },
  {
    lng: -44.8151397,
    lat: -23.1124862
  },
  {
    lng: -44.8144677,
    lat: -23.1127484
  },
  {
    lng: -44.8138613,
    lat: -23.1126664
  },
  {
    lng: -44.8129763,
    lat: -23.112732
  },
  {
    lng: -44.8123535,
    lat: -23.1132237
  },
  {
    lng: -44.8113865,
    lat: -23.1140759
  },
  {
    lng: -44.810944,
    lat: -23.1143873
  },
  {
    lng: -44.8106162,
    lat: -23.1148462
  },
  {
    lng: -44.8105998,
    lat: -23.1155346
  },
  {
    lng: -44.8109767,
    lat: -23.116223
  },
  {
    lng: -44.8116487,
    lat: -23.1175177
  },
  {
    lng: -44.8120912,
    lat: -23.117993
  },
  {
    lng: -44.8126649,
    lat: -23.1184683
  },
  {
    lng: -44.8134352,
    lat: -23.1185503
  },
  {
    lng: -44.814058,
    lat: -23.1191567
  },
  {
    lng: -44.8145661,
    lat: -23.1198287
  },
  {
    lng: -44.8148938,
    lat: -23.1204023
  },
  {
    lng: -44.8150905,
    lat: -23.1211398
  },
  {
    lng: -44.8151561,
    lat: -23.121484
  },
  {
    lng: -44.8152216,
    lat: -23.1220249
  },
  {
    lng: -44.8152216,
    lat: -23.1226477
  },
  {
    lng: -44.8150741,
    lat: -23.1233032
  },
  {
    lng: -44.8148447,
    lat: -23.123926
  },
  {
    lng: -44.8144513,
    lat: -23.1247783
  },
  {
    lng: -44.8141235,
    lat: -23.1255322
  },
  {
    lng: -44.814058,
    lat: -23.1262534
  },
  {
    lng: -44.8142055,
    lat: -23.1269581
  },
  {
    lng: -44.8144185,
    lat: -23.1274662
  },
  {
    lng: -44.8146152,
    lat: -23.1277448
  },
  {
    lng: -44.8148283,
    lat: -23.1278759
  },
  {
    lng: -44.8153691,
    lat: -23.1284987
  },
  {
    lng: -44.8161722,
    lat: -23.129646
  },
  {
    lng: -44.8169589,
    lat: -23.130318
  },
  {
    lng: -44.817762,
    lat: -23.1307277
  },
  {
    lng: -44.8183848,
    lat: -23.1308916
  },
  {
    lng: -44.8189584,
    lat: -23.1311046
  },
  {
    lng: -44.8192535,
    lat: -23.1315963
  },
  {
    lng: -44.8194501,
    lat: -23.132678
  },
  {
    lng: -44.8192862,
    lat: -23.1331206
  },
  {
    lng: -44.8193026,
    lat: -23.1338253
  },
  {
    lng: -44.8185979,
    lat: -23.134989
  },
  {
    lng: -44.8181881,
    lat: -23.1356118
  },
  {
    lng: -44.8166475,
    lat: -23.1365951
  },
  {
    lng: -44.8160903,
    lat: -23.1369229
  },
  {
    lng: -44.8158444,
    lat: -23.1373327
  },
  {
    lng: -44.8158608,
    lat: -23.1377096
  },
  {
    lng: -44.8160083,
    lat: -23.1382341
  },
  {
    lng: -44.8159919,
    lat: -23.1387258
  },
  {
    lng: -44.81591,
    lat: -23.1392502
  },
  {
    lng: -44.8160575,
    lat: -23.1403156
  },
  {
    lng: -44.8170573,
    lat: -23.1408728
  },
  {
    lng: -44.8183356,
    lat: -23.1410531
  },
  {
    lng: -44.8187946,
    lat: -23.1408236
  },
  {
    lng: -44.8196468,
    lat: -23.1405286
  },
  {
    lng: -44.8206138,
    lat: -23.140627
  },
  {
    lng: -44.8215644,
    lat: -23.1406761
  },
  {
    lng: -44.8224166,
    lat: -23.140922
  },
  {
    lng: -44.823482,
    lat: -23.1412006
  },
  {
    lng: -44.8240064,
    lat: -23.1415776
  },
  {
    lng: -44.8242687,
    lat: -23.1421348
  },
  {
    lng: -44.8245145,
    lat: -23.142479
  },
  {
    lng: -44.8254815,
    lat: -23.143741
  },
  {
    lng: -44.8253831,
    lat: -23.1445113
  },
  {
    lng: -44.8248423,
    lat: -23.1449374
  },
  {
    lng: -44.8243998,
    lat: -23.1457569
  },
  {
    lng: -44.8240392,
    lat: -23.1461994
  },
  {
    lng: -44.8234492,
    lat: -23.1476253
  },
  {
    lng: -44.8227116,
    lat: -23.1498215
  },
  {
    lng: -44.8221544,
    lat: -23.1518538
  },
  {
    lng: -44.8217611,
    lat: -23.152788
  },
  {
    lng: -44.821253,
    lat: -23.1535255
  },
  {
    lng: -44.8211383,
    lat: -23.1560495
  },
  {
    lng: -44.8213841,
    lat: -23.1566723
  },
  {
    lng: -44.821843,
    lat: -23.1569837
  },
  {
    lng: -44.8220725,
    lat: -23.1569673
  },
  {
    lng: -44.8223183,
    lat: -23.157459
  },
  {
    lng: -44.8226461,
    lat: -23.1580162
  },
  {
    lng: -44.8227608,
    lat: -23.1593602
  },
  {
    lng: -44.8227272,
    lat: -23.1600769
  },
  {
    lng: -44.8231378,
    lat: -23.1609172
  },
  {
    lng: -44.8244162,
    lat: -23.1626873
  },
  {
    lng: -44.8247931,
    lat: -23.1628675
  },
  {
    lng: -44.8251537,
    lat: -23.1630478
  },
  {
    lng: -44.8258257,
    lat: -23.1630806
  },
  {
    lng: -44.8264976,
    lat: -23.1625234
  },
  {
    lng: -44.8268582,
    lat: -23.162212
  },
  {
    lng: -44.8276449,
    lat: -23.1620153
  },
  {
    lng: -44.828448,
    lat: -23.1620153
  },
  {
    lng: -44.82971,
    lat: -23.1632281
  },
  {
    lng: -44.830005,
    lat: -23.163392
  },
  {
    lng: -44.8303819,
    lat: -23.1636542
  },
  {
    lng: -44.8307917,
    lat: -23.1642443
  },
  {
    lng: -44.8315456,
    lat: -23.1654407
  },
  {
    lng: -44.8326437,
    lat: -23.1664077
  },
  {
    lng: -44.8335123,
    lat: -23.1668338
  },
  {
    lng: -44.8345613,
    lat: -23.1671288
  },
  {
    lng: -44.8353316,
    lat: -23.1672599
  },
  {
    lng: -44.8364297,
    lat: -23.1673419
  },
  {
    lng: -44.8381014,
    lat: -23.1672435
  },
  {
    lng: -44.8381834,
    lat: -23.1670469
  },
  {
    lng: -44.8383473,
    lat: -23.1666044
  },
  {
    lng: -44.8389373,
    lat: -23.1659652
  },
  {
    lng: -44.8393634,
    lat: -23.1658504
  },
  {
    lng: -44.8398879,
    lat: -23.1664569
  },
  {
    lng: -44.8404123,
    lat: -23.1667027
  },
  {
    lng: -44.840904,
    lat: -23.1666535
  },
  {
    lng: -44.8417727,
    lat: -23.1666535
  },
  {
    lng: -44.8429035,
    lat: -23.1666863
  },
  {
    lng: -44.844018,
    lat: -23.1676369
  },
  {
    lng: -44.84528,
    lat: -23.1689808
  },
  {
    lng: -44.8448703,
    lat: -23.1701773
  },
  {
    lng: -44.8448375,
    lat: -23.1715704
  },
  {
    lng: -44.8448539,
    lat: -23.1723899
  },
  {
    lng: -44.844985,
    lat: -23.1724226
  },
  {
    lng: -44.8463781,
    lat: -23.1727176
  },
  {
    lng: -44.8468042,
    lat: -23.1729635
  },
  {
    lng: -44.8472795,
    lat: -23.1735043
  },
  {
    lng: -44.8479187,
    lat: -23.1741435
  },
  {
    lng: -44.8493774,
    lat: -23.1736355
  },
  {
    lng: -44.850033,
    lat: -23.1738321
  },
  {
    lng: -44.8503116,
    lat: -23.173996
  },
  {
    lng: -44.850623,
    lat: -23.1742091
  },
  {
    lng: -44.8517703,
    lat: -23.1737994
  },
  {
    lng: -44.8527373,
    lat: -23.1738977
  },
  {
    lng: -44.8531142,
    lat: -23.1741435
  },
  {
    lng: -44.8531142,
    lat: -23.1743894
  },
  {
    lng: -44.8522128,
    lat: -23.1749958
  },
  {
    lng: -44.8522783,
    lat: -23.1752089
  },
  {
    lng: -44.8525734,
    lat: -23.1759464
  },
  {
    lng: -44.8530814,
    lat: -23.176602
  },
  {
    lng: -44.8537534,
    lat: -23.1768806
  },
  {
    lng: -44.8563593,
    lat: -23.1766839
  },
  {
    lng: -44.8569494,
    lat: -23.1770445
  },
  {
    lng: -44.8573099,
    lat: -23.1773067
  },
  {
    lng: -44.8578508,
    lat: -23.1776673
  },
  {
    lng: -44.8582769,
    lat: -23.178159
  },
  {
    lng: -44.8585064,
    lat: -23.1785195
  },
  {
    lng: -44.858703,
    lat: -23.1787818
  },
  {
    lng: -44.8588997,
    lat: -23.1790604
  },
  {
    lng: -44.8591292,
    lat: -23.1796504
  },
  {
    lng: -44.8593914,
    lat: -23.1800765
  },
  {
    lng: -44.8599323,
    lat: -23.1805518
  },
  {
    lng: -44.8604567,
    lat: -23.180978
  },
  {
    lng: -44.8610304,
    lat: -23.1808796
  },
  {
    lng: -44.8624071,
    lat: -23.1808632
  },
  {
    lng: -44.8658161,
    lat: -23.1809288
  },
  {
    lng: -44.8666356,
    lat: -23.1810927
  },
  {
    lng: -44.8668978,
    lat: -23.1812894
  },
  {
    lng: -44.8670945,
    lat: -23.181863
  },
  {
    lng: -44.8677009,
    lat: -23.1823219
  },
  {
    lng: -44.8681762,
    lat: -23.1823875
  },
  {
    lng: -44.8702577,
    lat: -23.1824366
  },
  {
    lng: -44.8709132,
    lat: -23.1823383
  },
  {
    lng: -44.8711755,
    lat: -23.1821252
  },
  {
    lng: -44.8717821,
    lat: -23.1820065
  },
  {
    lng: -44.8719294,
    lat: -23.1819777
  },
  {
    lng: -44.8727325,
    lat: -23.1825022
  },
  {
    lng: -44.8729291,
    lat: -23.1829119
  },
  {
    lng: -44.8729455,
    lat: -23.1831906
  },
  {
    lng: -44.8736831,
    lat: -23.1838134
  },
  {
    lng: -44.8747484,
    lat: -23.1846984
  },
  {
    lng: -44.8749451,
    lat: -23.1854851
  },
  {
    lng: -44.8746992,
    lat: -23.1862718
  },
  {
    lng: -44.874437,
    lat: -23.1870913
  },
  {
    lng: -44.8741256,
    lat: -23.1897136
  },
  {
    lng: -44.8746208,
    lat: -23.1916477
  },
  {
    lng: -44.8749674,
    lat: -23.1925144
  },
  {
    lng: -44.8750398,
    lat: -23.1926953
  },
  {
    lng: -44.8761107,
    lat: -23.1936265
  },
  {
    lng: -44.8765763,
    lat: -23.1940223
  },
  {
    lng: -44.8768324,
    lat: -23.1944879
  },
  {
    lng: -44.876716,
    lat: -23.1950932
  },
  {
    lng: -44.8763202,
    lat: -23.195745
  },
  {
    lng: -44.8756218,
    lat: -23.1961408
  },
  {
    lng: -44.8749001,
    lat: -23.1967461
  },
  {
    lng: -44.8750783,
    lat: -23.1968765
  },
  {
    lng: -44.875404,
    lat: -23.197138
  },
  {
    lng: -44.8755515,
    lat: -23.1972692
  },
  {
    lng: -44.8771411,
    lat: -23.1993345
  },
  {
    lng: -44.8826453,
    lat: -23.2027127
  },
  {
    lng: -44.8831739,
    lat: -23.2032118
  },
  {
    lng: -44.8837207,
    lat: -23.2048051
  },
  {
    lng: -44.8835893,
    lat: -23.2056536
  }
];

export default polygonCoords_Paraty;