import React, { Component } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';
import apiStringServer from '../services/apiStringServer';

import Header from '../components/Header';
import FooterHome from '../components/FooterHome';
import Table from '../components/Table';
import Log from '../components/Log';

import Accordion from '@material-ui/core/Accordion';
import Divider from '@material-ui/core/Divider';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import House from '@material-ui/icons/House';
import Check from '@material-ui/icons/Check';
import Report from '@material-ui/icons/Report';
import LocationOn from '@material-ui/icons/LocationOn';
import Close from '@material-ui/icons/Close';
import DoNotDisturb from '@material-ui/icons/NotInterestedOutlined';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ListAltOutlined from '@material-ui/icons/ListAltOutlined';
import Add from '@material-ui/icons/Add';
import Apartment from '@material-ui/icons/Apartment';
import Button from '@material-ui/core/Button';
import MapIcon from '@material-ui/icons/Map';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Assignment from '@material-ui/icons/Assignment';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ClearAllIcon from '@material-ui/icons/ClearAll';

import { ThemeProvider } from '@material-ui/core';
import theme from '../themes/theme';
import themeDark from '../themes/themeDark';

import { Map, GoogleApiWrapper, Marker, InfoWindow, Polygon } from 'google-maps-react';

import yellow_marker from '../assets/yellow_marker.png';
import green_marker from '../assets/green_marker.png';
import red_marker from '../assets/red_marker.png';

import hospital_marker from '../assets/map_icons/healthcare_icon.png';
import school_marker from '../assets/map_icons/school_icon.png';
import police_marker from '../assets/map_icons/police_icon.png';

import polygonCoords_Paraty from './utils/polygonParaty';

export class HomeParaty extends Component {

  state = {
    login: this.props.location.state.fields.login,
    option: this.props.location.state.fields.option,
    user_type: this.props.location.state.fields.user_type,
    selected_area: this.props.location.state.fields.selected_area,
    selected_city: this.props.location.state.fields.selected_city,

    zoom: 11,
    latitude: '',
    longitude: '',
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},

    places: [],

    cpf_cnpj: '',
    name: '',
    email: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',

    new_lat: '',
    new_lng: '',

    expanded: false,
    openDialog: false,

    renderTable: false,
    renderLog: false,

    url: null,

    search: '',

    polygonCoords_ParatyGeral: [],

    comunities: [],
    completed_comunities: [],

    projectMap: true,

    hydrography: [],
    completed_hydrography: [],
    showHydrography: false,

    appDeclividade: [],
    completed_appDeclividade: [],
    showAppDeclividade: false,

    appLagosLagoas: [],
    completed_appLagosLagoas: [],
    showAppLagosLagoas: false,

    appNascentes: [],
    completed_appNascentes: [],
    showAppNascentes: false,

    appTopoDeMorro: [],
    completed_appTopoDeMorro: [],
    showAppTopoDeMorro: false,

    riscoDeslizamento: [],
    completed_riscoDeslizamento: [],
    showRiscoDeslizamento: false,

    riscoEnxurrada: [],
    completed_riscoEnxurrada: [],
    showRiscoEnxurrada: false,

    suscetibilidadeInundacoes: [],
    completed_suscetibilidadeInundacoes: [],
    showSuscetibilidadeInundacoes: false,

    hospitals: [],
    showHospitals: false,
    schools: [],
    showSchools: false,
    policeStations: [],
    showPoliceStations: false,

    bairros: [],
    completed_bairros: [],
    showBairros: false,
  }

  async componentDidMount() {

    if (this.props.location.state) {
      await this.setState({ login: this.props.location.state.fields.login });
      await this.setState({ option: this.props.location.state.fields.option });
      await this.setState({ user_type: this.props.location.state.fields.user_type });
      await this.setState({ selected_area: this.props.location.state.fields.selected_area });
      await this.setState({ selected_city: this.props.location.state.fields.selected_city });
    }

    /*navigator.geolocation.getCurrentPosition(async (position) => {
      this.setState({ status: null });
      await this.setState({ latitude: position.coords.latitude });
      await this.setState({ longitude: position.coords.longitude });
    }, () => {
      this.setState({ status: 'Impossível retornar a localização.' });
    });*/

    await this.setState({ latitude: -23.144090 });
    await this.setState({ longitude: -44.711651 });

    if (this.state.user_type !== 4) {

      try {
        let resp = await api.get('/locations', {
          params: {
            selected_city: this.state.selected_city
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {

              this.setState({ places: resp.data });

            } else {
              let msg = JSON.stringify(resp.data);
              console.log(msg)
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    } else {

      try {
        let resp = await api.get('/arealocations', {
          params: {
            selected_city: this.state.selected_city,
            selected_area: this.state.selected_area
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {

              this.setState({ places: resp.data });

            } else {
              let msg = JSON.stringify(resp.data);
              console.log(msg)
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    }

    this.getComunities();
    //buscar os polígonos de Paraty
    this.getHydrography();
    this.getAPPDeclividade();
    this.getAPPLagosLagoas();
    this.getAPPNascentes();
    this.getAPPTopoDeMorro();
    this.getRiscoDeslizamento();
    this.getRiscoEnxurrada();
    this.getSuscetibilidadeInundacoes();
    this.getHospitals();
    this.getSchools();
    this.getPoliceStations();
    this.getBairros();

  }

  getComunities = async () => {

    try {
      let resp = await api.get('/comunities', {
        params: {
          selected_city: 'municipios_paraty'
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('comunidade')) {

            await this.setState({ comunities: resp.data });

            let all_comunities = [];
            this.state.comunities.map((item) =>
              all_comunities.push(item.polygon)
            );

            //formatar os polígonos das comunidades do SJMeriti de Janeiro para serem exibidos no mapa com o componente Polygon, usando o exemplo da formatação presente no arquivo polygonSJMeriti.js, lembrando que na formatação de all_comunities, cada item é uma string que segue um padrão como neste exemplo de dois itens: [[-43.25148546299994,-22.994067564999966],[-43.251536560999966,-22.994302722999976]] e [[-43.251536560999966,-22.994302722999976],[-43.25148546299994,-22.994067564999966]].
            let polygons = [];
            all_comunities.map((item) => {
              let polygon = [];
              let coords = item.split('],[');
              coords.map((coord) => {
                let coord1 = coord.replace('[[', '').replace(']]', '').replace('[', '').replace(']', '');
                let lat = parseFloat(coord1.split(',')[1]);
                let lng = parseFloat(coord1.split(',')[0]);
                polygon.push({ lat: lat, lng: lng });
              });
              polygons.push(polygon);
            });

            await this.setState({ completed_comunities: polygons });

            //alert(JSON.stringify(this.state.completed_comunities));

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg)
          }
        }
      }
    } catch (err) {
      console.log(err);
    }

  }

  getHydrography = async () => {

    try {
      let resp = await api.get('/hydrography', {
        params: {
          selected_city: 'municipios_paraty'
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('municipio')) {
            await this.setState({ hydrography: resp.data });

            let all_hydrography = [];
            this.state.hydrography.map((item) =>
              all_hydrography.push(item.polygon)
            );

            //formatar os polígonos das comunidades do SJMeriti de Janeiro para serem exibidos no mapa com o componente Polygon, usando o exemplo da formatação presente no arquivo polygonSJMeriti.js, lembrando que na formatação de all_hydrography, cada item é uma string que segue um padrão como neste exemplo de dois itens: [[-43.25148546299994,-22.994067564999966],[-43.251536560999966,-22.994302722999976]] e [[-43.251536560999966,-22.994302722999976],[-43.25148546299994,-22.994067564999966]].
            let polygons = [];
            all_hydrography.map((item) => {
              let polygon = [];
              let coords = item.split('],[');
              coords.map((coord) => {
                let coord1 = coord.replace('[[', '').replace(']]', '').replace('[', '').replace(']', '');
                let lat = parseFloat(coord1.split(',')[1]);
                let lng = parseFloat(coord1.split(',')[0]);
                polygon.push({ lat: lat, lng: lng });
              });
              polygons.push(polygon);
            });

            await this.setState({ completed_hydrography: polygons });

            //alert(JSON.stringify(this.state.completed_hydrography));

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg)
          }
        }
      }
    } catch (err) {
      console.log(err);
    }

  }

  getAPPDeclividade = async () => {

    try {
      let resp = await api.get('/app_declividade', {
        params: {
          selected_city: 'municipios_paraty'
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('municipio')) {
            await this.setState({ appDeclividade: resp.data });

            let all_appDeclividade = [];
            this.state.appDeclividade.map((item) =>
              all_appDeclividade.push(item.polygon)
            );

            //formatar os polígonos das comunidades do SJMeriti de Janeiro para serem exibidos no mapa com o componente Polygon, usando o exemplo da formatação presente no arquivo polygonSJMeriti.js, lembrando que na formatação de all_appDeclividade, cada item é uma string que segue um padrão como neste exemplo de dois itens: [[-43.25148546299994,-22.994067564999966],[-43.251536560999966,-22.994302722999976]] e [[-43.251536560999966,-22.994302722999976],[-43.25148546299994,-22.994067564999966]].
            let polygons = [];
            all_appDeclividade.map((item) => {
              let polygon = [];
              let coords = item.split('],[');
              coords.map((coord) => {
                let coord1 = coord.replace('[[', '').replace(']]', '').replace('[', '').replace(']', '');
                let lat = parseFloat(coord1.split(',')[1]);
                let lng = parseFloat(coord1.split(',')[0]);
                polygon.push({ lat: lat, lng: lng });
              });
              polygons.push(polygon);
            });

            await this.setState({ completed_appDeclividade: polygons });

            //alert(JSON.stringify(this.state.completed_appDeclividade));

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg)
          }
        }
      }
    } catch (err) {
      console.log(err);
    }

  }

  getAPPLagosLagoas = async () => {

    try {
      let resp = await api.get('/app_lagoslagoas', {
        params: {
          selected_city: 'municipios_paraty'
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('municipio')) {
            await this.setState({ appLagosLagoas: resp.data });

            let all_appLagosLagoas = [];
            this.state.appLagosLagoas.map((item) =>
              all_appLagosLagoas.push(item.polygon)
            );

            //formatar os polígonos das comunidades do SJMeriti de Janeiro para serem exibidos no mapa com o componente Polygon, usando o exemplo da formatação presente no arquivo polygonSJMeriti.js, lembrando que na formatação de all_appLagosLagoas, cada item é uma string que segue um padrão como neste exemplo de dois itens: [[-43.25148546299994,-22.994067564999966],[-43.251536560999966,-22.994302722999976]] e [[-43.251536560999966,-22.994302722999976],[-43.25148546299994,-22.994067564999966]].
            let polygons = [];
            all_appLagosLagoas.map((item) => {
              let polygon = [];
              let coords = item.split('],[');
              coords.map((coord) => {
                let coord1 = coord.replace('[[', '').replace(']]', '').replace('[', '').replace(']', '');
                let lat = parseFloat(coord1.split(',')[1]);
                let lng = parseFloat(coord1.split(',')[0]);
                polygon.push({ lat: lat, lng: lng });
              });
              polygons.push(polygon);
            });

            await this.setState({ completed_appLagosLagoas: polygons });

            //alert(JSON.stringify(this.state.completed_appLagosLagoas));

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg)
          }
        }
      }
    } catch (err) {
      console.log(err);
    }

  }

  getAPPNascentes = async () => {

    try {
      let resp = await api.get('/app_nascentes', {
        params: {
          selected_city: 'municipios_paraty'
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('municipio')) {
            await this.setState({ appNascentes: resp.data });

            let all_appNascentes = [];
            this.state.appNascentes.map((item) =>
              all_appNascentes.push(item.polygon)
            );

            //formatar os polígonos das comunidades do SJMeriti de Janeiro para serem exibidos no mapa com o componente Polygon, usando o exemplo da formatação presente no arquivo polygonSJMeriti.js, lembrando que na formatação de all_appNascentes, cada item é uma string que segue um padrão como neste exemplo de dois itens: [[-43.25148546299994,-22.994067564999966],[-43.251536560999966,-22.994302722999976]] e [[-43.251536560999966,-22.994302722999976],[-43.25148546299994,-22.994067564999966]].
            let polygons = [];
            all_appNascentes.map((item) => {
              let polygon = [];
              let coords = item.split('],[');
              coords.map((coord) => {
                let coord1 = coord.replace('[[', '').replace(']]', '').replace('[', '').replace(']', '');
                let lat = parseFloat(coord1.split(',')[1]);
                let lng = parseFloat(coord1.split(',')[0]);
                polygon.push({ lat: lat, lng: lng });
              });
              polygons.push(polygon);
            });

            await this.setState({ completed_appNascentes: polygons });

            //alert(JSON.stringify(this.state.completed_appNascentes));

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg)
          }
        }
      }
    } catch (err) {
      console.log(err);
    }

  }

  getAPPTopoDeMorro = async () => {
    try {
      let resp = await api.get('/app_topodemorro', {
        params: {
          selected_city: 'municipios_paraty'
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('municipio')) {
            await this.setState({ appTopoDeMorro: resp.data });

            let all_appTopoDeMorro = [];
            this.state.appTopoDeMorro.map((item) =>
              all_appTopoDeMorro.push(item.polygon)
            );

            let polygons = [];
            all_appTopoDeMorro.map((item) => {
              let polygon = [];
              let coords = item.split('],[');
              coords.map((coord) => {
                let coord1 = coord.replace('[[', '').replace(']]', '').replace('[', '').replace(']', '');
                let lat = parseFloat(coord1.split(',')[1]);
                let lng = parseFloat(coord1.split(',')[0]);
                polygon.push({ lat: lat, lng: lng });
              });
              polygons.push(polygon);
            });

            await this.setState({ completed_appTopoDeMorro: polygons });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg)
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  getRiscoDeslizamento = async () => {
    try {
      let resp = await api.get('/riscodeslizamento', {
        params: {
          selected_city: 'municipios_paraty'
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('municipio')) {
            await this.setState({ riscoDeslizamento: resp.data });

            let all_riscoDeslizamento = [];
            this.state.riscoDeslizamento.map((item) =>
              all_riscoDeslizamento.push(item.polygon)
            );

            let polygons = [];
            all_riscoDeslizamento.map((item) => {
              let polygon = [];
              let coords = item.split('],[');
              coords.map((coord) => {
                let coord1 = coord.replace('[[', '').replace(']]', '').replace('[', '').replace(']', '');
                let lat = parseFloat(coord1.split(',')[1]);
                let lng = parseFloat(coord1.split(',')[0]);
                polygon.push({ lat: lat, lng: lng });
              });
              polygons.push(polygon);
            });

            await this.setState({ completed_riscoDeslizamento: polygons });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg)
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  getRiscoEnxurrada = async () => {
    try {
      let resp = await api.get('/riscoenxurrada', {
        params: {
          selected_city: 'municipios_paraty'
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('municipio')) {
            await this.setState({ riscoEnxurrada: resp.data });

            let all_riscoEnxurrada = [];
            this.state.riscoEnxurrada.map((item) =>
              all_riscoEnxurrada.push(item.polygon)
            );

            let polygons = [];
            all_riscoEnxurrada.map((item) => {
              let polygon = [];
              let coords = item.split('],[');
              coords.map((coord) => {
                let coord1 = coord.replace('[[', '').replace(']]', '').replace('[', '').replace(']', '');
                let lat = parseFloat(coord1.split(',')[1]);
                let lng = parseFloat(coord1.split(',')[0]);
                polygon.push({ lat: lat, lng: lng });
              });
              polygons.push(polygon);
            });

            await this.setState({ completed_riscoEnxurrada: polygons });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg)
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  getSuscetibilidadeInundacoes = async () => {
    try {
      let resp = await api.get('/suscetibilidade_inundacoes', {
        params: {
          selected_city: 'municipios_paraty'
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('municipio')) {
            await this.setState({ suscetibilidadeInundacoes: resp.data });

            let all_suscetibilidadeInundacoes = [];
            this.state.suscetibilidadeInundacoes.map((item) =>
              all_suscetibilidadeInundacoes.push(item.polygon)
            );

            let polygons = [];
            all_suscetibilidadeInundacoes.map((item) => {
              let polygon = [];
              let coords = item.split('],[');
              coords.map((coord) => {
                let coord1 = coord.replace('[[', '').replace(']]', '').replace('[', '').replace(']', '');
                let lat = parseFloat(coord1.split(',')[1]);
                let lng = parseFloat(coord1.split(',')[0]);
                polygon.push({ lat: lat, lng: lng });
              });
              polygons.push(polygon);
            });

            await this.setState({ completed_suscetibilidadeInundacoes: polygons });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg)
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  getHospitals = async () => {

    try {
      let resp = await api.get('/hospitals', {
        params: {
          selected_city: `municipios_paraty`
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('municipio')) {

            await this.setState({ hospitals: resp.data });

            //let latitude1 = this.state.hospitals.map((item) => item[0].latitude);

            //alert(JSON.stringify(latitude1));

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível buscar os dados dos hospitais nas proximidades. ' + err);
    }

  }

  getSchools = async () => {

    try {
      let resp = await api.get('/schools', {
        params: {
          selected_city: `municipios_paraty`
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('municipio')) {

            await this.setState({ schools: resp.data });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível buscar os dados das escolas nas proximidades. ' + err);
    }

  }

  getPoliceStations = async () => {

    try {
      let resp = await api.get('/police_stations', {
        params: {
          selected_city: `municipios_paraty`
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('municipio')) {

            await this.setState({ policeStations: resp.data });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível buscar os dados das delegacias nas proximidades. ' + err);
    }

  }

  getBairros = async () => {
    try {
      let resp = await api.get('/bairros', {
        params: {
          selected_city: 'municipios_paraty'
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('bairro')) {
            await this.setState({ bairros: resp.data });

            let all_bairros = [];
            this.state.bairros.map((item) =>
              all_bairros.push(item.polygon)
            );

            let polygons = [];
            all_bairros.map((item) => {
              let polygon = [];
              let coords = item.split('],[');
              coords.map((coord) => {
                let coord1 = coord.replace('[[', '').replace(']]', '').replace('[', '').replace(']', '');
                let lat = parseFloat(coord1.split(',')[1]);
                let lng = parseFloat(coord1.split(',')[0]);
                polygon.push({ lat: lat, lng: lng });
              });
              polygons.push(polygon);
            });

            await this.setState({ completed_bairros: polygons });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  handleLocations = async () => {

    if (this.state.user_type !== 4) {

      try {
        let resp = await api.get('/locations', {
          params: {
            selected_city: this.state.selected_city
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {

              this.setState({ places: resp.data });

            } else {
              let msg = JSON.stringify(resp.data);
              console.log(msg)
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    } else {

      try {
        let resp = await api.get('/arealocations', {
          params: {
            selected_city: this.state.selected_city,
            selected_area: this.state.selected_area
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {

              this.setState({ places: resp.data });

            } else {
              let msg = JSON.stringify(resp.data);
              console.log(msg)
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    }

  }

  handleRegular = async () => {

    if (this.state.user_type !== 4) {

      try {
        let resp = await api.get('/regularlocations', {
          params: {
            selected_city: this.state.selected_city
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {

              for (let i = 0; i < resp.data.length; i++) {

                await this.state.places.pop();

              }

              await this.setState({ places: [] });

              await this.setState({ places: resp.data });

            } else {
              await this.setState({ places: resp.data });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    } else {

      try {
        let resp = await api.get('/regulararealocations', {
          params: {
            selected_city: this.state.selected_city,
            selected_area: this.state.selected_area
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {

              for (let i = 0; i < resp.data.length; i++) {

                await this.state.places.pop();

              }

              await this.setState({ places: [] });

              await this.setState({ places: resp.data });

            } else {
              await this.setState({ places: resp.data });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    }

  }

  handleNegattive = async () => {

    if (this.state.user_type !== 4) {

      try {
        let resp = await api.get('/negattivelocations', {
          params: {
            selected_city: this.state.selected_city
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {

              for (let i = 0; i < resp.data.length; i++) {

                await this.state.places.pop();

              }

              await this.setState({ places: [] });

              await this.setState({ places: resp.data });

            } else {
              await this.setState({ places: resp.data });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    } else {

      try {
        let resp = await api.get('/negattivearealocations', {
          params: {
            selected_city: this.state.selected_city,
            selected_area: this.state.selected_area
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {

              for (let i = 0; i < resp.data.length; i++) {

                await this.state.places.pop();

              }

              await this.setState({ places: [] });

              await this.setState({ places: resp.data });

            } else {
              await this.setState({ places: resp.data });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    }

  }

  handlePendence = async () => {

    if (this.state.user_type !== 4) {

      try {
        let resp = await api.get('/pendencelocations', {
          params: {
            selected_city: this.state.selected_city
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {

              for (let i = 0; i < resp.data.length; i++) {

                await this.state.places.pop();

              }

              await this.setState({ places: [] });

              await this.setState({ places: resp.data });

            } else {
              await this.setState({ places: resp.data });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    } else {

      try {
        let resp = await api.get('/pendencearealocations', {
          params: {
            selected_city: this.state.selected_city,
            selected_area: this.state.selected_area
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {

              for (let i = 0; i < resp.data.length; i++) {

                await this.state.places.pop();

              }

              await this.setState({ places: [] });

              await this.setState({ places: resp.data });

            } else {
              await this.setState({ places: resp.data });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

    }

  }

  handleChangePanel = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  onMarkerClick = (props, marker) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  }

  handleChange = async e => {
    await this.setState({ [e.target.name]: e.target.value });

    // Search for neighborhoods (bairros) that contain the name of the searched locality, case-insensitive
    let localidade = this.state.search.toLowerCase();

    if (localidade !== '') {
      let bairro = this.state.bairros.filter(item => item.bairro.toLowerCase().includes(localidade));

      if (bairro.length > 0) {
        let coords = bairro[0].polygon.split('],[')[0];
        coords = coords.split('[[').join('');
        let lat = parseFloat(coords.split(',')[1]);
        let lng = parseFloat(coords.split(',')[0]);
        await this.setState({ latitude: lat });
        await this.setState({ longitude: lng });
        this.setState({ zoom: 16 });
      }
    } else {
      await this.setState({ latitude: -23.144090 });
      await this.setState({ longitude: -44.711651 });
      this.setState({ zoom: 11 });
    }
  }

  handleCheckboxChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handleUncheckAll = () => {
    this.setState({
      showHydrography: false,
      showAppDeclividade: false,
      showAppLagosLagoas: false,
      showAppNascentes: false,
      showAppTopoDeMorro: false,
      showRiscoDeslizamento: false,
      showRiscoEnxurrada: false,
      showSuscetibilidadeInundacoes: false,
      showHospitals: false,
      showSchools: false,
      showPoliceStations: false,
      showBairros: false,
    });
  }

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={styles.html}>
          <Header
            history={this.props.history}
            login={this.state.login}
            option={this.state.option}
            user_type={this.state.user_type}
            selected_area={this.state.selected_area}
            selected_city={this.state.selected_city}
            city={this.state.selected_city}
            {...this.props}
          />

          <ThemeProvider theme={theme}>
            <div style={styles.directionRow}>

              <div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>
                    <TextField
                      style={styles.text}
                      id="search"
                      name="search"
                      value={this.state.search}
                      placeholder='Pesquisar localidade'
                      onChange={this.handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                    />
                  </div>

                  <div style={styles.accordionDiv}>

                    <Accordion
                      TransitionProps={{ unmountOnExit: true }}
                      expanded={this.state.expanded === 'panel1'}
                      onChange={this.handleChangePanel('panel1')}
                      style={styles.accordion}
                      onClick={this.handleLocations}
                      theme={themeDark}
                    >
                      <ThemeProvider theme={themeDark}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <House />
                          <Typography>
                            Painel geral
                          </Typography>
                        </AccordionSummary>
                      </ThemeProvider>
                      <AccordionDetails>
                        <div style={{ display: 'flex', flexDirection: 'column', color: '#343434' }}>
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => {
                              this.setState({ renderTable: false, renderLog: false });
                              this.setState({ projectMap: true });
                              this.componentDidMount();
                            }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <MapIcon />
                              </ListItemIcon>
                              <ListItemText primary={`Mapa do Plano Diretor`} />
                            </ListItem>
                          </List>
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => {
                              this.setState({ renderTable: false, renderLog: false });
                              this.setState({ projectMap: false });
                              this.componentDidMount();
                            }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <MapIcon />
                              </ListItemIcon>
                              <ListItemText primary={`Mapa de Regularização`} />
                            </ListItem>
                          </List>
                          <Divider />
                          {this.state.user_type !== 3 && this.state.user_type !== 4 ? (
                            <List
                              component="div"
                              disablePadding
                              style={styles.list}
                              onClick={() => {
                                this.props.history.push({
                                  pathname: '/addarea',
                                  state: {
                                    fields: {
                                      login: this.state.login,
                                      option: this.state.option,
                                      user_type: this.state.user_type,
                                      selected_area: this.state.selected_area,
                                      selected_city: this.state.selected_city,
                                      city: this.state.selected_city
                                    }
                                  }
                                });
                              }}
                            >
                              <ListItem button>
                                <ListItemIcon>
                                  <Add />
                                </ListItemIcon>
                                <ListItemText primary={`Cadastrar áreas`} />
                              </ListItem>
                            </List>
                          ) : null}
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => {
                              this.props.history.push({
                                pathname: '/areas',
                                state: {
                                  fields: {
                                    login: this.state.login,
                                    selected_city: this.state.selected_city
                                  }
                                }
                              });
                            }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <ViewColumn />
                              </ListItemIcon>
                              <ListItemText primary={`Tabela de áreas`} />
                            </ListItem>
                          </List>
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => {
                              this.setState({ renderTable: false });
                              this.componentDidMount();
                            }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <Apartment />
                              </ListItemIcon>
                              <ListItemText primary={`RGI (Registro Geral de Imóveis)`} />
                            </ListItem>
                          </List>
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => {
                              this.setState({ renderTable: false });
                              this.componentDidMount();
                            }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <Add />
                              </ListItemIcon>
                              <ListItemText primary={`Cadastro imobiliário`} />
                            </ListItem>
                          </List>
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => {
                              this.props.history.push({
                                pathname: '/urbanproject',
                                state: {
                                  fields: {
                                    login: this.state.login,
                                    option: this.state.option,
                                    user_type: this.state.user_type,
                                    selected_area: this.state.selected_area,
                                    selected_city: this.state.selected_city
                                  }
                                }
                              });
                            }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <Apartment />
                              </ListItemIcon>
                              <ListItemText primary={`Projeto Urbanístico REURB-S`} />
                            </ListItem>
                          </List>
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => {
                              this.setState({ renderTable: true, renderLog: false });
                              this.componentDidMount();
                            }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <ViewColumn />
                              </ListItemIcon>
                              <ListItemText primary={`Tabela de registros`} />
                            </ListItem>
                          </List>
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => {
                              this.setState({ renderLog: true, renderTable: false });
                              this.componentDidMount();
                            }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <ListAltOutlined />
                              </ListItemIcon>
                              <ListItemText primary={`Log de usuários`} />
                            </ListItem>
                          </List>
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      expanded={this.state.expanded === 'panel2'}
                      onChange={this.handleChangePanel('panel2')}
                      style={styles.accordion}
                    >
                      <ThemeProvider theme={themeDark}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <LocationOn />
                          <Typography>
                            Filtros
                          </Typography>
                        </AccordionSummary>
                      </ThemeProvider>
                      <AccordionDetails>
                        <div style={{ display: 'flex', flexDirection: 'column', color: '#343434' }}>
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => { this.handleRegular(); }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <Check />
                              </ListItemIcon>
                              <ListItemText primary={`Imóveis regularizados`} />
                            </ListItem>
                          </List>
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => { this.handlePendence(); }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <Report />
                              </ListItemIcon>
                              <ListItemText primary={`Regularizações em andamento`} />
                            </ListItem>
                          </List>
                          <Divider />
                          <List
                            component="div"
                            disablePadding
                            style={styles.list}
                            onClick={() => { this.handleNegattive(); }}
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <Close />
                              </ListItemIcon>
                              <ListItemText primary={`Regularizações negadas`} />
                            </ListItem>
                          </List>
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      expanded={this.state.expanded === 'panel5'}
                      onChange={this.handleChangePanel('panel5')}
                      style={styles.accordion}
                    >
                      <ThemeProvider theme={themeDark}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel5a-content"
                          id="panel5a-header"
                        >
                          <Assignment />
                          <Typography>
                            Formulários
                          </Typography>
                        </AccordionSummary>
                      </ThemeProvider>
                      <AccordionDetails>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <Button
                            color="secondary"
                            style={styles.bt2}
                            startIcon={<Assignment />}
                          >
                            Decisão instauradora da REURB
                          </Button>

                          <Button
                            color="secondary"
                            style={styles.bt2}
                            startIcon={<Assignment />}
                          >
                            Decisão denegatória da instauração da REURB
                          </Button>

                          <Button
                            color="secondary"
                            style={styles.bt2}
                            startIcon={<Assignment />}
                          >
                            Autuação do procedimento
                          </Button>

                          <Button
                            color="secondary"
                            style={styles.bt2}
                            startIcon={<Assignment />}
                          >
                            Certificações comuns do procedimento administrativo
                          </Button>

                          <Button
                            color="secondary"
                            style={styles.bt2}
                            startIcon={<Assignment />}
                          >
                            Anuência expressa do notificado
                          </Button>

                          <Button
                            color="secondary"
                            style={styles.bt2}
                            startIcon={<Assignment />}
                          >
                            Impugnação expressa do notificado
                          </Button>
                        </div>

                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      expanded={this.state.expanded === 'panel6'}
                      onChange={this.handleChangePanel('panel6')}
                      style={styles.accordion}
                    >
                      <ThemeProvider theme={themeDark}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel6a-content"
                          id="panel6a-header"
                        >
                          <AssignmentTurnedIn />
                          <Typography>
                            Modelos de documentação
                          </Typography>
                        </AccordionSummary>
                      </ThemeProvider>
                      <AccordionDetails>

                        <div style={styles.examples}>
                          <a
                            href={`${apiStringServer}/docs/plano_diretor_estrutura_e_conteudo.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Plano Diretor - Estrutura e conteúdo
                          </a>
                          <a
                            href={`${apiStringServer}/docs/lei_complementar_2011_plano_diretor.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Plano Diretor - Lei complementar
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa1_macrozoneamento.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 1 - Macrozoneamento
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa2_hipsometria_hidrografia_e_subbacias_hidrograficas.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 2 - Hipsometria, Hidrografia e Sub-bacias hidrográficas
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa3_areas_protegidas.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 3 - Áreas protegidas
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa4_regioes_administrativas.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 4 - Regiões administrativas
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa5_regioes_de_planejamento.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 5 - Regiões de planejamento
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa6_uso_e_cobertura_do_solo.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 6 - Uso e cobertura do solo
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa7_rede_estrutural_viaria.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 7 - Rede estrutural viária
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa8_limites_administrativos_rarp.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 8 - Limites administrativos RARP
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa9_areas_de_intervencao_e_macrozona.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 9 - Áreas de intervenção Macrozona
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa10_areas_intervencao.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 10 - Áreas de intervenção
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa11_vetores_de_crescimento.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 11 - Vetores de crescimento
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa12_meio_ambiente.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 12 - Meio ambiente
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa13_meio_ambiente_sitios.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 13 - Meio ambiente (sítios)
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa14_saneamento_ambiental.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 14 - Saneamento ambiental
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa15_habitacao.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 15 - Habitação
                          </a>
                          <a
                            href={`${apiStringServer}/docs/mapa16_transportes.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Mapa 16 - Transportes
                          </a>
                          <a
                            href={`${apiStringServer}/docs/relatorio_2009_antecedentes_e_diagnostico.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Relatós Paraty 2009 - Antecedentes e Diagnóstico
                          </a>
                          <a
                            href={`${apiStringServer}/docs/relatorio_2009_proposta_de_politica_urbana_parte3.pdf`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Relatório 2009 - Proposta de política urbana (parte 3)
                          </a>
                          <a
                            href={`${apiStringServer}/docs/importancia_do_plano_diretor_municipal.docx`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            A iportância do Plano Diretor Municipal
                          </a>
                          <a
                            href={`${apiStringServer}/docs/atos_e_procedimentos_da_regularizacao_fundiaria.docx`}
                            style={styles.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Atos e procedimentos da regularização fundiária
                          </a>
                        </div>

                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>

              {this.state.renderTable ? (
                <div style={{ marginTop: '4.6%' }}>
                  <Table
                    history={this.props.history}
                    vars={this.state}
                    {...this.props}
                  />
                </div>
              ) : this.state.renderLog === false ? (
                <div style={{
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  zIndex: 0
                }}>
                  {this.state.projectMap === false ? (
                    <Map
                      key={'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0'}
                      google={this.props.google}
                      zoom={this.state.zoom}
                      style={styles.map}
                      mapType="map"
                      center={{
                        lat: this.state.latitude,
                        lng: this.state.longitude
                      }}
                      onClick={this.onMapClicked}
                    >

                      <Polygon
                        paths={polygonCoords_Paraty}
                        strokeColor="#001D5F"
                        strokeOpacity={0.5}
                        strokeWeight={2}
                        fillColor="#001D5F"
                        fillOpacity={0.2}
                      />

                      {this.state.completed_comunities.map((item) =>
                        <Polygon
                          paths={item}
                          strokeColor="#800"
                          strokeOpacity={0.5}
                          strokeWeight={2}
                          fillColor="#800"
                          fillOpacity={0.2}
                        />
                      )}

                      {this.state.places.map((item) =>
                        <Marker
                          icon={{
                            url: (item.active == 1) ? yellow_marker : (item.active == 2) ? green_marker : red_marker,
                            scaledSize: new window.google.maps.Size(32, 40)
                          }}
                          position={{
                            lat: item.latitude,
                            lng: item.longitude
                          }}
                          onClick={async (props, marker) => {
                            this.setState({ cpf_cnpj: item.cpf_cnpj });
                            this.setState({ name: item.name });
                            this.setState({ email: item.email });
                            this.setState({ street: item.street });
                            this.setState({ number: item.number });
                            this.setState({ district: item.district });
                            this.setState({ city: item.city });
                            this.setState({ state: item.state });
                            this.setState({ new_lat: item.latitude });
                            this.setState({ new_lng: item.longitude });
                            this.onMarkerClick(props, marker);
                          }}
                          name={item.name}
                        />
                      )}

                      <InfoWindow
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}
                      >

                        <a
                          href={`${apiString}/details?cpf_cnpj=${this.state.cpf_cnpj}&lat=${this.state.new_lat}&lng=${this.state.new_lng}&selected_city=${this.state.selected_city}&login=${this.state.login}`}
                        >
                          <Button
                            fullWidth
                            variant="text"
                            color="primary"
                            style={{ textTransform: 'none' }}
                          >
                            <Typography variant="h6" color="primary" align="left" style={{ maxWidth: 250 }}>
                              {`${this.state.name}`}
                            </Typography>
                          </Button>
                        </a>
                        <Typography variant="subtitle1" color="textSecondary" align="left" style={{ maxWidth: 250 }}>
                          {`${this.state.email}`}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary" align="left" style={{ maxWidth: 250 }}>
                          {`${this.state.street}, ${this.state.number} - ${this.state.district}, ${this.state.city} - ${this.state.state}`}
                        </Typography>

                      </InfoWindow>

                    </Map>
                  ) : (
                    <Map
                      key={'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0'}
                      google={this.props.google}
                      zoom={this.state.zoom}
                      style={styles.map}
                      mapType="map"
                      center={{
                        lat: this.state.latitude,
                        lng: this.state.longitude
                      }}
                      onClick={this.onMapClicked}
                    >

                      {/* Mapa do projeto urbanístico */}

                      <Polygon
                        paths={polygonCoords_Paraty}
                        strokeColor="#001D5F"
                        strokeOpacity={0.5}
                        strokeWeight={2}
                        fillColor="#001D5F"
                        fillOpacity={0.2}
                      />

                      {this.state.completed_comunities.map((item) =>
                        <Polygon
                          paths={item}
                          strokeColor="#800"
                          strokeOpacity={0.5}
                          strokeWeight={2}
                          fillColor="#800"
                          fillOpacity={0.2}
                        />
                      )}

                      {this.state.showHydrography && this.state.completed_hydrography.map((item) =>
                        <Polygon
                          paths={item}
                          strokeColor="#32cd32"
                          strokeOpacity={0.5}
                          strokeWeight={2}
                          fillColor="#32cd32"
                          fillOpacity={0.2}
                        />
                      )}

                      {this.state.showAppDeclividade && this.state.completed_appDeclividade.map((item) =>
                        <Polygon
                          paths={item}
                          strokeColor="#ff0000"
                          strokeOpacity={0.5}
                          strokeWeight={2}
                          fillColor="#ff0000"
                          fillOpacity={0.2}
                        />
                      )}

                      {this.state.showAppLagosLagoas && this.state.completed_appLagosLagoas.map((item) =>
                        <Polygon
                          paths={item}
                          strokeColor="#0000ff"
                          strokeOpacity={0.5}
                          strokeWeight={2}
                          fillColor="#0000ff"
                          fillOpacity={0.2}
                        />
                      )}

                      {this.state.showAppNascentes && this.state.completed_appNascentes.map((item) =>
                        <Polygon
                          paths={item}
                          strokeColor="#ff00ff"
                          strokeOpacity={0.5}
                          strokeWeight={2}
                          fillColor="#ff00ff"
                          fillOpacity={0.2}
                        />
                      )}

                      {this.state.showAppTopoDeMorro && this.state.completed_appTopoDeMorro.map((item) =>
                        <Polygon
                          paths={item}
                          strokeColor="#ffa500"
                          strokeOpacity={0.5}
                          strokeWeight={2}
                          fillColor="#ffa500"
                          fillOpacity={0.2}
                        />
                      )}

                      {this.state.showRiscoDeslizamento && this.state.completed_riscoDeslizamento.map((item) =>
                        <Polygon
                          paths={item}
                          strokeColor="#ff4500"
                          strokeOpacity={0.5}
                          strokeWeight={2}
                          fillColor="#ff4500"
                          fillOpacity={0.2}
                        />
                      )}

                      {this.state.showRiscoEnxurrada && this.state.completed_riscoEnxurrada.map((item) =>
                        <Polygon
                          paths={item}
                          strokeColor="#8b0000"
                          strokeOpacity={0.5}
                          strokeWeight={2}
                          fillColor="#8b0000"
                          fillOpacity={0.2}
                        />
                      )}

                      {this.state.showSuscetibilidadeInundacoes && this.state.completed_suscetibilidadeInundacoes.map((item) =>
                        <Polygon
                          paths={item}
                          strokeColor="#00008b"
                          strokeOpacity={0.5}
                          strokeWeight={2}
                          fillColor="#00008b"
                          fillOpacity={0.2}
                        />
                      )}

                      {this.state.showHospitals && this.state.hospitals.map((item) =>
                        <Marker
                          icon={{
                            url: hospital_marker,
                            scaledSize: new window.google.maps.Size(32, 32)
                          }}
                          position={{
                            lat: item.latitude,
                            lng: item.longitude
                          }}
                          name={`${item.unidade}`}
                          onClick={this.onMarkerClick}
                          title={`${item.unidade} - ${item.endereco}, ${item.bairro}`}
                        />
                      )}

                      {this.state.showSchools && this.state.schools.map((item) =>
                        <Marker
                          icon={{
                            url: school_marker,
                            scaledSize: new window.google.maps.Size(32, 40)
                          }}
                          position={{
                            lat: item.latitude,
                            lng: item.longitude
                          }}
                          name={`${item.unidade}`}
                          onClick={this.onMarkerClick}
                          title={`${item.unidade} - ${item.endereco}, ${item.bairro}`}
                        />
                      )}

                      {this.state.showPoliceStations && this.state.policeStations.map((item) =>
                        <Marker
                          icon={{
                            url: police_marker,
                            scaledSize: new window.google.maps.Size(32, 32)
                          }}
                          position={{
                            lat: item.latitude,
                            lng: item.longitude
                          }}
                          name={`${item.unidade}`}
                          onClick={this.onMarkerClick}
                          title={`${item.unidade} - ${item.endereco}`}
                        />
                      )}

                      {this.state.showBairros && this.state.completed_bairros.map((item) =>
                        <Polygon
                          paths={item}
                          strokeColor="#800080"
                          strokeOpacity={0.5}
                          strokeWeight={2}
                          fillColor="#800080"
                          fillOpacity={0.2}
                        />
                      )}

                    </Map>
                  )}
                  {this.state.projectMap === true ? (
                    <div style={{ position: 'absolute', top: 10, right: 50, backgroundColor: 'white', padding: 10, borderRadius: 5, display: 'flex', flexDirection: 'row' }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.showHydrography}
                              onChange={this.handleCheckboxChange}
                              name="showHydrography"
                              color="primary"
                            />
                          }
                          label="Hidrografia"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.showAppDeclividade}
                              onChange={this.handleCheckboxChange}
                              name="showAppDeclividade"
                              color="primary"
                            />
                          }
                          label="Declividade"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.showAppLagosLagoas}
                              onChange={this.handleCheckboxChange}
                              name="showAppLagosLagoas"
                              color="primary"
                            />
                          }
                          label="Lagos e Lagoas"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.showAppNascentes}
                              onChange={this.handleCheckboxChange}
                              name="showAppNascentes"
                              color="primary"
                            />
                          }
                          label="Nascentes"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.showHospitals}
                              onChange={this.handleCheckboxChange}
                              name="showHospitals"
                              color="primary"
                            />
                          }
                          label="Hospitais"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.showSchools}
                              onChange={this.handleCheckboxChange}
                              name="showSchools"
                              color="primary"
                            />
                          }
                          label="Escolas"
                        />
                      </div>
                      <Divider orientation="vertical" flexItem />
                      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.showAppTopoDeMorro}
                              onChange={this.handleCheckboxChange}
                              name="showAppTopoDeMorro"
                              color="primary"
                            />
                          }
                          label="Topo de Morro"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.showRiscoDeslizamento}
                              onChange={this.handleCheckboxChange}
                              name="showRiscoDeslizamento"
                              color="primary"
                            />
                          }
                          label="Risco de Deslizamento"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.showRiscoEnxurrada}
                              onChange={this.handleCheckboxChange}
                              name="showRiscoEnxurrada"
                              color="primary"
                            />
                          }
                          label="Risco de Enxurrada"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.showSuscetibilidadeInundacoes}
                              onChange={this.handleCheckboxChange}
                              name="showSuscetibilidadeInundacoes"
                              color="primary"
                            />
                          }
                          label="Suscetibilidade a Inundações"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.showPoliceStations}
                              onChange={this.handleCheckboxChange}
                              name="showPoliceStations"
                              color="primary"
                            />
                          }
                          label="Delegacias"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.showBairros}
                              onChange={this.handleCheckboxChange}
                              name="showBairros"
                              color="primary"
                            />
                          }
                          label="Bairros"
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<ClearAllIcon />}
                          onClick={this.handleUncheckAll}
                          style={{ fontSize: '0.875rem' }}
                        >
                          Desmarcar todos
                        </Button>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div style={{ marginTop: '5.5%' }}>
                  <Log
                    history={this.props.history}
                    vars={this.state}
                    {...this.props}
                  />
                </div>
              )}

            </div>
          </ThemeProvider>

        </div>
        <FooterHome />
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0',
  language: 'pt-BR'
})(HomeParaty)

let styles = {
  html: {
    backgroundColor: '#E5E6F0', //454545
    height: window.innerHeight,
    width: window.innerWidth,
    margin: -10,
    paddingBottom: 50,
    zIndex: 0,
  },

  directionRow: {
    display: 'flex',
    flexDirection: 'row'
  },

  directionColumn: {
    display: 'flex',
    flexDirection: 'column'
  },

  text: {
    margin: 10,
    marginTop: '30%',
    width: 335,
    backgroundColor: '#E5E6F0', //343434
    borderRadius: 5,
    zIndex: 1,
  },

  accordionDiv: {
    marginTop: 10,
    zIndex: 1,
  },

  accordion: {
    maxWidth: 350,
    backgroundColor: '#001D5F',
    marginLeft: 10,
    color: '#E5E6F0',
    borderRadius: 5,
    marginBottom: 2,
  },

  list: {
    backgroundColor: '#E5E6F0', //343434
    width: 315,
  },

  map: {
    height: window.innerHeight - 30,
    width: window.innerWidth,
    alignSelf: 'flex-start',
  },

  bt2: {
    margin: 10,
    width: '96.7%',
    textTransform: 'none',
    backgroundColor: '#E5E6F0', //343434
    color: '#343434'
  },

  examples: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#E5E6F0', //454545
    margin: 10,
    marginTop: 30,
    maxHeight: 370,
    overflowY: 'scroll',
  },

  href: {
    margin: 10,
    color: '#343434'
  },

};