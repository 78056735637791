import React from 'react';
import { Switch, Route} from 'react-router-dom';

import Login from './pages/Login';
import Register from './pages/Register';
import MobileReport from './pages/MobileReport';
import Home from './pages/Home';
import HomeRio from './pages/HomeRio';
import HomePaulo from './pages/HomePaulo';
import HomeTresRios from './pages/HomeTresRios';
import HomeArraial from './pages/HomeArraial';
import HomeCabo from './pages/HomeCabo';
import HomeBuzios from './pages/HomeBuzios';
import HomeOstras from './pages/HomeOstras';
import HomeSaquarema from './pages/HomeSaquarema';
import HomeNovaIguacu from './pages/HomeNovaIguacu';
import HomeQueimados from './pages/HomeQueimados';
import HomeSJMeriti from './pages/HomeSJMeriti';
import HomeComendador from './pages/HomeComendador';
import CitzenHome from './pages/CitzenHome';
import Details from './pages/Details';
import AddArea from './pages/AddArea';
import Areas from './pages/Areas';
import UrbanProject from './pages/UrbanProject';
import UrbanProject2 from './pages/UrbanProject2';
import Plan from './pages/Plan';

function Routes() {
  return (
    <Switch>
      <Route path='/' exact component={Login} />
      <Route path='/register' exact component={Register} />
      <Route path='/report' exact component={MobileReport} />
      <Route path='/home' exact component={Home} />
      {<Route path='/homerio' exact component={HomeRio} />}
      {<Route path='/homepaulo' exact component={HomePaulo} />}
      {<Route path='/hometresrios' exact component={HomeTresRios} />}
      {<Route path='/homearraial' exact component={HomeArraial} />}
      {<Route path='/homecabo' exact component={HomeCabo} />}
      {<Route path='/homebuzios' exact component={HomeBuzios} />}
      {<Route path='/homeostras' exact component={HomeOstras} />}
      {<Route path='/homesaquarema' exact component={HomeSaquarema} />}
      {<Route path='/homenova' exact component={HomeNovaIguacu} />}
      {<Route path='/homequeimados' exact component={HomeQueimados} />}
      {<Route path='/homesjmeriti' exact component={HomeSJMeriti} />}
      {<Route path='/homecomendador' exact component={HomeComendador} />}
      <Route path='/citzenhome' exact component={CitzenHome} />
      {<Route path='/details' exact component={Details} />}
      <Route path='/addarea' exact component={AddArea} />
      <Route path='/areas' exact component={Areas} />
      <Route path='/urbanproject' exact component={UrbanProject} />
      <Route path='/urbanproject2' exact component={UrbanProject2} />
      <Route path='/plan' exact component={Plan} />
    </Switch>
  );
}

export default Routes;